import React, { useState } from "react";
import qs from "query-string";
import { useMutation, usePaginatedQuery } from "react-query";

import {
    MainContainer,
    TitleContainer,
    MainTitle,
    ColumnContainer,
    Column,
    SimpleTable,
    ButtonsRow,
    MainButton,
    NormalPopup,
    MainForm,
    MainFormContent,
    TextInput,
    DateInput,
    Divider,
    LoadingContainer,
    SelectInput
} from "@beacharound/beacharound-ui";

import { getMemberships, createMemberships, getMembershipsPacks } from "../../../../../api/memberships";
// import { getMemberships, updateMembership, createMemberships, getMembershipsPacks } from "../../../../../api/memberships";
import { format, isBefore } from "date-fns";
import EditMembershipPopup from "../../../../../components/Memberships/EditMembershipPopup";

const message = "^Campo obbligatorio";

export default function Memberships({ history, match, location }) {
    
    const { elementId } = match.params;
    const { page } = location.search ? qs.parse(location.search) : { page: "0" };

    const [ limit, setLimit ] = useState(25);
    const [ editPopup, setEditPopup ] = useState();
    const [ newMembershipPopup, setNewMembershipPopup ] = useState();

    const options = {
        skip: Number(page) * limit,
        limit,
        sort: { name: 1 },
        params: {
            activity: elementId
        }
    }

    const { data: packs } = usePaginatedQuery(["membershipsPacks"], () =>
        getMembershipsPacks({params: {params: {}}})
    )

    const packOptions = packs?.map((p) => {
        return {
            label: `${p.name} - ${p.type}`,
            value: p._id
        }
    })

    const { data, isLoading: isLoadingMemberships } = usePaginatedQuery(["memberships", elementId, options], () =>
        getMemberships({params: options})
    )
     
    // const cache = useQueryCache()

    // const [update] = useMutation(updateMembership, {
    //     onSuccess(data) {
    //         cache.invalidateQueries(["memberships", elementId])
    //         return setEditPopup()
    //     },
    // });

    const [create, { status: createStatus }] = useMutation(createMemberships, {
        onSuccess: (data) => {
            
            return history.push(match.url.replace("create", data?._id))
        }
    })

    function addMembership(data) {
        
        return create({
            activityId: elementId,
            type: 'beaches',
            data: {
                ...data
            }
        })
    }

    const tableHead = [
        {
            text: "Pacchetto",
            key: "pack",
            flex: 0.8,
        },
        {
            text: "Tipologia",
            key: "type",
            color: (type) => ["FREE", "TRIAL"].includes(type) && "grey",
            flex: 0.8,
        },
        {
            text: "Scadenza",
            key: "expireDate",
            flex: 0.8,
            color: (date) => isBefore(new Date(date), new Date()) && "red",
            parse: (date) => `${format(new Date(date), "dd/MM/yyyy")}`,
        }, {
            text: "Prezzo",
            key: "price",
            flex: 0.5,
            parse: (price) => price ? `${Number(price).toFixed(2)} €` : '-'
        }, {
            text: "Fee",
            key: "fee",
            flex: 0.5,
            parse: (price) => price ? `${Number(price).toFixed(2)} %` : "-"
        }, {
            text: "Fisso",
            key: "fixed",
            flex: 0.5,
            parse: (price) => price ? `${Number(price).toFixed(2)} €` : "-"
        },{
            flex: 0.5,
            actions: (item) => {
                return [{
                    icon: "edit",
                    action: () => setEditPopup({...item, activityId: elementId})
                }]
            }
        }
    ]

    const tableRows = data || [];

    // async function saveMembership(data) {
        
    //     try {
    //         await update({
    //             elementId: editPopup?._id,
    //             data
    //         })
    //     } catch(error) {
    //         console.log(error)
    //     }
    // }

    return (
        <MainContainer large>

            <ColumnContainer noMargin>
                <Column column={2}>
                    <TitleContainer>
                        <MainTitle text="Memberships" />
                    </TitleContainer>
                </Column>
                <Column column={2}>
                    <ButtonsRow flexEnd>
                        <MainButton text="Nuova membership" action={() => setNewMembershipPopup(true)}/>
                    </ButtonsRow>
                </Column>
            </ColumnContainer>

            <LoadingContainer isLoading={isLoadingMemberships}>
                <ColumnContainer>
                    <Column>
                        <SimpleTable
                            match={match}
                            history={history}
                            setLimit={setLimit}
                            navigation
                            data={tableRows}
                            head={tableHead}
                            isLoading={isLoadingMemberships}
                            page={page}
                        />
                    </Column>
                </ColumnContainer>
            </LoadingContainer>

            {/* MODIFICA */}
            <EditMembershipPopup
                editPopup={editPopup}
                setEditPopup={setEditPopup}
            />

            {/* NUOVA */}
            <NormalPopup
                visible={newMembershipPopup}
                setVisible={setNewMembershipPopup}
                title="Nuova membership"
                overflow="visible"
            >
                <MainForm
                    onSubmit={addMembership}
                    constraints={{price: { presence: message}, expireDate: { presence: message}}}
                >
                    <MainFormContent flex>
                        <SelectInput
                            name="pack"
                            label="Pacchetto"
                            options={packOptions}
                        />
                        <TextInput
                            name="price"
                            label="Prezzo"
                            half
                        />
                        <DateInput
                            name="expireDate"
                            label="Scadenza"
                            half
                        />
                    </MainFormContent>

                    <Divider/>

                    <ButtonsRow fill>
                        <MainButton text="Annulla" border action={() => setEditPopup()}/>
                        <MainButton text="Crea" submit status={createStatus}/>
                    </ButtonsRow>
                </MainForm>

            </NormalPopup>
        </MainContainer>
    );
}
