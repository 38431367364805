import React, { Fragment } from 'react';
import { MainForm, MainFormContent, TextInput, NormalPopup, ButtonsRow, MainButton, Divider, SelectInput } from '@beacharound/beacharound-ui';

const constraintsGeneral = {
    name: { presence: true },
    adults: { presence: true },
};

export default function RoomPricesPopupEdit({
    prices,
    setPrices,
    status,
    pricePopup,
    setPricePopup,
    pricesOptions,
    priceType
}) {

    const isCreate = pricePopup === true ? true : false;

    async function savePrice(data) {
        try {

            let newPrices = [];
            let subPrices = Object.keys(data).filter(key => ["adults", "children", "babies"].includes(key)).reduce((acc, key) => {
                return {...acc, [key]: data[key]}
            }, {})

            if (prices) {
                newPrices = prices;
            }

            newPrices = newPrices.filter(({ index }) => index !== pricePopup.index)

            await setPrices([
                ...newPrices,
                { ...data, prices: subPrices, index: pricePopup.index || 0 },
            ])
            return setPricePopup(false);
        } catch(error) {
            console.log(error);
        }
    }

    async function deletePrice() {

        try {
            const newPrices = prices.filter(
                ({ index }) => index !== pricePopup.index
            );

            await setPrices(newPrices);
            return setPricePopup(false);
        } catch(error) {
            console.log(error);
        }
    }

    return (
        <NormalPopup
            visible={pricePopup}
            setVisible={() => setPricePopup(false)}
            title={isCreate ? "Crea pacchetto" : "Modifica pacchetto"}
        >
            <MainForm
                onSubmit={savePrice}
                data={pricePopup}
                constraints={constraintsGeneral}
            >
                <MainFormContent flex>
                    {pricesOptions ? (
                        <SelectInput
                            options={pricesOptions}
                            label="Pacchetto"
                            name="_id"
                        />
                    ) : (
                        <TextInput label="Nome pacchetto (es. Mezza pensione)" name="name" />
                    )}

                    <TextInput
                        label="Prezzo adulti"
                        name="adults"
                        type="number"
                        third
                    />
                    
                    {priceType === 'daily-each' &&
                        <Fragment>
                            <TextInput
                                label="Prezzo bambini"
                                name="children"
                                type="number"
                                third
                            />
                            <TextInput
                                label="Prezzo neonati"
                                name="babies"
                                type="number"
                                third
                            />
                        </Fragment>
                    }
                </MainFormContent>

                <Divider />

                <ButtonsRow fill>
                    <MainButton
                        text="Annulla"
                        border
                        action={() => setPricePopup(false)}
                    />
                    {pricePopup && typeof pricePopup === "object" && (
                        <MainButton
                            text="Elimina"
                            border
                            action={deletePrice}
                        />
                    )}
                    <MainButton text="Salva" submit status={status} />
                </ButtonsRow>
            </MainForm>
        </NormalPopup>
    );
}
