import React from 'react';
import { Link } from 'react-router-dom';
import { useMutation } from 'react-query';

import { ButtonsRow, Divider, MainButton, MainContainer, MainForm, MainFormContent, MainTitle, SubTitle, TextInput, TitleContainer } from '@beacharound/beacharound-ui';

import { updateUser } from "../../../api/user";

const constraints = {
    firstName: { presence: true },
    lastName: { presence: true },
    phone: { presence: true }
}

export default function SignupData({history}) {

    let buttonStatus;
    
    const [updateUserHandler, { isLoading, isError, isSuccess }] = useMutation(updateUser)

    if (isError) {
        buttonStatus = "ERROR"
    }
    if (isSuccess) {
        buttonStatus = "SUCCESS"
        history.push("/user/signup/activation")
    }
    if (isLoading) {
        buttonStatus = "LOADING"
    }

    function signupHandler(data) {
        updateUserHandler(data)
    }
    
    return (

        <MainContainer>

            <TitleContainer>
                <MainTitle text="Registrazione utente" />
                <SubTitle text="Step 2: dati principali" />
            </TitleContainer>

            <MainForm onSubmit={signupHandler} constraints={constraints}>
                <MainFormContent flex>
                    <TextInput name="firstName" label="Nome" placeholder="Il tuo nome" half/>
                    <TextInput name="lastName" label="Cognome" placeholder="Il tuo cognome" half/>
                    <TextInput name="phone" label="Numero di telefono" placeholder="Il tuo numero" half/>
                </MainFormContent>

                <Divider />

                <ButtonsRow spaceBetween>
                    <MainButton border text="Indietro" component={Link} to="/user/signup" />
                    <MainButton text="Prosegui" submit status={buttonStatus}/>
                </ButtonsRow>

            </MainForm>

        </MainContainer>
    )
}
