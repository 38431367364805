import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from "react-query";

import {
    MainContainer,
    TitleContainer,
    MainTitle,
    SubTitle,
    SectionTitle,
    MainFormContent,
    SimpleField,
    LoadingContainer,
    Column,
    Divider,
    ColumnContainer
} from "@beacharound/beacharound-ui";

import StatusHistory from '../../../components/Status/StatusHistory';
import { getActivityTransaction } from '../../../api/transactions';
import DetailCustomerSection from '../../../components/Users/DetailCustomerSection';
import { formatDate } from '@beacharound/beacharound-ui-helpers';
import TransactionsActions from '../../../components/Transactions/TransactionsActions';


export default function TransactionsDetail({ match, history }) {

    const { elementId, transactionId } = match.params;
    
    const { data: transaction, isLoading } = useQuery(["transactions", transactionId], () =>
        getActivityTransaction({elementId: transactionId, activityId: elementId})
    );

    return (

        <MainContainer>
            <ColumnContainer lowMargin>
                <Column column={2}>
                    <TitleContainer>
                        <MainTitle text="Dettaglio transazione" />
                        <SubTitle text="< Torna alle transazioni" component={Link} to={match.url.replace(`/${transactionId}`, "")}/>
                    </TitleContainer>
                </Column>
                <Column column={2}>
                    <TransactionsActions
                        match={match}
                        history={history}
                        flexEnd
                        transaction={transaction}
                    />
                </Column>
            </ColumnContainer>

            <LoadingContainer isLoading={isLoading}>
                <DetailCustomerSection customer={transaction?.paidBy} />

                <TitleContainer>
                    <SectionTitle text="Informazioni" />
                </TitleContainer>
                <Column>
                    <MainFormContent flex>
                        <SimpleField
                            label="Pagata il"
                            value={formatDate(transaction?.paidAt, 'dd/MM/yyyy')}
                            half
                        />
                        <SimpleField
                            label="Totale"
                            value={transaction?.amount}
                            half
                        />
                        <SimpleField
                            label="Dovuto alla spiagga"
                            value={transaction?.payout}
                            half
                        />
                        <SimpleField
                            label="Fee trattenuta"
                            value={transaction?.application}
                            half
                        />
                        <SimpleField
                            label="Status"
                            value={transaction?.status}
                            half
                        />
                        <SimpleField
                            label="Prenotazione"
                            value={transaction?.reservation}
                            half
                        />
                    </MainFormContent>
                </Column>

                <TitleContainer>
                    <SectionTitle text="Informazioni trasferimento" />
                </TitleContainer>

                <Column>
                    <MainFormContent flex>
                        <SimpleField
                            label="Percentuale della fee"
                            value={transaction?.fee}
                            half
                        />
                        <SimpleField
                            label="Fisso della fee"
                            value={transaction?.fixedFee}
                            half
                        />
                        <SimpleField
                            label="Tipologia"
                            value={transaction?.type}
                            half
                        />
                        <SimpleField
                            label="Metodo"
                            value={transaction?.method}
                            half
                        />
                        <SimpleField
                            label="Gateway"
                            value={transaction?.gateway}
                            half
                        />
                        <SimpleField
                            label="Riferimento gateway"
                            value={transaction?.gatewayId}
                            half
                        />
                    </MainFormContent>
                </Column>

                <Divider />

                <MainContainer>
                    <LoadingContainer isLoading={isLoading}>
                        <StatusHistory item={transaction} />
                    </LoadingContainer>
                </MainContainer>

            </LoadingContainer>

        </MainContainer>

    )
}