import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";

import { getUser } from "../../../api/users/users";

import {
    MainContainer,
    MainForm,
    MainFormContent,
    SectionTitle,
    TitleContainer,
    MainTitle,
    SubTitle,
    FormToggleGroup,
    FormToggleGroupPreview,
    SimpleFormField,
    FormToggleGroupEdit,
    TextInput,
    LoadingContainer,
    Column,
} from "@beacharound/beacharound-ui";

export default function Detail({ match }) {
    const { elementId } = match.params;
    const { data, isLoading } = useQuery(["users", "admin", elementId], () =>
        getUser({ role: "admin", elementId })
    );

    // const cache = useQueryCache()
    // const [update] = useMutation(updateInformationsRequest, {
    //     onSuccess: () => cache.invalidateQueries('informationsRequests')
    // })

    return (
        <MainContainer marginBottom>
            <TitleContainer>
                <MainTitle text="Dettaglio utente admin" />
                <SubTitle
                    text="< Torna alla lista"
                    component={Link}
                    to={`${match.url.replace(`/${elementId}`, "")}`}
                />
            </TitleContainer>

            <LoadingContainer isLoading={isLoading}>
                <TitleContainer>
                    <SectionTitle text="Dati generali" />
                </TitleContainer>

                <MainForm data={data?.profile}>
                    <MainFormContent>
                        <FormToggleGroup>
                            <FormToggleGroupPreview>
                                <SimpleFormField
                                    label="Nome"
                                    name="firstName"
                                    half
                                />
                                <SimpleFormField
                                    label="Nome"
                                    name="lastName"
                                    half
                                />
                            </FormToggleGroupPreview>

                            <FormToggleGroupEdit flex>
                                <TextInput label="Nome" name="firstName" half />
                                <TextInput label="Nome" name="lastName" half />
                            </FormToggleGroupEdit>
                        </FormToggleGroup>
                    </MainFormContent>
                </MainForm>

                <TitleContainer>
                    <SectionTitle text="Contatti" />
                </TitleContainer>

                <Column>
                    <MainForm data={data?.emails && data?.emails[0]}>
                        <MainFormContent flex>
                            <SimpleFormField
                                label="Indirizzo email"
                                name="address"
                                half
                            />
                            <SimpleFormField
                                label="Verificata"
                                name="verified"
                                half
                            />
                        </MainFormContent>
                    </MainForm>
                </Column>
            </LoadingContainer>
        </MainContainer>
    );
}
