import axios from './_defaults/user';

export async function getProductsTypes({params}) {

    try {
        const response = await axios.get("/admin-products-types", {
            params,
        });

        if (response.data) {
            return response.data;
        }
    } catch(error) {
        throw error;
    }
}

export async function updateProductType({elementId, data}) {

    try {
        const response = await axios.post(`/admin-products-types/${elementId}`, data);

        if (response.data) {
            return response.data;
        }
    } catch(error) {
        throw error;
    }
}