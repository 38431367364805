export function getSubFormData(data, keys) {

    let payload = {};

    if(!Array.isArray(keys)) {
        console.error("Nessuna chiave passata");
        return payload;
    }

    keys.map(key => {
        if(data[key]) {
            payload[key] = data[key];
        }
        return key
    });

    return payload;
}