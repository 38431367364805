import axios from './_defaults/user';

export async function createOrganization({data}) {
    const response = await axios.post("/admin-organizations", data);

    if (response.data) {
        return response.data
    }

    return response
}

export async function updateOrganization({ data, elementId }) {
    const response = await axios.post(`/admin-organizations/${elementId}`, data);

    if (response.data) {
        return response.data
    }

    return response
}

export async function getOrganizations({params}) {

    try {
        const response = await axios.get(`/admin-organizations`, { params });

        if (response.data) {
            return response.data;
        }
        
    } catch(error) {

        if (error?.response?.status === 404) {
            return [];
        }

        throw error
    }
}

export async function getOrganization({elementId}) {
    const response = await axios.get(`/admin-organizations/${elementId}`);

    if (response.data) {
        return response.data
    }

    return response
}

export async function connectActivityToOrganization({elementId, activityId, activityType, membershipsPacks}) {
    try {
        const response = await axios.post(
            `/admin-organizations/${elementId}/activities`,
            {
                activityId,
                activityType,
                membershipsPacks,
            }
        );

        return response?.data;
    } catch(error) {
        console.log(error);
    }
}

export async function disconnectActivityToOrganization({elementId, activityId, activityType}) {
    try {
        const response = await axios.delete(`/admin-organizations/${elementId}/activities`, {
            params: {
                activityType,
                activityId
            }
        });

        return response?.data;
    } catch(error) {
        console.log(error);
    }
}

export async function connectUserToOrganization({elementId, userId, role}) {
    try {
        const response = await axios.post(
            `/admin-organizations/${elementId}/users`,
            {
                userId,
                role,
            }
        );

        return response?.data;
    } catch(error) {
        console.log(error);
    }
}

export async function disconnectUserToOrganization({elementId, userId, role}) {
    try {
        const response = await axios.delete(
            `/admin-organizations/${elementId}/users`,
            {
                params: {
                    userId,
                    role,
                }
            }
        );

        return response?.data;
    } catch(error) {
        console.log(error);
    }
}