import React, { useState } from "react";
import qs from "query-string";
import { Link } from "react-router-dom";
import { usePaginatedQuery } from "react-query";

import {
    MainContainer,
    TitleContainer,
    MainTitle,
    ColumnContainer,
    Column,
    SimpleTable,
    ButtonsRow,
    MainButton
} from "@beacharound/beacharound-ui";

import { getOrganizations } from "../../../api/organizations";

export default function List({ history, match, location }) {
    
    const { page } = location.search ? qs.parse(location.search) : { page: "0" };

    const [ limit, setLimit ] = useState(25);
    const [ search, setSearch ] = useState();
    const [ params, setParams ] = useState({}); // eslint-disable-line no-unused-vars

    const options = {
        skip: Number(page) * limit,
        limit,
        sort: { name: 1 },
        search,
        params: {
            ...params
        }
    }
    
    const { data, isLoading } = usePaginatedQuery(["organizations", options], () =>
        getOrganizations({params: options})
    )

    const tableHead = [{
        text: "Nome",
        key: "name",
        sort: "text",
        highlight: true,
        default: true
    }, {
        flex: 0.5,
        actions: (item) => {
            return [
                {
                    icon: "edit",
                    component: Link,
                    to: `${match.url}/${item._id}`,
                }
            ]
        }
    }]

    const tableRows = data?.map(item => {
        return {
            ...item
        }
    }) || [];

    return (
        <MainContainer large>

            <ColumnContainer noMargin>
                <Column column={2}>
                    <TitleContainer noMargin>
                        <MainTitle text="Organizzazioni" />
                    </TitleContainer>
                </Column>
                <Column column={2}>
                    <ButtonsRow flexEnd noMargin>
                        <MainButton
                            text="Nuova organizzazione"
                            component={Link}
                            to={`${match.url}/create`}
                        />
                    </ButtonsRow>
                </Column>
            </ColumnContainer>
            

            <ColumnContainer>
                <Column>
                    <SimpleTable
                        match={match}
                        history={history}
                        setLimit={setLimit}
                        navigation
                        // showFilters
                        data={tableRows}
                        head={tableHead}
                        // availableFilters={availableFilters}
                        isLoading={isLoading}
                        page={page}
                        searched={search}
                        onSearch={(value) => {
                            setSearch(value);
                            history.push(match.url);
                        }}
                        onSearchClear={() => {
                            setSearch(undefined);
                            history.push(match.url);
                        }}
                    />
                </Column>
            </ColumnContainer>
        </MainContainer>
    );
}
