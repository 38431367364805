import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery, useMutation, useQueryCache } from 'react-query';
import Select from 'react-select';

import { MainContainer, TitleContainer, MainTitle, MainForm, MainFormContent, TextInput, SelectInput, SelectImageInput, ButtonsRow, MainButton, Divider, SectionTitle } from '@beacharound/beacharound-ui';

import { getProductsTypes } from '../../../../../api/productsTypes';
import { createProduct } from '../../../../../api/products';

import { getProductsOptions } from '../../../../../helpers/productsTypes';

export default function DeckchairsAdd({ match, history }) {

    const {elementId, type} = match.params;
    const [isHalfDay, setIsHalfDay] = useState()

    const constraints = {
        name: { presence: true },
        type: { presence: true },
        quantity: { presence: true },
        halfDay: { presence: true },
        daily: { presence: true },
        morning: { presence: isHalfDay},
        afternoon: { presence: isHalfDay},
    }

    // Tipologie di lettini da scegliere
    const { data: productsTypes } = useQuery(['productsTypes', "deckchairs"],
        () => getProductsTypes({params:{params:{type: "deckchair"}}})
    )

    const cache = useQueryCache()
    const [create, { status }] = useMutation(createProduct, {
        onSuccess: (data) => {
            cache.setQueryData(['product', data._id], data)
            cache.invalidateQueries('deckchairs')
            return history.push(match.url.replace("create", data?._id))
        }
    })

    async function saveNewProduct(data) {
        try {
            await create({ data, elementId })
        } catch(error) {
            console.log(error)
        }
    }

    function changeHalfDay(name, value) {
        return setIsHalfDay(value || false)
    }

    const booleanOptions = [{ value: true, label: 'Si' }, { value: false, label: 'No' }]

    return (
        <MainContainer>
            <TitleContainer>
                <MainTitle text="Nuovo lettino" />
            </TitleContainer>

            <MainForm onSubmit={saveNewProduct} constraints={constraints}>
                <TitleContainer>
                    <SectionTitle text="Dati principali" />
                </TitleContainer>
                <MainFormContent flex>
                    <SelectImageInput
                        Component={Select}
                        label="Tipologia elemento"
                        name="type"
                        options={getProductsOptions(productsTypes)}
                        placeholder="Scegli la tipologia"
                    />
                    <TextInput
                        label="Nome prodotto"
                        name="name"
                        half
                    />
                    <TextInput
                        type="number"
                        name="quantity"
                        label="Totale elementi"
                        half
                    />
                </MainFormContent>
                
                <Divider />

                <TitleContainer>
                    <SectionTitle text="Prezzi base" />
                </TitleContainer>
                <MainFormContent flex>
                    <SelectInput
                        Component={Select}
                        label="Disponibilità mezza giornata"
                        name="halfDay"
                        options={booleanOptions}
                        half
                        onChangeInput={changeHalfDay}
                    />
                    <TextInput
                        type="number"
                        label="Prezzo giornaliero base"
                        name="daily"
                        half
                    />

                    {isHalfDay && (
                        <React.Fragment>
                            <TextInput
                                type="number"
                                label="Prezzo solo mattina"
                                name="morning"
                                half
                            />
                            <TextInput
                                type="number"
                                label="Prezzo solo pomeriggio"
                                name="afternoon"
                                half
                            />
                        </React.Fragment>
                    )}
                </MainFormContent>

                <Divider />

                <ButtonsRow fill>
                    <MainButton
                        text="Annulla"
                        border
                        component={Link}
                        to={`/activities/beaches/${type}/${elementId}/deckchairs`}
                    />
                    <MainButton text="Crea prodotto" submit status={status} />
                </ButtonsRow>
            </MainForm>
        </MainContainer>
    );
}
