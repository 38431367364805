import MainLayout from '../layouts/MainLayout/MainLayout';

// Places

import Users from '../pages/Users/index.js';

// SubRoutes
import { subRoutesUsersCustomer, routesUsersCustomer } from "./users/usersCustomer";
import {subRoutesUsersPublicAdministration, routesUsersPublicAdministration} from './users/usersPublicAdministration';
import { subRoutesUsersBusiness, routesUsersBusiness } from "./users/usersBusiness";

export default [
    ...routesUsersCustomer,
    ...routesUsersPublicAdministration,
    ...routesUsersBusiness,
    {
        path: "",
        component: Users,
        layouts: MainLayout,
        routes: [
            ...subRoutesUsersCustomer,
            ...subRoutesUsersPublicAdministration,
            ...subRoutesUsersBusiness,
        ].map((item) => {
            return {
                ...item,
                path: `/users${item.path}`,
                private: true
            };
        }),
    },
].map((item) => {
    return {
        ...item,
        path: `/users${item.path}`,
        private: true
    };
});