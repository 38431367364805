import List from '../../pages/CostumerCare/Reports/List';
import Detail from '../../pages/CostumerCare/Reports/Detail';

export const subRoutesReports = [
    {
        path: "",
        component: List,
        exact: true,
    }
].map((item) => {
    return {
        ...item,
        path: `/reports${item.path}`,
        private: true
    };
});

export const routesReports = [
    {
        path: "/:elementId",
        component: Detail,
    }
].map((item) => {
    return {
        ...item,
        path: `/reports${item.path}`,
        private: true
    };
});