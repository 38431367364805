import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useMutation, useQueryCache } from "react-query";

import {
    MainContainer,
    TitleContainer,
    MainTitle,
    MainForm,
    MainFormContent,
    TextInput,
    TextAreaInput,
    GooglePlacesInput,
    ButtonsRow,
    MainButton,
    Divider,
    SectionTitle,
    SelectInput,
} from "@beacharound/beacharound-ui";

import { pointsOfInterestTypes } from "@beacharound/beacharound-ui-helpers";

import { createPointsOfInterest } from "../../../api/pointsOfInterests";

const constraints = {
    name: { presence: true },
    description: { presence: true },
    address: { presence: true }
}

export default function Create({ match, history }) {

    const cache = useQueryCache();
    const [published, setPublished] = useState();

    const [create, { status }] = useMutation(createPointsOfInterest, {
        onSuccess: (data) => {
            cache.invalidateQueries("pointsOfInterest")
            cache.setQueryData(["pointsOfInterest", data._id], data)
            return history.push(match.url.replace("create", data?._id))
        }
    })

    function save(data) {
       
        return create({ data: { ...data, published } });
    }

    return (
        <MainContainer marginBottom>
            <TitleContainer>
                <MainTitle text="Nuovo punto di interesse" />
            </TitleContainer>

            <MainForm onSubmit={save} constraints={constraints}>
                <TitleContainer>
                    <SectionTitle text="Dati generali" />
                </TitleContainer>
                <MainFormContent flex>
                    <TextInput label="Nome luogo" name="name" half />
                    <SelectInput
                        label="Tipologia"
                        name="type"
                        options={pointsOfInterestTypes}
                        placeholder="Scegli tipologia"
                        half
                    />
                    <TextInput label="Sottotitolo" name="subtitle" />
                    <TextAreaInput label="Descrizione" name="description" />
                </MainFormContent>

                <Divider />

                <TitleContainer>
                    <SectionTitle text="Indirizzo" />
                </TitleContainer>

                <MainFormContent flex>
                    <GooglePlacesInput
                        name="address"
                        label="Indirizzo"
                        zoom={16}
                        required
                    />
                </MainFormContent>

                <Divider />

                <ButtonsRow fill marginBottom>
                    <MainButton
                        text="Annulla"
                        border
                        component={Link}
                        to={`/places/pointsOfInterest`}
                    />
                    <MainButton
                        border
                        submit
                        actionOnSubmit={() => setPublished(false)}
                        text="Salva bozza"
                    />
                    <MainButton
                        text="Crea punto"
                        submit
                        status={status}
                        actionOnSubmit={() => setPublished(true)}
                    />
                </ButtonsRow>
            </MainForm>
        </MainContainer>
    );
}
