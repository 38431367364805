import React from 'react';
import { Link } from 'react-router-dom';
import { useMutation, useQueryCache } from 'react-query';

import { ButtonsRow, Divider, MainButton, MainContainer, MainForm, MainFormContent, MainTitle, SubTitle, TextInput, TitleContainer } from '@beacharound/beacharound-ui';

import { createUser } from "../../../api/user";

const constraints = {
    email: {
        presence: true,
        email: true
    },
    password: {
        presence: true,
        length: {
            minimum: 8
        }
    },
    passwordConfirm: {
        presence: true,
        equality: 'password'
    }
}

export default function SignupAccount({history}) {

    const cache = useQueryCache()
    const [addUser, { status }] = useMutation(createUser, {
        onSuccess: () => {
            cache.invalidateQueries('user')
            history.push("/user/signup/data");
        }
    })

    return (

        <MainContainer>

            <TitleContainer>
                <MainTitle text="Registrazione utente" />
                <SubTitle text="Step 1: account" />
            </TitleContainer>

            <MainForm onSubmit={addUser} constraints={constraints}>
                <MainFormContent flex>
                    <TextInput name="email" label="Email" placeholder="La tua email"/>
                    <TextInput type="password" name="password" autocomplete="new-password" label="Password" placeholder="Inserisci la tua password" half/>
                    <TextInput type="password" name="passwordConfirm" autocomplete="new-password" label="Conferma la password" placeholder="Per sicurezza scrivila di nuovo" half/>
                </MainFormContent>

                <Divider />

                <ButtonsRow spaceBetween>
                    <MainButton border text="Torna al login" component={Link} to="/user/login" />
                    <MainButton text="Prosegui" submit status={status} />
                </ButtonsRow>

            </MainForm>

        </MainContainer>
    )
}
