import axios from "../_defaults/user";
import { uploadImage } from '../images';

export async function createArticle({ data }) {
    try {
        const response = await axios.post("/admin-blog/articles", data);
    
        if (response.data) {
            return response.data;
        }
    } catch(error) {
        if(error.response.status === 404) {
            return {}
        }
        throw error;
    }
}

export async function updateArticle({ data, elementId }) {
    try {
        const response = await axios.post(`/admin-blog/articles/${elementId}`, data);
    
        if (response.data) {
            return response.data;
        }
    } catch(error) {
        if(error.response.status === 404) {
            return {}
        }
        throw error;
    }
}

export async function getArticles({ params }) {
    try {
        const response = await axios.get(`/admin-blog/articles`, {params});
    
        if (response.data) {
            return response.data;
        }
    } catch(error) {
        if(error.response.status === 404) {
            return []
        }
        throw error;
    }
}

export async function getArticle({ elementId, params }) {
    try {
        const response = await axios.get(`/admin-blog/articles/${elementId}`, {params});
    
        if (response.data) {
            return response.data;
        }
    } catch(error) {
        if(error.response.status === 404) {
            return {}
        }
        throw error;
    }
}

export async function getPutUrl({ elementId, file }) {
    try {
        const response = await axios.post(`/admin-blog/articles/${elementId}/images`, {
            contentType: file.type,
            extension: file.name.split(".")[1],
        });
    
        if (response.data) {
            return response.data;
        }
        
    } catch (error) {
        throw error;
    }
}

export async function updateImage({ elementId, file }) {
    try {
        const { url, key } = await getPutUrl({ elementId, file });

        await uploadImage({ url, file });

        const response = await updateArticle({
            elementId,
            data: {
                cover: {
                    url: key,
                },
            },
        });

        if (response.data) {
            return response.data;
        }

        return response;
    } catch (error) {
        throw error;
    }
}

export async function updateArticleStep({ data, elementId, stepId }) {
    try {
        const response = await axios.post(`/admin-blog/articles/${elementId}/steps/${stepId}`, data);
    
        if (response.data) {
            return response.data;
        }
    } catch(error) {
        if(error.response.status === 404) {
            return {}
        }
        throw error;
    }
}

export async function updateStepImage({ elementId, stepId, file }) {
    try {
        const { url, key } = await getPutUrl({ elementId, file });

        await uploadImage({ url, file });

        const response = await updateArticleStep({
            elementId,
            stepId,
            data: {
                cover: {
                    url: key,
                },
            },
        });

        if (response.data) {
            return response.data;
        }

        return response;
    } catch (error) {
        throw error;
    }
}