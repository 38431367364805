import React from "react";
import { Link } from "react-router-dom";
import { useMutation, useQuery, useQueryCache } from "react-query";

import {
    MainContainer,
    MainForm,
    MainFormContent,
    SectionTitle,
    TitleContainer,
    MainTitle,
    SubTitle,
    FormToggleGroup,
    FormToggleGroupPreview,
    SimpleFormField,
    FormToggleGroupEdit,
    TextAreaInput,
    ImageInput,
    GooglePlacesInput,
    LoadingContainer,
    TextInput,
    ColumnContainer,
    Column,
    ToggleInput
} from "@beacharound/beacharound-ui";

import { getLocation, updateLocation } from "../../../api/locations";
import { getSubFormData } from "@beacharound/beacharound-ui-helpers";

export default function Detail({ match }) {
    const { elementId } = match.params;
    const { data, isLoading } = useQuery(["regions", elementId], () =>
        getLocation({ type: "regions", elementId })
    );

    const cache = useQueryCache();
    const [update, {status: updateStatus, reset}] = useMutation(updateLocation, {
        onSuccess: () => cache.invalidateQueries(["regions", elementId]),
    });

    async function editElement(data, callback) {
        try {
            await update({
                type: "regions",
                elementId,
                data
            })
            return callback(reset)
        } catch(error) {
            return callback(null, error)
        }
    }

    async function editGeodata(data) {
        try {
            await update({
                elementId,
                data,
            });
        } catch(error) {
            throw error;
        }
    }

    return (
        <MainContainer marginBottom>

            <ColumnContainer>
                <Column column={2}>
                    <TitleContainer noMargin>
                        <MainTitle text={data?.name || "Gestisci regione"} />
                        <SubTitle
                            text="< Torna alla lista"
                            component={Link}
                            to={`/places/regions`}
                        />
                    </TitleContainer>
                </Column>

                <Column column={2}>
                    <ToggleInput
                        flexEnd
                        trueLabel="Pubblicato"
                        falseLabel="Non pubblicato"
                        status={updateStatus}
                        changeValue={()=> editElement({published: !data?.published}, (resetAfter) => resetAfter())}
                        value={data?.published}
                    />
                </Column>
            </ColumnContainer>

            <LoadingContainer isLoading={isLoading}>
                <TitleContainer>
                    <SectionTitle text="Dati generali" />
                </TitleContainer>

                <MainForm
                    onSubmit={editElement}
                    data={getSubFormData(data, ["description", "subtitle"])}
                >
                    <MainFormContent>
                        <FormToggleGroup>
                            <FormToggleGroupPreview>
                                <SimpleFormField
                                    label="Sottotitolo"
                                    name="subtitle"
                                />
                                <SimpleFormField
                                    label="Descrizione"
                                    name="description"
                                />
                            </FormToggleGroupPreview>

                            <FormToggleGroupEdit flex status={updateStatus}>
                                <TextInput
                                    label="Sottotitolo"
                                    name="subtitle"
                                />
                                <TextAreaInput
                                    label="Descrizione"
                                    name="description"
                                />
                            </FormToggleGroupEdit>
                        </FormToggleGroup>
                    </MainFormContent>
                </MainForm>

                <TitleContainer>
                    <SectionTitle text="Immagine" />
                </TitleContainer>

                <MainForm onSubmit={editElement} data={data}>
                    <MainFormContent>
                        <FormToggleGroup>
                            <FormToggleGroupPreview>
                                <SimpleFormField
                                    label="Immagine"
                                    name="image"
                                />
                            </FormToggleGroupPreview>

                            <FormToggleGroupEdit flex status={updateStatus}>
                                <ImageInput name="image" label="Immagine" />
                            </FormToggleGroupEdit>
                        </FormToggleGroup>
                    </MainFormContent>
                </MainForm>

                <TitleContainer>
                    <SectionTitle text="Posizione" />
                </TitleContainer>

                <MainForm onSubmit={editGeodata} data={data?.geocode}>
                    <MainFormContent>
                        <FormToggleGroup>
                            <FormToggleGroupPreview>
                                <SimpleFormField
                                    label="Coordinate"
                                    name="coordinates"
                                    half
                                />
                            </FormToggleGroupPreview>

                            <FormToggleGroupEdit flex status={updateStatus}>
                                <GooglePlacesInput
                                    name="coordinates"
                                    label="Indirizzo"
                                    placeholder="Cerca un luogo"
                                    height="370px"
                                    coordsOnly
                                    required
                                />
                            </FormToggleGroupEdit>
                        </FormToggleGroup>
                    </MainFormContent>
                </MainForm>
            </LoadingContainer>
        </MainContainer>
    );
}
