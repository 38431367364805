import React, { useState } from 'react';
import { useQuery, useMutation, useQueryCache } from 'react-query';
import Select from 'react-select';
import { Link } from 'react-router-dom';

import { getSubFormData } from '@beacharound/beacharound-ui-helpers';

import {
    MainContainer,
    MainForm,
    MainFormContent,
    SectionTitle,
    TitleContainer,
    MainTitle,
    SubTitle,
    FormToggleGroup,
    FormToggleGroupPreview,
    SimpleFormField,
    FormToggleGroupEdit,
    TextInput,
    SelectInput,
    LoadingContainer,
    ButtonsRow,
    MainButton,
    NormalPopup,
    Paragraph,
    Divider
} from '@beacharound/beacharound-ui';

import { getProduct, updateProduct, deleteProduct } from '../../../../../api/products';
import { getProductsElement } from '../../../../../helpers/products';

import ProductVariations from '../../../../../components/Beach/Catalog/ProductVariations'

const constraintsGeneral = {
    name: { presence: true },
    quantity: { presence: true }
}

const constraintsPrice = {
    priceType: { presence: true },
    price: { presence: true }
}

export default function ServicesDetail({ match, history }) {

    const { elementId, productId, type } = match.params;
    const [deletePopup, setDeletePopup] = useState();

    const { data: productData, isLoading } = useQuery(['products', 'service', elementId, productId],
        () => getProduct({ productId, elementId })
    )

    // CACHE
    const cache = useQueryCache()
    const [update, { status: updateStatus, reset }] = useMutation(updateProduct, {
        onSuccess: (data) => {
            cache.invalidateQueries(['products', "service", elementId, productId])
            cache.setQueryData(['serviceProduct', elementId, productId], data)
            history.push(match.url.replace(`/${elementId}`, ""))
        }
    })
    const [remove, { status: removeStatus }] = useMutation(deleteProduct, {
        onSuccess: () => {
            cache.invalidateQueries(['product', 'service', elementId])
            history.push(match.url.replace(`/${elementId}`, ""))
        }
    })

    const product = getProductsElement(productData);

    async function editProduct(data, callback) {
        try {
            await update({
                productId,
                elementId,
                data
            });
            return callback(reset)
        } catch (error) {
            return callback(null, error);
        }
    }

    async function deleteElement() {
        try {
            await remove({ elementId, productId })
            return setDeletePopup(false)
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <React.Fragment>
            {product &&
                <MainContainer>

                    <TitleContainer>
                        <MainTitle text="Gestisci servizio" />
                        <SubTitle text="< Torna alla lista" component={Link} to={`/activities/beaches/${type}/${elementId}/services`} />
                    </TitleContainer>

                    <LoadingContainer isLoading={isLoading}>

                        <TitleContainer>
                            <SectionTitle text="Dati principali" />
                        </TitleContainer>

                        <MainForm
                            onSubmit={editProduct}
                            data={getSubFormData(product, ["name", "quantity"])}
                            constraints={constraintsGeneral}
                        >
                            <MainFormContent>
                                <FormToggleGroup>
                                    <FormToggleGroupPreview>
                                        <SimpleFormField
                                            label="Nome servizio"
                                            name="name"
                                            half
                                        />
                                        <SimpleFormField
                                            label="Quantità elementi"
                                            name="quantity"
                                            half
                                        />
                                    </FormToggleGroupPreview>

                                    <FormToggleGroupEdit flex status={updateStatus}>
                                        <TextInput
                                            label="Nome servizio"
                                            name="name"
                                            half
                                        />
                                        <TextInput
                                            label="Quantità elementi"
                                            name="quantity"
                                            half
                                        />
                                    </FormToggleGroupEdit>
                                </FormToggleGroup>
                            </MainFormContent>
                        </MainForm>

                        <TitleContainer>
                            <SectionTitle text="Prezzi base" />
                        </TitleContainer>

                        <MainForm
                            onSubmit={editProduct}
                            data={getSubFormData(product, ["priceType", "price"])}
                            constraints={constraintsPrice}
                        >
                            <MainFormContent>
                                <FormToggleGroup>
                                    <FormToggleGroupPreview>
                                        <SimpleFormField
                                            label="Tipologia di prezzo"
                                            name="priceType"
                                            options={[{ value: 'HOURLY', label: 'Prezzo orario' }, { value: 'DAILY', label: 'Prezzo giornaliero' }]}
                                            half
                                        />
                                        <SimpleFormField
                                            label="Prezzo orario"
                                            name="price"
                                            half
                                            price
                                        />
                                    </FormToggleGroupPreview>

                                    <FormToggleGroupEdit flex status={updateStatus}>
                                        <SelectInput
                                            Component={Select}
                                            label="Tipologia di prezzo"
                                            placeholder="Scegli tipologia"
                                            name="priceType"
                                            options={[{ value: 'HOURLY', label: 'Prezzo orario' }, { value: 'DAILY', label: 'Prezzo giornaliero' }]}
                                            half
                                        />
                                        <TextInput
                                            label="Prezzo orario"
                                            name="price"
                                            half
                                        />
                                    </FormToggleGroupEdit>
                                </FormToggleGroup>
                            </MainFormContent>
                        </MainForm>

                        <ProductVariations productId={productId} elementId={elementId} queryId="serviceVariations" />

                        <Divider />

                        <ButtonsRow spaceBetween>
                            <MainButton border text="Elimina servizio" action={() => setDeletePopup(true)} />
                        </ButtonsRow>
                    </LoadingContainer>

                    <NormalPopup visible={deletePopup} title="Elimina servizio" setVisible={() => setDeletePopup(false)}>
                        <TitleContainer>
                            <Paragraph text="Sei sicuro di voler eliminare questo servizio?" />
                        </TitleContainer>
                        <Divider />
                        <ButtonsRow spaceBetween>
                            <MainButton border text="Annulla" action={() => setDeletePopup(false)} />
                            <MainButton text="Conferma" action={deleteElement} status={removeStatus} />
                        </ButtonsRow>
                    </NormalPopup>

                </MainContainer>
            }
        </React.Fragment>
    );
}
