import React from 'react';
import Cookies from 'js-cookie';
import { Redirect, Route } from "react-router-dom";
import { useQueryCache } from 'react-query'

import { hasRoles } from '@beacharound/beacharound-ui-helpers';

import MainLayout from '../../layouts/MainLayout/MainLayout';

import { decodeUser } from '../../api/user';

export default function MainRoute(route) {

    const cache = useQueryCache();
    const LayoutComponent = route.layout || MainLayout;

    return (
        <LayoutComponent options={route.layoutOptions}>
            <Route
                exact={route.exact}
                path={route.path}
                render={props => {
                    if (route.private) {
                        const token = Cookies.get('user-authorization');
                        const refreshToken = Cookies.get('user-refreshToken');
                        const userId = Cookies.get('user-id');

                        if (!token && !refreshToken && !userId) {
                            cache.invalidateQueries();
                            return <Redirect to="/user/login" />;
                        }

                        const { roles } = decodeUser();

                        if (!roles || !hasRoles(roles, "admin")) {
                            cache.invalidateQueries();
                            return <Redirect to={`/user/signup/activation?backUrlAfterActivation=${route.location?.pathname}`} />;
                        }
                    }
                    return <route.component {...props} {...route.props} routes={route.routes} />;
                }}
            />
        </LayoutComponent>
    );
}