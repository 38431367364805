import List from '../../pages/CostumerCare/Requests/List';
import Detail from '../../pages/CostumerCare/Requests/Detail';

export const subRoutesRequests = [
    {
        path: "/:type",
        component: List,
        exact: true,
    }
].map((item) => {
    return {
        ...item,
        path: `/informationsRequests${item.path}`,
        private: true
    };
});

export const routesRequests = [
    {
        path: "/:elementId",
        component: Detail,
    }
].map((item) => {
    return {
        ...item,
        path: `/informationsRequests/:type${item.path}`,
        private: true
    };
});