const categories = [
    {
        label: "Spiaggia per famiglie",
        value: "family",
    },
    {
        label: "Spiaggia per coppie",
        value: "love",
    },
    {
        label: "Spiaggia per ragazzi",
        value: "young",
    },
    {
        label: "Spiaggia esclusiva",
        value: "exclusive",
    },
];

export default categories;