import MainLayout from '../layouts/MainLayout/MainLayout';

import CostumerCare from '../pages/CostumerCare/index.js';

// SubRoutes
import {subRoutesReviews, routesReviews} from './costumerCare/costumerCareReviews';
import {subRoutesReports, routesReports} from './costumerCare/costumerCareReports';
import {subRoutesRequests, routesRequests} from './costumerCare/costumerCareRequests';

export default [
    ...routesReviews,
    ...routesReports,
    ...routesRequests,
    {
        path: "",
        component: CostumerCare,
        layouts: MainLayout,
        routes: [
            ...subRoutesReviews,
            ...subRoutesReports,
            ...subRoutesRequests,
        ].map((item) => {
            return {
                ...item,
                path: `/costumerCare${item.path}`
            };
        })
    }
].map((item) => {
    
    return {
        ...item,
        path: `/costumerCare${item.path}`,
        private: true
    };
});