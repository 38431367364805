import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { useQueryCache, useMutation } from "react-query";

import {
    MainContainer,
    TitleContainer,
    MainTitle,
    MainForm,
    MainFormContent,
    TextInput,
    TextAreaInput,
    ButtonsRow,
    MainButton,
    Divider,
    SectionTitle,
    DragListing,
    GooglePlacesInput,
    CheckboxInput,
    SelectInput,
} from "@beacharound/beacharound-ui";

import { createRanking } from '../../../api/blog/rankings';
import RankingsAddPlacePopup from "../../../components/Rankings/RankingsAddPlacePopup";

const constraints = {
    type: { presence: true },
    category: { presence: true },
    name: { presence: true },
    subtitle: { presence: true },
    description: { presence: true }
}

const typeOptions = [{
    label: 'Classifica visibile',
    value: 'ranking'
},{
    label: 'Lista',
    value: 'list'
}]

const categoryOptions = [{
    label: 'Punti di interesse',
    value: 'pointsOfInterest'
},{
    label: 'Spiagge',
    value: 'beaches'
},{
    label: 'Hotel',
    value: 'hotels'
}]

export default function Create({history, match}) {

    const cache = useQueryCache();
    const [ places, setPlaces ] = useState([]);
    const [ addPopup, setAddPopup ] = useState();
    const [ category, setCategory ] = useState();
    const [published, setPublished] = useState();

    const [create, { status }] = useMutation(
        createRanking,
        {
            onSuccess: (data) => {
                cache.invalidateQueries("rakings");
                cache.setQueryData(["rakings", data._id], data);
                return history.push(match.url.replace("create", data?._id))
            },
        }
    )

    async function save(data) {

        try {
            await create({ data: {
                ...data,
                published,
                proposedBy: {
                    name: data?.proposedBy
                },
                geocode: data?.address?.geocode,
                places: places.filter(place => place.type === category).map(place => {
                    return {
                        place: place._id,
                        placeType: place.type,
                        index: place.index
                    }
                })
            }});
        } catch(error) {
            console.log(error)
            throw error;
        }
    }

    function addElements(selectedElements) {
        
        if (selectedElements) {
            setPlaces([
                ...places,
                ...selectedElements
                    .filter(
                        ({ _id }) => !places.find((item) => item._id === _id)
                    )
                    .map((item, index) => {
                        return {
                            ...item,
                            index: places.length + index,
                        };
                    })
                    .sort((a, b) => a.index - b.index),
            ]);

            return setAddPopup(false);
        }
    }

    function removeElement(selectedElements) {
        
        if (selectedElements) {
            return setPlaces([
                ...selectedElements
                    .map((item, index) => {
                        return {
                            ...item,
                            index: places.length + index,
                        };
                    })
                    .sort((a, b) => a.index - b.index),
            ]);
        }
    }

    const selectedCatName = categoryOptions.find((cat) => cat.value === category)?.label;

    return (
        <MainContainer marginBottom>
            <TitleContainer>
                <MainTitle text="Nuova classifica" />
            </TitleContainer>

            <MainForm onSubmit={save} constraints={constraints}>
                <TitleContainer>
                    <SectionTitle text="Tipologia di classifica" />
                </TitleContainer>
                <MainFormContent flex>
                    <CheckboxInput
                        label="Lista ordinata di elementi"
                        name="ordered"
                    />
                    <SelectInput
                        label="Tipologia"
                        name="type"
                        options={typeOptions}
                        half
                    />
                    <SelectInput
                        label="Categoria"
                        name="category"
                        options={categoryOptions}
                        onChangeInput={(name,value) => setCategory(value)}
                        half
                    />
                </MainFormContent>

                <Divider/>

                <TitleContainer>
                    <SectionTitle text="Dati principali" />
                </TitleContainer>
                <MainFormContent flex>
                    <TextInput label="Titolo" name="name" />
                    <TextInput label="Sottotitolo" name="subtitle" />
                    <TextAreaInput label="Descrizione" name="description" />
                </MainFormContent>

                <Divider />

                {category &&
                    <Fragment>
                        <TitleContainer>
                            <SectionTitle text="Classifica" />
                        </TitleContainer>

                        <ButtonsRow fill marginBottom>
                            <MainButton
                                bplaces
                                text={`Aggiungi ${selectedCatName || 'elementi'}`}
                                action={() => setAddPopup(true)}
                            />
                        </ButtonsRow>

                        {places?.filter(place => place.type === category).length > 0 && (
                            <DragListing
                                title="Trascina gli elementi per ordinare la classifica"
                                list={places?.filter(place => place.type === category)}
                                onDragEndCallback={(items) =>
                                    setPlaces(
                                        items.map((item, index) => {
                                            return {
                                                ...item,
                                                index,
                                            };
                                        })
                                    )
                                }
                                onDelete={removeElement}
                            />
                        )}

                        <Divider />
                    </Fragment>
                }

                <TitleContainer>
                    <SectionTitle text="Proposto da" />
                </TitleContainer>

                <MainFormContent flex>
                    <TextInput label="Proposto da" name="proposedBy" />
                </MainFormContent>

                <Divider />

                <TitleContainer>
                    <SectionTitle text="Riferimento geografico" />
                </TitleContainer>
                <MainFormContent flex>
                    <GooglePlacesInput
                        name="address"
                        label="Indirizzo"
                        required
                    />
                </MainFormContent>

                <Divider />

                <ButtonsRow fill marginBottom>
                    <MainButton
                        border
                        text="Annulla"
                        component={Link}
                        to={`/blog/rankings`}
                    />
                    <MainButton
                        border
                        submit
                        actionOnSubmit={() => setPublished(false)}
                        text="Salva bozza"
                    />
                    <MainButton
                        text="Crea classifica"
                        submit
                        status={status}
                        actionOnSubmit={() => setPublished(true)}
                    />
                </ButtonsRow>
            </MainForm>

            <RankingsAddPlacePopup
                visible={addPopup}
                setVisible={setAddPopup}
                addElements={addElements}
                category={category}
                selectedCatName={selectedCatName}
            />
        </MainContainer>
    );
}
