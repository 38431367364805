import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useQuery, useMutation, useQueryCache } from "react-query";

import {
    MainContainer,
    TitleContainer,
    MainTitle,
    MainForm,
    MainFormContent,
    TextInput,
    TextAreaInput,
    GooglePlacesInput,
    ButtonsRow,
    MainButton,
    Divider,
    SectionTitle,
    SelectInput,
    CheckboxInput
} from "@beacharound/beacharound-ui";

import { starsList, hotelTypes } from "@beacharound/beacharound-ui-helpers";

import { createHotel } from "../../../api/hotels/hotels";
import { getServices } from "../../../api/services";

const constraints = {
    name: { presence: true },
    description: { presence: true },
    address: { presence: true }
}

export default function Create({ match, history }) {

    const cache = useQueryCache();
    const [published, setPublished] = useState();

    const [create, { status }] = useMutation(createHotel, {
        onSuccess: (data) => {
            cache.invalidateQueries("hotels");
            cache.setQueryData(["hotels", data._id], data);
            return history.push(match.url.replace("create", data?._id))
        }
    })

    const servicesOptions = {
        params: { type: 'hotels'}
    }

    const { data: allServices } = useQuery(["allServices", "hotels"], () =>
        getServices({ type: "services", params: servicesOptions })
    )

    const { data: allAccessibilities } = useQuery(["allAccessibilities", "hotels"], () =>
        getServices({ type: "accessibilities", params: servicesOptions })
    )

    function save(data) {

        let accessibilities = Object
            .keys(data)
            .filter(key => allAccessibilities?.some(accessibility => accessibility.slug === key))
            .map(key => {
                const accessibility = allAccessibilities?.find(accessibility => accessibility.slug === key)
                return {
                    _id: accessibility._id,
                    isCustom: false,
                    value: data[key]
                };
            })

        let services = Object
            .keys(data)
            .filter(key => allServices?.some(service => service.slug === key))
            .map(key => {
                const service = allServices?.find(
                    (service) => service.slug === key
                );
                return {
                    _id: service._id,
                    isCustom: false,
                };
            })
        
        if(data.email || data.phone) {
            data.publicContact = {
                email: data.email,
                phone: data.phone
            }
        }

        return create({
            data: {
                ...data,
                services,
                accessibilities,
                published
            }
        });
    }

    return (
        <MainContainer marginBottom>
            <TitleContainer>
                <MainTitle text="Crea hotel" />
            </TitleContainer>

            <MainForm onSubmit={save} constraints={constraints}>
                <TitleContainer>
                    <SectionTitle text="Dati generali" />
                </TitleContainer>
                <MainFormContent flex>
                    <TextInput label="Nome" name="name" />
                    <SelectInput
                        label="Stelle"
                        name="stars"
                        options={starsList}
                        half
                    />
                    <SelectInput
                        label="Tipologia"
                        name="type"
                        options={hotelTypes}
                        half
                    />
                    <TextAreaInput label="Descrizione" name="description" />
                </MainFormContent>

                <Divider />

                <TitleContainer>
                    <SectionTitle text="Contatti pubblici" />
                </TitleContainer>
                <MainFormContent flex>
                    <TextInput name="email" label="Email per info" half />
                    <TextInput name="phone" label="Telefono per info" half />
                </MainFormContent>

                <Divider />

                <TitleContainer>
                    <SectionTitle text="Indirizzo" />
                </TitleContainer>
                <MainFormContent flex>
                    <GooglePlacesInput
                        name="address"
                        label="Indirizzo"
                        zoom={16}
                        required
                    />
                </MainFormContent>

                <Divider />

                <TitleContainer>
                    <SectionTitle text="Servizi" />
                </TitleContainer>
                <MainFormContent flex>
                    {allServices
                        ?.sort((a, b) => {
                            if (a.name > b.name) return 1;
                            if (a.name < b.name) return -1;
                            return 0;
                        })
                        .map((element, index) => (
                            <CheckboxInput
                                key={index}
                                name={element.slug}
                                label={element.name}
                                half
                            />
                        ))}
                </MainFormContent>

                <Divider />

                <TitleContainer>
                    <SectionTitle text="Accessibilità" />
                </TitleContainer>
                <MainFormContent flex>
                    {allAccessibilities
                        ?.sort((a, b) => {
                            if (a.name > b.name) return 1;
                            if (a.name < b.name) return -1;
                            return 0;
                        })
                        .map((element, index) => (
                            <SelectInput
                                name={element.slug}
                                placeholder="-"
                                key={index}
                                label={element.name}
                                options={[
                                    { label: "Si", value: true },
                                    { label: "No", value: false },
                                ]}
                                half
                            />
                        ))}
                </MainFormContent>

                <Divider />

                <ButtonsRow fill marginBottom>
                    <MainButton
                        text="Annulla"
                        border
                        component={Link}
                        to={`/places/hotels`}
                    />
                    <MainButton
                        border
                        submit
                        actionOnSubmit={() => setPublished(false)}
                        text="Salva bozza"
                    />
                    <MainButton
                        text="Crea hotel"
                        submit
                        status={status}
                        actionOnSubmit={() => setPublished(true)}
                    />
                </ButtonsRow>
            </MainForm>
        </MainContainer>
    );
}
