import List from '../../pages/Activities/Hotels/List';
import HotelDetail from '../../pages/Activities/Hotels/Detail';

import HotelReservationsOffers from '../../pages/Activities/Hotels/Detail/ReservationsOffers/List';
import HotelReservationsOffersDetail from '../../pages/Activities/Hotels/Detail/ReservationsOffers/Detail';

import HotelQuotations from '../../pages/Activities/Hotels/Detail/Quotations/List';
import HotelQuotationsDetail from '../../pages/Activities/Hotels/Detail/Quotations/Detail';

import HotelRooms from '../../pages/Activities/Hotels/Detail/Rooms/List';
import HotelRoomsCreate from '../../pages/Activities/Hotels/Detail/Rooms/Create';
import HotelRoomsDetail from '../../pages/Activities/Hotels/Detail/Rooms/Detail';
import HotelRoomsDetailSeasonPrice from '../../pages/Activities/Hotels/Detail/Rooms/SeasonPrice';

import HotelOffers from '../../pages/Activities/Hotels/Detail/Offers/List';
import HotelOffersCreate from "../../pages/Activities/Hotels/Detail/Offers/Create";
import HotelOffersDetail from "../../pages/Activities/Hotels/Detail/Offers/Detail";

export const subRoutesHotels = [{
    path: "",
    component: List,
}].map((item) => {
    return {
        ...item,
        path: `/hotels${item.path}`,
        private: true
    }
})

export const routesHotelsDetails = [{
    path: "/reservations-offers/:reservationId",
    component: HotelReservationsOffersDetail,
},{
    path: "/quotations/:quotationId",
    component: HotelQuotationsDetail,
},{
    path: "/rooms/create",
    component: HotelRoomsCreate,
    exact: true,
},{
    path: "/rooms/:roomId",
    component: HotelRoomsDetail,
    exact: true,
},{
    path: "/rooms/:roomId/seasonPrice/create",
    component: HotelRoomsDetailSeasonPrice,
    exact: true,
},{
    path: "/rooms/:roomId/seasonPrice/:seasonId",
    component: HotelRoomsDetailSeasonPrice,
},{
    path: "/offers/create",
    component: HotelOffersCreate,
},{
    path: "/offers/:offerId",
    component: HotelOffersDetail,
}].map((item) => {
    return {
        ...item,
        path: `/hotels/:elementId${item.path}`,
        private: true,
    }
})

export const routesHotels = [{
    path: "",
    component: HotelDetail,
    routes: [{
        path: "/reservations-offers",
        component: HotelReservationsOffers,
    },{
        path: "/quotations",
        component: HotelQuotations,
    },{
        path: "/rooms",
        component: HotelRooms,
    },{
        path: "/offers",
        component: HotelOffers,
    }]
}].map((item) => {
    return {
        ...item,
        routes: item.routes?.map((route) => {
            return {
                ...route,
                path: `/hotels/:elementId${route.path}`,
            };
        }),
        path: `/hotels/:elementId${item.path}`,
        private: true,
    }
})

