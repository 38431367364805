import React from "react";
import { Link } from "react-router-dom";
import { useMutation, usePaginatedQuery, useQuery, useQueryCache } from 'react-query';

import {
    MainContainer,
    MainForm,
    MainFormContent,
    SectionTitle,
    TitleContainer,
    MainTitle,
    SubTitle,
    FormToggleGroup,
    FormToggleGroupPreview,
    SimpleFormField,
    FormToggleGroupEdit,
    TextInput,
    TextAreaInput,
    TextEditorInput,
    LoadingContainer,
    EditImageGroup,
    ColumnContainer,
    Column,
    ToggleInput,
    SelectInput,
    DateInput
} from "@beacharound/beacharound-ui";

import { getSubFormData } from "@beacharound/beacharound-ui-helpers";

import { getArticle, updateArticle, updateImage } from "../../../api/blog/articles";
import { getCategories } from "../../../api/blog/categories";

export default function Detail({ match }) {

    const { elementId } = match.params;

    const { data, isLoading } = useQuery(["articles", elementId], () =>
        getArticle({ elementId, params: {} })
    )

    const { data: categories, isLoading: isLoadingCategories } = usePaginatedQuery("categories", () =>
        getCategories({ params: {params: {}}})
    )
    const cache = useQueryCache()
    const [update, {status: updateStatus, reset}] = useMutation(updateArticle, {
        onSuccess: (data) => {
            cache.invalidateQueries(["articles", elementId]);
            cache.setQueryData(["articles", elementId], data);
        },
    });
    const [updateArticleImage, {status: updateImageStatus}] = useMutation(updateImage, {
        onSuccess: (data) => {
            cache.invalidateQueries(["articles", elementId]);
            cache.setQueryData(["articles", elementId], data);
        },
    });

    async function editElement(data, callback) {
        try {
            await update({
                elementId,
                data
            });
            return callback(reset)
        } catch(error) {
            return callback(null, error);
        }
    }

    async function editCover(file) {
        try {
            await updateArticleImage({ elementId, file });
        } catch (error) {
            console.log(error);
        }
    }

    const categoriesOptions = categories?.map((cat) => {
        return {
            label: cat.name,
            value: cat._id
        }
    })

    const typesOptions = [{
        label: 'Business',
        value: 'business'
    },{
        label: 'Privati',
        value: 'private'
    }]

    const sectionsOptions = [{
        label: 'Articolo',
        value: 'article'
    },{
        label: 'Tutorial',
        value: 'tutorial'
    }]

    return (
        <MainContainer marginBottom>

            <ColumnContainer>
                <Column column={2}>
                    <TitleContainer noMargin>
                        <MainTitle text={data?.name || "Gestisci articolo"} />
                        <SubTitle
                            text="< Torna alla lista"
                            component={Link}
                            to={`/blog/articles`}
                        />
                    </TitleContainer>
                </Column>

                <Column column={2}>
                    <ToggleInput
                        flexEnd
                        trueLabel="Pubblicato"
                        falseLabel="Non pubblicato"
                        status={updateStatus}
                        changeValue={()=> editElement({published: !data?.published}, (resetAfter) => resetAfter())}
                        value={data?.published}
                    />
                </Column>
            </ColumnContainer>

            <LoadingContainer isLoading={isLoading || isLoadingCategories} isError={!data}>
                <TitleContainer>
                    <SectionTitle text="Dati principali" />
                </TitleContainer>

                <MainForm
                    onSubmit={editElement}
                    data={ data && getSubFormData(data, [
                        "name",
                        "subtitle",
                        "description",
                        "publishedAt",
                        "categories",
                        "section",
                        "type",
                    ])}>
                    <MainFormContent>
                        <FormToggleGroup>
                            <FormToggleGroupPreview>
                                <SimpleFormField
                                    label="Categoria"
                                    name="categories"
                                    options={categoriesOptions}
                                    isMulti
                                    half
                                />
                                <SimpleFormField
                                    label="Tipologia"
                                    name="type"
                                    options={typesOptions}
                                    isMulti
                                    half
                                />
                                <SimpleFormField
                                    label="Sezione"
                                    name="section"
                                    options={sectionsOptions}
                                    isMulti
                                    half
                                />
                                <SimpleFormField
                                    label="Data di pubblicazione"
                                    name="publishedAt"
                                    date
                                    half
                                />
                                <SimpleFormField
                                    label="Titolo"
                                    name="name"
                                />
                                <SimpleFormField
                                    label="Sottotitolo"
                                    name="subtitle"
                                />
                                <SimpleFormField
                                    label="Descrizione breve"
                                    name="description"
                                />
                            </FormToggleGroupPreview>

                            <FormToggleGroupEdit flex status={updateStatus}>
                                <SelectInput
                                    label="Categoria"
                                    name="categories"
                                    isMulti
                                    half
                                    options={categoriesOptions}
                                />
                                <SelectInput
                                    label="Tipologia"
                                    name="type"
                                    isMulti
                                    half
                                    options={typesOptions}
                                />
                                <SelectInput
                                    label="Sezione"
                                    name="section"
                                    half
                                    options={sectionsOptions}
                                />
                                <DateInput
                                    label="Data di pubblicazione"
                                    name="publishedAt"
                                    half
                                />
                                <TextInput
                                    label="Title"
                                    name="name"
                                />
                                <TextInput
                                    label="Sottotitolo"
                                    name="subtitle"
                                />
                                <TextAreaInput
                                    label="Descrizione breve"
                                    name="description"
                                />
                                
                            </FormToggleGroupEdit>
                        </FormToggleGroup>
                    </MainFormContent>
                </MainForm>

                <EditImageGroup data={data?.cover} onSubmit={editCover} status={updateImageStatus} />

                <TitleContainer>
                    <SectionTitle text="Testo articolo" />
                </TitleContainer>

                <MainForm
                    onSubmit={editElement}
                    data={ data && getSubFormData(data, ["content"])} >
                    <MainFormContent>
                        <FormToggleGroup>
                            <FormToggleGroupPreview>
                                <SimpleFormField
                                    label="Descrizione completa"
                                    name="content"
                                />
                            </FormToggleGroupPreview>

                            <FormToggleGroupEdit flex status={updateStatus}>
                                <TextEditorInput
                                    label="Descrizione completa"
                                    name="content"
                                />
                            </FormToggleGroupEdit>
                        </FormToggleGroup>
                    </MainFormContent>
                </MainForm>

            </LoadingContainer>
        </MainContainer>
    );
}
