import { hasRoles } from '@beacharound/beacharound-ui-helpers';
import Cookies from 'js-cookie';
import decode from 'jwt-decode';
import moment from 'moment'; // eslint-disable-line no-unused-vars

import axios from './_defaults/anonymous';
import userAxios from './_defaults/user';

const DEFAULT_EXPIRES = 1;

export function setLocalUser(data) {
    return window.localStorage.setItem("user", JSON.stringify(data));
}

export function getLocalUser() {
    const userId = Cookies.get("user-id");
    const storageUser = JSON.parse(window.localStorage.getItem("user"));
    return storageUser._id === userId && storageUser;
}

export function decodeUser(externalToken) {
    const token = externalToken || Cookies.get("user-authorization");
    if (!token) {
        console.error("DECODE", "nessun token trovato");
        return {};
    }

    const user = decode(token);
    if (!user) {
        console.error("DECODE", "nessun user trovato");
        return {};
    }

    return user;
}

function setUserCookies(response) {

    const user = decode(response.data.token);
    // const expires_token = moment.duration(user.exp);

    Cookies.set("user-authorization", response.data.token, {
        expires: DEFAULT_EXPIRES,
        // expires: expires_token.asDays(),
        // domain: window.location.host,
    });
    Cookies.set("user-refreshToken", response.data.refreshToken, {
        expires: DEFAULT_EXPIRES,
        // domain: window.location.host,
    });
    Cookies.set("user-id", user._id, {
        expires: DEFAULT_EXPIRES,
        // expires: expires_token.asDays(),
        // domain: window.location.host,
    });

}

export async function createUser(data) {
    const response = await axios.post("/admin-users/", data);

    if (response.data && response.data.auth) {

        const user = decode(response.data.token);

        setLocalUser({
            email: user.email,
            ...user.profile,
            _id: user._id
        })

        setUserCookies(response);

        return user.profile
    }

    return response
}

export async function loginUser(data) {
    const response = await axios.post("/admin-users/login", data);

    if (response.data && response.data.auth) {

        const user = decode(response.data.token);

        if (!user || !hasRoles(user.roles, "admin")) {
            throw Error(
                "Questo utente non è abilitato per lavorare in questa app"
            );
        }

        setUserCookies(response);

        return user.profile
    }

    return response
}

export async function logoutUser() {

    Cookies.remove('user-authorization')
    Cookies.remove('user-refreshToken')
    Cookies.remove('user-id')

    return true
}

export async function refreshUser() {

    let refreshToken = Cookies.get("user-refreshToken");
    let userId = Cookies.get("user-id");

    const response = await axios.post("/admin-users/token", { _id: userId, refreshToken });

    if (response.data && response.data.auth) {

        const user = decode(response.data.token);

        if (!user || !hasRoles(user.roles, "admin")) {
            throw Error(
                "Questo utente non è abilitato per lavorare in questa app"
            );
        }

        setUserCookies(response);

        return user.profile
    }

    return response
}

export async function updateUser(data) {
    const response = await userAxios.put("/admin-users/profile", data);

    if (response?.data) {
        return response.data;
    }

    return response;
}

export async function getUser() {
    const response = await userAxios.get("/admin-users/me");

    if (response.data) {
        return response.data
    }

    return response
}

export async function activateUser({ activationCode }) {

    let refreshToken = Cookies.get("user-refreshToken");

    const user = getLocalUser();

    const response = await userAxios.post("/admin-users/invitations/activate", {
        activationCode,
        refreshToken,
        email: user?.email
    });

    if (response.data) {
        Cookies.set("user-admin", response.isAdmin, { expires: 1 });
        return response.data
    }

    return response
}