import React from 'react';
import { MainContainer, MainTitle, TitleContainer, Column, ColumnContainer, LineChart } from '@beacharound/beacharound-ui';
import { useQuery } from 'react-query';

import { getInformationsRequests } from "../api/counters/informationsRequests";
import { getUsers } from '../api/counters/users';
import QuotationsChart from '../components/Charts/QuotationsChart';
import ReservationsChart from '../components/Charts/ReservationsChart';
import {getChardData} from '../helpers/charts';

export default function Dashboard() {

    const { data: informationsRequestsData } = useQuery(
        ["dataCounters", "informationsRequests"],
        getInformationsRequests
    );

    const { data: usersCostumersData } = useQuery(
        ["dataCounters", "costumerUsers"],
        () => getUsers({role: "costumers"})
    );

    // const { data: publicUsersData } = useQuery(
    //     ["dataCounters", "publicUsers"],
    //     () => getUsers({role: "civil"})
    // );

    const { data: businessUsersData } = useQuery(
        ["dataCounters", "businessUsers"],
        () => getUsers({role: "business"})
    );

    return (
        <MainContainer large>
            <TitleContainer>
                <MainTitle text="Dashboard" />
            </TitleContainer>

            <ColumnContainer noMargin>
                <Column colums={1}>
                    <ReservationsChart />
                </Column>
            </ColumnContainer>
            <ColumnContainer noMargin>
                <Column colums={1}>
                    <QuotationsChart />
                </Column>
            </ColumnContainer>

            <ColumnContainer noMargin>
                <Column colums={1}>
                    <LineChart
                        title="Richieste della settimana"
                        data={getChardData(informationsRequestsData)}
                    />
                </Column>
            </ColumnContainer>

            <TitleContainer>
                <MainTitle text="Iscrizioni della settimana" />
            </TitleContainer>

            <ColumnContainer>
                <Column column={2}>
                    <LineChart
                        simple
                        title="Utenti finali"
                        data={getChardData(usersCostumersData)}
                    />
                </Column>
                <Column column={2}>
                    <LineChart
                        simple
                        title="Business"
                        data={getChardData(businessUsersData)}
                    />
                </Column>
            </ColumnContainer>
        </MainContainer>
    );
}