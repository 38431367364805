import MainLayout from '../layouts/MainLayout/MainLayout';

// Places

import Places from '../pages/Places/index.js';

// SubRoutes
import {subRoutesBeaches, routesBeaches} from './places/placesBeaches';
import {subRoutesHotels, routesHotels} from './places/placesHotels';
import {subRoutesPointsOfInterest, routesPointsOfInterest} from './places/placesPointsOfInterest';
import {subRoutesCities, routesCities} from './places/placesCities';
import {subRoutesDistricts, routesDistricts} from './places/placesDistricts';
import {subRoutesRegions, routesRegions} from './places/placesRegions';
import { subRoutesMaps } from './places/placesMaps';
import { subRoutesSearchTerms } from './places/placesSearchTerms';
import { subRoutesImages } from './places/placesImages';

export default [
    ...routesBeaches,
    ...routesHotels,
    ...routesPointsOfInterest,
    ...routesCities,
    ...routesDistricts,
    ...routesRegions,
    {
        path: "",
        component: Places,
        layouts: MainLayout,
        routes: [
            ...subRoutesBeaches,
            ...subRoutesHotels,
            ...subRoutesPointsOfInterest,
            ...subRoutesCities,
            ...subRoutesDistricts,
            ...subRoutesRegions,
            ...subRoutesMaps,
            ...subRoutesImages,
            ...subRoutesSearchTerms,
        ].map((item) => {
            return {
                ...item,
                path: `/places${item.path}`
            };
        })
    }
].map((item) => {
    
    return {
        ...item,
        path: `/places${item.path}`,
        private: true
    };
});