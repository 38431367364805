export function getOffersList(offers) {
    if (!Array.isArray(offers)) {
        return [];
    }
    return offers?.map(getOffersElement) || [];
}

export function getOffersElement(offer) {
    
    if (!offer) {
        return {};
    }

    return {
        ...offer,
        purchasableFromDate: offer.purchasable?.fromDate,
        purchasableToDate: offer.purchasable?.toDate,
        availabilityFromDate: offer.availability?.fromDate,
        availabilityToDate: offer.availability?.toDate,
    };
}

export function cleanOfferBeforeUpdate(data) {
    let payload = {
        ...data
    };

    let purchasable;

    if(data.purchasableFromDate) {
        purchasable = !purchasable
            ? {
                  fromDate: data.purchasableFromDate,
              }
            : {
                  ...purchasable,
                  fromDate: data.purchasableFromDate,
              };
    }

    if(data.purchasableToDate) {
        purchasable = !purchasable
            ? {
                  toDate: data.purchasableToDate,
              }
            : {
                  ...purchasable,
                  toDate: data.purchasableToDate,
              };
    }

    let availability;

    if (data.availabilityFromDate) {
        availability = !availability
            ? {
                  fromDate: data.availabilityFromDate,
              }
            : {
                  ...availability,
                  fromDate: data.availabilityFromDate,
              };
    }

    if(data.availabilityToDate) {
        availability = !availability
            ? {
                  toDate: data.availabilityToDate,
              }
            : {
                  ...availability,
                  toDate: data.availabilityToDate,
              };
    }

    if(purchasable) {
        payload = {
            ...payload,
            purchasable
        }
    }

    if(availability) {
        payload = {
            ...payload,
            availability
        }
    }

    return payload;
}
