import axios from "./_defaults/user";

export async function getImages({params}) {
    
    try {
        const response = await axios.get(`/admin-media/`, {params});
        
        if (response?.data) {
            return response?.data?.reduce((accumulator, item) => {
                let images = [];

                if(item.cover) {
                    images = [...images, {image: item.cover, type: item.type, _id: item._id}];
                }

                if(item.gallery) {
                    images = [...images, ...item.gallery.map(image => {
                        return {
                            image: image.url,
                            type: item.type,
                            _id: item._id,
                        };
                    })]
                }

                return images.length > 0 ? [...accumulator, ...images] : accumulator;

            } , []);
        }
    } catch (error) {

        if (error?.response?.status === 404) {
            return [];
        }

        throw error;
    }
}
