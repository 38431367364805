import React from 'react';
import { Link } from 'react-router-dom'
import { useQuery } from 'react-query';

import { MainContainer, TitleContainer, MainButton, MainTitle, Paragraph, ButtonsRow } from '@beacharound/beacharound-ui';

import { getUser } from '../api/users/users.js';

export default function NotFound() {
    const { data: user } = useQuery('user', getUser);

    return (
        <MainContainer>
            <TitleContainer center>
                <MainTitle text="Pagina non trovata" component="h1" center />
                <Paragraph text="Ci dispiace.. la pagina che stai cercando non esiste" center />
            </TitleContainer>

            <ButtonsRow center>
                <MainButton text="Torna alla dashboard" component={Link} to={user ? "/" : "/user/login"} />
            </ButtonsRow>
        </MainContainer>
    );
}
