import TransactionsList from '../../pages/Financial/Transactions/List';

export const routesTransactions = [
    {
        path: '',
        component: TransactionsList
    }
].map((item) => {
    return {
        ...item,
        routes: item.routes?.map(route => {
            return {
                ...route,
                path: `/transactions${route.path}`
            }
        }),
        path: `/transactions${item.path}`,
        private: true
    }
})