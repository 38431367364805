import React from "react";

import {
    SectionTitle,
    TitleContainer,
    Tile,
    SimpleLink,
    ColumnContainer,
    Column,
} from "@beacharound/beacharound-ui";

export default function RelatedRooms({ title, rooms, setRoomsPopup }) {

    return (
        <React.Fragment>
            <TitleContainer>
                <SectionTitle text={title || "Camere collegate"} />
            </TitleContainer>

            <ColumnContainer>
                <Column>
                    {rooms?.map((room, index) => {
                        return (
                            <Tile
                                key={index}
                                title={room.name}
                                buttonAction={() => setRoomsPopup(room)}
                                buttonLinkText="Modifica"
                                buttonRight
                            />
                        );
                    })}
                    <SimpleLink
                        text="Aggiungi camera"
                        action={() => setRoomsPopup(true)}
                    />
                </Column>
            </ColumnContainer>
        </React.Fragment>
    );
}
