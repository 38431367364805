import React from "react";
import Select from "react-select";
import { useQuery } from "react-query";

import {
    MainForm,
    MainFormContent,
    SelectInput,
    NormalPopup,
    ButtonsRow,
    MainButton,
    Divider,
    LoadingContainer,
} from "@beacharound/beacharound-ui";

import { getRooms } from "../../../api/hotels/rooms";
import { getRoomsOptions } from "../../../helpers/rooms";

export default function RelatedRoomsPopup({ rooms, setRooms, roomsPopup, setRoomsPopup, elementId }) {

    const { data: dataRooms, isLoading } = useQuery(["hotels", elementId, "rooms"], () =>
        getRooms({
            elementId
        })
    )

    async function addRoom(data) {
        try {
            let newRooms = rooms || [];

            // Togliamo i letti con lo stesso tipo nel caso ci siano così da evitare inserimenti doppi
            newRooms = newRooms.filter(({ _id }) => _id !== data._id);

            await setRooms([
                ...newRooms,
                dataRooms.find(({ _id }) => _id === data._id),
            ]);
            return setRoomsPopup(false);
        } catch (error) {
            console.log(error);
        }
    }

    async function deleteRoom() {
        try {
            // Togliamo i letti con lo stesso tipo nel caso ci siano così da evitare inserimenti doppi

            await setRooms(
                rooms?.filter(({ _id }) => _id !== roomsPopup._id)
            );
            return setRoomsPopup(false);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <NormalPopup
            visible={roomsPopup}
            setVisible={setRoomsPopup}
            title={roomsPopup?.type ? "Modifica camera" : "Aggiungi camera"}
        >
            <LoadingContainer isLoading={isLoading}>
                <MainForm
                    onSubmit={addRoom}
                    data={roomsPopup}
                    constraints={{_id: { presence: true }}}
                >
                    <MainFormContent flex>
                        <SelectInput
                            Component={Select}
                            label="Scegli la camera"
                            name="_id"
                            options={getRoomsOptions(dataRooms)}
                        />
                    </MainFormContent>

                    <Divider />

                    <ButtonsRow fill>
                        <MainButton
                            text="Annulla"
                            border
                            action={() => setRoomsPopup(false)}
                        />
                        {roomsPopup?.type && (
                            <MainButton
                                text="Elimina"
                                border
                                action={deleteRoom}
                            />
                        )}
                        <MainButton text="Salva" submit />
                    </ButtonsRow>
                </MainForm>
            </LoadingContainer>
        </NormalPopup>
    );
};