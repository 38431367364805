import React, { useState } from "react";
import { Link } from "react-router-dom";
import qs from "query-string";
import { usePaginatedQuery } from 'react-query';
import moment from "moment";

import {
    MainContainer,
    TitleContainer,
    MainTitle,
    ButtonsRow,
    MainButton,
    ColumnContainer,
    Column,
    SimpleTable,
} from "@beacharound/beacharound-ui";

import { getRankings } from "../../../api/blog/rankings";

export default function List({ match, location, history }) {
    const { page } = location.search ? qs.parse(location.search) : { page: "0" };

    const [limit, setLimit] = useState(25);
    const [search, setSearch] = useState();
    const [params, setParams] = useState({}); // eslint-disable-line no-unused-vars

    const options = {
        skip: Number(page) * limit,
        limit,
        sort: { name: 1 },
        search,
        params: {
            ...params
        }
    }

    const { data, isLoading } = usePaginatedQuery(["rankings", options], () =>
        getRankings({
            params: options,
        })
    )

    const tableHead = [{
        text: "Titolo",
        key: ["name"],
        sort: "text",
        highlight: true,
    },
    {
        text: "Data",
        key: "createdAt",
        default: true,
        sort: "date",
        ascending: false,
        flex: 0.5,
        parse: (value) => moment(value).format("L"),
    },
    {
        flex: 0.5,
        actions: (item) => {
            return [
                {
                    icon: "arrow-right",
                    component: Link,
                    to: `${match.url}/${item._id}`,
                }
            ]
        }
    }]

    const tableRows = data?.map((item) => {
        return {
            ...item,
            ...item.contact
        }
    })

    return (
        <MainContainer large>
            
            <ColumnContainer noMargin>
                <Column column={2}>
                    <TitleContainer>
                        <MainTitle text="Classifiche" />
                    </TitleContainer>
                </Column>
                <Column column={2}>
                    <ButtonsRow flexEnd noMargin>
                        <MainButton
                            text="Nuova classifica"
                            component={Link}
                            to="/blog/rankings/create"
                        />
                    </ButtonsRow>
                </Column>
            </ColumnContainer>

            <ColumnContainer>
                <Column>
                    <SimpleTable
                        match={match}
                        history={history}
                        setLimit={setLimit}
                        navigation
                        search
                        data={tableRows}
                        head={tableHead}
                        isLoading={isLoading}
                        page={page}
                        searched={search}
                        onSearch={(value) => {
                            setSearch(value);
                            history.push(match.url);
                        }}
                        onSearchClear={() => {
                            setSearch(undefined);
                            history.push(match.url);
                        }}
                    />
                </Column>
            </ColumnContainer>
        </MainContainer>
    )
}
