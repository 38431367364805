import React from "react";
import Select from "react-select";

import { bedsOptions } from "@beacharound/beacharound-ui-helpers";

import {
    MainForm,
    MainFormContent,
    TextInput,
    SelectInput,
    NormalPopup,
    ButtonsRow,
    MainButton,
    Divider,
} from "@beacharound/beacharound-ui";

const constraintsGeneral = {
    type: { presence: true },
    quantity: { presence: true }
}

export default function RoomBedsPopupEdit({ beds, setBeds, status, bedPopup, setBedPopup }) {

    async function addBed(data) {
        try {
            let newBeds = [];
            if (beds) {
                newBeds = beds;
            }

            // Togliamo i letti con lo stesso tipo nel caso ci siano così da evitare inserimenti doppi
            newBeds = newBeds.filter(({ index }) => index !== bedPopup.index);

            await setBeds([...newBeds, {...data, index: bedPopup.index || 0}]);
            return setBedPopup(false);
        } catch(error) {
            console.log(error);
        }
    }

    async function deleteBed() {
        try {
            // Togliamo i letti con lo stesso tipo nel caso ci siano così da evitare inserimenti doppi

            await setBeds(
                beds?.filter(({ index }) => index !== bedPopup.index)
            );
            return setBedPopup(false);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <NormalPopup
            visible={bedPopup}
            setVisible={setBedPopup}
            title={bedPopup?.type ? "Modifica letto" : "Aggiungi letto"}
        >
            <MainForm
                onSubmit={addBed}
                data={bedPopup}
                constraints={constraintsGeneral}
            >
                <MainFormContent flex>
                    {/* Passiamo allo skip delle options un array di soli id pescando il valore da reference perchè è quello il collegamento con il prodotto reale */}
                    {/* <SelectImageInput label="Tipologia elemento" name="reference" half options={getProductsOptions(allRelatedProducts, {skip: {elements: product?.relatedProducts?.map(({reference}) => reference)}})} /> */}
                    <SelectInput
                        Component={Select}
                        label="Tipologia"
                        name="type"
                        half
                        options={bedsOptions.filter(
                            ({ value }) =>
                                !beds?.filter(({type}) => type !== bedPopup?.type).some(({ type }) => type === value)
                        )}
                    />
                    <TextInput
                        label="Quantità"
                        name="quantity"
                        type="number"
                        half
                    />
                </MainFormContent>

                <Divider />

                <ButtonsRow fill>
                    <MainButton
                        text="Annulla"
                        border
                        action={() => setBedPopup(false)}
                    />
                    {bedPopup?.type && (
                        <MainButton
                            text="Elimina"
                            border
                            action={deleteBed}
                        />
                    )}
                    <MainButton text="Salva" submit status={status} />
                </ButtonsRow>
            </MainForm>
        </NormalPopup>
    );
};