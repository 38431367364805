import axios from "./_defaults/user";

export async function searchActivities({ params }) {
    try {
        const response = await axios.get(`/admin-search/activities`, { params: {
            ...params,
            limit: 10
        } });

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error?.response?.status === 404) {
            return [];
        }

        throw error;
    }
}

export async function searchUsers({ params }) {
    try {
        const response = await axios.get(`/admin-search/users`, { params: {
            ...params,
            limit: 10
        } });

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error?.response?.status === 404) {
            return [];
        }

        throw error;
    }
}

export async function searchPointsOfInterest({ params }) {
    try {
        const response = await axios.get(`/admin-search/activities/pointsOfInterest`, {
            params: {
                ...params,
                limit: 10,
            },
        });

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error?.response?.status === 404) {
            return [];
        }

        throw error;
    }
}