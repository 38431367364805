import React from 'react';
import { Link } from 'react-router-dom';
import { useMutation, useQueryCache } from 'react-query';
import Select from 'react-select';

import { MainContainer, TitleContainer, MainTitle, MainForm, MainFormContent, TextInput, SelectInput, ButtonsRow, MainButton, Divider, SectionTitle } from '@beacharound/beacharound-ui';

import { createProduct } from '../../../../../api/products';

const constraints = {
    name: { presence: true },
    quantity: { presence: true },
    priceType: { presence: true },
    price: { presence: true }
}

export default function ServicesAdd({ match, history }) {

    const {elementId, type} = match.params;

    const cache = useQueryCache()
    const [create, { status }] = useMutation(createProduct, {
            onSuccess: (data) => {
                cache.setQueryData(['product', 'service', elementId, data._id], data)
                cache.invalidateQueries('services')
                return history.push(match.url.replace("create", data?._id))
            }
        }
    )

    async function saveNewProduct(data) {
        try {
            await create({ data, elementId })
        } catch(error) {
            console.log(error)
        }
    }

    return (

        <MainContainer>

            <TitleContainer>
                <MainTitle text="Nuovo servizio" />
            </TitleContainer>

            <MainForm onSubmit={saveNewProduct} constraints={constraints}>

                <TitleContainer>
                    <SectionTitle text="Dati principali" />
                </TitleContainer>
                <MainFormContent flex>
                    <TextInput
                        name="name"
                        label="Nome servizio"
                        half
                    />
                    <TextInput
                        name="quantity"
                        label="Totale elementi"
                        type="number"
                        half
                    />
                </MainFormContent>

                <Divider />

                <TitleContainer>
                    <SectionTitle text="Prezzi base" />
                </TitleContainer>
                <MainFormContent flex>
                    <SelectInput
                        Component={Select}
                        label="Tipologia di prezzo"
                        placeholder="Scegli tipologia"
                        name="priceType"
                        options={[{ value: 'hourly', label: 'Prezzo orario' }, { value: 'daily', label: 'Prezzo giornaliero' }]}
                        half
                    />
                    <TextInput
                        label="Prezzo"
                        name="price"
                        type="number"
                        half
                    />
                </MainFormContent>

                <Divider />

                <ButtonsRow fill>
                    <MainButton
                        text="Annulla"
                        border
                        component={Link}
                        to={`/activities/beaches/${type}/${elementId}/services`}
                    />
                    <MainButton text="Crea prodotto" submit status={status} />
                </ButtonsRow>
            </MainForm>

        </MainContainer>
    )
}
