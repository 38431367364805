import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useMutation, useQueryCache, useQuery } from "react-query";
import moment from 'moment';

import {
    MainContainer,
    TitleContainer,
    MainTitle,
    SubTitle,
    SectionTitle,
    Column,
    MainFormContent,
    SimpleField,
    Divider,
    ButtonsRow,
    MainButton,
    LoadingContainer,
    MainForm,
    TextInput,
    DateInput,
    Tile,
    FormToggleGroup,
    FormToggleGroupEdit,
    FormToggleGroupPreview,
    SimpleFormField,
} from "@beacharound/beacharound-ui";

import { getAddressString, getSubFormData } from "@beacharound/beacharound-ui-helpers";

import { getReservation, updateReservation } from "../../../../../api/reservations/offers";
import { getPeopleString, getStatusHistoryString, getStatusString, getStatusColor } from '../../../../../helpers/quotations';


export default function RevervationsOffersDetail({ match }) {

    const { elementId, reservationId } = match.params;
    
    const { data: reservation, isLoading } = useQuery(["reservations", elementId, reservationId], () =>
        getReservation({elementId, reservationId})
    );

    const cache = useQueryCache();
    const [update, { status: updateStatus, errors, reset }] = useMutation(updateReservation, {
        onSuccess(data) {
            cache.invalidateQueries(["reservations", elementId, reservationId]);
            cache.setQueryData(["reservations", elementId, reservationId, data._id], data);
        }
    })

    async function editReservation(data, callback) {

        const details = [
            ...(reservation?.details?.filter(({key}) => !["adults", "children", "babies"].includes(key)) || []),
            ...(Object.keys(data).filter(key => ["adults", "children", "babies"].includes(key)).reduce((accumulator, key) => {
                return [...accumulator, {key: key, value: data[key]}]
            }, []))
        ]

        try {
            await update({
                data: {
                    ...data,
                    details
                },
                elementId,
                reservationId
            })
            return callback(reset);
        } catch(error) {
            return callback(null, error)
        }
    }

    return (

        <MainContainer>

            <TitleContainer>
                <MainTitle text="Dettaglio prenotazione" />
                <SubTitle text="< Torna alle prenotazioni" component={Link} to={`/activities/hotels/${elementId}/reservations`}/>
            </TitleContainer>

            <LoadingContainer isLoading={isLoading}>
                {reservation?.user &&
                    <Fragment>
                        <TitleContainer>
                            <SectionTitle text="Dati utente" />
                        </TitleContainer>
                        <Column>
                            <MainFormContent flex>
                                <SimpleField
                                    label="Nome"
                                    value={`${reservation.user.profile?.firstName} ${reservation.user.profile?.lastName}`}
                                    half
                                />
                                <SimpleField
                                    label="Email"
                                    value={reservation.user.emails?.[0]?.address}
                                    half
                                />
                                {reservation.user.profile?.address &&
                                    <SimpleField
                                        label="Indirizzo"
                                        value={getAddressString(reservation.user.profile?.address)}
                                    />
                                }
                            </MainFormContent>
                        </Column>
                        <Divider />
                    </Fragment>
                }

                <TitleContainer>
                    <SectionTitle text="Prenotazione" />
                </TitleContainer>

                <MainForm
                    onSubmit={editReservation}
                    data={getSubFormData({
                        ...reservation,
                        ...(reservation?.details?.reduce((accumulator, detail) => {
                            return {
                                ...accumulator,
                                [detail.key]: detail.value
                            }
                        }, {}) || {})
                    }, [
                        "adults",
                        "children",
                        "babies",
                        "checkin",
                        "checkout"
                    ])}
                    constraints={{
                        adults: { presence: true },
                        checkin: { presence: true },
                        checkout: { presence: true }
                    }}
                    errors={errors}
                >
                    <MainFormContent>
                        <FormToggleGroup>
                            <FormToggleGroupPreview>
                                <SimpleFormField
                                    label="Camera"
                                    value={reservation?.products?.[0]?.product?.name}
                                    half
                                />
                                <SimpleFormField
                                    label="Persone"
                                    value={getPeopleString(reservation?.details)}
                                    half
                                />
                                <SimpleFormField
                                    label="Checkin"
                                    value={moment(reservation?.checkin).format("L")}
                                    half
                                />
                                <SimpleFormField
                                    label="Checkout"
                                    value={moment(reservation?.checkout).format("L")}
                                    half
                                />
                                <SimpleFormField
                                    label="Stato"
                                    value={getStatusString(reservation?.status)}
                                    valueColor={getStatusColor(reservation?.status)}
                                    half
                                />
                                {/* TODO: [BEACHDEV-99] passare updatedAt delle prenotazioni offerte */}
                                <SimpleFormField
                                    label="Ultima informazione"
                                    value={moment(reservation?.statusUpdatedAt || reservation?.updatedAt).format("L")}
                                    date
                                    half
                                />
                            </FormToggleGroupPreview>

                            <FormToggleGroupEdit flex status={updateStatus}>
                                <DateInput
                                    label="Checkin"
                                    name="checkin"
                                    half
                                />
                                <DateInput
                                    label="Checkout"
                                    name="checkout"
                                    half
                                />
                                <TextInput
                                    label="Adulti"
                                    name="adults"
                                    type="number"
                                    half
                                />
                                <TextInput
                                    label="Bambini"
                                    name="children"
                                    type="number"
                                    half
                                />
                                <TextInput
                                    label="Neonati"
                                    name="babies"
                                    type="number"
                                    half
                                />
                            </FormToggleGroupEdit>
                        </FormToggleGroup>
                    </MainFormContent>
                </MainForm>

                {reservation?.statusHistory?.length > 0 &&
                    <Fragment>
                        <TitleContainer>
                            <SectionTitle text="Storia della prenotazione" />
                        </TitleContainer>
                        <Column>
                            <Tile
                                title={getStatusHistoryString(reservation?.status)}
                                text={`il ${moment(
                                    reservation?.statusUpdatedAt
                                ).format("LLL")}`}
                            />
                            {reservation?.statusHistory?.sort((a,b) => new Date(b.statusUpdatedAt) - new Date(a.statusUpdatedAt)).map((chapter) => (
                                    <Tile
                                        key={chapter.status}
                                        title={getStatusHistoryString(
                                            chapter.status
                                        )}
                                        text={`il ${moment(
                                            chapter.statusUpdatedAt
                                        ).format("LLL")}`}
                                    />
                                ))}
                        </Column>

                        <Divider />
                    </Fragment>
                }

                {reservation?.status === "ACCEPTED" &&
                    <ButtonsRow fill>
                        <MainButton text="Segnala pagamento" component={Link} to={`${match.url}/payment`} />
                    </ButtonsRow>
                }

            </LoadingContainer>

        </MainContainer>

    )
}