// services

import Catalogues from "../pages/Catalogues/index";
import ServicesList from "../pages/Catalogues/Services/List";
import ProductsTypesList from "../pages/Catalogues/ProductsTypes/List";
import ServicesDetail from "../pages/Catalogues/Services/Detail";
import ServicesCreate from "../pages/Catalogues/Services/Create";

const servicesRoutes = [
    {
        path: "/:type/create",
        component: ServicesCreate,
        exact: true
    },
    {
        path: "/:type/edit/:elementId",
        component: ServicesDetail
    },
    {
        path: "",
        component: Catalogues,
        routes: [
            {
                path: "/productsTypes/:type",
                component: ProductsTypesList
            },
            {
                path: "/:type",
                component: ServicesList
            }
        ]
    }
].map((item) => {
    return {
        ...item,
        routes: item.routes?.map((route) => {
            return {
                ...route,
                path: `/catalogues${route.path}`,
                exact: true,
            };
        }),
        path: `/catalogues${item.path}`,
        private: true
    }
})

export default servicesRoutes;