import MainLayout from '../layouts/MainLayout/MainLayout';

// Activities
import Financial from '../pages/Financial/index.js';

// SubRoutes
import {routesReservations} from './financial/reservations';
import {routesTransactions} from './financial/transactions';
import {routesPayments, subRoutesPayments} from './financial/payments';

export default [
    ...routesPayments,
    {
        path: "",
        component: Financial,
        layouts: MainLayout,
        routes: [
            ...routesReservations,
            ...routesTransactions,
            ...subRoutesPayments
        ].map((item) => {
            return {
                ...item,
                path: `/financial${item.path}`
            }
        })
    }
].map((item) => {
    
    return {
        ...item,
        path: `/financial${item.path}`,
        private: true
    };
});