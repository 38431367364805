import React from "react";
import { Link } from "react-router-dom";
import { useMutation, useQuery, useQueryCache } from 'react-query';

import {
    MainContainer,
    MainForm,
    MainFormContent,
    SectionTitle,
    TitleContainer,
    MainTitle,
    SubTitle,
    FormToggleGroup,
    FormToggleGroupPreview,
    SimpleFormField,
    FormToggleGroupEdit,
    TextInput,
    TextAreaInput,
    LoadingContainer,
    EditImageGroup,
    SelectInput,
    ColumnContainer,
    Column,
    ToggleInput
} from "@beacharound/beacharound-ui";

import { getSubFormData } from "@beacharound/beacharound-ui-helpers";

// import { uploadImage } from "../../../api/images";
import { getCategory, updateCategory, updateImage } from "../../../api/blog/categories";
import { typeOptions } from "../../../helpers/blog";

export default function Detail({ match }) {


    const { type, elementId } = match.params;

    const { data, isLoading } = useQuery(["blog", "categories", elementId], () =>
        getCategory({
            elementId,
        })
    );

    const cache = useQueryCache()
    const [update, {status: updateStatus, reset}] = useMutation(updateCategory, {
        onSuccess: (data) => {
            cache.invalidateQueries(["blog", "categories"])
        },
    });
    const [updateCover, {status: updateCoverStatus, reset: resetCover}] = useMutation(updateImage, {
        onSuccess: (data) => {
            cache.invalidateQueries(["blog", "categories"])
        },
    });

    async function editElement(data, callback) {
        try {
            await update({
                type,
                elementId,
                data
            });
            return callback(reset)
        } catch(error) {
            return callback(null, error);
        }
    }

    async function editCover(file) {
        try {
            await updateCover({ elementId, file });
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <MainContainer marginBottom>
            <ColumnContainer>
                <Column column={2}>
                    <TitleContainer noMargin>
                        <MainTitle text={data?.name || "Gestisci categoria"} />
                        <SubTitle
                            text="< Torna alla lista"
                            component={Link}
                            to={`/blog/categories`}
                        />
                    </TitleContainer>
                </Column>

                <Column column={2}>
                    <ToggleInput
                        flexEnd
                        trueLabel="Pubblicato"
                        falseLabel="Non pubblicato"
                        status={updateStatus}
                        changeValue={()=> editElement({published: !data?.published}, (resetAfter) => resetAfter())}
                        value={data?.published}
                    />
                </Column>
            </ColumnContainer>

            <LoadingContainer isLoading={isLoading} isError={!data}>
                <TitleContainer>
                    <SectionTitle text="Dati principali" />
                </TitleContainer>

                <MainForm
                    onSubmit={editElement}
                    data={ data && getSubFormData(data, [
                        "name",
                        "type",
                        "subtitle",
                        "description",
                    ])}>
                    <MainFormContent>
                        <FormToggleGroup>
                            <FormToggleGroupPreview>
                                <SimpleFormField
                                    label="Nome"
                                    name="name"
                                    half
                                />
                                <SimpleFormField
                                    label="Tipologia"
                                    name="type"
                                    half
                                    isMulti
                                    options={typeOptions}
                                />
                                <SimpleFormField
                                    label="Sottotitolo"
                                    name="subtitle"
                                />
                                <SimpleFormField
                                    label="Descrizione"
                                    name="description"
                                />
                            </FormToggleGroupPreview>

                            <FormToggleGroupEdit flex status={updateStatus}>
                                <TextInput
                                    label="Titolo"
                                    name="name"
                                    half
                                />
                                <SelectInput
                                    label="Tipologia"
                                    name="type"
                                    isMulti
                                    half
                                    options={typeOptions}
                                />
                                <TextInput
                                    label="Sottotitolo"
                                    name="subtitle"
                                />
                                <TextAreaInput
                                    label="Descrizione"
                                    name="description"
                                />
                            </FormToggleGroupEdit>
                        </FormToggleGroup>
                    </MainFormContent>
                </MainForm>

                <EditImageGroup
                    data={data?.cover}
                    onSubmit={editCover}
                    reset={resetCover}
                    status={updateCoverStatus}
                />

            </LoadingContainer>
        </MainContainer>
    );
}
