import React from "react";
import { Link } from "react-router-dom";
import { useMutation, useQuery, useQueryCache } from 'react-query';

import {
    MainContainer,
    MainForm,
    MainFormContent,
    SectionTitle,
    TitleContainer,
    MainTitle,
    SubTitle,
    FormToggleGroup,
    FormToggleGroupPreview,
    SimpleFormField,
    FormToggleGroupEdit,
    TextInput,
    TextAreaInput,
    LoadingContainer,
    SelectInput,
    CheckboxInput,
    EditAddressGroup,
    EditImageGroup,
    ColumnContainer,
    Column,
    ToggleInput
} from "@beacharound/beacharound-ui";

import { getSubFormData, starsList, hotelTypes } from "@beacharound/beacharound-ui-helpers";

import { getHotelNameWithStars } from '../../../helpers/hotels/stars';
import {
    getHotel,
    updateImage,
    uploadGalleryImage as updateGalleryImage,
    updateHotel,
} from "../../../api/hotels/hotels";
import { getServices } from "../../../api/services";

import {getServicesObject, getAccessibilitiesObject, getServicesString, getAccessibilitiesArray, getServicesArray} from '../../../helpers/services';

export default function Detail({ match }) {

    const { elementId } = match.params;

    const servicesOptions = {
        params: { type: 'hotels'}
    }

    const { data, isLoading } = useQuery(["hotels", elementId], () =>
        getHotel({ elementId })
    );

    const { data: allServices } = useQuery(["allServices"],
        () => getServices({type: 'services', params: servicesOptions})
    )

    const { data: allAccessibilities } = useQuery(["allAccessibilities"],
        () =>  getServices({type: 'accessibilities', params: servicesOptions})
    )

    const cache = useQueryCache()
    const [update, {status: updateStatus, reset}] = useMutation(updateHotel, {
        onSuccess: (data) => {
            cache.invalidateQueries(["hotels"])
            cache.setQueryData(["hotels", elementId], data);
        }
    });

    const [uploadImage, { status: updateImageStatus, reset: imageReset }] = useMutation(updateImage, {
        onSuccess: (data) => {
            cache.invalidateQueries(["hotels"]);
            cache.setQueryData(["hotels", elementId], data);
        },
    });

    const [uploadGallery] = useMutation(
        updateGalleryImage,
        {
            onSuccess: (data) => {
                cache.invalidateQueries(["hotels"]);
                cache.setQueryData(["hotels", elementId], data);
            },
        }
    );

    async function editElement(data, callback) {
        try {
        
            if (data.email || data.phone) {
                data.publicContact = {
                    email: data.email,
                    phone: data.phone,
                };
            }

            await update({
                elementId,
                data
            })
            return callback(reset)
        } catch(error) {
            return callback(null, error)
        }
    }

    async function editCover(file, callback) {
        try {
            await uploadImage({ elementId, file });
        } catch (error) {
            throw error;
        }
    }

    async function uploadGalleryImage(file, index, callback) {
        try {
            await uploadGallery({ elementId, file, index });
            return callback("LOADED");
        } catch (error) {
            throw error;
        }
    }

    async function removeGalleryImage(images, index, callback) {
        try {
            await update({
                elementId,
                data: {
                    $pull: {
                        gallery: {
                            index,
                        },
                    },
                },
            });
            return callback();
        } catch (error) {
            throw error;
        }
    }

    async function editGeodata(data) {
        try {
            await update({
                elementId,
                data: {
                    address: {
                        ...data
                    }
                },
            });
        } catch(error) {
            throw error;
        }
    }

    async function editServices(data, callback) {
        try {
            const services = getServicesArray(allServices, data);
            await update({
                elementId,
                data: {
                    services
                }
            })
            return callback(reset)
        } catch(error) {
            return callback(null, error)
        }
    }

    async function editAccessibilities(data, callback) {
        try {
            const accessibilities = getAccessibilitiesArray(allAccessibilities, data);
            await update({
                elementId,
                data: {
                    accessibilities
                }
            })
            return callback(reset)
        } catch(error) {
            return callback(null, error)
        }
    }

    return (
        <MainContainer marginBottom>

            <ColumnContainer>
                <Column column={2}>
                    <TitleContainer noMargin>
                        <MainTitle text={data && getHotelNameWithStars(data.name, data.stars)}/>
                        <SubTitle text="< Torna alla lista" component={Link} to={`/places/hotels`}/>
                    </TitleContainer>
                </Column>

                <Column column={2}>
                    <ToggleInput
                        flexEnd
                        trueLabel="Pubblicato"
                        falseLabel="Non pubblicato"
                        status={updateStatus}
                        changeValue={()=> editElement({published: !data?.published}, (resetAfter) => resetAfter())}
                        value={data?.published}
                    />
                </Column>
            </ColumnContainer>

            <LoadingContainer isLoading={isLoading}>
                <TitleContainer>
                    <SectionTitle text="Dati generali" />
                </TitleContainer>

                <MainForm
                    onSubmit={editElement}
                    data={
                        data &&
                        getSubFormData(data, [
                            "name",
                            "stars",
                            "type",
                            "description",
                        ])
                    }
                    constraints={{ name: { presence: true } }}
                >
                    <MainFormContent>
                        <FormToggleGroup>
                            <FormToggleGroupPreview>
                                <SimpleFormField label="Nome" name="name" />
                                <SimpleFormField
                                    label="Stelle"
                                    name="stars"
                                    options={starsList}
                                    half
                                />
                                <SimpleFormField
                                    label="Tipologia"
                                    name="type"
                                    options={hotelTypes}
                                    half
                                />
                                <SimpleFormField
                                    label="Descrizione"
                                    name="description"
                                />
                            </FormToggleGroupPreview>

                            <FormToggleGroupEdit flex status={updateStatus}>
                                <TextInput label="Nome" name="name" />
                                <SelectInput
                                    label="Stelle"
                                    name="stars"
                                    options={starsList}
                                    half
                                />
                                <SelectInput
                                    label="Tipologia"
                                    name="type"
                                    options={hotelTypes}
                                    half
                                />
                                <TextAreaInput
                                    label="Descrizione"
                                    name="description"
                                />
                            </FormToggleGroupEdit>
                        </FormToggleGroup>
                    </MainFormContent>
                </MainForm>

                <TitleContainer>
                    <SectionTitle text="Contatti pubblici" />
                </TitleContainer>

                <MainForm
                    onSubmit={editElement}
                    data={data?.publicContact}
                    constraints={{ email: { presence: true } }}
                >
                    <MainFormContent>
                        <FormToggleGroup>
                            <FormToggleGroupPreview>
                                <SimpleFormField
                                    label="Email"
                                    name="email"
                                    half
                                />
                                <SimpleFormField
                                    label="Telefono"
                                    name="phone"
                                    half
                                />
                            </FormToggleGroupPreview>

                            <FormToggleGroupEdit flex status={updateStatus}>
                                <TextInput label="Email" name="email" half />
                                <TextInput label="Telefono" name="phone" half />
                            </FormToggleGroupEdit>
                        </FormToggleGroup>
                    </MainFormContent>
                </MainForm>

                <EditImageGroup
                    data={data?.cover}
                    onSubmit={editCover}
                    reset={imageReset}
                    status={updateImageStatus}
                    gallery={data?.gallery}
                    uploadGalleryImage={uploadGalleryImage}
                    removeGalleryImage={removeGalleryImage}
                />

                <EditAddressGroup
                    data={data?.address}
                    onSubmit={editGeodata}
                    reset={reset}
                    status={updateStatus}
                />

                <TitleContainer>
                    <SectionTitle text="Servizi" />
                </TitleContainer>

                <MainForm
                    onSubmit={editServices}
                    data={getServicesObject(allServices, data?.services)}
                >
                    <MainFormContent>
                        <FormToggleGroup>
                            <FormToggleGroupPreview>
                                <SimpleFormField
                                    label="Servizi"
                                    value={getServicesString(
                                        allServices,
                                        data?.services
                                    )}
                                />
                            </FormToggleGroupPreview>

                            <FormToggleGroupEdit flex status={updateStatus}>
                                {allServices
                                    ?.sort((a, b) => {
                                        if (a.name > b.name) return 1;
                                        if (a.name < b.name) return -1;
                                        return 0;
                                    })
                                    .map((service, index) => {
                                        return (
                                            <CheckboxInput
                                                key={index}
                                                label={service.name}
                                                name={service.slug}
                                                half
                                            />
                                        );
                                    })}
                            </FormToggleGroupEdit>
                        </FormToggleGroup>
                    </MainFormContent>
                </MainForm>

                <TitleContainer>
                    <SectionTitle text="Accessibilità" />
                </TitleContainer>

                <MainForm
                    onSubmit={editAccessibilities}
                    data={getAccessibilitiesObject(
                        allAccessibilities,
                        data?.accessibilities
                    )}
                >
                    <MainFormContent>
                        <FormToggleGroup>
                            <FormToggleGroupPreview>
                                {allAccessibilities?.map((item, index) => {
                                    return (
                                        <SimpleFormField
                                            key={index}
                                            label={item.name}
                                            name={item.slug}
                                            options={[
                                                { label: "Si", value: true },
                                                { label: "No", value: false },
                                            ]}
                                            half
                                        />
                                    );
                                })}
                            </FormToggleGroupPreview>

                            <FormToggleGroupEdit flex status={updateStatus}>
                                {allAccessibilities
                                    ?.sort((a, b) => {
                                        if (a.name > b.name) return 1;
                                        if (a.name < b.name) return -1;
                                        return 0;
                                    })
                                    .map((item, index) => {
                                        return (
                                            <SelectInput
                                                placeholder="-"
                                                name={item.slug}
                                                key={index}
                                                label={item.name}
                                                options={[
                                                    {
                                                        label: "Si",
                                                        value: true,
                                                    },
                                                    {
                                                        label: "No",
                                                        value: false,
                                                    },
                                                ]}
                                                half
                                            />
                                        );
                                    })}
                            </FormToggleGroupEdit>
                        </FormToggleGroup>
                    </MainFormContent>
                </MainForm>
            </LoadingContainer>
        </MainContainer>
    );
}
