import React, { useState } from "react";
import qs from "query-string";
import { Link } from "react-router-dom";
import { usePaginatedQuery } from "react-query";

import {
    MainContainer,
    TitleContainer,
    MainTitle,
    ColumnContainer,
    Column,
    SimpleTable,
    ButtonsRow,
    MainButton
} from "@beacharound/beacharound-ui";

import { getMembershipsPacks, updateMembershipsPack } from "../../../api/memberships";
import PublishPopup from "../../../components/Popups/PublishPopup";

export default function List({ history, match, location }) {
    
    const { page } = location.search ? qs.parse(location.search) : { page: "0" };

    const [ limit, setLimit ] = useState(25);
    const [ search, setSearch ] = useState();
    const [ params, setParams ] = useState({}); // eslint-disable-line no-unused-vars
    const [ publishItem, setPublishItem ] = useState();

    const options = {
        skip: Number(page) * limit,
        limit,
        sort: { name: 1 },
        search,
        params: {}
    }
    
    const { data, isLoading } = usePaginatedQuery(["membershipsPacks", options], () =>
        getMembershipsPacks({
            params: options
        })
    )

    const tableHead = [{
        text: "Nome",
        key: "name",
        sort: "text",
        highlight: true,
        default: true
    }, {
        text: "Pacchetto",
        key: "pack",
        flex: 0.8,
    }, {
        text: "Tipologia",
        key: "type",
        color: (type) => ["FREE", "TRIAL"].includes(type) && 'grey',
        flex: 0.8,
    }, {
        text: "Prezzo",
        key: "price",
        flex: 0.5,
        parse: (price) => `${Number(price).toFixed(2)} €`
    }, {
        text: "Fee",
        key: "fee",
        flex: 0.5,
        parse: (price) => price ? `${Number(price).toFixed(2)} %` : "-"
    }, {
        text: "Fisso",
        key: "fixed",
        flex: 0.5,
        parse: (price) => price ? `${Number(price).toFixed(2)} €` : "-"
    },{
        flex: 0.5,
        actions: (item) => {
            return [{
                icon: item.published ? "visible" : "not-visible",
                action: () => setPublishItem(item),
            },{
                icon: "edit",
                component: Link,
                to: `${match.url}/${item._id}`,
            }]
        }
    }]

    const tableRows = data?.map(item => {
        return {
            ...item
        }
    }) || [];

    return (
        <MainContainer large>

            <ColumnContainer noMargin>
                <Column column={2}>
                    <TitleContainer>
                        <MainTitle text="Pacchetti" />
                    </TitleContainer>
                </Column>
                <Column column={2}>
                    <ButtonsRow flexEnd noMargin>
                        <MainButton
                            text="Nuovo pacchetto"
                            component={Link}
                            to={`${match.url}/create`}
                        />
                    </ButtonsRow>
                </Column>
            </ColumnContainer>

            <ColumnContainer>
                <Column>
                    <SimpleTable
                        match={match}
                        history={history}
                        setLimit={setLimit}
                        navigation
                        data={tableRows}
                        head={tableHead}
                        isLoading={isLoading}
                        page={page}
                        searched={search}
                        onSearch={(value) => {
                            setSearch(value);
                            history.push(match.url);
                        }}
                        onSearchClear={() => {
                            setSearch(undefined);
                            history.push(match.url);
                        }}
                    />
                </Column>
            </ColumnContainer>

            <PublishPopup
                item={publishItem}
                setItem={setPublishItem}
                type={"membershipsPacks"}
                updateFunction={updateMembershipsPack}
            />
        </MainContainer>
    );
}
