import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useQuery } from 'react-query';

import {
    MainContainer,
    TitleContainer,
    MainTitle,
    ColumnContainer,
    Column,
    SimpleTable
} from "@beacharound/beacharound-ui";

import { getLocations } from "../../../api/locations";
import LocationActivationPopup from "../../../components/Locations/LocationActivationPopup";

export default function List({ match }) {

    const [selectedItem, setSelectedItem] = useState();

    const { data, isLoading } = useQuery("regions", () => getLocations({ type: "regions" }))

    const tableHead = [
        {
            text: "Nome",
            key: "name",
            sort: "text",
            highlight: true,
        },
        {
            text: "Attivo",
            key: "published",
            hasMobileLabel: true,
            flex: 0.5,
            parse: (value) => value === true ? "Attivo" : "Non attivo"
        },
        {
            flex: 0.5,
            actions: (item) => {
                return [
                    {
                        icon: item.published ? "visible" : "not-visible",
                        action: () => setSelectedItem(item),
                    },
                    {
                        icon: "edit",
                        component: Link,
                        to: `${match.url}/${item._id}`,
                    },
                ];
            },
        },
    ];

    const tableRows = data || [];

    return (
        <MainContainer large>
            <TitleContainer>
                <MainTitle text="Regioni" />
            </TitleContainer>

            <ColumnContainer>
                <Column>
                    <SimpleTable
                        data={tableRows}
                        head={tableHead}
                        isLoading={isLoading}
                    />
                </Column>
            </ColumnContainer>

            <LocationActivationPopup
                item={selectedItem}
                setItem={setSelectedItem}
                locationType={"regions"}
            />

        </MainContainer>
    );
}
