import React, { Fragment } from 'react';
// import { useQuery, useMutation, useQueryCache } from 'react-query';

import { SectionTitle, TitleContainer, Tile, SimpleLink, Column, ColumnContainer, InputError } from '@beacharound/beacharound-ui';
import { getDayOfTheWeekName } from '@beacharound/beacharound-ui-helpers';

export default function OfferConditions({
    conditions,
    setConditionsPopup,
    setConditionItemPopup,
    error
}) {
    const conditionsData = conditions?.map((condition) => {
        let string;
        if (condition.min) {
            string = `minimo: ${condition.min}`;
        }

        if (condition.max) {
            string = `${string} - massimo: ${condition.max}`;
        }

        if (condition.checkinDay) {
            string = `Giorno della settimana: ${getDayOfTheWeekName(
                condition.checkinDay
            )}`;
        }

        return {
            ...condition,
            string,
        };
    });

    return (
        <Fragment>
            <TitleContainer>
                <SectionTitle text="Condizioni" />
            </TitleContainer>

            {conditionsData?.map((item, index) => {
                return (
                    <Tile
                        key={index}
                        title={item.title}
                        text={item.string}
                        buttonAction={() => setConditionItemPopup(item)}
                        buttonLinkText="Modifica"
                        buttonRight
                    />
                );
            })}

            <ColumnContainer>
                <Column>
                    <SimpleLink
                        text="Aggiungi condizione"
                        action={() => setConditionsPopup(true)}
                    />

                    {error && <InputError errors={[error]} />}

                </Column>
            </ColumnContainer>
        </Fragment>
    );
}