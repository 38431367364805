import React from "react";
import { useQuery } from "react-query";
import { LineChart } from "@beacharound/beacharound-ui";

import { addDays, format, getDayOfYear, setDayOfYear } from "date-fns";

import { getQuotations } from "../../api/counters/quotations";

const findingStatus = [
    "CHECKINGOUT",
    "ACCEPTED",
    "CONFIRMED",
    "DECLINED",
    "REJECTED",
];

function getChardData(list, options) {

    const daysOfPeriod = Array.apply(null, Array(6)).map((time, index) => {
        return {
            dayOfYear: getDayOfYear(addDays(new Date(), -index)),
            count: 0,
        };
    });

    let filterdList =
        list &&
        Array.isArray(list) ?
        list?.reduce((accumulator, value) => {
            const existingIndex = accumulator.findIndex(
                (existing) => existing.dayOfYear === value.dayOfYear
            );
            if (existingIndex >= 0) {
                accumulator[existingIndex] = {
                    ...accumulator[existingIndex],
                    values: {...accumulator[existingIndex]["values"], [value.status]: value.count }
                };
            } else {
                accumulator = [
                    ...accumulator,
                    { dayOfYear: value.dayOfYear, values: {[value.status]: value.count }},
                ];
            }
            return accumulator;
        }, []) : [];

    return daysOfPeriod
        .sort((a, b) => a.dayOfYear - b.dayOfYear)
        .map(({ dayOfYear: defaultDayOfYear, count }) => {
            const data = filterdList.find((item) => item.dayOfYear === defaultDayOfYear);

            let dateByDay = setDayOfYear(new Date(), defaultDayOfYear);

            return {
                date: format(dateByDay, "LLLL"),
                name: format(dateByDay, "dd MMM"),
                ...(data?.values || {}),
            };
        });
}

export default function QuotationsChart({activity, simple}) {

    let options = {};

    if(activity) {
        options = {
            ...options,
            activity
        }
    }

    const { data: quoationsData } = useQuery(
        ["dataCounters", "quotations", options],
        () => getQuotations({params: options})
    );

    return (
        <LineChart
            simple={simple}
            title="Preventivi della settimana"
            data={getChardData(quoationsData, options)}
            lines={findingStatus}
        />
    );
}