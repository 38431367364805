import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useMutation, useQuery, useQueryCache } from 'react-query';

import {
    MainContainer,
    MainForm,
    MainFormContent,
    SectionTitle,
    TitleContainer,
    MainTitle,
    SubTitle,
    FormToggleGroup,
    FormToggleGroupPreview,
    SimpleFormField,
    FormToggleGroupEdit,
    TextInput,
    SelectInput,
    LoadingContainer,
    TextAreaInput
} from '@beacharound/beacharound-ui';

import { getSubFormData } from '@beacharound/beacharound-ui-helpers'

import { packTypeOptions, paymentTypeOptions, activityTypeOptions } from '../../../helpers/memberships';
import { getMembershipsPack, updateMembershipsPack } from "../../../api/memberships";

const constraints = {
    name: { presence: true },
    type: { presence: true },
    pack: { presence: true },
    activityType: { presence: true },
    price: { presence: true },
    duration: { presence: true },
}

export default function Detail({ match }) {

    const { elementId } = match.params;
    const options = { params: {} }
    
    const { data, isLoading } = useQuery(["membershipsPacks", elementId], () =>
        getMembershipsPack({ elementId, params: options })
    );

    const [ activity, setActivity ] = useState()
    // const [ payment, setPayment ] = useState()

    const cache = useQueryCache()
    const [update, {status: updateStatus, reset}] = useMutation(updateMembershipsPack, {
        onSuccess: (data) => {
            cache.invalidateQueries("membershipsPacks");
            cache.setQueryData(["membershipsPacks", data._id], data);
        },
    });

    async function editElement(data, callback) {
        try {
            await update({
                elementId,
                data
            })
            return callback(reset)
        } catch(error) {
            return callback(null, error)
        }
    }

    function setActivityHandler(name, value) {
        return setActivity(value)
    }

    // function setPaymentHandler(name, value) {
    //     return setPayment(value)
    // }

    return (
        <MainContainer marginBottom>
            <TitleContainer>
                <MainTitle text={data?.name} />
                <SubTitle
                    text="< Torna alla lista"
                    component={Link}
                    to={`/activities/memberships-packs`}
                />
            </TitleContainer>

            <LoadingContainer isLoading={isLoading}>
                <TitleContainer>
                    <SectionTitle text="Dati generali" />
                </TitleContainer>

                <MainForm
                    onSubmit={editElement}
                    data={getSubFormData({...data, activityType: data?.activityType[0]}, ["name", "activityType", "pack", "type", "price", "fee", "fixed", "duration"])}
                    constraints={constraints}
                >
                    <MainFormContent>
                        <FormToggleGroup>
                            <FormToggleGroupPreview>
                                <SimpleFormField
                                    label="Nome"
                                    name="name"
                                    half
                                />
                                <SimpleFormField
                                    label="Tipologia attività"
                                    name="activityType"
                                    options={activityTypeOptions}
                                    half
                                />
                                <SimpleFormField
                                    label="Tipologia pacchetto"
                                    name="pack"
                                    options={packTypeOptions}
                                    half
                                />
                                <SimpleFormField
                                    label="Tipologia pagamento"
                                    name="type"
                                    options={paymentTypeOptions}
                                    half
                                />
                                <SimpleFormField
                                    label="Prezzo"
                                    name="price"
                                    price
                                    half
                                />
                                <SimpleFormField
                                    label="Percentuale"
                                    name="fee"
                                    half
                                />
                                <SimpleFormField
                                    label="Fisso a transazione"
                                    name="fixed"
                                    price
                                    half
                                />
                                <SimpleFormField
                                    label="Durata (in giorni)"
                                    name="duration"
                                    half
                                />
                            </FormToggleGroupPreview>

                            <FormToggleGroupEdit flex status={updateStatus}>
                                <TextInput
                                    label="Nome"
                                    name="name"
                                    half
                                />
                                <SelectInput
                                    label="Tipologia attività"
                                    name="activityType"
                                    options={activityTypeOptions}
                                    onChangeInput={setActivityHandler}
                                    half
                                />
                                <SelectInput
                                    label="Tipologia pacchetto"
                                    name="pack"
                                    readOnly={!activity && !data?.activityType?.[0]}
                                    options={packTypeOptions.filter((opt) => {
                                        return activity ? opt.type.includes(activity) : opt.type.includes(data?.activityType?.[0])
                                    })}
                                    half
                                />
                                <SelectInput
                                    label="Tipologia pagamento"
                                    name="type"
                                    options={paymentTypeOptions}
                                    // onChangeInput={setPaymentHandler}
                                    half
                                />
                                <TextInput
                                    label='Prezzo'
                                    minNum={0}
                                    price
                                    name="price"
                                    type="number"
                                    half
                                />
                                <TextInput
                                    label="Percentuale"
                                    name="fee"
                                    minNum={0}
                                    maxNum={100}
                                    step="0.01"
                                    type="number"
                                    half
                                />
                                <TextInput
                                    label="Fisso a transazione"
                                    name="fixed"
                                    minNum={0}
                                    price
                                    type="number"
                                    half
                                />
                                <TextInput
                                    label="Durata (in giorni)"
                                    name="duration"
                                    type="number"
                                    half
                                />
                            </FormToggleGroupEdit>
                        </FormToggleGroup>
                    </MainFormContent>
                </MainForm>

                <TitleContainer>
                    <SectionTitle text="Dettagli pacchetto" />
                </TitleContainer>

                <MainForm
                    onSubmit={editElement}
                    data={data}
                    constraints={constraints}
                >
                    <MainFormContent>
                        <FormToggleGroup>
                            <FormToggleGroupPreview>
                                <SimpleFormField
                                    label="Sottotitolo"
                                    name="subtitle"
                                />
                                <SimpleFormField
                                    label="Descrizione"
                                    name="description"
                                />
                            </FormToggleGroupPreview>

                            <FormToggleGroupEdit flex status={updateStatus}>
                                <TextInput
                                    label="Sottotitolo"
                                    name="subtitle"
                                />
                                <TextAreaInput
                                    label="Descrizione"
                                    name="description"
                                />
                            </FormToggleGroupEdit>
                        </FormToggleGroup>
                    </MainFormContent>
                </MainForm>
            </LoadingContainer>
        </MainContainer>
    );
}
