import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from 'react-query';

import { getUser } from "../../../api/users/users";

import { MainContainer, MainForm, MainFormContent, SectionTitle, TitleContainer, MainTitle, SubTitle, SimpleFormField, LoadingContainer, Column, Divider } from '@beacharound/beacharound-ui';

// const constraints = {
//     firstName: { presence: true },
//     latName: { presence: true }
// }

export default function Detail({ match }) {

    const { elementId } = match.params;
    const { data, isLoading } = useQuery(["users", "business", elementId], () =>
        getUser({ role: "business", elementId })
    );


    // const cache = useQueryCache()
    // const [update] = useMutation(updateInformationsRequest, {
    //     onSuccess: () => cache.invalidateQueries('informationsRequests')
    // })

    // function editElement(data) {
    //     console.log(data)
    // }

    return (
        <MainContainer marginBottom>
            <TitleContainer>
                <MainTitle text="Dettaglio utente business" />
                <SubTitle
                    text="< Torna alla lista"
                    component={Link}
                    to={`${match.url.replace(`/${elementId}`, "")}`}
                />
            </TitleContainer>

            <LoadingContainer isLoading={isLoading}>
                <TitleContainer>
                    <SectionTitle text="Dati generali" />
                </TitleContainer>

                {/* <MainForm onSubmit={editElement} data={data?.profile} constraints={constraints}>
                    <MainFormContent>
                        <FormToggleGroup>
                            <FormToggleGroupPreview>
                                <SimpleFormField
                                    label="Nome"
                                    name="firstName"
                                    half
                                />
                                <SimpleFormField
                                    label="Cognome"
                                    name="lastName"
                                    half
                                />
                            </FormToggleGroupPreview>

                            <FormToggleGroupEdit flex>
                                <TextInput label="Nome" name="firstName" half />
                                <TextInput label="Cognome" name="lastName" half />
                            </FormToggleGroupEdit>
                        </FormToggleGroup>
                    </MainFormContent>
                </MainForm> */}

                <MainForm data={data?.profile}>
                    <Column>
                        <MainFormContent flex>
                            <SimpleFormField label="Nome" name="firstName" half />
                            <SimpleFormField label="Cognome" name="lastName" half />
                        </MainFormContent>
                    </Column>
                </MainForm>
                
                <Divider/>

                <TitleContainer>
                    <SectionTitle text="Contatti" />
                </TitleContainer>

                <MainForm data={data?.emails && data?.emails[0]}>
                    <Column>
                        <MainFormContent flex>
                            <SimpleFormField label="Indirizzo email" name="address" half />
                            <SimpleFormField label="Verificata" name="verified" half />
                        </MainFormContent>
                    </Column>
                </MainForm>
            </LoadingContainer>
        </MainContainer>
    );
}
