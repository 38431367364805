import React from "react";
import { Link, Switch } from "react-router-dom";

import {
    MainContainer,
    MainTitle,
    TitleContainer,
    ColumnContainer,
    Column,
    Tabs,
} from "@beacharound/beacharound-ui";

import SubRoute from '../../components/Routes/SubRoute';

export default function Settings({ match, routes, location }) {

    const tabs = [
        {
            text: "Utenti",
            component: Link,
            to: `${match.url}/admins`,
        },
        {
            text: "Inviti",
            component: Link,
            to: `${match.url}/invitations`,
        }
    ]

    const tabsArray = tabs && tabs.map((t) => t.to)

    return (
        <React.Fragment>
            <MainContainer large>
                <TitleContainer>
                    <MainTitle text="Impostazioni" />
                </TitleContainer>

                <ColumnContainer noMargin>
                    <Column>
                        <Tabs list={tabs} activeIndex={tabsArray.indexOf(location.pathname)}/>
                    </Column>
                </ColumnContainer>

                <ColumnContainer>
                    <Column>
                        <Switch>
                            {routes?.map((page, index) => {
                                return <SubRoute key={index} {...page} />;
                            })}
                        </Switch>
                    </Column>
                </ColumnContainer>
            </MainContainer>

        </React.Fragment>
    )
}
