import React from "react";
import { Link } from "react-router-dom";
import { useMutation, useQueryCache } from "react-query";

import {
    MainContainer,
    TitleContainer,
    MainTitle,
    MainForm,
    MainFormContent,
    TextInput,
    SelectInput,
    ButtonsRow,
    MainButton,
    Divider,
    SectionTitle
} from "@beacharound/beacharound-ui";

import { createOrganization } from "../../../api/organizations";

const constraints = {
    name: { presence: true },
    type: { presence: true }
}

const typeOptions = [{
    label: 'Privata',
    value: 'private'
},{
    label: 'Pubblica',
    value: 'civil'
}]

export default function Create({ match, history }) {

    const cache = useQueryCache();
    const [create, { status }] = useMutation(createOrganization, {
        onSuccess: (data) => {
            cache.invalidateQueries("organizations");
            cache.setQueryData(["organizations", data._id], data);
            return history.push(match.url.replace("create", data?._id))
        }
    })

    function save(data) {
        return create({
            data: {
                ...data
            }
        })
    }

    return (
        <MainContainer marginBottom>
            <TitleContainer>
                <MainTitle text="Crea organizzazione" />
            </TitleContainer>

            <MainForm onSubmit={save} constraints={constraints}>
                <TitleContainer>
                    <SectionTitle text="Dati generali" />
                </TitleContainer>
                <MainFormContent flex>
                    <TextInput
                        label="Nome"
                        name="name"
                        half
                    />
                    <SelectInput
                        label="Tipologia"
                        name="type"
                        options={typeOptions}
                        half
                    />
                </MainFormContent>

                <Divider />

                <ButtonsRow fill marginBottom>
                    <MainButton
                        text="Annulla"
                        border
                        component={Link}
                        to={`/activities/organizations`}
                    />
                    <MainButton text="Crea organizzazione" submit status={status} />
                </ButtonsRow>
            </MainForm>


        </MainContainer>
    );
}
