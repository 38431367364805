import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import {
    MainContainer,
    TitleContainer,
    MainTitle,
    SectionTitle,
    MainForm,
    MainFormContent,
    TextInput,
    TextAreaInput,
    Divider,
    DateInput,
    ButtonsRow,
    MainButton,
    NormalPopup,
    ImageInput,
    InputError
} from '@beacharound/beacharound-ui';

import OfferConditions from '../../../../../components/Hotel/Offers/OfferConditions';
import OfferConditionsPopup from '../../../../../components/Hotel/Offers/OfferConditionsPopup';
import RelatedRooms from '../../../../../components/Hotel/Rooms/RelatedRooms';
import RelatedRoomsPopup from '../../../../../components/Hotel/Rooms/RelatedRoomsPopup';
import { useMutation, useQueryCache } from 'react-query';
import { createOffer, updateOffer } from '../../../../../api/hotels/offers';
import { getPutUrl } from '../../../../../api/hotels/offers';
import { uploadImage } from '../../../../../api/images';

const constraints = {
    name: { presence: true },
    price: { presence: true },
    description: { presence: true },
    purchasableFromDate: { presence: true },
};

export default function OffersCreate({ match, history }) {

    const { elementId } = match.params;

    const [conditions, setConditions] = useState();
    const [errors, setErrors] = useState();
    const [conditionsListPopup, setConditionListPopup] = useState();
    const [conditionItemPopup, setConditionItemPopup] = useState();
    const [file, setFile] = useState();
    const [published, setPublished] = useState();

    const [rooms, setRooms] = useState();
    const [roomsPopup, setRoomsPopup] = useState();

    const [beforeUpdateErrors, setBeforeUpdateErrors] = useState(); // eslint-disable-line no-unused-vars

    const cache = useQueryCache();

    const [create, { status, data }] = useMutation(createOffer, {
        onSuccess: (data) => {
            cache.invalidateQueries(["hotels", elementId, "offers"]);
            return cache.setQueryData(["hotels", elementId, "offers", data._id], data)
        }
    });

    const [update, { status: updateStatus }] = useMutation(updateOffer, {
        onSuccess: (data) => {
            cache.invalidateQueries(["hotels", elementId, "offers"]);
            cache.setQueryData(["hotels", elementId, "offers", data._id], data);
            return saveOfferImageCallback()
        },
    });

    async function saveOffer(data) {

        if (!conditions || conditions.length < 0) {
            setErrors("Non puoi inserire un'offerta senza condizioni")
            return setBeforeUpdateErrors({
                conditions: "Non puoi inserire un'offerta senza condizioni",
            });
        }

        if (!rooms || rooms.length < 0) {
            setErrors("Non puoi inserire un'offerta senza camere collegate")
            return setBeforeUpdateErrors({
                rooms: "Non puoi inserire un'offerta senza camere collegate",
            });
        }
        
        try {
            await create({
                data: {
                    ...data,
                    relatedProducts: rooms.map(({_id}) => _id),
                    conditions,
                    published
                },
                elementId
            })
            
        } catch(error) {
            console.log(error);
        }
    }

    function saveOfferImageCallback() {
        return history.push(match.url.replace("create", data?._id))
    }

    async function saveOfferImage() {

        try {
            const { url, key } = await getPutUrl({ elementId, offerId: data?._id, file });
            await uploadImage({ url, file });
            await update({
                elementId,
                offerId: data?._id,
                data: {
                    cover: {
                        url: key,
                    },
                },
            });
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <MainContainer>
            <TitleContainer>
                <MainTitle text="Nuova offerta" />
            </TitleContainer>

            <MainForm onSubmit={saveOffer} constraints={constraints}>
                <TitleContainer>
                    <SectionTitle text="Dettagli offerta" />
                </TitleContainer>
                <MainFormContent flex>
                    <TextInput
                        label="Nome"
                        name="name"
                        half
                    />
                    <TextInput
                        label="Prezzo a persona a notte"
                        name="price"
                        type="number"
                        price
                        half
                    />
                    <TextAreaInput label="Descrizione" name="description" />
                </MainFormContent>

                <Divider />

                <TitleContainer>
                    <SectionTitle text="Validità" />
                </TitleContainer>
                <MainFormContent flex>
                    <DateInput
                        label="Acquistabile dal"
                        name="purchasableFromDate"
                        half
                    />
                    <DateInput
                        label="Acquistabile fino al"
                        name="purchasableToDate"
                        half
                    />
                </MainFormContent>

                <Divider />

                <TitleContainer>
                    <SectionTitle text="Prenotazioni" />
                </TitleContainer>
                <MainFormContent flex>
                    <DateInput
                        label="Check-in cliente dal"
                        name="availabilityFromDate"
                        half
                    />
                    <DateInput
                        label="Check-in cliente fino al"
                        name="availabilityToDate"
                        half
                    />
                </MainFormContent>

                <Divider />

                <OfferConditions
                    conditions={conditions}
                    setConditionItemPopup={setConditionItemPopup}
                    setConditionsPopup={setConditionListPopup}
                />

                <Divider />

                <RelatedRooms rooms={rooms} setRoomsPopup={setRoomsPopup} />

                <Divider />

                <InputError errors={errors} marginBottom/>

                <ButtonsRow fill >
                    <MainButton
                        border
                        text="Annulla"
                        component={Link}
                        to={match.url.replace("/create", "")}
                    />
                    <MainButton
                        border
                        submit
                        actionOnSubmit={() => setPublished(false)}
                        text="Salva bozza"
                    />
                    <MainButton
                        text="Crea promozione"
                        submit
                        status={status}
                        actionOnSubmit={() => setPublished(true)}
                    />
                </ButtonsRow>
            </MainForm>

            <OfferConditionsPopup
                conditions={conditions}
                setConditions={setConditions}
                conditionsListPopup={conditionsListPopup}
                setConditionListPopup={setConditionListPopup}
                conditionItemPopup={conditionItemPopup}
                setConditionItemPopup={setConditionItemPopup}
            />

            <RelatedRoomsPopup
                rooms={rooms}
                setRooms={setRooms}
                roomsPopup={roomsPopup}
                setRoomsPopup={setRoomsPopup}
                elementId={elementId}
            />

            <NormalPopup visible={data?._id} setVisible={saveOfferImageCallback} title="Immagine dell'offerta">
                <MainForm constraints={{cover: { presence: true }}} onSubmit={saveOfferImage}>
                    <MainFormContent>
                        <ImageInput
                            label="Aggiungi un'immagine di copertina"
                            name="cover"
                            onChangeInput={(name, file) => setFile(file)}
                            large
                        />
                    </MainFormContent>
                    <Divider/>
                    <ButtonsRow fill>
                        <MainButton border text="Carica più tardi" action={saveOfferImageCallback}/>
                        <MainButton submit text="Salva" status={updateStatus}/>
                    </ButtonsRow>
                </MainForm>
            </NormalPopup>
        </MainContainer>
    );
}