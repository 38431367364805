import React, { Fragment } from 'react';
import html2pdf from 'html2pdf.js';
import { renderToString } from 'react-dom/server';

import { Column, ColumnContainer, Divider, EmptyChart, MainContainer, TitleContainer, SectionTitle, SimpleField, SimpleTable, SubTitle } from '@beacharound/beacharound-ui';
import { formatDate, getAddressString } from '@beacharound/beacharound-ui-helpers';

const Page = ({logo, transfer: data}) => {

    const tableData = data?.transactions;

    const tableHead = [{
        text: "Prenotazione",
        key: "reservation",
        parse: (reservation) => reservation?._id || '-',
        flex: 0.7
    },{
        text: "Transazione",
        key: "transaction",
        parse: (transaction) => transaction?._id || '-',
        flex: 0.7
    },{
        text: "Cliente",
        key: "reservation",
        parse: (reservation) => reservation?.customer?.firstName ? `${reservation?.customer?.firstName} ${reservation?.customer?.lastName}` : '-',
        flex: 0.6
    },{
        text: "Checkin",
        key: "reservation",
        parse: (reservation) => reservation?.checkin ? formatDate(new Date(reservation?.checkin), "dd MMM") : '-',
        flex: 0.2
    },{
        text: "Pagata il",
        key: "transaction",
        flex: 0.2,
        parse: (transaction) => transaction?.paidAt ? formatDate(new Date(transaction?.paidAt), "dd MMM") : '-',
    },{
        text: "Importo",
        key: "transaction",
        flex: 0.2,
        parse: (transaction) => transaction?.amount && `${transaction?.amount}€`
    },{
        text: "Dovuto",
        key: "transaction",
        flex: 0.2,
        parse: (transaction) => transaction.payout && `${transaction?.payout}€`
    }]

    let bankCityString = data?.billingProfile?.bank?.city || "-";

    if(data?.billingProfile?.bank?.postalCode) {
        bankCityString = `${bankCityString} (${data?.billingProfile?.bank?.postalCode})`
    }

    return (
        <Fragment>
            <header className="header-empty" style={{marginBottom: "20px"}}>
                <div className="header-body">
                    <MainContainer large>
                        <div className="header-inner">
                            <div className="header-logo-container">
                                <img src={logo} alt="Beacharound logo" height="36px" width="192px" />
                            </div>
                        </div>
                    </MainContainer>
                </div>
            </header>

            <MainContainer>
                <TitleContainer lowMargin>
                    <SectionTitle text={`Pagamento ${data?.activity?.name || ''}`} />
                    <SubTitle text={`${data?.number || "PRO FORMA"} del ${formatDate(data?.createdAt, 'dd/MM/yyyy')}`} />
                </TitleContainer>

                <ColumnContainer noMargin>
                    <Column column={2}>
                        <EmptyChart
                            title="Transazioni al"
                            value={data?.last?.transaction ? `${formatDate(data?.last?.transaction, 'dd/MM/yyyy')}` : '-'}
                        />
                    </Column>
                    <Column column={2}>
                        <EmptyChart
                            title="Prenotazioni con checkin al"
                            value={data?.last?.reservation ? `${formatDate(data?.last?.reservation, 'dd/MM/yyyy')}` : '-'}
                        />
                    </Column>
                </ColumnContainer>

                <ColumnContainer noMargin>
                    <Column column={3}>
                        <EmptyChart
                            title="Transato"
                            value={data?.amount ? `${Number(data?.amount).toFixed(2)}€` : '-'}
                        />
                    </Column>
                    <Column column={3}>
                        <EmptyChart
                            title={data?.status === "TRANSFERED" ? "Bonificato" : "Da bonificare"}
                            value={data?.transfer?.total ? `${Number(data?.transfer?.total).toFixed(2)}€` : '-'}
                        />
                    </Column>
                    <Column column={3}>
                        <EmptyChart
                            title={data?.status === "TRANSFERED" ? "Fatturato" : "Da fatturare"}
                            value={data?.invoice?.total ? `${Number(data?.invoice?.total).toFixed(2)}€` : '-'}
                        />
                    </Column>
                </ColumnContainer>
                
                <Divider/>

                <TitleContainer lowMargin>
                    <SectionTitle text="Dati di fatturazione" />
                </TitleContainer>
                <ColumnContainer lowMargin>
                    <Column column={2}>
                        <SimpleField
                            label="Nome"
                            value={data?.billingProfile?.name || "-"}
                        />
                    </Column>
                    <Column column={2}>
                        <SimpleField
                            label="Partita iva"
                            value={data?.billingProfile?.vat?.number || "-"}
                        />
                    </Column>
                    <Column column={2}>
                        <SimpleField
                            label="Pec"
                            value={data?.billingProfile?.pec || "-"}
                        />
                    </Column>
                    <Column column={2}>
                        <SimpleField
                            label="Codice univoco"
                            value={data?.billingProfile?.uniqueCode || "-"}
                        />
                    </Column>
                    <Column column={1}>
                        <SimpleField
                            label="Indirizzo"
                            value={getAddressString(data?.billingProfile?.billingAddress)}
                        />
                    </Column>
                </ColumnContainer>
                
                <Divider/>

                <TitleContainer lowMargin>
                    <SectionTitle text="Dati di bancari" />
                </TitleContainer>
                <ColumnContainer lowMargin>
                    <Column column={2}>
                        <SimpleField
                            label="Banca"
                            value={data?.billingProfile?.bank?.name || "-"}
                        />
                    </Column>
                    <Column column={2}>
                        <SimpleField
                            label="Iban"
                            value={data?.billingProfile?.bank?.iban || "-"}
                        />
                    </Column>
                    <Column column={2}>
                        <SimpleField
                            label="Bic"
                            value={data?.billingProfile?.bank?.bic || "-"}
                        />
                    </Column>
                    <Column column={2}>
                        <SimpleField
                            label="Città"
                            value={data?.billingProfile?.bank?.city ? bankCityString : "-"}
                        />
                    </Column>
                </ColumnContainer>

                <Divider/>
            </MainContainer>

            <MainContainer printStyle>
                <TitleContainer lowMargin>
                    <SectionTitle text="Lista transazioni" />
                </TitleContainer>

                <ColumnContainer>
                    <Column>
                        <SimpleTable
                            data={tableData || []}
                            head={tableHead}
                            printStyle
                        />
                    </Column>
                </ColumnContainer>
            </MainContainer>
        </Fragment>
    )
}

export async function generateTransferPdf(transfer, activity) {
    const beacharoundImage = await getImageFromUrl(`${process.env.REACT_APP_IMAGES_PATH_ROOT}/logo.png`);
    const htmlString = renderToString(<Page logo={beacharoundImage} transfer={transfer} activity={activity}/>);
    const options = {
        filename: `${formatDate(transfer?.createdAt, "yyyy-MM-dd")}-${transfer?.activity?.name}-${transfer.number?.replace("/", "-") || "pro-forma"}.pdf`
    }
    return html2pdf().set(options).from(htmlString, "string").toPdf().save();
}


export async function getImageFromUrl(image) {
    if(!image) {
        return;
    }
    try {
        let blob = await fetch(image).then(r => r.blob());
        let dataUrl = await new Promise(resolve => {
          let reader = new FileReader();
          reader.onload = () => resolve(reader.result);
          reader.readAsDataURL(blob);
        });
        return dataUrl;
    } catch(error) {
        console.log(error);
        return;
    }
}