// cities

import List from '../../pages/Users/Business/List';
import Detail from '../../pages/Users/Business/Detail';

export const subRoutesUsersBusiness = [
    {
        path: "",
        component: List,
        exact: true,
    },
].map((item) => {
    return {
        ...item,
        path: `/business${item.path}`,
        private: true
    };
});

export const routesUsersBusiness = [
    {
        path: "/:elementId",
        component: Detail,
    },
].map((item) => {
    return {
        ...item,
        path: `/business${item.path}`,
        private: true
    };
});