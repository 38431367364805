import React from 'react';
import { Link } from 'react-router-dom';
import { useMutation, useQueryCache } from 'react-query';

import { Column, ColumnContainer, Divider, MainButton, MainContainer, MainForm, MainTitle, MainFormContent, SimpleLink, TextInput, TitleContainer } from '@beacharound/beacharound-ui';

import { loginUser } from "../../api/user";

const constraints = {
    email: { presence: { message: "^Campo obbligatorio" }, email: { message: "^Email non valida" } },
    password: { presence: { message: "^Campo obbligatorio" } }
};

export default function Login({ history }) {

    let buttonStatus;

    const cache = useQueryCache()
    const [addUser, { isLoading, isError, isSuccess }] = useMutation(loginUser, {
        onSuccess: () => cache.invalidateQueries('user')
    });

    if (isError) {
        buttonStatus = "ERROR";
    }
    if (isSuccess) {
        buttonStatus = "SUCCESS";
        history.push("/");
    }
    if (isLoading) {
        buttonStatus = "LOADING";
    }

    return (
        <MainContainer small>
            <ColumnContainer>
                <Column>
                    <TitleContainer>
                        <MainTitle text="Accedi a Beacharound" />
                    </TitleContainer>

                    <MainForm onSubmit={addUser} constraints={constraints}>
                        <MainFormContent>
                            <TextInput name="email" label="Email" required />
                            <TextInput name="password" label="Password" type="password" required />
                            <MainButton fullWidth text="Accedi" submit status={buttonStatus} />
                        </MainFormContent>
                    </MainForm>

                    <SimpleLink component={Link} to="/user/forgot-password" text="Hai dimenticato la password?" />
                </Column>
            </ColumnContainer>

            <Divider />

            <ColumnContainer>
                <Column>
                    <MainButton border fullWidth text="Registrati ora" component={Link} to="/user/signup" />
                </Column>
            </ColumnContainer>
        </MainContainer>
    );
}
