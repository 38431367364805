const mainRoles = ["admin", "business", "civil", "private"];
const adminRoles = ["super", "financial", "editor", "costumer-care"];

export function hasRoles(roles, mainRole) {
    if(!roles) {
        throw "Nessun ruolo trovato"
    }

    let rolesForCheck = [];

    if(mainRole === "admin") {
        rolesForCheck = adminRoles;
    }

    if(!roles.includes(mainRole)) {
        console.log("Ruolo principale non adatto");
        return false
    }

    if(!roles.filter(role => !mainRoles.includes(role)).some(role => rolesForCheck.includes(role))) {
        console.log("Nessun ruolo adatto");
        return false
    }

    return true;
}