import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery, useMutation, useQueryCache } from 'react-query';
import Select from 'react-select';

import { getProductImage, getSubFormData } from '@beacharound/beacharound-ui-helpers';

import {
    MainContainer,
    MainForm,
    MainFormContent,
    SectionTitle,
    TitleContainer,
    MainTitle,
    SubTitle,
    FormToggleGroup,
    FormToggleGroupPreview,
    SimpleFormField,
    FormToggleGroupEdit,
    TextInput,
    SelectInput,
    SelectImageInput,
    LoadingContainer,
    Divider,
    ButtonsRow,
    MainButton,
    NormalPopup,
    Paragraph
} from '@beacharound/beacharound-ui';

import { getProductsTypes } from '../../../../../api/productsTypes';
import { getProduct, updateProduct, deleteProduct } from '../../../../../api/products';

import { getProductsElement } from '../../../../../helpers/products';
import { getProductsOptions } from '../../../../../helpers/productsTypes';

import ProductVariations from '../../../../../components/Beach/Catalog/ProductVariations'

const constraintsGeneral = {
    productType: { presence: true },
    name: { presence: true },
    quantity: { presence: true }
}

export default function DeckchairsDetail({ match, history }) {

    const { elementId, productId, type } = match.params;

    const { data: productsTypes } = useQuery(['productsTypes', 'deckchair'],
        () => getProductsTypes({ params: {params: {type: "deckchair" }}})
    )
    const { data: productData, isLoading } = useQuery(['products', 'deckchair', elementId, productId],
        () => getProduct({ productId, elementId })
    )

    const [ deletePopup, setDeletePopup ] = useState();
    
    const cache = useQueryCache()
    const [update, {status: updateStatus, reset}] = useMutation(updateProduct, {
        onSuccess: () => cache.invalidateQueries('products')
    })
    const [remove, {status: removeStatus}] = useMutation(deleteProduct, {
        onSuccess: () => {
            cache.invalidateQueries('product')
            history.push(match.url.replace(`/${elementId}`, ""))
        }
    })

    const product = getProductsElement(productData);

    const [isHalfDay, setIsHalfDay] = useState(product?.halfDay)

    const constraintsPrice = {
        daily: { presence: true },
        morning: { presence: isHalfDay},
        afternoon: { presence: isHalfDay},
    }

    async function editProduct(data, callback) {
        try {
            await update({
                productId,
                elementId,
                data
            });
            return callback(reset)
        } catch(error) {
            return callback(null, error);
        }
    }

    function getDataProductImage(_id) {
        return getProductImage(productsTypes?.find(p => p._id === _id) || {})
    }


    async function deleteElement() {
        try {
            await remove({elementId, productId})
            return setDeletePopup(false)
        } catch(error) {
            console.log(error)
        }
    }

    function changeHalfDay(name, value) {
        return setIsHalfDay(value || false)
    }

    const booleanOptions = [{ value: true, label: 'Si' }, { value: false, label: 'No' }]

    return (

        <React.Fragment>

            <MainContainer>

                <TitleContainer>
                    <MainTitle text="Gestisci lettino" />
                    <SubTitle text="< Torna alla lista" component={Link} to={`/activities/beaches/${type}/${elementId}/deckchairs`}/>
                </TitleContainer>

                <LoadingContainer isLoading={isLoading}>

                    <TitleContainer>
                        <SectionTitle text="Dati principali" />
                    </TitleContainer>

                    <MainForm
                        onSubmit={editProduct}
                        data={getSubFormData(product, ["name", "productType", "quantity"])}
                        constraints={constraintsGeneral}
                    >
                        <MainFormContent>
                            <FormToggleGroup>
                                <FormToggleGroupPreview>
                                    <SimpleFormField
                                        label="Tipologia elemento"
                                        name="productType"
                                        externalImageUrl={(name, value) => getDataProductImage(value)}
                                        options={getProductsOptions(productsTypes)}
                                    />
                                    <SimpleFormField
                                        label="Nome prodotto"
                                        name="name"
                                        half
                                    />
                                    <SimpleFormField
                                        label="Quantità elementi"
                                        name="quantity"
                                        half
                                    />
                                </FormToggleGroupPreview>

                                <FormToggleGroupEdit flex status={updateStatus}>
                                    <SelectImageInput
                                        Component={Select}
                                        label="Tipologia elemento"
                                        name="productType"
                                        options={getProductsOptions(productsTypes)}
                                    />
                                    <TextInput
                                        label="Nome prodotto"
                                        name="name"
                                        half
                                    />
                                    <TextInput
                                        label="Quantità elementi"
                                        name="quantity"
                                        half
                                    />
                                </FormToggleGroupEdit>
                            </FormToggleGroup>
                        </MainFormContent>
                    </MainForm>

                    <TitleContainer>
                        <SectionTitle text="Prezzi base" />
                    </TitleContainer>

                    <MainForm
                        onSubmit={editProduct}
                        data={getSubFormData(product, ["halfDay", "daily", "morning", "afternoon"])}
                        constraints={constraintsPrice}
                    >
                        <MainFormContent>
                            <FormToggleGroup>
                                <FormToggleGroupPreview>
                                    <SimpleFormField
                                        label="Disponibilità mezza giornata"
                                        name="halfDay"
                                        options={booleanOptions}
                                        half
                                    />
                                    <SimpleFormField
                                        label="Prezzo giornaliero base"
                                        name="daily"
                                        half
                                        price
                                    />
                                    {(isHalfDay !== undefined ? isHalfDay : product?.halfDay) &&
                                        <SimpleFormField
                                            label="Prezzo solo mattina"
                                            name="morning"
                                            half
                                            price
                                        />
                                    }
                                    {(isHalfDay !== undefined ? isHalfDay : product?.halfDay) &&
                                        <SimpleFormField
                                            label="Prezzo solo pomeriggio"
                                            name="afternoon"
                                            half
                                            price
                                        />
                                    }
                                </FormToggleGroupPreview>

                                <FormToggleGroupEdit flex status={updateStatus}>
                                    <SelectInput
                                        Component={Select}
                                        label="Disponibilità mezza giornata"
                                        name="halfDay"
                                        options={booleanOptions}
                                        half
                                        onChangeInput={changeHalfDay}
                                    />
                                    <TextInput
                                        type="number"
                                        label="Prezzo giornaliero base"
                                        name="daily"
                                        half
                                    />
                                    {(isHalfDay !== undefined ? isHalfDay : product?.halfDay) &&
                                        <TextInput
                                            type="number"
                                            label="Prezzo solo mattina"
                                            name="morning"
                                            half
                                        />
                                    }
                                    {(isHalfDay !== undefined ? isHalfDay : product?.halfDay) &&
                                        <TextInput
                                            type="number"
                                            label="Prezzo solo pomeriggio"
                                            name="afternoon"
                                            half
                                        />
                                    }
                                </FormToggleGroupEdit>
                            </FormToggleGroup>
                        </MainFormContent>
                    </MainForm>

                    <ProductVariations productId={productId} beachId={elementId} queryId="deckchairVariations" />

                    <Divider/>

                    <ButtonsRow spaceBetween>
                        <MainButton border text="Elimina lettino" action={() => setDeletePopup(true)} />
                    </ButtonsRow>

                </LoadingContainer>

                <NormalPopup visible={deletePopup} title="Elimina lettino" setVisible={() => setDeletePopup(false)}>
                    <TitleContainer>
                        <Paragraph text="Sei sicuro di voler eliminare questo lettino?"/>
                    </TitleContainer>
                    <Divider/>
                    <ButtonsRow spaceBetween>
                        <MainButton border text="Annulla" action={() => setDeletePopup(false)}/>
                        <MainButton text="Conferma" action={deleteElement} status={removeStatus}/>
                    </ButtonsRow>
                </NormalPopup> 

            </MainContainer>

        </React.Fragment>

    )
}
