import React, { useState } from "react";
import qs from "query-string";
import { Link } from "react-router-dom";
import { usePaginatedQuery } from "react-query";

import {
    MainContainer,
    ColumnContainer,
    Column,
    SimpleTable,
    ButtonsRow,
    MainButton,
    TitleContainer,
    MainTitle
} from "@beacharound/beacharound-ui";

import { getServices } from "../../../api/services";
import LocationActivationPopup from "../../../components/Locations/LocationActivationPopup";

export default function List({ history, match, location }) {

    const { page } = location.search ? qs.parse(location.search) : { page: "0" };
    const { type } = match.params;

    const [limit, setLimit] = useState(25);
    const [search, setSearch] = useState();
    const [params, setParams] = useState({});
    const [selectedItem, setSelectedItem] = useState();

    // TODO: [BEACHDEV-74] La ricerca dei servizi non prende i parametri 

    const options = {
        skip: Number(page) * limit,
        limit,
        sort: { name: -1 },
        search,
        params: {
            ...params,
            type,
        }
    }

    const { data, isLoading } = usePaginatedQuery( ["services", type, options], () =>
        getServices({
            type,
            params: {
                options,
            }
        })
    )

    const tableHead = [{
        text: "Nome",
        key: "name",
        sort: "text",
        highlight: true,
        default: true,
    }, {
        text: "Attivo",
        key: "published",
        hasMobileLabel: true,
        flex: 0.5,
        parse: (value) => (value === true ? "Attivo" : "Non attivo"),
    }, {
        flex: 0.5,
        actions: (item) => {
            return [{
                icon: item.published ? "visible" : "not-visible",
                action: () => setSelectedItem(item),
            },{
                icon: "edit",
                component: Link,
                to: `${match.url}/edit/${item._id}`,
            }]
        }
    }]

    const tableRows = data || [];

    const availableFilters = [{
        label: "Attivi",
        name: "published",
        type: "boolean",
        action: (name, value, options) => {
            setParams(options?.isRemoving ? { } : {
                ...params,
                "published": value,
            });
            history.push(`${match.url}`);
        }
    }]

    return (
        <MainContainer large>

            <ColumnContainer noMargin>
                <Column column={2}>
                    <TitleContainer noMargin>
                        <MainTitle text={type === 'services' ? "Servizi" : "Accessibilità"} />
                    </TitleContainer>
                </Column>
                <Column column={2}>
                    <ButtonsRow flexEnd noMargin>
                        <MainButton
                            text={type === 'services' ? "Nuovo servizio" : "Nuova accessibilità"}
                            component={Link}
                            to={`${match.url}/create`}
                        />
                    </ButtonsRow>
                </Column>
            </ColumnContainer>

            <ColumnContainer>
                <Column>
                    <SimpleTable
                        match={match}
                        history={history}
                        setLimit={setLimit}
                        navigation
                        showFilters
                        data={tableRows}
                        head={tableHead}
                        availableFilters={availableFilters}
                        isLoading={isLoading}
                        page={page}
                        searched={search}
                        onSearch={(value) => {
                            setSearch(value);
                            history.push(match.url);
                        }}
                        onSearchClear={() => {
                            setSearch(undefined);
                            history.push(match.url);
                        }}
                    />
                </Column>
            </ColumnContainer>

            <LocationActivationPopup
                item={selectedItem}
                setItem={setSelectedItem}
                locationType={"cities"}
            />
        </MainContainer>
    )
}
