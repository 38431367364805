import axios from './_defaults/user';

export async function createActivityTransaction({ data, activityId }) {
    const response = await axios.post(`/admin-transactions/${activityId}`, data);

    if (response.data) {
        return response.data
    }

    return response
}

export async function getActivityTransactions({ activityId, params }) {
    try {
        const response = await axios.get(`/admin-transactions/${activityId}`, { params });

        if (response.data) {
            return response.data;
        }
        
    } catch(error) {

        if (error?.response?.status === 404) {
            return [];
        }

        throw error
    }
}

export async function getTransactions({ params }) {
    try {
        const response = await axios.get(`/admin-transactions`, { params });

        if (response.data) {
            return response.data;
        }
        
    } catch(error) {

        if (error?.response?.status === 404) {
            return [];
        }

        throw error
    }
}

export async function getActivityTransaction({ activityId, elementId }) {
    try {
        const response = await axios.get(`/admin-transactions/${activityId}/${elementId}`);

        if (response.data) {
            return response.data;
        }
        
    } catch(error) {

        if (error?.response?.status === 404) {
            return [];
        }

        throw error
    }
}

export async function updateActivityTransaction({ activityId, data, elementId }) {
    try {
        const response = await axios.post(`/admin-transactions/${activityId}/${elementId}`, data);

        if (response.data) {
            return response.data;
        }
        
    } catch(error) {

        if (error?.response?.status === 404) {
            return [];
        }

        throw error
    }
}