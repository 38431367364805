import React, { useState } from "react";
import qs from "query-string";
import moment from 'moment';
import { Link } from "react-router-dom";
import { usePaginatedQuery } from "react-query";

import {
    MainContainer,
    TitleContainer,
    MainTitle,
    ColumnContainer,
    Column,
    SimpleTable,
    ButtonsRow,
    MainButton,
} from "@beacharound/beacharound-ui";

import { getUsers } from "../../../api/users/users";
import SendInvitationPopup from "../../../components/Settings/Invitations/SendInvitationPopup";

export default function List({ match, location, history }) {
    
    const { page } = location.search ? qs.parse(location.search) : { page: "0" };

    const [limit, setLimit] = useState(25);
    const [search, setSearch] = useState();
    const [invitePopup, setInvitePopup] = useState()

    const options = {
        skip: Number(page) * limit,
        limit,
        sort: { name: 1 },
        search
    };

    const { resolvedData: data, isLoading } = usePaginatedQuery(["users", "admin", options], () =>
        getUsers({
            role: "admin",
            params: options,
        })
    );

    const tableHead = [{
        text: "Nome",
        key: ["lastName", "firstName"],
        sort: "text",
        highlight: true,
    },{
        text: "Attivo dal",
        key: "adminActivation",
        color: (value) => value === undefined ? 'red' : undefined,
        flex: 0.5,
        parse: (value) => value ? moment(value).format("l") : "Non attivo",
    },{
        flex: 0.5,
        actions: (item) => {
            return [{
                icon: "edit",
                component: Link,
                to: `${match.url}/${item._id}`,
            }]
        }
    }]

    const tableRows = data?.map(({ _id, profile, adminActivation }) => {
        return {
            ...profile,
            _id,
            adminActivation,
        };
    }) || [];

    return (
        <MainContainer large>
            <ColumnContainer noMargin>
                <Column column={2}>
                    <TitleContainer>
                        <MainTitle text="Utenti admin" />
                    </TitleContainer>
                </Column>
                <Column column={2}>
                    <ButtonsRow flexEnd>
                        <MainButton text="Invita amministratore" action={() => setInvitePopup(true)}/>
                    </ButtonsRow>
                </Column>
            </ColumnContainer>

            <ColumnContainer>
                <Column>
                    <SimpleTable
                        match={match}
                        history={history}
                        setLimit={setLimit}
                        navigation
                        data={tableRows}
                        head={tableHead}
                        isLoading={isLoading}
                        page={page}
                        searched={search}
                        onSearch={(value) => {
                            setSearch(value);
                            history.push(match.url);
                        }}
                        onSearchClear={() => {
                            setSearch(undefined);
                            history.push(match.url);
                        }}
                    />
                </Column>
            </ColumnContainer>
              
            {/* POPUP PER L'INVIO DEGLI INVITI */}

            <SendInvitationPopup
                active={invitePopup}
                setActive={setInvitePopup}
            />

        </MainContainer>
    );
}
