import List from "../../pages/Activities/Memberships/List";

export const subRoutesMemberships = [
    {
        path: "",
        component: List,
        exact: true,
    }
].map((item) => {
    return {
        ...item,
        path: `/memberships${item.path}`,
        private: true
    };
});

// export const routesMemberships = [
//     {
//         path: "/create",
//         component: Create,
//         exact: true
//     },
//     {
//         path: "/:elementId",
//         component: Detail,
//     }
// ].map((item) => {
//     return {
//         ...item,
//         path: `/memberships${item.path}`,
//         private: true
//     };
// });