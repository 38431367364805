import React from "react";
import { Link, Switch } from "react-router-dom";
import { useQuery } from "react-query";

import { MainContainer, MainTitle, TitleContainer, ColumnContainer, Column, Tabs, } from "@beacharound/beacharound-ui";

import { getRequests } from "../../api/requestsForAssistance";

import SubRoute from '../../components/Routes/SubRoute';

export default function CostumerCare({ match, routes, location }) {

    const requestsForAssistanceOptions = {
        params: { reply: { $exists: false } }
    };
    const { data: requestsForAssistance } = useQuery(
        ["requestsForAssistance", requestsForAssistanceOptions],
        () => getRequests({ params: requestsForAssistanceOptions })
    );

    const tabs = [{
        text: "Recensioni",
        component: Link,
        to: `${match.url}/reviews`,
    }, {
        text: "Segnalazioni",
        component: Link,
        to: `${match.url}/reports`,
        notifications: requestsForAssistance?.length,
    }, {
        text: "Richieste informazioni",
        component: Link,
        to: `${match.url}/informationsRequests/interest`,
    }, {
        text: "Richieste iscrizione",
        component: Link,
        to: `${match.url}/informationsRequests/preorder`,
    }];

    const tabsArray = tabs && tabs.map((t) => t.to);

    return (
        <MainContainer large>
            <TitleContainer>
                <MainTitle text="Costumer Care" />
            </TitleContainer>

            <ColumnContainer noMargin>
                <Column>
                    <Tabs list={tabs} activeIndex={tabsArray.indexOf(location.pathname)} />
                </Column>
            </ColumnContainer>

            <ColumnContainer>
                <Column>
                    <Switch>
                        {routes?.map((page, index) => <SubRoute key={index} {...page} />)}
                    </Switch>
                </Column>
            </ColumnContainer>
        </MainContainer>
    );
}
