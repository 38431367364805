import { returnError } from "./_defaults/error";
import axios from "./_defaults/user";

export async function getInformationsRequests({params}) {
    try {
        const response = await axios.get(`/admin-information-requests`, {params});
        return response?.data;
    } catch(error) {
        return returnError(error);
    }
}

export async function createInformationsRequest({data}) {
    try {
        const response = await axios.post(`/admin-information-requests`, data);
        return response?.errors ? response?.data : {};
    } catch (error) {
        return error;
    }
}

export async function getInformationsRequest({elementId}) {
    try {
        const response = await axios.get(`/admin-information-requests/${elementId}`)
        return response?.data;
    } catch(error) {
        return error;
    }
}

export async function updateInformationsRequest({elementId, data}) {
    try {
        const response = await axios.post(`/admin-informations-requests/${elementId}`, data);
        return response?.data;
    } catch(error) {
        return error;
    }
}

export async function removeInformationsRequest({elementId}) {
    try {
        const response = await axios.delete(`/admin-informations-requests/${elementId}`);
        return response?.data;
    } catch(error) {
        return error;
    }
}
