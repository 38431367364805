import React, { useState } from "react";
import qs from "query-string";
import { Link } from "react-router-dom";
import { usePaginatedQuery, useQuery } from "react-query";

import {
    MainContainer,
    TitleContainer,
    MainTitle,
    ColumnContainer,
    Column,
    SimpleTable
} from "@beacharound/beacharound-ui";

import { getLocations, updateLocation } from "../../../api/locations";
import LocationActivationPopup from "../../../components/Locations/LocationActivationPopup";
import HighlightPopup from "../../../components/Popups/HighlightPopup";

export default function List({ history, match, location }) {
    
    const { page } = location.search ? qs.parse(location.search) : { page: "0" };
    const { data: districts } = useQuery("districts", () => getLocations({ type: "districts" }));
    const { data: regions } = useQuery("regions", () => getLocations({ type: "regions" }));

    const [ limit, setLimit ] = useState(25);
    const [ search, setSearch ] = useState();
    const [ params, setParams ] = useState({});
    const [ highlightItem, setHighlightItem ] = useState();
    const [ selectedItem, setSelectedItem ] = useState();

    const options = {
        skip: Number(page) * limit,
        limit,
        sort: { name: 1 },
        search,
        params: {
            ...params
        },
    };
    
    const { resolvedData: data, isLoading } = usePaginatedQuery(["cities", options], () =>
        getLocations({
            type: "cities",
            params: options,
        })
    )

    const tableHead = [
        {
            text: "Nome",
            key: "name",
            sort: "text",
            highlight: true,
            default: true,
            image: (item) => item?.cover,
        },
        {
            text: "Provincia",
            key: "districtName",
            sort: true,
        },
        {
            text: "Attivo",
            key: "published",
            hasMobileLabel: true,
            flex: 0.5,
            parse: (value) => (value === true ? "Si" : "No"),
        },
        {
            flex: 0.5,
            actions: (item) => {
                return [
                    {
                        icon: item.published ? "visible" : "not-visible",
                        action: () => setSelectedItem(item),
                    },
                    {
                        icon: item.highlight ? "heart-full" : "heart",
                        action: () => setHighlightItem(item),
                    },
                    {
                        icon: "edit",
                        component: Link,
                        to: `${match.url}/${item._id}`,
                    },
                ];
            },
        },
    ];

    const tableRows = data?.map(item => {
        return {
            ...item,
            districtName: item.district?.name
        }
    }) || [];

    function filterActionHandler(name, value, options) {
        setParams(
            options?.isRemoving
                ? {
                        ...params,
                        [name]: undefined,
                    }
                : {
                        ...options,
                        [name]: value,
                    }
        );
        return history.push(`${match.url}`);
    }

    const availableFilters = [
        {
            label: "Regione",
            name: "region",
            type: "select",
            options: regions?.map(({ _id, name }) => {
                return {
                    value: _id,
                    label: name,
                };
            }),
            action: filterActionHandler,
        },
        {
            label: "Provincia",
            name: "district",
            type: "select",
            options: districts?.map(({ _id, name }) => {
                return {
                    value: _id,
                    label: name,
                };
            }),
            action: filterActionHandler,
        },
        {
            label: "Attivi",
            name: "published",
            type: "boolean",
            action: filterActionHandler,
        },
    ];

    // TODO: [BEACHDEV-70] Sistemare il filtraggio multiplo: quando inserisco più filtri prende solo l'ultimo

    return (
        <MainContainer large>
            <TitleContainer>
                <MainTitle text="Comuni" />
            </TitleContainer>

            <ColumnContainer>
                <Column>
                    <SimpleTable
                        match={match}
                        history={history}
                        setLimit={setLimit}
                        navigation
                        showFilters
                        data={tableRows}
                        head={tableHead}
                        availableFilters={availableFilters}
                        isLoading={isLoading}
                        page={page}
                        searched={search}
                        onSearch={(value) => {
                            setSearch(value);
                            history.push(match.url);
                        }}
                        onSearchClear={() => {
                            setSearch(undefined);
                            history.push(match.url);
                        }}
                    />
                </Column>
            </ColumnContainer>

            <LocationActivationPopup
                item={selectedItem}
                setItem={setSelectedItem}
                locationType={"cities"}
            />

            <HighlightPopup
                item={highlightItem}
                setItem={setHighlightItem}
                type={"cities"}
                updateFunction={updateLocation}
            />
        </MainContainer>
    )
}
