import axios from './_defaults/user';

export async function getQuotations({ elementId, params }) {
    try {
        const response = await axios.get(
            `/admin-hotels/${elementId}/quotations`,
            {
                params,
            }
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error?.response?.status === 404) {
            return [];
        }

        throw error;
    }
}

export async function getQuotation({ elementId, quotationId }) {
    try {
        const response = await axios.get(
            `/admin-hotels/${elementId}/quotations/${quotationId}`
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error?.response?.status === 404) {
            return {};
        }

        throw error;
    }
}

export async function updateQuotation({ elementId, quotationId, data }) {
    try {
        const response = await axios.post(
            `/admin-hotels/${elementId}/quotations/${quotationId}`,
            { ...data }
        );

        if (response?.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response.status === 404) return [];
    }
}