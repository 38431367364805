export function getServicesObject(allServices, activeServices) {

    if(!allServices || !Array.isArray(allServices)) return {};
    if(!activeServices || !Array.isArray(activeServices)) return {};

    return activeServices?.reduce((acc, service) => {
        const element = allServices?.find((el) => el._id === service._id);
        return {...acc, [element?.slug]: service ? true : false }
    }, {}) || {};
}

export function getServicesString(allServices, activeServices) {
    return Object.keys(getServicesObject(allServices, activeServices))?.reduce(
        (acc, key) =>
            acc.length > 0 ? `${acc}, ${key}` : `${key}`,
            ""
    )
}

export function getServicesArray(allServices, data) {

    if(!data) return [];
    if(!allServices || !Array.isArray(allServices)) return [];

    return Object
            .keys(data)
            .filter(key => allServices.some(service => service.slug === key) && data[key] === true)
            .map(key => {
                const service = allServices.find(
                    (service) => service.slug === key
                );
                return {
                    _id: service._id,
                    isCustom: false,
                };
            })
}

export function getAccessibilitiesObject(allAccessibilities, activeAccessibilities) {
    return activeAccessibilities?.reduce((acc, accessibility) => {
        const element = allAccessibilities?.find((el) => el._id === accessibility._id);
        return {...acc, [element?.slug]: accessibility?.value }
    }, {}) || {};
}

export function getAccessibilitiesArray(allAccessibilities, data) {

    if(!data) return [];
    if(!allAccessibilities || !Array.isArray(allAccessibilities)) return [];

    return Object
            .keys(data)
            .filter(key => allAccessibilities.some(accessibility => accessibility.slug === key))
            .map(key => {
                const accessibility = allAccessibilities.find(accessibility => accessibility.slug === key)
                return {
                    _id: accessibility._id,
                    isCustom: false,
                    value: data[key]
                };
            })
}