import BeachesList from '../../pages/Financial/Reservations/Beaches';
import PublicBeachesList from '../../pages/Financial/Reservations/PublicBeaches';
import OffersList from '../../pages/Financial/Reservations/Offers';


export const routesReservations = [
    {
        path: '/beaches/private',
        component: BeachesList
    },{
        path: '/beaches/public',
        component: PublicBeachesList
    },{
        path: '/hotels',
        component: OffersList
    }
].map((item) => {
    return {
        ...item,
        routes: item.routes?.map(route => {
            return {
                ...route,
                path: `/reservations${route.path}`
            }
        }),
        path: `/reservations${item.path}`,
        private: true
    }
})