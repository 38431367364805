import React from "react";
import { Link, Switch } from "react-router-dom";
import { useQuery } from 'react-query';

import {
    MainContainer,
    Tabs,
    TitleContainer,
    MainTitle,
    SubTitle,
    ColumnContainer,
    Column,
    LoadingContainer
} from "@beacharound/beacharound-ui";

import { getImageCrop } from '@beacharound/beacharound-ui-helpers';

import SubRoute from '../../../components/Routes/SubRoute';

import { getBeach } from "../../../api/beach";

export default function Detail({ match, routes, location }) {

    const { elementId, type } = match.params;
    const { data, isLoading } = useQuery(["beaches", elementId], () =>
        getBeach({ elementId })
    )

    let tabs = [{
        text: "Transazioni",
        component: Link,
        to: `${match.url}/transactions`
    },{
        text: "Impostazioni",
        component: Link,
        to: `${match.url}/settings`
    },{
        text: "Memberships",
        component: Link,
        to: `${match.url}/memberships`
    },{
        text: "Organizzazioni",
        component: Link,
        to: `${match.url}/organizations`
    },{
        text: "Prenotazioni",
        component: Link,
        to: `${match.url}/reservations`
    },{
        text: "Accessi",
        component: Link,
        to: `${match.url}/entrances`
    },{
        text: "Ombrelloni",
        component: Link,
        to: `${match.url}/umbrellas`,
        noPublic: true
    },{
        text: "Lettini",
        component: Link,
        to: `${match.url}/deckchairs`,
        noPublic: true
    },{
        text: "Parcheggi",
        component: Link,
        to: `${match.url}/parkings`,
        noPublic: true
    },{
        text: "Servizi",
        component: Link,
        to: `${match.url}/services`,
        noPublic: true
    }]

    if (type === 'public') {
        tabs = tabs.filter((tab) => !tab.noPublic )
    }

    const tabsArray = tabs?.map((t) => t.to)

    return (
        <MainContainer large marginBottom>

            <LoadingContainer isLoading={isLoading}>

                <TitleContainer image={{type: (type === 'public' ? 'public-beach' : 'beach'), url: getImageCrop(data?.cover, 'xs')}}>
                    <MainTitle text={data?.name} />
                    <SubTitle
                        text="< Torna alla lista"
                        component={Link}
                        to={`/activities/beaches/${type}`}
                    />
                </TitleContainer>

                <ColumnContainer lowMargin>
                    <Column>
                        <Tabs list={tabs} activeIndex={tabsArray.indexOf(location.pathname)}/>
                    </Column>
                </ColumnContainer>

                <ColumnContainer>
                    <Column>
                        <Switch>
                            {routes?.map((page, index) => {
                                return <SubRoute key={index} {...page} />;
                            })}
                        </Switch>
                    </Column>
                </ColumnContainer>
            </LoadingContainer>
            
        </MainContainer>
    )
}
