import React from "react";
import { Link } from "react-router-dom";
import { useMutation, useQuery, useQueryCache } from "react-query";

import { getSubFormData } from "@beacharound/beacharound-ui-helpers";
import {
    MainContainer,
    MainForm,
    MainFormContent,
    SectionTitle,
    TitleContainer,
    MainTitle,
    SubTitle,
    FormToggleGroup,
    FormToggleGroupPreview,
    SimpleFormField,
    FormToggleGroupEdit,
    TextAreaInput,
    GooglePlacesInput,
    LoadingContainer,
    EditImageGroup,
    TextInput,
    ColumnContainer,
    Column,
    ToggleInput
} from "@beacharound/beacharound-ui";

import { getLocation, updateLocation, getPutUrl } from "../../../api/locations";
import { uploadImage } from "../../../api/images";

export default function Detail({ match }) {
    const { elementId } = match.params;
    const { data, isLoading } = useQuery(["cities", elementId], () =>
        getLocation({ type: "cities", elementId })
    );

    const cache = useQueryCache();
    const [update, { status: updateStatus, reset }] = useMutation(
        updateLocation,
        {
            onSuccess: (data) => {
                cache.invalidateQueries("cities");
                cache.setQueryData(["cities", elementId], data);
            },
        }
    );

    async function editElement(data, callback) {
        try {
            await update({
                type: "cities",
                elementId,
                data,
            });
            return callback(reset);
        } catch (error) {
            return callback(null, error);
        }
    }

    async function editGeodata({ coordinates }) {
        try {
            await update({
                type: "cities",
                elementId,
                data: {
                    "geocode.coordinates": coordinates,
                },
            });
        } catch (error) {
            throw error;
        }
    }

    async function editCover(file) {
        try {
            const { url, key } = await getPutUrl({
                elementId,
                file,
                type: "cities",
            });

            await uploadImage({ url, file });

            await update({
                type: "cities",
                elementId,
                data: {
                    cover: {
                        url: key,
                    },
                },
            });
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <MainContainer marginBottom>

            <ColumnContainer>
                <Column column={2}>
                    <TitleContainer noMargin>
                        <MainTitle text={data?.name || "Gestisci comune"} />
                        <SubTitle
                            text="< Torna alla lista"
                            component={Link}
                            to={`/places/cities`}
                        />
                    </TitleContainer>
                </Column>

                <Column column={2}>
                    <ToggleInput
                        flexEnd
                        trueLabel="Pubblicato"
                        falseLabel="Non pubblicato"
                        status={updateStatus}
                        changeValue={()=> editElement({published: !data?.published}, (resetAfter) => resetAfter())}
                        value={data?.published}
                    />
                </Column>
            </ColumnContainer>

            <LoadingContainer isLoading={isLoading}>
                <TitleContainer>
                    <SectionTitle text="Dati generali" />
                </TitleContainer>

                <MainForm
                    onSubmit={editElement}
                    data={getSubFormData(data, ["description", "subtitle"])}
                >
                    <MainFormContent>
                        <FormToggleGroup>
                            <FormToggleGroupPreview>
                                <SimpleFormField
                                    label="Sottotitolo"
                                    name="subtitle"
                                />
                                <SimpleFormField
                                    label="Descrizione"
                                    name="description"
                                />
                            </FormToggleGroupPreview>

                            <FormToggleGroupEdit flex status={updateStatus}>
                                <TextInput
                                    label="Sottotitolo"
                                    name="subtitle"
                                />
                                <TextAreaInput
                                    label="Descrizione"
                                    name="description"
                                />
                            </FormToggleGroupEdit>
                        </FormToggleGroup>
                    </MainFormContent>
                </MainForm>

                <EditImageGroup data={data?.cover} onSubmit={editCover} />

                <TitleContainer>
                    <SectionTitle text="Posizione" />
                </TitleContainer>

                <MainForm onSubmit={editGeodata} data={data?.geocode}>
                    <MainFormContent>
                        <FormToggleGroup>
                            <FormToggleGroupPreview>
                                <SimpleFormField
                                    label="Coordinate"
                                    name="coordinates"
                                    half
                                />
                            </FormToggleGroupPreview>

                            <FormToggleGroupEdit flex status={updateStatus}>
                                <GooglePlacesInput
                                    name="coordinates"
                                    label="Indirizzo"
                                    placeholder="Cerca un luogo"
                                    height="370px"
                                    coordsOnly
                                    required
                                />
                            </FormToggleGroupEdit>
                        </FormToggleGroup>
                    </MainFormContent>
                </MainForm>
            </LoadingContainer>
        </MainContainer>
    );
}
