import List from '../../pages/Places/Images/List';

export const subRoutesImages = [
    {
        path: "",
        component: List,
        exact: true,
    }
].map((item) => {
    return {
        ...item,
        path: `/images${item.path}`,
        private: true
    };
});