import React from "react";
import { Link } from "react-router-dom";
import { useMutation, useQuery, useQueryCache } from "react-query";

import {
    getPointOfInterest,
    updatePointOfInterest,
    uploadGalleryImage as updateGalleryImage,
    updateImage,
} from "../../../api/pointsOfInterests";

import {
    MainContainer,
    MainForm,
    MainFormContent,
    SectionTitle,
    TitleContainer,
    MainTitle,
    SubTitle,
    FormToggleGroup,
    FormToggleGroupPreview,
    SimpleFormField,
    FormToggleGroupEdit,
    TextInput,
    TextAreaInput,
    LoadingContainer,
    EditAddressGroup,
    EditImageGroup,
    SelectInput,
    ColumnContainer,
    Column,
    ToggleInput
} from "@beacharound/beacharound-ui";

import {
    getSubFormData,
    pointsOfInterestTypes,
} from "@beacharound/beacharound-ui-helpers";

const constraints = {
    name: { presence: true },
    subtitle: { presence: true },
    description: { presence: true },
}

export default function Detail({ match }) {
    const { elementId } = match.params;
    const { data, isLoading } = useQuery(["pointsOfInterest", elementId], () =>
        getPointOfInterest({ elementId })
    );

    const cache = useQueryCache();
    const [update, { status: updateStatus, reset } ] = useMutation(
        updatePointOfInterest,
        {
            onSuccess: (data) => {
                cache.invalidateQueries("pointsOfInterest");
                cache.setQueryData(["pointsOfInterest", elementId], data);
            },
        }
    );

    const [
        uploadImage,
        { status: updateImageStatus, reset: imageReset },
    ] = useMutation(updateImage, {
        onSuccess: (data) => {
            cache.invalidateQueries(["pointsOfInterest"]);
            cache.setQueryData(["pointsOfInterest", elementId], data);
        },
    });

    const [uploadGallery] = useMutation(
        updateGalleryImage,
        {
            onSuccess: (data) => {
                cache.invalidateQueries(["pointsOfInterest"]);
                cache.setQueryData(["pointsOfInterest", elementId], data);
            },
        }
    );

    function editElement(data) {
        return update({
            elementId,
            data,
        });
    }

    async function editGeodata(data) {
        try {
            await update({
                elementId,
                data: {
                    address: {
                        ...data
                    }
                },
            });
        } catch(error) {
            throw error;
        }
    }

    async function editCover(file) {
        try {
            await uploadImage({ elementId, file });
        } catch (error) {
            throw error;
        }
    }

    async function uploadGalleryImage(file, index, callback) {
        try {
            await uploadGallery({ elementId, file, index });
            return callback("LOADED");
        } catch (error) {
            throw error;
        }
    }

    async function removeGalleryImage(images, index, callback) {
        try {

            await update({
                elementId,
                data: {
                    $pull: {
                        gallery: {
                            index
                        }
                    }  
                },
            });
            return callback();

        } catch (error) {
            throw error;
        }
    }

    return (
        <MainContainer marginBottom>

            <ColumnContainer>
                <Column column={2}>
                    <TitleContainer noMargin>
                        <MainTitle text={data?.name || "Gestisci luogo"} />
                        <SubTitle
                            text="< Torna alla lista"
                            component={Link}
                            to={`/places/pointsOfInterest`}
                        />
                    </TitleContainer>
                </Column>

                <Column column={2}>
                    <ToggleInput
                        flexEnd
                        trueLabel="Pubblicato"
                        falseLabel="Non pubblicato"
                        status={updateStatus}
                        changeValue={()=> editElement({published: !data?.published}, (resetAfter) => resetAfter())}
                        value={data?.published}
                    />
                </Column>
            </ColumnContainer>

            <LoadingContainer isLoading={isLoading}>
                <TitleContainer>
                    <SectionTitle text="Dati generali" />
                </TitleContainer>
                <MainForm
                    onSubmit={editElement}
                    data={getSubFormData(data, [
                        "name",
                        "description",
                        "subtitle",
                        "type",
                    ])}
                    constraints={constraints}
                >
                    <MainFormContent>
                        <FormToggleGroup>
                            <FormToggleGroupPreview>
                                <SimpleFormField
                                    label="Nome"
                                    name="name"
                                    half
                                />
                                <SimpleFormField
                                    label="Tipologia"
                                    name="type"
                                    options={pointsOfInterestTypes}
                                    half
                                />
                                <SimpleFormField
                                    label="Sottotitolo"
                                    name="subtitle"
                                />
                                <SimpleFormField
                                    label="Descrizione"
                                    name="description"
                                />
                            </FormToggleGroupPreview>

                            <FormToggleGroupEdit flex status={updateStatus}>
                                <TextInput label="Nome" name="name" half />
                                <SelectInput
                                    label="Tipologia"
                                    name="type"
                                    options={pointsOfInterestTypes}
                                    half
                                />
                                <TextInput
                                    label="Sottotitolo"
                                    name="subtitle"
                                />
                                <TextAreaInput
                                    label="Descrizione"
                                    name="description"
                                />
                            </FormToggleGroupEdit>
                        </FormToggleGroup>
                    </MainFormContent>
                </MainForm>

                <EditImageGroup
                    data={data?.cover}
                    onSubmit={editCover}
                    reset={imageReset}
                    status={updateImageStatus}
                    gallery={data?.gallery}
                    uploadGalleryImage={uploadGalleryImage}
                    removeGalleryImage={removeGalleryImage}
                />

                <EditAddressGroup
                    data={data?.address}
                    onSubmit={editGeodata}
                    reset={reset}
                    status={updateStatus}
                />
            </LoadingContainer>
        </MainContainer>
    );
}
