import axios from '../_defaults/user';

export async function getReservations({ elementId, params }) {
    try {
        const response = await axios.get(
            `/admin-hotels/${elementId}/reservations`,
            {
                params,
            }
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error?.response?.status === 404) {
            return [];
        }

        throw error;
    }
}

export async function getReservation({ elementId, reservationId }) {
    try {
        const response = await axios.get(
            `/admin-hotels/${elementId}/reservations/${reservationId}`
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error?.response?.status === 404) {
            return {};
        }

        throw error;
    }
}

export async function replyReservation({ elementId, reservationId, data }) {
    try {
        const response = await axios.post(
            `/admin-hotels/${elementId}/reservations/${reservationId}/send`,
            { ...data }
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response.status === 404) return [];
        console.log(error.response);
    }
}

export async function updateReservation({ elementId, reservationId, data }) {
    try {
        const response = await axios.post(
            `/admin-hotels/${elementId}/reservations/${reservationId}`,
            { ...data }
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response.status === 404) return [];
        console.log(error.response);
    }
}