export function getStatusString(status) {
    if (!status) return "";

    switch (status) {
        case "CHECKINGOUT":
            return "Non inviata";
        case "PENDING":
            return "Da rispondere";
        case "ACCEPTED":
            return "Preventivo inviato";
        case "DECLINED":
            return "Richiesta rifiutata";
        case "CONFIRMED":
            return "Confermato";
        case "REJECTED":
            return "Rifiutato dall'utente";
        case "PAID":
            return "Pagato dall'utente";
        default:
            return "";
    }
}

export function getStatusColor(status) {
    if (!status) return "";

    switch (status) {
        case "CHECKINGOUT":
            return "grey1";
        case "PENDING":
            return "red";
        case "ACCEPTED":
            return "green";
        case "DECLINED":
            return "grey1";
        case "CONFIRMED":
            return "blue";
        case "REJECTED":
            return "grey1";
        case "PAID":
            return "green";
        default:
            return "-";
    }
}

export function getStatusHistoryString(status) {
    if (!status) return "";

    switch (status) {
        case "CHECKINGOUT":
            return "L'utente iniziato a creare la prenotazione";
        case "PENDING":
            return "L'utente ha inviato la richiesta di prenotazione";
        case "ACCEPTED":
            return "La struttura ha accettato la prenotazione";
        case "DECLINED":
            return "La struttura ha rifiutato la prenotazione";
        case "CONFIRMED":
            return "L'utente ha confermato il nuovo prezzo di prenotazione";
        case "REJECTED":
            return "L'utente ha rifiutato il nuovo prezzo di prenotazione";
        case "PAID":
            return "L'utente ha pagato la prenotazione";
        default:
            return "";
    }
}

export function getPeopleString(details) {
    if (!details || !Array.isArray(details)) return "";
    return details
        .filter(({ key }) => ["adults", "children", "babies"].includes(key))
        .map(({ key, value }) => {
            let name;
            switch (key) {
                case "adults":
                    name = "Adulti";
                    break;
                case "children":
                    name = "Bambini";
                    break;
                case "babies":
                    name = "Neonati";
                    break;
                default:
                    name = "";
            }
            return `${value} ${name}`;
        })
        .join(", ");
}
