export const activityTypeOptions = [
    {
        label: "Spiaggia",
        value: "beach",
    },
    {
        label: "Hotel",
        value: "hotel",
    },
    {
        label: "Esperienza",
        value: "experience",
    },
];
export const requestTypeOptions = [
    {
        label: "Preordine",
        value: "PREORDER",
    },
    {
        label: "Interesse",
        value: "INTEREST",
    },
    {
        label: "Collaborazione",
        value: "COLLABORATION",
    },
];
