import axios from "../_defaults/user";

const baseUrl = "/admin-data-counters";

export async function getReservations({ params }) {
    try {
        const response = await axios.get(`${baseUrl}/reservations`, {
            params,
        });

        if (response?.data) {
            return response?.data;
        }
    } catch (error) {
        return error;
    }
}
