import React from "react";
import { useMutation, useQueryCache } from "react-query";

import {
    NormalPopup,
    ButtonsRow,
    MainButton,
    Paragraph,
    TitleContainer
} from "@beacharound/beacharound-ui";

import { removeInvitation } from "../../../api/users/admin";

export default function RemoveInvitationPopup({ item, setItem }) {
    const cache = useQueryCache();

    const [remove, { status }] = useMutation(removeInvitation, {
        onSuccess: () => {
            cache.invalidateQueries("userInvitations");
            return setItem(undefined);
        },
    });

    function removeInvitationHandler() {
        remove({
            elementId: item._id,
        });
    }

    return (
        <NormalPopup
            visible={item}
            setVisible={() => setItem(false)}
            title="Cancella invito"
            large
        >
            <TitleContainer>
                <Paragraph
                    text={item?.status === "PENDING" ? "Sei sicuro di voler cancellare questo invito?" : "Non puoi cancellare gli inviti già accettati"}
                />
            </TitleContainer>

            <ButtonsRow fill>
                <MainButton
                    text="Annulla"
                    border
                    action={() => setItem(false)}
                />
                <MainButton
                    text="Conferma"
                    submit
                    status={item?.status === "PENDING" ? status : "DISABLED"}
                    action={removeInvitationHandler}
                />
            </ButtonsRow>
        </NormalPopup>
    );
}
