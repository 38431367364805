import generalRoutes from "./general";
import settings from "./settings";
import users from "./users";
import places from "./places";
import activities from "./activities";
import blog from "./blog";
import costumerCare from "./costumerCare";
import servicesRoutes from "./catalogues";
import financial from "./financial";

export default [
    ...generalRoutes?.filter(r => ["/"].includes(r.path)),
    ...settings,
    ...users,
    ...places,
    ...activities,
    ...financial,
    ...blog,
    ...costumerCare,
    ...servicesRoutes,
].map((route) => ({
    ...route,
    path: `${route.path}`,
    private: true
}));
