import React, { useState } from "react";
import qs from "query-string";
import { Link } from "react-router-dom";
import { usePaginatedQuery } from "react-query";

import {
    MainContainer,
    TitleContainer,
    MainTitle,
    ColumnContainer,
    Column,
    SimpleTable,
    ButtonsRow,
    MainButton
} from "@beacharound/beacharound-ui";

import { getProducts } from '../../../../../api/products';

export default function Services({ history, match, location }) {
    
    const { elementId } = match.params;
    const { page } = location.search ? qs.parse(location.search) : { page: "0" };

    const [ limit, setLimit ] = useState(25);

    const options = {
        skip: Number(page) * limit,
        limit,
        sort: { name: 1 },
        params: {}
    }
    
    const { data, isLoading } = usePaginatedQuery(["services", options], () =>
        getProducts({
            elementId,
            params: {
                type: 'service',
                ...options
            }
        })
    )

    // TODO: [BEACHDEV-98] Non funziona la lista dei servizi della spiaggia

    const tableHead = [{
        text: "Nome",
        key: "name",
        sort: "text",
        highlight: true,
        default: true,
        image: (item) => item?.cover,
    },{
        flex: 0.5,
        actions: (item) => {
            return [{
                icon: "edit",
                component: Link,
                to: `${match.url}/${item._id}`
            }]
        }
    }]

    const tableRows = data || [];

    return (
        <MainContainer large>

            <ColumnContainer noMargin>
                <Column column={2}>
                    <TitleContainer>
                        <MainTitle text="Servizi" />
                    </TitleContainer>
                </Column>
                <Column column={2}>
                    <ButtonsRow flexEnd>
                        <MainButton text="Nuovo servizio" component={Link} to={`${match.url}/create`}/>
                    </ButtonsRow>
                </Column>
            </ColumnContainer>

            <ColumnContainer>
                <Column>
                    <SimpleTable
                        match={match}
                        history={history}
                        setLimit={setLimit}
                        navigation
                        data={tableRows}
                        head={tableHead}
                        isLoading={isLoading}
                        page={page}
                    />
                </Column>
            </ColumnContainer>
        </MainContainer>
    );
}
