// districts

import List from '../../pages/Places/Districts/List';
import Detail from '../../pages/Places/Districts/Detail';

export const subRoutesDistricts = [
    {
        path: "",
        component: List,
        exact: true,
    }
].map((item) => {
    return {
        ...item,
        path: `/districts${item.path}`,
        private: true
    };
});

export const routesDistricts = [
    {
        path: "/:elementId",
        component: Detail,
    }
].map((item) => {
    return {
        ...item,
        path: `/districts${item.path}`,
        private: true
    };
});