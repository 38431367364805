import React, { Fragment } from 'react';
// import { useQuery, useMutation, useQueryCache } from 'react-query';

import { MainForm, MainFormContent, TextInput, NormalPopup, ButtonsRow, MainButton, Divider, SelectionBoxInput, SelectInput } from '@beacharound/beacharound-ui';

import { daysOfTheWeekOptions } from "@beacharound/beacharound-ui-helpers";

const conditionOptions = [{
    title: 'Limite di persone',
    description: 'Imposta un minimo e un massimo di persone',
    value: 'people'
},{
    title: 'Limite di notti',
    description: 'Imposta una durata minima e massima di permanenza',
    value: 'duration'
},{
    title: 'Giorno del checkin',
    description: 'Imposta un giorno della settimana per il checkin',
    value: 'checkinDay'
}]

export default function OfferConditions({
    conditions,
    setConditions,
    conditionsListPopup,
    conditionItemPopup,
    setConditionListPopup,
    setConditionItemPopup,
    status
}) {
    async function addCondition(data) {
        try {
            let newCoditions = [];
            if (conditions) {
                newCoditions = conditions;
            }

            // Togliamo i letti con lo stesso tipo nel caso ci siano così da evitare inserimenti doppi
            newCoditions = newCoditions.filter(
                ({ type }) => type !== data.type
            );

            const condition = conditionOptions.find(option => option.value === data.type);

            await setConditions([...newCoditions, {...data, ...condition}]);
            setConditionListPopup(false);
            return setConditionItemPopup(data);
        } catch (error) {
            console.log(error);
        }
    }

    async function editCondition(data) {
        try {
            
            let newCoditions = conditions || [];

            const conditionIndex = conditions.findIndex(
                (condition) => condition.type === conditionItemPopup.type
            );
            
            newCoditions[conditionIndex] = {
                ...(newCoditions[conditionIndex] || {}),
                ...data
            };

            await setConditions(newCoditions);
            return setConditionItemPopup(false);
        } catch (error) {
            console.log(error);
        }
    }

    async function deleteCondition() {
        try {
            // Togliamo i letti con lo stesso tipo nel caso ci siano così da evitare inserimenti doppi

            await setConditions(
                conditions?.filter(
                    ({ type }) => type !== conditionItemPopup.type
                )
            );
            return setConditionItemPopup(false);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Fragment>
            {/* POPUP PER LA SELEZIONE DEL TIPO DI CONDIZIONE */}
            <NormalPopup
                visible={conditionsListPopup}
                setVisible={() => setConditionListPopup(false)}
                title="Seleziona tipo di condizione"
            >
                <MainForm
                    onSubmit={addCondition}
                    constraints={{ type: { presence: true } }}
                >
                    <MainFormContent flex>
                        <SelectionBoxInput
                            options={conditionOptions}
                            name="type"
                        />
                    </MainFormContent>
                    <Divider />
                    <ButtonsRow fill>
                        <MainButton
                            text="Annulla"
                            border
                            action={() => setConditionListPopup(false)}
                        />
                        <MainButton text="Avanti" submit />
                    </ButtonsRow>
                </MainForm>
            </NormalPopup>

            {/* POPUP PER LIMITE DI PERSONE */}
            <NormalPopup
                visible={conditionItemPopup?.type === "people"}
                setVisible={() => setConditionItemPopup(false)}
                title="Limite di persone"
            >
                <MainForm
                    onSubmit={editCondition}
                    constraints={{
                        min: { presence: true },
                        max: { presence: true }
                    }}
                    data={conditionItemPopup}
                >
                    <MainFormContent flex>
                        <TextInput
                            label="Numero minimo di persone"
                            type="number"
                            name="min"
                            half
                        />
                        <TextInput
                            label="Numero massimo di persone"
                            type="number"
                            name="max"
                            half
                        />
                    </MainFormContent>
                    <Divider />
                    <ButtonsRow fill>
                        {(conditionItemPopup?.min ||
                            conditionItemPopup?.max) && (
                            <MainButton
                                text="Annulla"
                                border
                                action={() => setConditionItemPopup(false)}
                            />
                        )}
                        <MainButton
                            text="Rimuovi"
                            border
                            action={deleteCondition}
                        />
                        <MainButton text="Salva" submit status={status} />
                    </ButtonsRow>
                </MainForm>
            </NormalPopup>

            {/* POPUP PER LIMITE DI NOTTI */}
            <NormalPopup
                visible={conditionItemPopup?.type === "duration"}
                setVisible={() => setConditionItemPopup(false)}
                title="Limite di notti"
                data={conditionItemPopup}
            >
                <MainForm
                    onSubmit={editCondition}
                    constraints={{
                        min: { presence: true },
                        max: { presence: true }
                    }}
                >
                    <MainFormContent flex>
                        <TextInput
                            label="Numero minimo di notti"
                            type="number"
                            name="min"
                            half
                        />
                        <TextInput
                            label="Numero massimo di notti"
                            type="number"
                            name="max"
                            half
                        />
                    </MainFormContent>
                    <Divider />
                    <ButtonsRow fill>
                        {(conditionItemPopup?.min ||
                            conditionItemPopup?.max) && (
                            <MainButton
                                text="Annulla"
                                border
                                action={() => setConditionItemPopup(false)}
                            />
                        )}
                        <MainButton
                            text="Rimuovi"
                            border
                            action={deleteCondition}
                        />
                        <MainButton text="Salva" submit status={status} />
                    </ButtonsRow>
                </MainForm>
            </NormalPopup>

            {/* POPUP PER IL GIORNO DI CHECKIN */}
            <NormalPopup
                visible={conditionItemPopup?.type === "checkinDay"}
                setVisible={() => setConditionItemPopup(false)}
                title="Limite di persone"
                data={conditionItemPopup}
            >
                <MainForm
                    onSubmit={editCondition}
                    constraints={{ checkinDay: { presence: true } }}
                >
                    <MainFormContent flex>
                        <SelectInput
                            label="Giorno della settimana"
                            options={daysOfTheWeekOptions}
                            name="checkinDay"
                        />
                    </MainFormContent>
                    <Divider />
                    <ButtonsRow fill>
                        {conditionItemPopup?.checkinDay && (
                            <MainButton
                                text="Annulla"
                                border
                                action={() => setConditionItemPopup(false)}
                            />
                        )}
                        <MainButton
                            text="Rimuovi"
                            border
                            action={deleteCondition}
                        />
                        <MainButton text="Salva" submit status={status} />
                    </ButtonsRow>
                </MainForm>
            </NormalPopup>
        </Fragment>
    );
}