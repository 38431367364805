import React from "react";
import { Link, Switch } from "react-router-dom";

import {
    MainContainer,
    MainTitle,
    TitleContainer,
    ColumnContainer,
    Column,
    Tabs,
} from "@beacharound/beacharound-ui";

import SubRoute from '../../components/Routes/SubRoute';

export default function Blog({ match, routes, location }) {

    const tabs = [{
        text: "Articoli",
        component: Link,
        to: `${match.url}/articles`,
    },{
        text: "Classifiche",
        component: Link,
        to: `${match.url}/rankings`,
    },{
        text: "Tutorial",
        component: Link,
        to: `${match.url}/tutorials`,
    },{
        text: "Categorie",
        component: Link,
        to: `${match.url}/categories`,
    }];

    const tabsArray = tabs?.map((t) => t.to)

    return (
        <React.Fragment>
            <MainContainer large>
                <TitleContainer>
                    <MainTitle text="Blog" />
                </TitleContainer>

                <ColumnContainer noMargin>
                    <Column>
                        <Tabs list={tabs} activeIndex={tabsArray.indexOf(location.pathname)}/>
                    </Column>
                </ColumnContainer>

                <ColumnContainer>
                    <Column>
                        <Switch>
                            {routes?.map((page, index) => {
                                return <SubRoute key={index} {...page} />;
                            })}
                        </Switch>
                    </Column>
                </ColumnContainer>
            </MainContainer>
        </React.Fragment>
    );
}
