// users
import List from '../../pages/Settings/Users/List';
import Detail from '../../pages/Settings/Users/Detail';

export const subRoutesUsers = [
    {
        path: "",
        component: List,
        exact: true,
    },
].map((item) => {
    return {
        ...item,
        path: `/admins${item.path}`,
        private: true
    };
});

export const routesUsers = [
    {
        path: "/:elementId",
        component: Detail,
    }
].map((item) => {
    return {
        ...item,
        path: `/admins${item.path}`,
        private: true
    };
});