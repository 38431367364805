import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery, useMutation, useQueryCache } from 'react-query';
import Select from 'react-select';

import { getProductImage, getSubFormData } from '@beacharound/beacharound-ui-helpers';

import { MainContainer, MainForm, MainFormContent, SectionTitle, TitleContainer, MainTitle, SubTitle, FormToggleGroup, FormToggleGroupPreview, SimpleFormField, FormToggleGroupEdit, TextInput, SelectInput, SelectImageInput, LoadingContainer } from '@beacharound/beacharound-ui';

import { getProductsTypes } from '../../../../../api/productsTypes';
import { getProduct, updateProduct } from '../../../../../api/products';

import { getProductsElement } from '../../../../../helpers/products';
import { getProductsOptions } from '../../../../../helpers/productsTypes';

import ProductVariations from '../../../../../components/Beach/Catalog/ProductVariations'

const constraintsGeneral = {
    type: { presence: true },
    name: { presence: true },
    quantity: { presence: true }
}

const constraintsPrice = {
    daily: { presence: true }
}

export default function ParkingsDetail({ match }) {

    const { elementId, productId, type } = match.params;
    const { data: productsTypes } = useQuery(['productsTypes', 'parking'],
        () => getProductsTypes({ params: {params: {type: "parking" }}})
    )
    const { data: productData, isLoading } = useQuery(['products', 'parking', elementId, productId],
        () => getProduct({ productId, elementId })
    )

    const product = getProductsElement(productData);
    const [isHalfDay, setIsHalfDay] = useState(product?.halfDay);

    // CACHE
    const cache = useQueryCache()
    const [update] = useMutation(updateProduct, {
        onSuccess: () => cache.invalidateQueries('product')
    })

    function editProduct(data) {
        return update({ data, elementId, productId })
    }

    function getDataProductImage(_id) {
        return getProductImage(productsTypes?.find(p => p._id === _id) || {})
    }

    function changeHalfDay(name, value) {
        return setIsHalfDay(value || false)
    }

    const booleanOptions = [{ value: true, label: 'Si' }, { value: false, label: 'No' }]

    return (

        <React.Fragment>

            <MainContainer>

                <TitleContainer>
                    <MainTitle text="Gestisci parcheggio" />
                    <SubTitle text="< Torna alla lista" component={Link} to={`/activities/beaches/${type}/${elementId}/parkings`} />
                </TitleContainer>

                <LoadingContainer isLoading={isLoading}>

                    <TitleContainer>
                        <SectionTitle text="Dati principali" />
                    </TitleContainer>

                    <MainForm
                        onSubmit={editProduct}
                        data={getSubFormData(product, ["productType", "quantity"])} 
                        constraints={constraintsGeneral}
                    >
                        <MainFormContent>
                            <FormToggleGroup>
                                <FormToggleGroupPreview>
                                    <SimpleFormField
                                        label="Tipologia elemento"
                                        name="productType"
                                        externalImageUrl={(name, value) => getDataProductImage(value)}
                                        options={getProductsOptions(productsTypes)}
                                    />
                                    <SimpleFormField
                                        label="Nome"
                                        name="name"
                                        half
                                    />
                                    <SimpleFormField
                                        label="Quantità elementi"
                                        name="quantity"
                                        half
                                    />
                                </FormToggleGroupPreview>

                                <FormToggleGroupEdit flex>
                                    <SelectImageInput
                                        Component={Select}
                                        label="Tipologia elemento"
                                        placeholder="Scegli tipologia"
                                        name="productType"
                                        options={getProductsOptions(productsTypes)}
                                        half
                                    />
                                    <TextInput
                                        label="Quantità elementi"
                                        name="quantity"
                                        half
                                    />
                                    <TextInput
                                        label="Nome"
                                        name="name"
                                        half
                                    />
                                </FormToggleGroupEdit>
                            </FormToggleGroup>
                        </MainFormContent>
                    </MainForm>

                    <TitleContainer>
                        <SectionTitle text="Prezzi base" />
                    </TitleContainer>

                    <MainForm
                        onSubmit={editProduct}
                        data={getSubFormData(product, ["halfDay", "daily", "morning", "afternoon"])}
                        constraints={constraintsPrice}
                    >
                        <MainFormContent>
                            <FormToggleGroup>
                                <FormToggleGroupPreview>
                                    <SimpleFormField
                                        label="Disponibilità mezza giornata"
                                        placeholder="Scegli disponibilità"
                                        name="halfDay"
                                        options={booleanOptions}
                                        half
                                    />
                                    <SimpleFormField
                                        label="Prezzo giornaliero base"
                                        name="daily"
                                        half
                                        price
                                    />
                                    {product?.halfDay &&
                                        <SimpleFormField
                                            label="Prezzo solo mattina"
                                            name="morning"
                                            half
                                            price
                                        />
                                    }
                                    {product?.halfDay &&
                                        <SimpleFormField
                                            label="Prezzo solo pomeriggio"
                                            name="afternoon"
                                            half
                                            price
                                        />
                                    }
                                </FormToggleGroupPreview>

                                <FormToggleGroupEdit flex>
                                    <SelectInput
                                        label="Disponibilità mezza giornata"
                                        name="halfDay"
                                        options={booleanOptions}
                                        half
                                        onChangeInput={changeHalfDay}
                                    />
                                    <TextInput
                                        type="number"
                                        label="Prezzo giornaliero base"
                                        name="daily"
                                        half
                                    />

                                    {isHalfDay &&
                                        <React.Fragment>
                                            <TextInput
                                                type="number"
                                                label="Prezzo solo mattina"
                                                name="morning"
                                                half
                                            />
                                            <TextInput
                                                type="number"
                                                label="Prezzo solo pomeriggio"
                                                name="afternoon"
                                                half
                                            />
                                        </React.Fragment>
                                    }
                                </FormToggleGroupEdit>
                            </FormToggleGroup>
                        </MainFormContent>
                    </MainForm>

                    <ProductVariations productId={productId} beachId={elementId} queryId="parkingVariations" />

                </LoadingContainer>

            </MainContainer>

        </React.Fragment>

    )
}
