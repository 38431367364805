import List from "../../pages/Activities/MembershipsPacks/List";
import Create from "../../pages/Activities/MembershipsPacks/Create";
import Detail from "../../pages/Activities/MembershipsPacks/Detail";

export const subRoutesMembershipsPacks = [
    {
        path: "",
        component: List,
        exact: true,
    }
].map((item) => {
    return {
        ...item,
        path: `/memberships-packs${item.path}`,
        private: true
    };
});

export const routesMembershipsPacks = [
    {
        path: "/create",
        component: Create,
        exact: true
    },
    {
        path: "/:elementId",
        component: Detail,
    }
].map((item) => {
    return {
        ...item,
        path: `/memberships-packs${item.path}`,
        private: true
    };
});