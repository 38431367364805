import axios from './_defaults/user';

export async function createMemberships({ data, activityId, type }) {

    const response = await axios.post("/admin-memberships", {...data, activityId, activityType: type});

    if (response.data) {
        return response.data
    }

    return response
}

export async function getMemberships({ params }) {
    try {
        const response = await axios.get(`/admin-memberships`, { params });

        if (response.data) {
            return response.data;
        }
        
    } catch(error) {

        if (error?.response?.status === 404) {
            return [];
        }

        throw error
    }
}

export async function getMembership({ params, elementId }) {
    try {
        const response = await axios.get(`/admin-memberships/${elementId}`, { params });

        if (response.data) {
            return response.data;
        }
        
    } catch(error) {

        if (error?.response?.status === 404) {
            return [];
        }

        throw error
    }
}

export async function updateMembership({ elementId, data }) {
    try {
        const response = await axios.post(`/admin-memberships/${elementId}`, data);

        if (response.data) {
            return response.data;
        }
        
    } catch(error) {

        throw error
    }
}

export async function getMembershipsPacks({ params }) {
    try {
        const response = await axios.get(`/admin-memberships/packs`, { params });

        if (response.data) {
            return response.data;
        }
        
    } catch(error) {

        if (error?.response?.status === 404) {
            return [];
        }

        throw error
    }
}

export async function getMembershipsPack({ elementId, params }) {
    try {
        const response = await axios.get(`/admin-memberships/packs/${elementId}`, { params });

        if (response.data) {
            return response.data;
        }
        
    } catch(error) {

        if (error?.response?.status === 404) {
            return [];
        }

        throw error
    }
}

export async function createMembershipsPack({ data }) {
    try {
        const response = await axios.post(`/admin-memberships/packs`, { ...data });

        if (response.data) {
            return response.data;
        }
        
    } catch(error) {

        if (error?.response?.status === 404) {
            return [];
        }

        throw error
    }
}

export async function updateMembershipsPack({ data, elementId }) {
    try {
        const response = await axios.post(`/admin-memberships/packs/${elementId}`, { ...data });

        if (response.data) {
            return response.data;
        }
        
    } catch(error) {

        if (error?.response?.status === 404) {
            return [];
        }

        throw error
    }
}
