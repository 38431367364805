export function getRoomsList(rooms) {
    if (!Array.isArray(rooms)) {
        return [];
    }
    return rooms?.map(getRoomsElement) || [];
}

export function getRoomsElement(room) {
    if (!room) {
        return {};
    }

    const prices = room.activityPrices?.map((price, index) =>{
        return {
            name: price.name,
            prices: price.prices?.reduce((acc, subprice) => {
                return {...acc, [subprice.type]: subprice.price}
            },{}),
            index
        }
    });

    return {
        ...room,
        beds: room.beds?.map((bed, index) => { return {...bed, index}}),
        productType: room.type?._id,
        name: room.name,
        image: room.cover,
        _id: room._id,
        prices,
    };
}

export function cleanRoomBeforeUpdate(data) {

    let payload = {
        ...data
    };

    if(data.prices) {
        payload = {
            ...payload,
            activityPrices: data.prices.map((price) => {
                return {
                    name: price.name,
                    prices: Object.keys(price.prices).map((key) => {
                        return {
                            type: key,
                            price: price.prices[key],
                        };
                    })
                };
            })
        }
    }

    if (data?.productType) {
        payload = {
            ...payload,
            type: data?.productType,
        };
    }

    return payload;
}

export function getRoomsOptions(rooms) {
    if(!rooms || !Array.isArray(rooms)) return [];

    return rooms.map(room => {
        return {
            label: room.name,
            value: room._id
        }
    })
}