/* eslint-disable import/no-anonymous-default-export */
import Login from "../pages/User/Login";
import SignupAccount from "../pages/User/Signup/SignupAccount";
import SignupData from "../pages/User/Signup/SignupData";
import SignupActivation from "../pages/User/Signup/SignupActivation";
import SignupThanks from "../pages/User/Signup/SignupThanks";
import ForgotPassword from "../pages/User/ForgotPassword";
import ChangePassword from "../pages/User/ChangePassword";

import EmptyLayout from "../layouts/EmptyLayout/EmptyLayout";

export default [
    {
        path: "/user/login",
        component: Login,
        layout: EmptyLayout,
    },
    {
        path: "/user/signup",
        component: SignupAccount,
        layout: EmptyLayout,
        exact: true,
    },
    {
        path: "/user/signup/data",
        component: SignupData,
        layout: EmptyLayout,
    },
    {
        path: "/user/signup/activation",
        component: SignupActivation,
        layout: EmptyLayout,
    },
    {
        path: "/user/signup/thanks",
        component: SignupThanks,
        layout: EmptyLayout,
    },
    {
        path: "/user/forgot-password",
        component: ForgotPassword,
        layout: EmptyLayout,
    },
    {
        path: "/user/change-password/:token",
        component: ChangePassword,
        layout: EmptyLayout,
    },
];
