import React, { useState } from 'react';
import Select from 'react-select';

import { getProductImage } from '@beacharound/beacharound-ui-helpers';
import { MainForm, MainFormContent, SectionTitle, TitleContainer, TextInput, SelectImageInput, Tile, NormalPopup, ButtonsRow, MainButton, Divider, SimpleLink, ColumnContainer, Column } from '@beacharound/beacharound-ui';

import { getProductsOptions } from '../../../helpers/products';

const constraintsGeneral = {
    min: { presence: true },
    max: { presence: true }
}

export default function RelatedProducts({ beachId, productId, title, product, updateProduct, updateResult, allRelatedProducts, relatedType }) {

    const [relatedPopup, setRelatedPopup] = useState()

    function editRelatedProduct(data) {

        let newRelatedProduct = {
            ...data,
            type: relatedType,
            overrides: {
                minOrder: data.min,
                maxOrder: data.max
            }
        }

        let newRelatedProducts = product.relatedProducts || [];

        if (relatedPopup.reference) {
            newRelatedProducts[relatedPopup.index] = newRelatedProduct
        } else {
            newRelatedProducts.push(newRelatedProduct)
        }

        return updateRelatedProducts(newRelatedProducts)
    }

    function deleteRelatedProduct() {
        const { reference } = relatedPopup;
        let mainProduct = product;
        const newRelatedProducts = mainProduct?.relatedProducts.filter(related => related.reference !== reference);

        return updateRelatedProducts(newRelatedProducts);
    }

    async function updateRelatedProducts(newRelatedProducts) {
        await updateProduct({ data: { relatedProducts: newRelatedProducts }, beachId, productId });
        return setRelatedPopup(undefined);
    }

    // Lista di prodotti filtrati per le option del popup filtrati in base ai prodotti esistenti e quelli già utilizzati
    const productsToSkip = product?.relatedProducts?.map(({ reference }) => reference)

    // Valore per capire se esistono prodotti oltre a quelli filtrati
    const areProductsToRelate = productsToSkip?.length !== allRelatedProducts?.length;

    return (
        <React.Fragment>
            <TitleContainer>
                <SectionTitle text={title || "Prodotti collegati"} />
            </TitleContainer>

            <ColumnContainer>
                <Column>
                    {product?.relatedProducts?.map((related, index) => {
                        let quantityString = related.overrides?.minOrder || related.overrides?.maxOrder ? "" : undefined;

                        if (related.overrides?.minOrder) {
                            quantityString += `Minimo: ${related.overrides?.minOrder}`;
                        }

                        if (related.overrides?.minOrder && related.overrides?.maxOrder) {
                            quantityString += " - ";
                        }

                        if (related.overrides?.maxOrder) {
                            quantityString += `Massimo: ${related.overrides?.maxOrder}`;
                        }

                        return (
                            <Tile
                                key={index}
                                image={getProductImage(
                                    related.reference?.type || {}
                                )}
                                title={related.reference?.name}
                                text={quantityString}
                                buttonAction={() =>
                                    setRelatedPopup({ ...related, index })
                                }
                                buttonLinkText="Modifica"
                                buttonRight
                            />
                        );
                    })}

                    {areProductsToRelate && (
                        <SimpleLink
                            text="Collega prodotto"
                            action={() => setRelatedPopup(true)}
                        />
                    )}
                </Column>
            </ColumnContainer>

            {/* POPUP DEI LETTINI */}

            <NormalPopup
                visible={relatedPopup}
                setVisible={() => setRelatedPopup(false)}
                title="Modifica collegamento al lettino"
            >
                <MainForm
                    onSubmit={editRelatedProduct}
                    data={{
                        reference: relatedPopup?.reference?._id,
                        min: relatedPopup?.overrides?.minOrder,
                        max: relatedPopup?.overrides?.maxOrder,
                    }}
                    constraints={constraintsGeneral}
                >
                    <MainFormContent flex>
                        {/* Passiamo allo skip delle options un array di soli id pescando il valore da reference perchè è quello il collegamento con il prodotto reale */}
                        {/* <SelectImageInput label="Tipologia elemento" name="reference" half options={getProductsOptions(allRelatedProducts, {skip: {elements: product?.relatedProducts?.map(({reference}) => reference)}})} /> */}
                        <SelectImageInput
                            Component={Select}
                            label="Tipologia elemento"
                            name="reference"
                            half
                            options={getProductsOptions(allRelatedProducts, {
                                skip: {
                                    elements: productsToSkip?.filter(
                                        (reference) =>
                                            reference !==
                                            relatedPopup?.reference
                                    ),
                                },
                            })}
                        />
                        <TextInput label="Minimo" name="min" half />
                        <TextInput label="Massimo" name="max" half />
                    </MainFormContent>

                    <Divider />

                    <ButtonsRow fill>
                        <MainButton
                            text="Annulla"
                            border
                            action={() => setRelatedPopup(false)}
                        />
                        {relatedPopup?.reference && (
                            <MainButton
                                text="Elimina"
                                border
                                action={deleteRelatedProduct}
                            />
                        )}
                        <MainButton text="Salva" submit {...updateResult} />
                    </ButtonsRow>
                </MainForm>
            </NormalPopup>
        </React.Fragment>
    );
}
