import List from '../../pages/Blog/Categories/List';
import Detail from '../../pages/Blog/Categories/Detail';
import Create from '../../pages/Blog/Categories/Create';

export const subRoutesCategories = [
    {
        path: "",
        component: List,
        exact: true,
    }
].map((item) => {
    return {
        ...item,
        path: `/categories${item.path}`,
        private: true
    };
});

export const routesCategories = [
    {
        path: "/create",
        component: Create,
        exact: true
    },
    {
        path: "/:elementId",
        component: Detail,
    }
].map((item) => {
    return {
        ...item,
        path: `/categories${item.path}`,
        private: true
    };
});