import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation, useQuery, useQueryCache } from 'react-query';

import {
    MainContainer,
    TitleContainer,
    MainTitle,
    SubTitle,
    SectionTitle,
    MainFormContent,
    Divider,
    LoadingContainer,
    MainForm,
    FormToggleGroup,
    FormToggleGroupPreview,
    FormToggleGroupEdit,
    SimpleFormField,
    TextInput,
    TextAreaInput,
    SelectInput,
    EditImageGroup,
    ColumnContainer,
    Column,
    ToggleInput
} from '@beacharound/beacharound-ui';

import { getSubFormData } from "@beacharound/beacharound-ui-helpers";

import RoomPricesListEdit from '../../../../../components/Hotel/Rooms/RoomPricesListEdit';
import { getPutUrl, getRoom, updateRoom } from '../../../../../api/hotels/rooms';
import { getProductsTypes } from '../../../../../api/productsTypes';
import { uploadImage } from "../../../../../api/images";
import { getProductsOptions } from '../../../../../helpers/productsTypes';
import { getRoomsElement } from '../../../../../helpers/rooms';
import RoomBedsPopupEdit from '../../../../../components/Hotel/Rooms/RoomBedsPopupEdit';
import RoomPricesPopupEdit from '../../../../../components/Hotel/Rooms/RoomPricesPopupEdit';
import RoomBedsListEdit from '../../../../../components/Hotel/Rooms/RoomBedsListEdit';

const priceTypes = [{
    label: 'A notte a persona',
    value: 'daily-each'
},{
    label: 'A notte a camera',
    value: 'daily-all'
}]

export default function RoomsDetail({ match, history }) {
    const { elementId, roomId } = match.params;
    // const [deletePopup, setDeletePopup] = useState();

    const [bedPopup, setBedPopup] = useState();
    const [pricePopup, setPricePopup] = useState();

    // const [beforeUpdateErrors, setBeforeUpdateErrors] = useState();

    // Dati per avere le tipologie di camera
    const { data: roomTypes } = useQuery(["productsType", "rooms"], () =>
        getProductsTypes({ params: { params: { type: "room" } } })
    )

    const { data, isLoading } = useQuery(["hotels", elementId, "rooms", roomId],
        () => getRoom({ elementId, roomId })
    )

    const room = getRoomsElement(data);

    const cache = useQueryCache();
    const [update, { status: updateStatus, errors, reset }] = useMutation(updateRoom, {
        onSuccess(data) {
            cache.invalidateQueries(["hotels", elementId, "rooms"]);
            cache.setQueryData(["hotels", elementId, "rooms", data._id], data);
        }
    })

    async function editRoom(data, callback) {
        try {
            await update({
                data,
                elementId,
                roomId
            })
            return callback(reset);
        } catch(error) {
            return callback(null, error)
        }
    }

    async function editBeds(beds) {

        try {
            await update({
                data: {
                    beds
                },
                elementId,
                roomId
            })
        } catch(error) {
            throw error;
        }
    }

    async function editPrices(prices) {

        try {
            await update({
                data: {
                    prices
                },
                elementId,
                roomId
            })
        } catch(error) {
            throw error;
        }
    }

    async function editCover(file) {
        try {
            const { url, key } = await getPutUrl({ elementId, roomId, file });
            await uploadImage({ url, file });
            await update({
                elementId,
                roomId,
                data: {
                    cover: {
                        url: key,
                    },
                },
            });
        } catch (error) {
            console.log(error);
        }
    }

    // function deleteElement() {
    //     setDeletePopup(false);
    // }

    return (
        <MainContainer>
            <LoadingContainer isLoading={isLoading}>

                <ColumnContainer>
                    <Column column={2}>
                        <TitleContainer noMargin>
                            <MainTitle text={room?.name} />
                            <SubTitle
                                text="< Torna alle camere"
                                component={Link}
                                to={`/activities/hotels/${elementId}/rooms`}
                            />
                        </TitleContainer>
                    </Column>

                    <Column column={2}>
                        <ToggleInput
                            flexEnd
                            trueLabel="Pubblicato"
                            falseLabel="Non pubblicato"
                            status={updateStatus}
                            changeValue={()=> editRoom({published: !data?.published}, (resetAfter) => resetAfter())}
                            value={data?.published}
                        />
                    </Column>
                </ColumnContainer>

                <TitleContainer>
                    <SectionTitle text="Dati principali" />
                </TitleContainer>

                <MainForm
                    onSubmit={editRoom}
                    data={getSubFormData(room, [
                        "name",
                        "description",
                        "productType",
                        "priceType"
                    ])}
                    constraints={{
                        name: { presence: true },
                        productType: { presence: true },
                        description: { presence: true },
                        priceType: { presence: true },
                    }}
                    errors={errors}
                >
                    <MainFormContent>
                        <FormToggleGroup>
                            <FormToggleGroupPreview>
                                <SimpleFormField
                                    label="Nome camera"
                                    name="name"
                                />
                                <SimpleFormField
                                    label="Tipologia di camera"
                                    name="productType"
                                    options={getProductsOptions(roomTypes)}
                                    half
                                />
                                <SimpleFormField
                                    label="Tipologia di prezzo"
                                    name="priceType"
                                    options={priceTypes}
                                    half
                                />
                                <SimpleFormField
                                    name="description"
                                    label="Descrizione"
                                />
                            </FormToggleGroupPreview>

                            <FormToggleGroupEdit flex status={updateStatus}>
                                <TextInput
                                    label="Nome camera"
                                    name="name"
                                />
                                <SelectInput
                                    label="Tipologia di camera"
                                    name="productType"
                                    options={getProductsOptions(roomTypes)}
                                    half
                                />
                                <SelectInput
                                    label="Tipologia di prezzo"
                                    name="priceType"
                                    options={priceTypes}
                                    half
                                />
                                <TextAreaInput
                                    name="description"
                                    label="Descrizione"
                                />
                            </FormToggleGroupEdit>
                        </FormToggleGroup>
                    </MainFormContent>
                </MainForm>

                <MainFormContent>
                    <RoomPricesListEdit
                        prices={room?.prices}
                        setPricePopup={setPricePopup}
                        priceType={room?.priceType}
                    />
                    <Divider />

                    <RoomBedsListEdit
                        beds={room?.beds}
                        setBedPopup={setBedPopup}
                    />

                    <Divider />

                    <EditImageGroup data={room?.cover} onSubmit={editCover} />
{/* 
                    <ButtonsRow spaceBetween>
                        <MainButton
                            border
                            text="Elimina camera"
                            action={() => setDeletePopup(true)}
                        />
                    </ButtonsRow> */}
                </MainFormContent>
            </LoadingContainer>

            <RoomBedsPopupEdit
                beds={room?.beds}
                setBeds={editBeds}
                setBedPopup={setBedPopup}
                bedPopup={bedPopup}
                status={updateStatus}
            />

            <RoomPricesPopupEdit
                prices={room?.prices}
                setPrices={editPrices}
                setPricePopup={setPricePopup}
                pricePopup={pricePopup}
                status={updateStatus}
                priceType={room?.priceType}
            />

            {/* <NormalPopup
                visible={deletePopup}
                title="Elimina camera"
                setVisible={() => setDeletePopup(false)}
            >
                <TitleContainer>
                    <Paragraph text="Sei sicuro di voler eliminare questa camera?" />
                </TitleContainer>
                <Divider />
                <ButtonsRow fill>
                    <MainButton
                        border
                        text="Annulla"
                        action={() => setDeletePopup(false)}
                    />
                    <MainButton text="Conferma" action={deleteElement} />
                </ButtonsRow>
            </NormalPopup> */}
        </MainContainer>
    )
}