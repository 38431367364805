import { returnError } from "./_defaults/error";
import axios from "./_defaults/user";

function checkLocationsType(type) {
    if(!type) console.error("Location type: manca type");
    if(!["cities", "districts", "regions"].includes(type)) console.error(`Location type: ${type} non è corretto come valore`)
}

export async function getLocations({type, params}) {
    checkLocationsType(type);
    
    try {
        const response = await axios.get(`/admin-locations/${type}`, {params});
        if (response?.data) {
            return response?.data;
        }
    } catch (error) {
        returnError(error);
    }
}

// export async function createInformationsRequest({ data }) {
//     try {
//         const response = await axios.post(`/admin-information-requests`, data);
//         return response?.errors ? response?.data : {};
//     } catch (error) {
//         return error;
//     }
// }

export async function getLocation({ type, elementId }) {
    try {
        const response = await axios.get(
            `/admin-locations/${type}/${elementId}`
        );
        if (response?.data) {
            return response?.data;
        }
    } catch (error) {
        returnError(error);
    }
}

export async function updateLocation({ type, elementId, data }) {
    try {
        const response = await axios.post(
            `/admin-locations/${type}/${elementId}`,
            data
        );
        if (response?.data) {
            return response?.data;
        }
    } catch (error) {
        returnError(error);
    }
}

export async function locationActivation({ type, elementId, published }) {
    try {
        const response = await axios.post(
            `/admin-locations/${type}/${elementId}/activate`,
            { published }
        );
        if (response?.data) {
            return response?.data;
        }
    } catch (error) {
        returnError(error);
    }
}

export async function getPutUrl({ elementId, file, type }) {
    const response = await axios.post(
        `/admin-locations/${type}/${elementId}/images`,
        {
            contentType: file.type,
            extension: file.name.split(".")[1],
        }
    );

    if (response.data) {
        return response.data;
    }

    return response;
}

