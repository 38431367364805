import axios from "./_defaults/user";

export async function createReview({ data }) {
    const response = await axios.post("/admin-reviews", data);

    if (response.data) {
        return response.data;
    }

    return response;
}

export async function createReviewMultiple({ data }) {
    const response = await axios.post("/admin-reviews/multiple", data);

    if (response.data) {
        return response.data;
    }

    return response;
}

export async function getReviews({ params }) {
    const response = await axios.get("/admin-reviews", {
        params,
    });

    if (response.data) {
        return response.data;
    }

    return response;
}

export async function getReview({ elementId }) {
    const response = await axios.get(`/admin-reviews/${elementId}`);

    if (response.data) {
        return response.data;
    }

    return response;
}

export async function updateReview({ elementId, data }) {
    const response = await axios.post(`/admin-reviews/${elementId}`, data);

    if (response.data) {
        return response.data;
    }

    return response;
}
