import React from "react";
import { Link } from "react-router-dom";
import { useMutation, useQuery, useQueryCache } from 'react-query';

import {
    MainContainer,
    MainForm,
    MainFormContent,
    SectionTitle,
    TitleContainer,
    MainTitle,
    SubTitle,
    FormToggleGroup,
    FormToggleGroupPreview,
    SimpleFormField,
    FormToggleGroupEdit,
    LoadingContainer,
    SelectInput,
    ColumnContainer
} from "@beacharound/beacharound-ui";

import { getSubFormData } from "@beacharound/beacharound-ui-helpers";

import { getBeach, updateBeach, } from "../../../../../api/beach";

export default function Detail({ match }) {

    const { type, elementId } = match.params;
    const { data, isLoading } = useQuery(["beaches", type, elementId],
        () => getBeach({ type, elementId })
    );

    const cache = useQueryCache()
    const [update, { status: updateStatus, reset }] = useMutation(updateBeach, {
        onSuccess: (data) => {
            cache.invalidateQueries(["beaches", type])
            cache.setQueryData(["beaches", type, elementId], data);
        }
    });

    async function editElement(data, callback) {
        try {
            await update({ type, elementId, data });
            return callback(reset);
        } catch (error) {
            return callback(null, error);
        }
    }

    async function editStripeType(data, callback) {
        let payload = {};

        if (data?.type) {
            payload = { ...payload, "stripe.type": data?.type };
        }
        if (data?.enabled !== undefined) {
            payload = { ...payload, "stripe.enabled": data?.enabled };
        }
        return editElement(payload, callback);
    }

    async function editHybrid(data, callback) {
        let payload = {};
        if (data?.enabled !== undefined) {
            payload = { ...payload, "hybrid.enabled": data?.enabled };
        }
        return editElement(payload, callback);
    }

    return (
        <MainContainer marginBottom>
            <ColumnContainer>
                <TitleContainer noMargin>
                    <MainTitle text={data?.name || "Gestisci spiaggia"} />
                    <SubTitle
                        text="< Torna alla lista"
                        component={Link}
                        to={`/places/beaches/${type}`}
                    />
                </TitleContainer>
            </ColumnContainer>

            <LoadingContainer isLoading={isLoading} isError={!data}>
                <TitleContainer>
                    <SectionTitle text="Stripe" />
                </TitleContainer>

                <MainForm
                    onSubmit={editStripeType}
                    data={
                        data?.stripe &&
                        getSubFormData(data?.stripe, [
                            "enabled",
                            "type"
                        ])
                    }
                >
                    <MainFormContent>
                        <FormToggleGroup>
                            <FormToggleGroupPreview>
                                <SimpleFormField
                                    label="Abilitato"
                                    name="enabled"
                                    options={[{ label: "No", value: false }, { label: "Si", value: true }]}
                                    half
                                />
                                <SimpleFormField
                                    label="Tipologia"
                                    name="type"
                                    half
                                />
                            </FormToggleGroupPreview>

                            <FormToggleGroupEdit flex status={updateStatus}>
                                <SelectInput
                                    label="Abilitato"
                                    name="enabled"
                                    options={[{ label: "No", value: false }, { label: "Si", value: true }]}
                                    half
                                />
                                <SelectInput
                                    label="Tipologia"
                                    name="type"
                                    options={[{ value: "standard", label: "Standard" }, { value: "express", label: "Express" }]}
                                    placeholder="Di default è express"
                                    half
                                />
                            </FormToggleGroupEdit>
                        </FormToggleGroup>
                    </MainFormContent>
                </MainForm>

                <TitleContainer>
                    <SectionTitle text="Spiaggia ibrida" />
                </TitleContainer>

                <MainForm
                    onSubmit={editHybrid}
                    data={
                        data?.hybrid &&
                        getSubFormData(data?.hybrid, ["enabled"])
                    }
                >
                    <MainFormContent>
                        <FormToggleGroup>
                            <FormToggleGroupPreview>
                                <SimpleFormField
                                    label="Abilitato"
                                    name="enabled"
                                    options={[{ label: "No", value: false }, { label: "Si", value: true }]}
                                    half
                                />
                            </FormToggleGroupPreview>

                            <FormToggleGroupEdit flex status={updateStatus}>
                                <SelectInput
                                    label="Abilitato"
                                    name="enabled"
                                    options={[{ label: "No", value: false }, { label: "Si", value: true }]}
                                    half
                                />
                            </FormToggleGroupEdit>
                        </FormToggleGroup>
                    </MainFormContent>
                </MainForm>

            </LoadingContainer>
        </MainContainer>
    );
}
