
import List from "../../pages/Activities/Organizations/List";
import Detail from "../../pages/Activities/Organizations/Detail";
import Create from "../../pages/Activities/Organizations/Create";

export const subRoutesOrganizations = [
    {
        path: "",
        component: List,
        exact: true,
    }
].map((item) => {
    return {
        ...item,
        path: `/organizations${item.path}`,
        private: true
    };
});

export const routesOrganizations = [
    {
        path: "/create",
        component: Create,
        exact: true
    },
    {
        path: "/:elementId",
        component: Detail,
    }
].map((item) => {
    return {
        ...item,
        path: `/organizations${item.path}`,
        private: true
    };
});