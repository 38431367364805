import React, { useState } from "react";
import qs from "query-string";
import { usePaginatedQuery } from "react-query";

import {
    MainContainer,
    TitleContainer,
    MainTitle,
    ColumnContainer,
    Column,
    SimpleTable,

} from "@beacharound/beacharound-ui";

import { getSearchTerms } from "../../../api/searchTerms";


export default function List({ match, history, location }) {

    // const [solvedItemPopup, setSolvedItemPopup] = useState();
    const { page } = location.search ? qs.parse(location.search) : { page: "0" };

    const [limit, setLimit] = useState(5);
    const [search, setSearch] = useState();
    const [sort, setSort] = useState({ count: -1 });

    const options = {
        skip: Number(page) * limit,
        limit,
        sort,
        search
    }

    const { data, isLoading } = usePaginatedQuery(["searchTerms", options], () => getSearchTerms({ params: options }))

    const tableHead = [
        {
            text: "Termine",
            key: "term",
            sort: (ascending) => {
                setSort({ term: ascending ? -1 : 1 })
            },
            highlight: true,
        },
        {
            text: "N. ricerche",
            key: "count",
            sort: (ascending) => {
                setSort({ count: ascending ? -1 : 1 })
            },
            flex: 0.5,
            hasMobileLabel: true,
        },
        // {
        //     text: "Click risultato",
        //     key: "selected",
        //     flex: 0.7,
        //     hasMobileLabel: true,
        //     parse: (value) => value ? "Si" : "No"
        // },
        // {
        //     text: "Click cerca altro",
        //     key: "noSelected",
        //     flex: 0.7,
        //     hasMobileLabel: true,
        //     parse: (value) => value ? "Si" : "No"
        // },
        // {
        //     text: "Risolto",
        //     key: "resolutions",
        //     hasMobileLabel: true,
        //     flex: 0.5,
        //     parse: (value) => value === true ? "Si" : "No"
        // },
        // {
        //     flex: 0.5,
        //     actions: (item) => {
        //         return [
        //             // {
        //             //     icon: item.resolutions ? "checked" : "cross",
        //             //     action: () => setSolvedItemPopup(item),
        //             // },
        //             {
        //                 icon: "arrow-right",
        //                 component: Link,
        //                 to: `${match.url}/${item._id}`,
        //             },
        //         ];
        //     },
        // },
    ];

    // function solveHandler() {
    //     // update();
    // }

    const tableRows = data || [];

    return (
        <MainContainer large>
            <TitleContainer>
                <MainTitle text="Termini di ricerca" />
            </TitleContainer>

            <ColumnContainer>
                <Column>
                    <SimpleTable
                        match={match}
                        history={history}
                        setLimit={setLimit}
                        navigation
                        data={tableRows}
                        head={tableHead}
                        isLoading={isLoading}
                        page={page}
                        searched={search}
                        onSearch={(value) => {
                            setSearch(value);
                            history.push(match.url);
                        }}
                        onSearchClear={() => {
                            setSearch(undefined);
                            history.push(match.url);
                        }}
                    />
                </Column>
            </ColumnContainer>

            {/* <NormalPopup
                visible={solvedItemPopup}
                setVisible={() => setSolvedItemPopup(false)}
                title={`Risolvi ${solvedItemPopup?.name}`}
                large
            >

                <TitleContainer>
                    <Paragraph text={`Sei sicuro di voler impostare come ${solvedItemPopup?.resolutions ? "NON RISOLTO" : "RISOLTO"}`}/>
                </TitleContainer>

                <ButtonsRow fill>
                    <MainButton
                        text="Annulla"
                        border
                        action={() => setSolvedItemPopup(false)}
                    />
                    <MainButton
                        text="Conferma"
                        action={solveHandler}
                    />
                </ButtonsRow>
            </NormalPopup> */}

        </MainContainer>
    );
}
