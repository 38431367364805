import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { useMutation, useQuery, useQueryCache } from "react-query";

import {
    MainContainer,
    TitleContainer,
    ColumnContainer,
    Column,
    SubTitle,
    LoadingContainer,
    SectionTitle,
    SimpleField,
    SimpleTable,
    Divider,
    EmptyChart,
    NormalPopup,
    Paragraph,
    ButtonsRow,
    MainButton
} from "@beacharound/beacharound-ui";

import { formatDate } from "@beacharound/beacharound-ui-helpers";
import { getTransfer, removeTransactionFromTransfer } from "../../../api/payments";
import PaymentActions from '../../../components/Payment/PaymentActions';
import StatusHistory from "../../../components/Status/StatusHistory";

export default function Payments({ history, match }) {

    const { elementId } = match.params;

    const [cancelPopup, setCancelPopup] = useState();

    const cache = useQueryCache();

    const { data, isLoading } = useQuery(["transfers", elementId], () =>
        getTransfer({ elementId })
    )

    const [remove, { status: removeStatus }] = useMutation(removeTransactionFromTransfer, {
        onSuccess: () => {
            cache.invalidateQueries(["transfers", elementId]);
            return setCancelPopup()
        }
    });

    function removeHandler() {
        return remove({
            elementId: elementId,
            transactionId: cancelPopup?._id
        })
    }

    const tableData = data?.transactions;

    const tableHead = [{
        text: "Prenotazione",
        key: "reservation",
        parse: (reservation) => reservation?._id || '-',
        highlight: true,
        default: true,
        flex: 0.7
    },{
        text: "Transazione",
        key: "transaction",
        parse: (transaction) => transaction?._id || '-',
        highlight: true,
        default: true,
        flex: 0.7
    },{
        text: "Cliente",
        key: "reservation",
        parse: (reservation) => reservation?.customer?.firstName ? `${reservation?.customer?.firstName} ${reservation?.customer?.lastName}` : '-',
        highlight: true,
        default: true,
        flex: 0.6
    },{
        text: "Checkin",
        key: "reservation",
        parse: (reservation) => reservation?.checkin ? formatDate(new Date(reservation?.checkin), "dd MMM") : '-',
        highlight: true,
        default: true,
        flex: 0.2
    },{
        text: "Pagata",
        key: "transaction",
        flex: 0.2,
        parse: (transaction) => transaction?.paidAt ? formatDate(new Date(transaction?.paidAt), "dd MMM") : '-',
    },{
        text: "Importo",
        key: "transaction",
        flex: 0.2,
        hasMobileLabel: true,
        price: true,
        parse: (transaction) => transaction?.amount && `${transaction?.amount}`
    },{
        text: "Fee",
        key: "transaction",
        flex: 0.2,
        hasMobileLabel: true,
        price: true,
        parse: (transaction) => transaction?.gateway === "stripe_connect" ? "connect" : (transaction.application && `${transaction?.application}`)
    },{
        flex: 0.2,
        actions: (item) => {
            return [{
                icon: "delete",
                action: () => setCancelPopup(item?.transaction)
            }, {
                icon: "arrow-right",
                component: Link,
                to: `/activities/beaches/private/${item.activityId?._id}/transactions/${item._id}`
            }]
        }
    }]

    let bankCityString = data?.billingProfile?.bank?.city || "-";

    if(data?.billingProfile?.bank?.postalCode) {
        bankCityString = `${bankCityString} (${data?.billingProfile?.bank?.postalCode})`
    }

    return (
        <Fragment>
            <MainContainer>
                <ColumnContainer lowMargin>
                    <Column column={2}>
                        <TitleContainer noMargin>
                            <SectionTitle text={`Pagamento ${data?.activity?.name || ''}`} />
                            <SubTitle
                                text="< Torna alla lista"
                                component={Link}
                                to={`/financial/payments`}
                            />
                        </TitleContainer>
                    </Column>
                    <Column column={2}>
                        <PaymentActions
                            match={match}
                            history={history}
                            flexEnd
                            transfer={data}
                        />
                    </Column>
                </ColumnContainer>

                <LoadingContainer isLoading={isLoading} isError={!data}>
                    <ColumnContainer noMargin>
                        <Column column={3}>
                            <EmptyChart
                                title="Transato"
                                value={data?.amount ? `${Number(data?.amount).toFixed(2)}€` : '-'}
                            />
                        </Column>
                        <Column column={3}>
                            <EmptyChart
                                title={data?.status === "TRANSFERED" ? "Bonificato" : "Da bonificare"}
                                value={data?.transfer?.total ? `${Number(data?.transfer?.total).toFixed(2)}€` : '-'}
                            />
                        </Column>
                        <Column column={3}>
                            <EmptyChart
                                title={data?.status === "TRANSFERED" ? "Fatturato" : "Da fatturare"}
                                value={data?.invoice?.total ? `${Number(data?.invoice?.total).toFixed(2)}€` : '-'}
                            />
                        </Column>
                    </ColumnContainer>

                    <Divider/>

                    <TitleContainer>
                        <SectionTitle text="Dettagli" />
                    </TitleContainer>

                    <ColumnContainer lowMargin>
                        <Column column={2}>
                            <SimpleField
                                label="Numero"
                                value={data?.number || "Non ancora confermato"}
                            />
                        </Column>
                        <Column column={2}>
                            <SimpleField
                                label="Creato il"
                                value={formatDate(new Date(data?.createdAt), 'dd/MM/yyyy')}
                            />
                        </Column>
                        <Column column={2}>
                            <SimpleField
                                label="Stato"
                                value={data?.status || '-'}
                            />
                        </Column>
                    </ColumnContainer>

                    <ColumnContainer noMargin>
                        <Column column={2}>
                            <SimpleField
                                label="Ultima transazione"
                                value={formatDate(new Date(data?.last?.transaction), 'dd/MM/yyyy')}
                            />
                        </Column>
                        <Column column={2}>
                            <SimpleField
                                label="Ultimo checkin"
                                value={formatDate(new Date(data?.last?.reservation), 'dd/MM/yyyy')}
                            />
                        </Column>
                    </ColumnContainer>

                    <Divider/>

                    <TitleContainer>
                        <SectionTitle text="Dati di fatturazione" />
                    </TitleContainer>
                    <ColumnContainer lowMargin>
                        <Column column={1}>
                            <SimpleField
                                label="Nome"
                                value={data?.billingProfile?.name || "-"}
                            />
                        </Column>
                        <Column column={2}>
                            <SimpleField
                                label="P.iva"
                                value={data?.billingProfile?.vat?.number || "-"}
                            />
                        </Column>
                        <Column column={2}>
                            <SimpleField
                                label="pec"
                                value={data?.billingProfile?.pec || "-"}
                            />
                        </Column>
                        <Column column={2}>
                            <SimpleField
                                label="Via"
                                value={data?.billingProfile?.billingAddress?.street || "-"}
                            />
                        </Column>
                        <Column column={2}>
                            <SimpleField
                                label="Numero civico"
                                value={data?.billingProfile?.billingAddress?.streetNumber || "-"}
                            />
                        </Column>
                        <Column column={2}>
                            <SimpleField
                                label="Città"
                                value={data?.billingProfile?.billingAddress?.city || "-"}
                            />
                        </Column>
                        <Column column={2}>
                            <SimpleField
                                label="Codice postale"
                                value={data?.billingProfile?.billingAddress?.postalCode || "-"}
                            />
                        </Column>
                        <Column column={2}>
                            <SimpleField
                                label="Provincia"
                                value={data?.billingProfile?.billingAddress?.district || "-"}
                            />
                        </Column>
                        <Column column={2}>
                            <SimpleField
                                label="Regione"
                                value={data?.billingProfile?.billingAddress?.region || "-"}
                            />
                        </Column>
                    </ColumnContainer>

                    <Divider/>

                    <TitleContainer>
                        <SectionTitle text="Dati bancari" />
                    </TitleContainer>
                    <ColumnContainer lowMargin>
                        <Column column={2}>
                            <SimpleField
                                label="Banca"
                                value={data?.billingProfile?.bank?.name || "-"}
                            />
                        </Column>
                        <Column column={2}>
                            <SimpleField
                                label="Iban"
                                value={data?.billingProfile?.bank?.iban || "-"}
                            />
                        </Column>
                        <Column column={2}>
                            <SimpleField
                                label="Bic"
                                value={data?.billingProfile?.bank?.bic || "-"}
                            />
                        </Column>
                        <Column column={2}>
                            <SimpleField
                                label="Città"
                                value={data?.billingProfile?.bank?.city ? bankCityString : "-"}
                            />
                        </Column>
                    </ColumnContainer>

                    <Divider/>
                </LoadingContainer>
            </MainContainer>

            <MainContainer large>
                <LoadingContainer isLoading={isLoading}>
                    <TitleContainer lowMargin>
                        <SectionTitle text="Lista transazioni" />
                    </TitleContainer>

                    <ColumnContainer>
                        <Column>
                            <SimpleTable
                                match={match}
                                history={history}
                                data={tableData || []}
                                head={tableHead}
                                isLoading={isLoading}
                            />
                        </Column>
                    </ColumnContainer>
                </LoadingContainer>
            </MainContainer>

            <MainContainer>
                <LoadingContainer isLoading={isLoading}>
                    <StatusHistory item={data} />
                </LoadingContainer>
            </MainContainer>

            {/* CANCELLA */}
            <NormalPopup
                visible={cancelPopup}
                setVisible={setCancelPopup}
                title="Rimuovi transazione"
            >
                <TitleContainer>
                    <Paragraph text={`Sei sicuro di voler rimuovere questa transazione dal pagamento? La transazione tornerà in status di ACCEPTED e sarà inserita nei prossimi pagamenti.`}/>
                </TitleContainer>
                <Divider/>
                <ButtonsRow fill>
                    <MainButton
                        text="Annulla"
                        border
                        action={() => setCancelPopup()}
                    />
                    <MainButton
                        text="Conferma"
                        border
                        action={removeHandler}
                        status={removeStatus}
                    />
                </ButtonsRow>
            </NormalPopup>
        </Fragment>
    );
}
