import React, { useState } from "react";
import qs from "query-string";
import { Link } from "react-router-dom";
import { usePaginatedQuery } from "react-query";
import moment from 'moment';

import {
    MainContainer,
    TitleContainer,
    MainTitle,
    ColumnContainer,
    Column,
    SimpleTable,
    DateDropdown
} from "@beacharound/beacharound-ui";

import { getReservations } from '../../../../../api/reservations/offers';
import { getPeopleString, getStatusString, getStatusColor } from '../../../../../helpers/reservations';

export default function ReservationsOffers({ history, match, location }) {
    
    const { page } = location.search ? qs.parse(location.search) : { page: "0" };
    const { elementId } = match.params;

    const [limit, setLimit] = useState(25);
    const [dateFilter, setDateFilter] = useState();
    const [search, setSearch] = useState();
    const [params, setParams] = useState({});

    const options = {
        skip: Number(page) * limit,
        limit,
        search,
        sort: { createdAt: -1, status: -1 },
        params: {
            ...params,
        },
    };

    if (dateFilter) {
        options["params"] = {
            ...options?.params,
            createdAt: {
                $gte: dateFilter.fromDate,
            },
        };
    }
    
    const { data, isLoading } = usePaginatedQuery(["reservations", elementId, options], () =>
        getReservations({elementId, params: options})
    );

    const tableHead = [{
        text: "Camera",
        key: "products",
        highlight: true,
        default: true,
        flex: 0.5,
        parse: (products) => {
            return products && products[0]?.product?.name;
        },
    }, {
        text: "Date",
        key: ["checkin", "checkout"],
        sort: true,
        flex: 0.4,
        hasMobileLabel: true,
        parse: ([checkin, checkout]) => {
            return `${moment(checkin).format("DD/MM")} - ${moment(
                checkout
            ).format("DD/MM")}`;
        },
    }, {
        text: "Persone",
        key: "details",
        flex: 0.5,
        hasMobileLabel: true,
        parse: getPeopleString,
    },{
        text: "Prezzo",
        key: "reply",
        flex: 0.4,
        parse: (reply) => reply?.price && `${reply?.price.toFixed(2)} €`,
    },{
        text: "Stato",
        key: "status",
        color: (value) => getStatusColor(value),
        flex: 0.5,
        parse: getStatusString,
    },{
        text: "Ricevuta il",
        key: "createdAt",
        sort: true,
        flex: 0.4,
        hasMobileLabel: true,
        parse: (value) => moment(value).format("DD/MM"),
    },{
        flex: 0.2,
        actions: (item) => {
            return [{
                icon: "edit",
                component: Link,
                to: `${match.url}/${item._id}`,
            }]
        }
    }]

    const tableRows = data || [];

    const availableFilters = [
        {
            label: "Stato dei preventivi",
            name: "status",
            type: "select",
            options: [
                { label: "In creazione", value: "CHECKINGOUT" },
                { label: "In attese di risposta", value: "PENDING" },
                { label: "Accettate dalla struttura", value: "ACCEPTED" },
                { label: "Rifiutate dalla struttura", value: "DECLINED" },
                { label: "Confermate dall'utente", value: "CONFIRMED" },
                { label: "Rifiutate dall'utente", value: "REJECTED" },
            ],
            action: (name, value, options) => {
                setParams(
                    options?.isRemoving
                        ? { ...params, status: undefined }
                        : { ...params, status: value }
                );
                history.push(`${match.url}`);
            },
        },
    ];

    // Imposto il filtro per data
    function dateFilterHandler([fromDate, toDate]) {
        setDateFilter({ fromDate, toDate });
    }

    return (
        <MainContainer large>
            <TitleContainer>
                <MainTitle text="Prenotazioni per le offerte dell'hotel" />
                <DateDropdown action={dateFilterHandler} />
            </TitleContainer>

            <ColumnContainer>
                <Column>
                    <SimpleTable
                        match={match}
                        history={history}
                        setLimit={setLimit}
                        navigation
                        showFilters
                        data={tableRows}
                        head={tableHead}
                        availableFilters={availableFilters}
                        isLoading={isLoading}
                        page={page}
                        searched={search}
                        onSearch={(value) => {
                            setSearch(value);
                            history.push(match.url);
                        }}
                        onSearchClear={() => {
                            setSearch(undefined);
                            history.push(match.url);
                        }}
                    />
                </Column>
            </ColumnContainer>
        </MainContainer>
    );
}
