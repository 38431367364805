/*eslint no-unused-expressions: 0*/
import React, { Fragment, useState } from 'react';
import { useMutation, useQueryCache } from 'react-query';

import { SimpleDropdown, NormalPopup, TitleContainer, Paragraph, ButtonsRow, MainButton, Divider } from '@beacharound/beacharound-ui';
import { updateActivityTransaction } from '../../api/transactions';
import { Link } from 'react-router-dom';

export default function TransactionsActions({ history, match, full, flexEnd, transaction }) {


    const [ recoverPopup, setRecoverPopup ] = useState()
    const [ cancelPopup, setCancelPopup ] = useState()
    const [ paymentPopup, setPaymentPopup ] = useState()

    const cache = useQueryCache()

    const [update, { status: updateStatus }] = useMutation(updateActivityTransaction, {
        onSuccess: () => {
            cache.invalidateQueries(["transactions", transaction?._id]);
            return setCancelPopup()
        }
    });

    let actions = []

    if (!["TRANSFERED", "TRANSFERPENDING", "CANCELED"].includes(transaction?.status)) {
        actions = [
            {
                text: "Imposta rimborso",
                icon: "cross",
                action: () => setCancelPopup(true)
            },
            ...actions
        ]
    }

    if (!["TRANSFERED", "TRANSFERPENDING", "ACCEPTED"].includes(transaction?.status)) {
        actions = [
            {
                text: "Ripristina transazione",
                icon: "arrow-left",
                action: () => setRecoverPopup(true)
            },
            ...actions
        ]
    }

    if (["TRANSFERED", "TRANSFERPENDING"].includes(transaction?.status)) {
        actions = [
            {
                text: "Prenotazione in un pagamento",
                icon: "cross",
                action: () => setPaymentPopup(true)
            },
            ...actions
        ]
    }

    function recoverHandler() {
        return update({
            elementId: transaction?._id,
            activityId: transaction?.activityId?._id,
            data: {
                status: "ACCEPTED"
            }
        })
    }

    function cancelHandler() {
        return update({
            elementId: transaction?._id,
            activityId: transaction?.activityId?._id,
            data: {
                status: "REFOUNDED"
            }
        })
    }

    return (
        <Fragment>
            <SimpleDropdown
                buttonText="Cosa vuoi fare?"
                list={actions}
                alignRight={!full}
                flexEnd={flexEnd}
                buttonStyle
                fullWrapper
            />


            {/* CONFERMA */}
            <NormalPopup
                visible={recoverPopup}
                setVisible={setRecoverPopup}
                title="Ripristina transazione"
            >
                <TitleContainer>
                    <Paragraph text={`Sei sicuro di voler ripristinare questa transazione? La transazione risulterà con status ACCEPTED, sarà inserita nei futuri pagamenti e sarà nuovamente considerata nel calcolo delle prenotazioni.`}/>
                </TitleContainer>
                <Divider/>
                <ButtonsRow fill>
                    <MainButton
                        text="Annulla"
                        border
                        action={() => setRecoverPopup()}
                    />
                    <MainButton
                        text="Conferma"
                        border
                        action={recoverHandler}
                        status={updateStatus}
                    />
                </ButtonsRow>
            </NormalPopup>

            {/* CANCELLA */}
            <NormalPopup
                visible={cancelPopup}
                setVisible={setCancelPopup}
                title="Rimborsa transazione"
            >
                <TitleContainer>
                    <Paragraph text={`Sei sicuro di voler impostare questa transazione come rimborsata? La transazione non verrà inserita nei futuri pagamenti e non sarà più letta nel calcolo delle prenotazioni.`}/>
                </TitleContainer>
                <Divider/>
                <ButtonsRow fill>
                    <MainButton
                        text="Annulla"
                        border
                        action={() => setCancelPopup()}
                    />
                    <MainButton
                        text="Conferma"
                        border
                        action={cancelHandler}
                        status={updateStatus}
                    />
                </ButtonsRow>
            </NormalPopup>

            {/* CANCELLA */}
            <NormalPopup
                visible={paymentPopup}
                setVisible={setPaymentPopup}
                title="Prenotazione in pagamento"
            >
                <TitleContainer>
                    <Paragraph text={`Questa prenotazione è in un pagamento. Vuoi andare ai pagamenti?`}/>
                </TitleContainer>
                <Divider/>
                <ButtonsRow fill>
                    <MainButton
                        text="Annulla"
                        border
                        action={() => setCancelPopup()}
                    />
                    <MainButton
                        text="Vai ai pagamenti"
                        border
                        component={Link}
                        to={`/financial/payments`}
                    />
                </ButtonsRow>
            </NormalPopup>

        </Fragment>
    );
}