import { getProductImage } from '@beacharound/beacharound-ui-helpers'

export function getProductsList(products) {
    if(!Array.isArray(products)) { return [] }
    return products?.map(getProductsElement) || []
}

export function getProductsElement(product) {
    if(!product) { return {} }

    const priceDefault = product.activityPrices?.find(price => price.type === "DEFAULT")
    const priceMorning = product.activityPrices?.find(price => price.type === "MORNING")
    const priceAfternoon = product.activityPrices?.find(price => price.type === "AFTERNOON")
    
    const priceService = product.activityPrices?.find(price => ["DAILY","HOURLY"].includes(price.type))

    return {
        ...product,
        productType: product.type?._id,
        name: product.name || product.type?.name,
        image: getProductImage(product?.type || {}),
        _id: product._id,
        halfDay: priceMorning && priceAfternoon ? true : false,
        daily: priceDefault?.value,
        morning: priceMorning?.value,
        afternoon: priceAfternoon?.value,
        priceType: priceService?.type,
        price: priceService?.value
    }
}

export function cleanProductBeforeUpdate(data) {

    let activityPrices;

    let payload = {
        ...data
    }

    if(data?.productType) {
        payload = {
            ...payload,
            type: data?.productType
        }
    }

    if (data.daily || data.morning || data.afternoon) {

        activityPrices = [{
            value: data.daily,
            type: "DEFAULT"
        }]
        
        if (data.halfDay && data.morning && data.afternoon) {
            activityPrices = [
                ...activityPrices,
                { value: data.morning, type: "MORNING" },
                { value: data.afternoon, type: "AFTERNOON" }
            ]
        }
    }

    // PER LE CAMERE
    if (data.priceType) {

        let type;

        switch (data.priceType) {
            // SERVIZI
            case 'daily': type = 'DAILY'; break;
            case 'hourly': type = 'HOURLY'; break;
        
            default: type = 'DEFAULT'; break;
        }
        
        activityPrices = [{
            value: data.price,
            type
        }]
    }

    if(activityPrices) {
        payload = {
            ...payload,
            activityPrices
        }
    }
    
    return payload;
}

export function getProductsOptions(products) {
    if (!Array.isArray(products)) { return [] }
    return products?.map(({ _id, name, type }) => {
        return {
            value: _id,
            label: name,
            image: getProductImage(type || {}),
        }
    }) || [];
}