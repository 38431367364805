import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation, useQueryCache, useQuery } from 'react-query';

import { MainContainer, TitleContainer, MainTitle, SectionTitle, MainFormContent, Divider, ButtonsRow, MainButton, MainForm, TextInput, TextAreaInput, SelectInput } from '@beacharound/beacharound-ui';
import RoomBedsListEdit from "../../../../../components/Hotel/Rooms/RoomBedsListEdit";
import RoomBedsPopupEdit from "../../../../../components/Hotel/Rooms/RoomBedsPopupEdit";
import RoomPricesListEdit from "../../../../../components/Hotel/Rooms/RoomPricesListEdit";
import RoomPricesPopupEdit from "../../../../../components/Hotel/Rooms/RoomPricesPopupEdit";
import { createRoom } from '../../../../../api/hotels/rooms';
import { getProductsTypes } from '../../../../../api/productsTypes';
import { getProductsOptions } from '../../../../../helpers/productsTypes';

const constraints = {
    name: { presence: true },
    description: { presence: true },
    productType: { presence: true },
    priceType: { presence: true },
}

const priceTypes = [{
    label: 'A notte a persona',
    value: 'daily-each'
},{
    label: 'A notte a camera',
    value: 'daily-all'
}]

export default function RoomsCreate({ match, history }) {
    
    const { elementId } = match.params;

    const [beds, setBeds] = useState([]);
    const [bedPopup, setBedPopup] = useState();
    const [priceTypeState, setPriceTypeState] = useState();
    const [published, setPublished] = useState();
    const [prices, setPrices] = useState([]);
    const [pricePopup, setPricePopup] = useState();
    const [beforeUpdateErrors, setBeforeUpdateErrors] = useState();

    const { data: roomTypes } = useQuery(["productsType", "rooms"], () =>
        getProductsTypes({params: {params: {type: "room"}}})
    );

    const cache = useQueryCache();
    const [update, { status, errors }] = useMutation(createRoom, {
        onSuccess(data) {
            cache.invalidateQueries(["hotels", elementId, "rooms"]);
            cache.setQueryData(["hotels", elementId, "rooms", data._id], data);
            return history.push(match.url.replace("create", data._id));
        }
    })

    async function saveNewRoom(data) {

        if (!beds || beds.length <= 0) {
            return setBeforeUpdateErrors({
                beds: "Non puoi inserire una camera senza letti",
            })
        }

        if (!prices || prices.length <= 0) {
            return setBeforeUpdateErrors({
                prices: "Non puoi inserire una camera senza prezzi",
            })
        }

        try {
            await update({
                data: {
                    ...data,
                    beds,
                    prices,
                    published
                },
                elementId,
            })
        } catch(error) {
            console.log(error);
        }
    }

    function changePriceType(type) {
        setPriceTypeState(type)
    }

    return (
        <MainContainer>
            <TitleContainer>
                <MainTitle text="Nuova Camera" />
            </TitleContainer>

            <MainForm
                onSubmit={saveNewRoom}
                constraints={constraints}
                errors={errors || (beforeUpdateErrors && [beforeUpdateErrors])}
            >
                <TitleContainer>
                    <SectionTitle text="Dati principali" />
                </TitleContainer>
                <MainFormContent flex>
                    <TextInput
                        label="Nome camera"
                        name="name"
                    />
                    <SelectInput
                        label="Tipologia di camera"
                        name="productType"
                        options={getProductsOptions(roomTypes)}
                        half
                    />
                    <SelectInput
                        label="Tipologia di prezzo"
                        name="priceType"
                        options={priceTypes}
                        onChangeInput={(name, type) => changePriceType(type)}
                        half
                    />
                    <TextAreaInput
                        name="description"
                        label="Descrizione"
                    />
                </MainFormContent>

                <Divider />

                <RoomBedsListEdit
                    beds={beds}
                    setBedPopup={setBedPopup}
                    error={beforeUpdateErrors?.beds} 
                />

                <Divider />
                
                {priceTypeState &&
                    <Fragment>
                        <RoomPricesListEdit
                            prices={prices}
                            setPricePopup={setPricePopup}
                            priceType={priceTypeState}
                            error={beforeUpdateErrors?.prices} 
                        />
                        <Divider />
                    </Fragment>
                }

                <ButtonsRow fill>
                    <MainButton
                        border
                        text="Annulla"
                        component={Link}
                        to={`/activities/hotels/${elementId}/rooms`}
                    />
                    <MainButton
                        border
                        submit
                        actionOnSubmit={() => setPublished(false)}
                        text="Salva bozza"
                    />
                    <MainButton
                        submit
                        text="Crea camera"
                        status={status}
                        actionOnSubmit={() => setPublished(true)}
                    />
                </ButtonsRow>
            </MainForm>

            <RoomBedsPopupEdit
                beds={beds}
                setBeds={setBeds}
                setBedPopup={setBedPopup}
                bedPopup={bedPopup}
            />

            <RoomPricesPopupEdit
                prices={prices}
                setPrices={setPrices}
                setPricePopup={setPricePopup}
                pricePopup={pricePopup}
                priceType={priceTypeState}
            />
        </MainContainer>
    );
}