import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useMutation, useQueryCache } from "react-query";

import {
    MainContainer,
    TitleContainer,
    MainTitle,
    MainForm,
    MainFormContent,
    TextInput,
    SelectInput,
    ButtonsRow,
    MainButton,
    Divider,
    SectionTitle,
    TextAreaInput
} from "@beacharound/beacharound-ui";

import { createMembershipsPack } from "../../../api/memberships";
import { packTypeOptions, paymentTypeOptions, activityTypeOptions } from '../../../helpers/memberships';

const constraints = {
    name: { presence: true },
    type: { presence: true },
    pack: { presence: true },
    activityType: { presence: true },
    price: { presence: true },
    duration: { presence: true },
}

export default function Create({ match, history }) {

    const cache = useQueryCache();
    const [ activity, setActivity ] = useState()
    // const [ payment, setPayment ] = useState()

    const [create, { status }] = useMutation(createMembershipsPack, {
        onSuccess: (data) => {
            cache.invalidateQueries("membershipsPacks");
            cache.setQueryData(["membershipsPacks", data._id], data);
            return history.push(match.url.replace("create", data?._id))
        }
    })

    function save(data) {
        return create({
            data: {
                ...data
            }
        })
    }

    function setActivityHandler(name, value) {
        return setActivity(value)
    }

    // function setPaymentHandler(name, value) {
    //     return setPayment(value)
    // }

    return (
        <MainContainer marginBottom>
            <TitleContainer>
                <MainTitle text="Crea pacchetto" />
            </TitleContainer>

            <MainForm onSubmit={save} constraints={constraints}>
                <TitleContainer>
                    <SectionTitle text="Dati generali" />
                </TitleContainer>
                <MainFormContent flex>
                    <TextInput
                        label="Nome"
                        name="name"
                        half
                    />
                    <SelectInput
                        label="Tipologia attività"
                        name="activityType"
                        options={activityTypeOptions}
                        onChangeInput={setActivityHandler}
                        half
                    />
                    <SelectInput
                        label="Tipologia pacchetto"
                        name="pack"
                        readOnly={!activity}
                        options={packTypeOptions.filter((opt) => {
                            return opt.type.includes(activity)
                        })}
                        half
                    />
                    <SelectInput
                        label="Tipologia pagamento"
                        name="type"
                        options={paymentTypeOptions}
                        // onChangeInput={setPaymentHandler}
                        half
                    />
                    <TextInput
                        label={'Prezzo di acquisto'}
                        minNum={0}
                        price
                        name="price"
                        type="number"
                        half
                    />
                    <TextInput
                        label="Percentuale"
                        name="fee"
                        minNum={0}
                        maxNum={100}
                        type="number"
                        step="0.01"
                        half
                    />
                    <TextInput
                        label="Fisso a transazione"
                        name="fixed"
                        minNum={0}
                        price
                        type="number"
                        half
                    />
                    <TextInput
                        label="Durata (in giorni)"
                        name="duration"
                        type="number"
                        half
                    />
                    <Divider/>

                    <TitleContainer>
                        <SectionTitle text="Dettagli pacchetto"/>
                    </TitleContainer>

                    <TextInput
                        label="Sottotitolo"
                        name="subtitle"
                    />
                    <TextAreaInput
                        label="Descrizione"
                        name="description"
                    />
                </MainFormContent>

                <Divider />

                <ButtonsRow fill marginBottom>
                    <MainButton
                        text="Annulla"
                        border
                        component={Link}
                        to={`/memberships-packs`}
                    />
                    <MainButton text="Crea pacchetto" submit status={status} />
                </ButtonsRow>
            </MainForm>


        </MainContainer>
    );
}
