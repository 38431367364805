import { lazy } from 'react';

import MainLayout from "../layouts/MainLayout/MainLayout";
import EmptyLayout from '../layouts/EmptyLayout/EmptyLayout';

import Dashboard from "../pages/Dashboard";
const NotFound = lazy(() => import('../pages/NotFound'));
const Terms = lazy(() => import('../pages/General/Terms'));
const Privacy = lazy(() => import('../pages/General/Privacy'));

const generalRoutes = [
    {
        path: "/",
        component: Dashboard,
        layout: MainLayout,
        exact: true,
        private: true
    },
    {
        path: "/404",
        component: NotFound,
        layout: MainLayout,
        analytics: { name: ["404"] },
        private: false
    },
    {
        path: '/terms',
        component: Terms,
        layout: EmptyLayout,
        exact: true,
        analytics: { name: ["Termini e condizioni"] },
        private: false
    },
    {
        path: '/privacy',
        component: Privacy,
        layout: EmptyLayout,
        exact: true,
        analytics: { name: ["Privacy policy"] },
        private: false
    }
];

export default generalRoutes;