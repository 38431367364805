import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { useMutation, useQuery, useQueryCache } from 'react-query';

import { getInformationsRequest, updateInformationsRequest } from "../../../api/informationsRequests";

import { MainContainer, MainForm, MainFormContent, SectionTitle, TitleContainer, MainTitle, SubTitle, FormToggleGroup, FormToggleGroupPreview, SimpleFormField, FormToggleGroupEdit, TextInput, SelectInput, GooglePlacesInput, LoadingContainer, ColumnContainer, Column, SimpleField, Divider } from '@beacharound/beacharound-ui';
import { getSubFormData } from '@beacharound/beacharound-ui-helpers';
import { activityTypeOptions } from "../../../helpers/informationsRequests";

export default function Detail({ match }) {

    const { elementId } = match.params;
    const { data, isLoading } = useQuery(["informationsRequests", elementId], () =>
        getInformationsRequest({ elementId })
    );

    const cache = useQueryCache()
    const [update, {status: updateStatus, reset}] = useMutation(updateInformationsRequest, {
        onSuccess: () => cache.invalidateQueries('informationsRequests')
    })

    async function editElementContact(contact, callback) {
        try {
            await update({
                elementId,
                data: {
                    contact
                }
            })
            return callback(reset)
        } catch(error) {
            return callback(null, error)
        }
    }

    async function editElementAddress(address, callback) {
        try {
            await update({
                elementId,
                data: {
                    address
                }
            })
            return callback(reset)
        } catch(error) {
            return callback(null, error)
        }
    }

    async function editElement(data, callback) {
        try {
            await update({
                elementId,
                data
            })
            return callback(reset)
        } catch(error) {
            return callback(null, error)
        }
    }

    return (
        <MainContainer marginBottom>
            <TitleContainer>
                <MainTitle text="Gestisci richiesta" />
                <SubTitle
                    text="< Torna alla lista"
                    component={Link}
                    to={match.url.replace(`/${elementId}`, "")}
                />
            </TitleContainer>

            <LoadingContainer isLoading={isLoading}>
                <TitleContainer>
                    <SectionTitle text="Dati utente" />
                </TitleContainer>

                <MainForm onSubmit={editElementContact} data={data?.contact}>
                    <MainFormContent>
                        <FormToggleGroup>
                            <FormToggleGroupPreview>
                                <SimpleFormField
                                    label="Nome utente"
                                    name="firstName"
                                    half
                                />
                                <SimpleFormField
                                    label="Cognome utente"
                                    name="lastName"
                                    half
                                />
                                <SimpleFormField
                                    label="Numero di telefono"
                                    name="phone"
                                    half
                                />
                                <SimpleFormField
                                    label="Email"
                                    name="email"
                                    half
                                />
                            </FormToggleGroupPreview>

                            <FormToggleGroupEdit flex status={updateStatus}>
                                <TextInput
                                    label="Nome utente"
                                    name="firstName"
                                    half
                                />
                                <TextInput
                                    label="Cognome utente"
                                    name="lastName"
                                    half
                                />
                                <TextInput
                                    label="Numero di telefono"
                                    name="phone"
                                    half
                                />
                                <TextInput label="Email" name="email" half />
                            </FormToggleGroupEdit>
                        </FormToggleGroup>
                    </MainFormContent>
                </MainForm>

                {data?.message && (
                    <Fragment>
                        <TitleContainer>
                            <SectionTitle text="Dati attività" />
                        </TitleContainer>
                        <MainContainer>
                            <ColumnContainer noMargin>
                                <Column>
                                    <SimpleField
                                        label="Oggetto"
                                        value={data?.message?.title}
                                    />
                                    <SimpleField
                                        label="Messaggio"
                                        value={data?.message?.description}
                                    />
                                    <Divider />
                                </Column>
                            </ColumnContainer>
                        </MainContainer>
                    </Fragment>
                )}

                <TitleContainer>
                    <SectionTitle text="Dati attività" />
                </TitleContainer>

                <MainForm
                    onSubmit={editElement}
                    data={getSubFormData(data, [
                        "activityType",
                        "activityName",
                    ])}
                >
                    <MainFormContent>
                        <FormToggleGroup>
                            <FormToggleGroupPreview>
                                <SimpleFormField
                                    label="Tipologia attività"
                                    name="activityType"
                                    half
                                />
                                <SimpleFormField
                                    label="Nome attività"
                                    name="activityName"
                                    half
                                />
                            </FormToggleGroupPreview>

                            <FormToggleGroupEdit flex status={updateStatus}>
                                <SelectInput
                                    label="Tipologia attività"
                                    options={activityTypeOptions}
                                    name="activityType"
                                    half
                                />
                                <TextInput
                                    label="Nome attività"
                                    name="activityName"
                                    half
                                />
                            </FormToggleGroupEdit>
                        </FormToggleGroup>
                    </MainFormContent>
                </MainForm>

                <TitleContainer>
                    <SectionTitle text="Indirizzo" />
                </TitleContainer>

                <MainForm onSubmit={editElementAddress} data={data?.address}>
                    <MainFormContent>
                        <FormToggleGroup>
                            <FormToggleGroupPreview>
                                <SimpleFormField
                                    label="Città"
                                    name="locality"
                                    half
                                />
                                <SimpleFormField
                                    label="Regione"
                                    name="district"
                                    half
                                />
                                <SimpleFormField
                                    label="Via"
                                    name="street"
                                    half
                                />
                                <SimpleFormField
                                    label="Numero civico"
                                    name="streetNumber"
                                    half
                                />
                            </FormToggleGroupPreview>

                            <FormToggleGroupEdit flex status={updateStatus}>
                                <GooglePlacesInput
                                    name="address"
                                    label="Indirizzo"
                                    center={{
                                        lat: 43.9694106,
                                        lng: 12.7317885,
                                    }}
                                    height="370px"
                                    required
                                />
                            </FormToggleGroupEdit>
                        </FormToggleGroup>
                    </MainFormContent>
                </MainForm>
            </LoadingContainer>
        </MainContainer>
    );
}
