import axios from './_defaults/user';

import { cleanProductBeforeUpdate } from '../helpers/products'

export async function createProduct({ data, elementId }) {
    const payload = cleanProductBeforeUpdate(data);
    const response = await axios.post(`/admin-beaches/${elementId}/products`, payload);

    if (response.data) {
        return response.data
    }

    return response
}

export async function getProducts({ params, elementId }) {
    try {
        const response = await axios.get(`/admin-beaches/${elementId}/products`, {
            params
        });

        return response?.data;
    } catch (error) {
        if (error.response?.status === 404) {
            return []
        }
        throw error;
    }
}

export async function getProduct({ productId, elementId }) {
    try {
        const response = await axios.get(`/admin-beaches/${elementId}/products/${productId}`);

        return response?.data;
    } catch (error) {
        throw error;
    }
}

export async function updateProduct({ productId, elementId, data }) {
    try {
        const payload = cleanProductBeforeUpdate(data);
        const response = await axios.post(`/admin-beaches/${elementId}/products/${productId}`, payload);
        return response?.data;
    } catch (error) {
        throw error;
    }
}

export async function deleteProduct({ productId, elementId }) {
    try {
        const response = await axios.delete(`/admin-beaches/${elementId}/products/${productId}`);
        return response?.data;
    } catch (error) {
        throw error;
    }
}

export async function getProductVariations({ productId, elementId }) {
    try {
        const response = await axios.get(`/admin-beaches/${elementId}/products/${productId}/variations`);

        return response?.data;
    } catch (error) {
        throw error;
    }
}

export async function updateProductVariation({ productId, elementId, data }) {
    try {
        const payload = cleanProductBeforeUpdate(data);
        const response = await axios.post(`/admin-beaches/${elementId}/products/${productId}/variations`, payload, {
            params: {
                variationId: data._id
            }
        });

        return response?.data;
    } catch (error) {
        return error;
    }
}