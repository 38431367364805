import React, { useState } from 'react';

import { Column, ColumnContainer, Divider, MainButton, MainContainer, MainForm, MainFormContent, MainTitle, TextInput, TitleContainer } from '@beacharound/beacharound-ui';

const constraints = {
    email: { presence: true, email: true }
}

export default function ForgotPassword() {

    const [buttonStatus, setButtonStatus] = useState();

    function requestPswHandler(data) {
        setButtonStatus("LOADING")
    }

    return (

        <MainContainer small>

            <ColumnContainer>
                <Column>

                    <TitleContainer>
                        <MainTitle text="Recupero password" />
                    </TitleContainer>

                    <MainForm onSubmit={requestPswHandler} constraints={constraints}>
                        <MainFormContent>
                            <TextInput name="email" label="Email" required />
                        </MainFormContent>
                    </MainForm>

                    <Divider />

                    <MainButton fullWidth text="Richiedi modifica password" submit status={buttonStatus}/>

                </Column>
            </ColumnContainer>

        </MainContainer>
    )
}
