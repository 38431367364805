import React from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import { useMutation, useQuery, useQueryCache } from "react-query";

import {
    MainContainer,
    MainForm,
    MainFormContent,
    SectionTitle,
    TitleContainer,
    MainTitle,
    SubTitle,
    FormToggleGroup,
    FormToggleGroupPreview,
    SimpleFormField,
    FormToggleGroupEdit,
    TextInput,
    LoadingContainer,
    SelectInput,
    ColumnContainer,
    Column,
    ToggleInput
} from "@beacharound/beacharound-ui";

import { getService, updateService } from "../../../api/services";
import { beachCategoriesOptions, getSubFormData } from '@beacharound/beacharound-ui-helpers';

const typeOptions = [
    { label: "Spiaggie", value: "beaches" },
    { label: "Hotel", value: "hotels" },
    { label: "Luoghi", value: "places" },
]

const constraints = {
    name: { presence: true },
    type: { presence: true },
    categories: { presence: true },
}

export default function Detail({ match, history }) {
    const { type, elementId } = match.params;
    const { data, isLoading } = useQuery(["services", type, elementId], () =>
        getService({ type, elementId })
    )

    const cache = useQueryCache();
    const [update, {status: updateStatus, reset}] = useMutation(updateService, {
        onSuccess: () => cache.invalidateQueries(["services", type, elementId]),
    })
    
    // const [remove, {status: removeStatus}] = useMutation(deleteService, {
    //     onSuccess: () => {
    //         cache.invalidateQueries(["services", type])
    //         history.push(match.url.replace(`/${elementId}`, ""))
    //     }
    // })

    async function editElement(data, callback) {
        try {
            await update({
                type,
                elementId,
                data,
            });
            return callback(reset)
        } catch(error) {
            console.log(error)
            return callback(null, error)
        }
    }

    // async function deleteElement() {
    //     try {
    //         await remove({
    //             type,
    //             elementId
    //         });
    //     } catch(error) {
    //         console.log(error)
    //     }
    // }

    return (
        <MainContainer marginBottom>

            <ColumnContainer>
                <Column column={2}>
                    <TitleContainer noMargin>
                        <MainTitle text={data?.name || "Gestisci servizio"} />
                        <SubTitle
                            text="< Torna alla lista"
                            component={Link}
                            to={`${match.url.replace(`/edit/${elementId}`, "")}`}
                        />
                    </TitleContainer>
                </Column>

                <Column column={2}>
                    <ToggleInput
                        flexEnd
                        trueLabel="Pubblicato"
                        falseLabel="Non pubblicato"
                        status={updateStatus}
                        changeValue={()=> editElement({published: !data?.published}, (resetAfter) => resetAfter())}
                        value={data?.published}
                    />
                </Column>
            </ColumnContainer>

            <LoadingContainer isLoading={isLoading}>
                <TitleContainer>
                    <SectionTitle text="Dati generali" />
                </TitleContainer>

                <MainForm onSubmit={editElement} data={getSubFormData(data, ["name", "type", "categories"])} constraints={constraints}>
                    <MainFormContent>
                        <FormToggleGroup>
                            <FormToggleGroupPreview>
                                <SimpleFormField
                                    label="Nome"
                                    name="name"
                                    half
                                />
                                <SimpleFormField
                                    label="Tipologia"
                                    name="type"
                                    options={typeOptions}
                                    isMulti
                                />
                                <SimpleFormField
                                    label="Categoria"
                                    name="categories"
                                    options={beachCategoriesOptions}
                                    isMulti
                                />
                            </FormToggleGroupPreview>

                            <FormToggleGroupEdit flex status={updateStatus}>
                                <TextInput
                                    label="Nome"
                                    name="name"
                                    half
                                />
                                <SelectInput
                                    Component={Select}
                                    label="Tipologia"
                                    name="type"
                                    options={typeOptions}
                                    isMulti
                                />
                                <SelectInput
                                    Component={Select}
                                    label="Categorie"
                                    name="categories"
                                    options={beachCategoriesOptions}
                                    isMulti
                                />
                            </FormToggleGroupEdit>
                        </FormToggleGroup>
                    </MainFormContent>
                </MainForm>

                {/* <ButtonsRow>
                    <MainButton border text="Elimina servizio" action={deleteElement} status={removeStatus}/>
                </ButtonsRow> */}
            </LoadingContainer>
        </MainContainer>
    )
}
