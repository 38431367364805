import React, { useState } from "react";
import qs from "query-string";
import { Link } from "react-router-dom";
import { usePaginatedQuery } from "react-query";

import {
    MainContainer,
    TitleContainer,
    MainTitle,
    ColumnContainer,
    Column,
    SimpleTable,
    ButtonsRow,
    MainButton
} from "@beacharound/beacharound-ui";

import { getUsers } from "../../../api/users/users";
import CreateUserPopup from "../../../components/Users/CreateUserPopup";

export default function List({ match, location, history }) {
    const { page } = location.search ? qs.parse(location.search) : { page: "0" };

    const [limit, setLimit] = useState(25);
    const [createUserPopup, setCreateUserPopup] = useState();

    const options = {
        skip: Number(page) * limit,
        limit,
        sort: { name: 1 },
        params: {},
    };

    const { resolvedData: data, isLoading } = usePaginatedQuery(["users", "business", options], () =>
        getUsers({
            role: "business",
            params: options,
        })
    );

    const tableHead = [{
        text: "Nome",
        key: ["lastName", "firstName"],
        sort: "text",
        highlight: true,
    },{
        text: "Email",
        key: ["email"],
        sort: "text",
    },{
        actions: (item) => {
            return [
                {
                    icon: "edit",
                    component: Link,
                    to: `${match.url}/${item._id}`,
                }
            ]
        }
    }]

    const tableRows =
        data?.map(({ _id, profile, emails }) => {
            return {
                ...profile,
                email: emails?.[0]?.address,
                _id,
            };
        }) || [];

    return (
        <MainContainer large>
            <ColumnContainer noMargin>
                <Column column={2}>
                    <TitleContainer>
                        <MainTitle text="Utenti business" />
                    </TitleContainer>
                </Column>
                <Column column={2}>
                    <ButtonsRow flexEnd>
                        <MainButton text="Crea utente business" action={() => setCreateUserPopup(true)}/>
                    </ButtonsRow>
                </Column>
            </ColumnContainer>

            <ColumnContainer>
                <Column>
                    <SimpleTable
                        match={match}
                        history={history}
                        setLimit={setLimit}
                        navigation
                        search
                        data={tableRows}
                        head={tableHead}
                        isLoading={isLoading}
                        page={page}
                        
                    />
                </Column>
            </ColumnContainer>

            <CreateUserPopup visible={createUserPopup} setVisible={setCreateUserPopup} organizationType="business"/>
        </MainContainer>
    );
}
