import React, { useState } from "react";
import qs from "query-string";
import { Link } from "react-router-dom";
import { usePaginatedQuery } from "react-query";

import {
    MainContainer,
    TitleContainer,
    MainTitle,
    ColumnContainer,
    Column,
    SimpleTable,
    ButtonsRow,
    MainButton
} from "@beacharound/beacharound-ui";

import { getProducts } from '../../../../../api/products';

export default function Parkings({ history, match, location }) {
    
    const { elementId } = match.params;
    const { page } = location.search ? qs.parse(location.search) : { page: "0" };

    const [ limit, setLimit ] = useState(25);

    const options = {
        skip: Number(page) * limit,
        limit,
        sort: { name: 1 },
        params: {}
    }
    
    const { data, isLoading } = usePaginatedQuery(["parkings", elementId, options], () =>
        getProducts({
            elementId,
            params: {
                type: 'parking',
                ...options
            }
        })
    )   

    const tableHead = [{
            text: "Nome",
            key: "name",
            sort: "text",
            highlight: true,
            default: true,
            image: (item) => item?.cover,
        },{
            text: 'Quantità',
            key: 'quantity'
        },{
            flex: 0.5,
            actions: (item) => {
                return [{
                    icon: "edit",
                    component: Link,
                    to: `${match.url}/${item._id}`,
                }]
            }
        }
    ]

    const tableRows = data || [];

    return (
        <MainContainer large>

            <ColumnContainer>
                <Column column={2}>
                    <TitleContainer>
                        <MainTitle text="Parcheggi" />
                    </TitleContainer>
                </Column>
                <Column column={2}>
                    <ButtonsRow flexEnd>
                        <MainButton text="Nuovo parcheggio" component={Link} to={`${match.url}/create`}/>
                    </ButtonsRow>
                </Column>
            </ColumnContainer>

            <ColumnContainer>
                <Column>
                    <SimpleTable
                        match={match}
                        history={history}
                        setLimit={setLimit}
                        navigation
                        data={tableRows}
                        head={tableHead}
                        isLoading={isLoading}
                        page={page}
                    />
                </Column>
            </ColumnContainer>
        </MainContainer>
    );
}
