import React, { useState, Fragment } from 'react';
import moment from 'moment';
import { useQuery, useMutation, useQueryCache } from 'react-query';

import { MainForm, MainFormContent, SectionTitle, TitleContainer, TextInput, SelectInput, Tile, NormalPopup, DateInput, ButtonsRow, MainButton, Divider, SimpleLink, Column, ColumnContainer } from '@beacharound/beacharound-ui';

import { getProductVariations, updateProductVariation } from '../../../api/products';

import { getProductsList } from '../../../helpers/products';

export default function ProductVariations({productId, elementId, queryId}) {

    const cache = useQueryCache()
    const { data: variationsData } = useQuery(queryId, () => getProductVariations({ productId, elementId }));
    const [updateVariation, result] = useMutation(updateProductVariation, {
        onSuccess: () => cache.invalidateQueries(queryId)
    })

    const seasonPrices = getProductsList(variationsData);
    const [seasonPricePopup, setSeasonPricePopup] = useState()

    const [isHalfDay, setIsHalfDay] = useState()

    const constraints = {
        fromDate: { presence: true },
        toDate: { presence: true },
        halfDay: { presence: true },
        daily: { presence: true },
        morning: { presence: isHalfDay},
        afternoon: { presence: isHalfDay},
    }

    async function editSeasonPrice(data) {
        let variation = {};

        if (data.fromDate) {
            variation = {
                ...variation,
                fromDate: data.fromDate
            }
        }

        if (data.toDate) {
            variation = {
                ...variation,
                toDate: data.toDate
            }
        }

        const payload = {
            ...data,
            variation
        }

        await updateVariation({ data: payload, elementId, productId });

        return setSeasonPricePopup(undefined)
    }

    function changeHalfDay(name, value) {
        return setIsHalfDay(value || false)
    }
    
    return (
        <Fragment>
            <TitleContainer>
                <SectionTitle text="Prezzi stagionali" />
            </TitleContainer>
            
            {
                seasonPrices?.map((p, index) => {

                    let priceString = `Giornata: ${p?.daily}€`;
                    const title = `Dal ${moment(p.variation?.fromDate).format('LL')} al ${moment(p.variation?.toDate).format('LL')}`;

                    if (p?.morning && p?.afternoon) {
                        priceString = `Giornata: ${p?.daily}€ - Mattina: ${p?.morning}€ - Pomeriggio: ${p?.afternoon}€`;
                    }

                    return (
                        <Tile
                            key={index}
                            title={title}
                            text={priceString}
                            buttonAction={() => setSeasonPricePopup(p)}
                            buttonLinkText="Modifica"
                            buttonRight
                        />
                    )
                })
            }

            <ColumnContainer>
                <Column>
                    <SimpleLink text="Aggiungi prezzo stagionale" action={() => setSeasonPricePopup(true)} />
                </Column>
            </ColumnContainer>

            <NormalPopup visible={seasonPricePopup} setVisible={() => setSeasonPricePopup(false)} title="Modifica prezzo stagionale">

                <MainForm
                    onSubmit={editSeasonPrice}
                    data={seasonPricePopup?.variation ? {
                        fromDate: seasonPricePopup.variation.fromDate,
                        toDate: seasonPricePopup.variation.toDate,
                        ...seasonPricePopup
                    } : {}}
                    constraints={constraints}
                >
                    <MainFormContent flex>
                        <DateInput
                            name="fromDate"
                            label="Data di inizio"
                            required
                            half
                        />
                        <DateInput
                            name="toDate"
                            label="Data di fine"
                            required
                            half
                        />
                        <SelectInput
                            label="Disponibilità mezza giornata"
                            name="halfDay"
                            options={[{ value: true, label: 'Si' }, { value: false, label: 'No' }]}
                            onChangeInput={changeHalfDay}
                            half
                        />
                        <TextInput
                            label="Prezzo giornaliero base"
                            name="daily"
                            half
                        />

                        {isHalfDay &&
                            <React.Fragment>
                                <TextInput
                                    label="Prezzo solo mattina"
                                    name="morning"
                                    half
                                />
                                <TextInput
                                    label="Prezzo solo pomeriggio"
                                    name="afternoon"
                                    half
                                />
                            </React.Fragment>
                        }

                    </MainFormContent>

                    <Divider/>

                    <ButtonsRow fill >
                        <MainButton text="Annulla" border action={() => setSeasonPricePopup(false)} />
                        <MainButton text="Salva" submit {...result} />
                    </ButtonsRow>
                </MainForm>

            </NormalPopup>
        </Fragment>
    )
}