import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery, useMutation, useQueryCache } from 'react-query';
import Select from "react-select";

import { MainContainer, TitleContainer, MainTitle, MainForm, MainFormContent, TextInput, SelectInput, SelectImageInput, ButtonsRow, MainButton, Divider, SectionTitle, CheckboxInput } from '@beacharound/beacharound-ui';

import { getProductsTypes } from '../../../../../api/productsTypes';
import { getProducts, createProduct } from '../../../../../api/products';

import { getProductsOptions } from '../../../../../helpers/productsTypes';

export default function UmbrellasAdd({ match, history }) {

    const {elementId, type} = match.params;

    // Tipologie di ombrelloni da scegliere
    const { data: productsTypes } = useQuery(['productsTypes', 'umbrellas'],
        () => getProductsTypes({params:{params:{type: "umbrella"}}})
    )

    // Lettini che l'utente può collegare
    // TODO: [BEACHDEV-65] Se non inserisco params come oggetto vuoto non mi torna tutti i risultati
    const { data: deckchairs } = useQuery(["deckchairs", elementId], () =>
        getProducts({
            elementId,
            params: {
                type: "deckchair",
                params: {}
            }
        })
    )

    const cache = useQueryCache()
    const [create, { status }] = useMutation(createProduct, {
        onSuccess: (data) => {
            cache.setQueryData(['product', "umbrella", elementId, data._id], data)
            cache.invalidateQueries('umbrellas')
            return history.push(match.url.replace('create', data?._id))
        }
    })

    const [isHalfDay, setIsHalfDay] = useState()

    const constraints = {
        type: { presence: true },
        name: { presence: true },
        quantity: { presence: true },
        halfDay: { presence: true },
        daily: { presence: true },
        morning: { presence: isHalfDay },
        afternoon: { presence: isHalfDay }
    }

    async function saveNewProduct(data) {

        const relatedProducts = Object.keys(data).filter(key => key.includes("deckchairs")).map(key => {
            return {
                reference: key.split('.')[1],
                type: "deckchairs"
            }
        })

        const payload = {
            ...data,
            relatedProducts
        }

        try {
            await create({ data: payload, elementId })
        } catch(error) {
            console.log(error)
        }
    }

    function changeHalfDay(name, value) {
        return setIsHalfDay(value || false)
    }

    const booleanOptions = [{ value: true, label: 'Si' }, { value: false, label: 'No' }]

    return (
        <MainContainer>
            <TitleContainer>
                <MainTitle text="Nuovo ombrellone" />
            </TitleContainer>

            <MainForm onSubmit={saveNewProduct} constraints={constraints}>
                <TitleContainer>
                    <SectionTitle text="Dati principali" />
                </TitleContainer>
                <MainFormContent flex>
                    <SelectImageInput
                        Component={Select}
                        label="Tipologia elemento"
                        name="type"
                        options={getProductsOptions(productsTypes)}
                        placeholder="Scegli la tipologia"
                    />
                    <TextInput
                        name="name"
                        label="Nome"
                        half 
                    />
                    <TextInput
                        name="quantity"
                        label="Totale elementi"
                        half 
                    />
                </MainFormContent>

                <Divider />

                <TitleContainer>
                    <SectionTitle text="Lettini compatibili" />
                </TitleContainer>
                <MainFormContent flex>
                    {deckchairs?.map((d, index) => {
                        return (
                            <CheckboxInput
                                key={index}
                                name={`deckchairs.${d._id}`}
                                label={d.name || d.productType?.name}
                            />
                        );
                    })}
                </MainFormContent>

                <Divider />

                <TitleContainer>
                    <SectionTitle text="Prezzi base" />
                </TitleContainer>
                <MainFormContent flex>
                    <SelectInput
                        Component={Select}
                        label="Disponibilità mezza giornata"
                        name="halfDay"
                        options={booleanOptions}
                        half
                        onChangeInput={changeHalfDay}
                    />
                    <TextInput
                        label="Prezzo giornaliero base"
                        name="daily"
                        half
                    />

                    {isHalfDay && (
                        <React.Fragment>
                            <TextInput
                                label="Prezzo solo mattina"
                                name="morning"
                                half
                            />
                            <TextInput
                                label="Prezzo solo pomeriggio"
                                name="afternoon"
                                half
                            />
                        </React.Fragment>
                    )}
                </MainFormContent>

                <Divider />

                <ButtonsRow fill>
                    <MainButton
                        text="Annulla"
                        border
                        component={Link}
                        to={`/activities/beaches/${type}/${elementId}/umbrellas`}
                    />
                    <MainButton text="Crea ombrellone" submit status={status} />
                </ButtonsRow>
            </MainForm>
        </MainContainer>
    );
}
