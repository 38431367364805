import MainLayout from '../layouts/MainLayout/MainLayout';

import Blog from '../pages/Blog/index.js';

// SubRoutes
import {subRoutesArticles, routesArticles} from './blog/blogArticles';
import {subRoutesRankings, routesRankings} from './blog/blogRankings';
import {subRoutesCategories, routesCategories} from './blog/blogCategories';
import { routesTutorials, subRoutesTutorials } from './blog/blogTutorials';

export default [
    ...routesArticles,
    ...routesTutorials,
    ...routesRankings,
    ...routesCategories,
    {
        path: "",
        component: Blog,
        layouts: MainLayout,
        routes: [
            ...subRoutesArticles,
            ...subRoutesTutorials,
            ...subRoutesRankings,
            ...subRoutesCategories,
        ].map((item) => {
            return {
                ...item,
                path: `/blog${item.path}`
            };
        })
    }
].map((item) => {
    
    return {
        ...item,
        path: `/blog${item.path}`,
        private: true
    };
});