import React, { Fragment, useState } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useMutation, useQuery, useQueryCache } from 'react-query';

import { Header, MobileMenu } from '@beacharound/beacharound-ui';

import { getRequests } from '../api/requestsForAssistance';
import { logoutUser } from '../api/user';

export default function MainHeader({ empty, user, activities }) {

    const [menuOpen, setMenuOpen] = useState(false)

    const history = useHistory();
    const { pathname } = useLocation();

    const activity = activities?.find(activity => pathname.indexOf(activity._id) > 0);

    // Controlliamo se ci sono elementi per le notifiche
    const requestsForAssistanceOptions = {
        params: { reply: { $exists: false } }
    };

    const cache = useQueryCache()

    const { data: requestsForAssistance } = useQuery(["requestsForAssistance", requestsForAssistanceOptions], () =>
        getRequests({ params: requestsForAssistanceOptions })
    );

    const [logout] = useMutation(logoutUser, {
        onSuccess: () => cache.invalidateQueries('user')
    });

    const menuList = [
        {
            text: "Dashboard",
            url: "/",
            active: pathname === "/" ? true : false,
        },
        {
            text: "Attività",
            url: "/activities/beaches/private",
            routeActive: "/activities",
        },
        {
            text: "Financial",
            url: "/financial/reservations/beaches/private",
            routeActive: "/financial",
        },
        {
            text: "Luoghi",
            url: "/places/beaches/private",
            routeActive: "/places",
        },
        {
            text: "Cataloghi",
            url: "/catalogues/services",
            routeActive: "/catalogues",
        },
        {
            text: "Blog",
            url: "/blog/articles",
            routeActive: "/blog",
        },
        {
            text: "Costumer Care",
            url: "/costumerCare/reviews",
            routeActive: "/costumerCare",
            notifications: requestsForAssistance?.length,
        },
        {
            text: "Utenti",
            url: "/users/customer",
            routeActive: "/users",
        },
        {
            text: "Impostazioni",
            url: "/settings/admins",
            routeActive: "/settings",
        },
    ].map((route) => ({
        ...route,
        active: route.active !== undefined ? route.active : pathname.includes(route.routeActive)
    }));

    function logoutHandler() {
        logout();
        history.push(`/user/login`);
    }

    return (
        <Fragment>
            <Header
                menuList={menuList}
                empty={empty}
                user={user}
                activityId={activity?._id}
                userName={user && `${user.firstName} ${user.lastName}`}
                activities={activities}
                linkComponent={Link}
                logoUrl={"/"}
                openMenu={() => setMenuOpen(true)}
                backAction={() => history.goBack()}
                logoutHandler={logoutHandler}
            />
            <MobileMenu
                linkComponent={Link}
                open={menuOpen}
                list={menuList}
                closeMenu={() => setMenuOpen(false)}
            />
        </Fragment>
    );
}
