import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { ButtonsRow, Divider, MainButton, MainContainer, MainForm, MainFormContent, MainTitle, Paragraph, TextInput, TitleContainer } from '@beacharound/beacharound-ui';

const constraints = {
    password: { presence: true },
    confirmPassword: { presence: true, equality: "password" }
}

export default function ChangePassword() {

    const [buttonStatus, setButtonStatus] = useState();

    function changePswHandler(data) {
        setButtonStatus("LOADING")
    }

    return (

        <MainContainer>

            <TitleContainer>
                <MainTitle text="Crea una nuova password" />
                <Paragraph text="Hai inviato una richiesta di modifica della password del tuo account" />
            </TitleContainer>

            <MainForm onSubmit={changePswHandler} constraints={constraints}>
            
                <MainFormContent flex>
                    <TextInput name="password" type="password" label="Password" placeholder="Nuova password" half/>
                    <TextInput name="confirmPassword" type="password" label="Conferma password" placeholder="Conferma password" half/>
                </MainFormContent>

                <Divider />

                <ButtonsRow spaceBetween>
                    <MainButton border fullWidth text="Torna al login" component={Link} to="/user/login" />
                    <MainButton fullWidth text="Conferma nuova password" submit status={buttonStatus}/>
                </ButtonsRow>
            </MainForm>

        </MainContainer>
    )
}
