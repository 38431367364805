import React from 'react';
import { useMutation, useQueryCache } from "react-query";
import Cookies from 'js-cookie';

import {
    NormalPopup,
    ButtonsRow,
    MainButton,
    MainForm,
    MainFormContent,
    DateInput,
    Divider
} from "@beacharound/beacharound-ui";

export default function HighlightPopup({item, setItem, type, updateFunction, elementId, queryKey}) {

    const cache = useQueryCache();

    const [update, { status }] = useMutation(updateFunction, {
        onSuccess: () => {
            cache.invalidateQueries(queryKey || type);
            return setItem(undefined);
        },
    });

    async function setHighlightHandler(formData) {
        try {
            let data;

            if(formData) {
                data = {
                    ...formData,
                    highlightBy: Cookies.get("user-id"),
                    highlightAt: new Date()
                }
            }

            if(!formData) {
                data = {
                    $unset: {highlight: ""}
                }
            }

            if(type === 'offers') {
                await update({
                    elementId: elementId,
                    offerId: item._id,
                    data: {
                        published: true,
                        highlight: data
                    }
                });
            } else if(type === "cities") {
                await update({
                    type: "cities",
                    elementId: item._id,
                    data: {
                        published: true,
                        highlight: data
                    }
                });
            } else {
                await update({
                    elementId: item._id,
                    data: {
                        published: true,
                        highlight: data
                    }
                });
            }
            return setItem(false);
        } catch(error) {
            console.log(error)
        }
    }

    // TODO: [BEACHDEV-91] rimozione di highlight su spiagge e hotel

    return (
        <NormalPopup
            visible={item}
            setVisible={() => setItem(false)}
            title={item?.name}
            large
        >
            <MainForm onSubmit={setHighlightHandler} constrains={{fromDate: {presence: true}}}>
                <MainFormContent>
                    <DateInput label="Da quando sarà in evidenza?" name="fromDate" half/>
                </MainFormContent>
                <Divider/>

                <ButtonsRow fill>
                    <MainButton
                        text="Rimuovi highlight"
                        border
                        action={() => setHighlightHandler()}
                        status={status}
                    />
                    <MainButton
                        text="Conferma"
                        submit
                        status={status}
                    />
                </ButtonsRow>

            </MainForm>
        </NormalPopup>
    );
}