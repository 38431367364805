import React, { useState } from "react";
import qs from "query-string";
import { usePaginatedQuery } from "react-query";

import {
    MainContainer,
    TitleContainer,
    MainTitle,
    ColumnContainer,
    Column,
    SimpleTable
} from "@beacharound/beacharound-ui";

import { getReservations } from "../../../api/reservations/index.js";
import { formatDate, getBeachesStatusColor }  from "@beacharound/beacharound-ui-helpers";

export default function Beaches({ history, match, location }) {

    const { page } = location.search ? qs.parse(location.search) : { page: "0" };

    const [dateFilter, setDateFilter] = useState();
    const [search, setSearch] = useState();
    const [limit, setLimit] = useState(25);
    const [params, setParams] = useState({
        status: {$ne: "CHECKINGOUT"},
        createdFrom: {$in: ["private", "external"]},
        type: "beaches"
    });
    const [sort, setSort] = useState();
    const [dateParams, setDateParams] = useState({});

    let fromDateParam;
    let toDateParam;

    if(dateParams?.fromDate) {
        fromDateParam = dateParams?.fromDate;
    }

    if(dateParams?.toDate) {
        toDateParam = dateParams?.toDate;
    }

    if(dateFilter?.[0]) {
        fromDateParam = dateFilter?.[0];
    }

    if(dateFilter?.[1]) {
        toDateParam = dateFilter?.[1];
    }

    const options = {
        skip: Number(page) * limit,
        limit,
        search,
        fromDate: fromDateParam && formatDate(new Date(fromDateParam), "yyyy-MM-dd"),
        toDate: toDateParam && formatDate(new Date(toDateParam), "yyyy-MM-dd"),
        checkin: dateParams?.checkin && formatDate(new Date(dateParams?.checkin), "yyyy-MM-dd"),
        checkout: dateParams?.checkout && formatDate(new Date(dateParams?.checkout), "yyyy-MM-dd"),
        sort,
        activityType: "public",
        params: {
            ...params,
        }
    }

    const { data: reservations, isLoading } = usePaginatedQuery(["reservations", options], () =>
        getReservations({
            params: options,
        })
    )

    let tableHead = [{
        text: "Attività",
        key: "activity",
        parse: (activity) => activity ? `${activity.name}` : '-',
        highlight: true,
        default: true,
        flex: 0.5
    },{
        text: "Utente",
        key: "user",
        parse: (user) => user?.profile ? `${user?.profile.lastName} ${user?.profile.firstName}` : '-',
        highlight: true,
        default: true,
        flex: 0.5
    },{
        text: "Checkin",
        key: "checkin",
        sort: true,
        flex: 0.4,
        hasMobileLabel: true,
        parse: (date, item) => formatDate(new  Date(date), 'dd/MM/yy')
    },{
        text: "Checkout",
        key: "checkout",
        flex: 0.4,
        sort: true,
        hasMobileLabel: true,
        parse: (value, item) => (item?.durationType && item?.durationType !== 'daily') ? (item?.durationType === 'morning' ? 'Mattina' : 'Pomeriggio') : (value ? formatDate(new Date(value), 'dd/MM/yy') : '-'),
    },{
        text: "Stato",
        key: "status",
        sort: true,
        flex: 0.5,
        color: (value) => getBeachesStatusColor(value, {isPublicBeach: true}),
        // parse: (value) => getBeachesStatusString(value, {isPublicBeach}),
    }];

    const tableRows = reservations?.map(item => {
        const newItem = {
            ...item,
            ...item.user
        }
        return newItem
    })

    // Imposto il filtro per data
    // function dateFilterHandler(dates) {
    //     setDateFilter(dates);
    //     setDateParams({});
    // }

    function filterActionHandler(name, value, options) {
        setParams(options?.isRemoving ? {
            ...params,
            [name]: undefined,
        } : {
            ...options,
            [name]: value,
        })

        return history.push(`${match.url}`);
    }

    function filterActionDateHandler(name, value, options) {
        setDateParams(options?.isRemoving ? {
            [name]: undefined,
        } : {
            ...options,
            [name]: formatDate(new Date(value), "yyyy-MM-dd"),
        })
        setDateFilter()
        return history.push(`${match.url}`);
    }

    const availableFilters = [{
        label: "Prenotazioni esistenti dal",
        name: "fromDate",
        type: "date",
        action: filterActionDateHandler,
        parse: (date) => date && formatDate(new Date(date), "dd MMM"),
        half: true,
    },{
        label: "Prenotazioni esistenti al",
        name: "toDate",
        type: "date",
        action: filterActionDateHandler,
        parse: (date) => date && formatDate(new Date(date), "dd MMM"),
        half: true,
    }, {
        label: "Checkin il",
        name: "checkin",
        type: "date",
        action: filterActionDateHandler,
        parse: (date) => date && formatDate(new Date(date), "dd MMM"),
        half: true,
    },{
        label: "Checkout il",
        name: "checkout",
        type: "date",
        action: filterActionDateHandler,
        parse: (date) => date && formatDate(new Date(date), "dd MMM"),
        half: true,
    },{
        label: "Accesso",
        name: "status",
        type: "select",
        options: [{
            value: "ACCEPTED",
            label: "In attesa di accesso",
        },{
            value: "ACCESSED",
            label: "Accesso eseguito",
        },{
            value: "CANCELED",
            label: "Cancellata",
        }],
        action: filterActionHandler,
        half: true,
    }];

    return (
        <MainContainer large>

            <TitleContainer>
                <MainTitle text="Spiagge pubbliche" />
            </TitleContainer>

            <ColumnContainer>
                <Column>
                    <SimpleTable
                        match={match}
                        history={history}
                        setLimit={setLimit}
                        elementsLimit={50}
                        navigation
                        showFilters
                        data={tableRows}
                        head={tableHead}
                        availableFilters={availableFilters}
                        isLoading={isLoading}
                        page={page}
                        searched={search}
                        sort={sort}
                        onSort={setSort}
                        onSearch={(value) => {
                            setSearch(value);
                            history.push(match.url);
                        }}
                        onSearchClear={() => {
                            setSearch(undefined);
                            history.push(match.url);
                        }}
                        hasDownload
                        downloadName="prenotazioni"
                    />
                </Column>
            </ColumnContainer>

        </MainContainer>
    )
}
