import { Column, Divider, MainFormContent, SectionTitle, SimpleField, TitleContainer } from '@beacharound/beacharound-ui';
import { getAddressString } from '@beacharound/beacharound-ui-helpers';
import React, { Fragment } from 'react';

export default function DetailCustomerSection({customer}) {
    if(!customer) {
        return <Fragment></Fragment>
    }
    return (
        <Fragment>
            <TitleContainer>
                <SectionTitle text="Dati cliente" />
            </TitleContainer>
            <Column>
                <MainFormContent flex>
                    <SimpleField
                        label="Nome"
                        value={`${customer?.firstName} ${customer?.lastName}`}
                        half
                    />
                    <SimpleField
                        label="Telefono"
                        value={customer?.phone}
                        half
                    />
                    <SimpleField
                        label="Email"
                        value={customer?.email}
                    />
                    {customer?.address &&
                        <SimpleField
                            label="Indirizzo"
                            value={getAddressString(customer?.address)}
                        />
                    }
                </MainFormContent>
            </Column>
            <Divider />
        </Fragment>
    )
}