import React, { useState } from "react";
import { Link } from "react-router-dom";
import Select from 'react-select';
import qs from "query-string";
import { usePaginatedQuery } from 'react-query';

import {
    MainContainer,
    TitleContainer,
    MainTitle,
    ButtonsRow,
    MainButton,
    ColumnContainer,
    Column,
    SimpleTable,
} from "@beacharound/beacharound-ui";

import ReviewsActivationPopup from '../../../components/Reviews/ReviewsActivationPopup'
import CreateMultipleReviews from '../../../components/Reviews/CreateMultipleReviews'

import { getReviews } from "../../../api/reviews";

export default function List({ match, location, history }) {
    const { page } = location.search ? qs.parse(location.search) : { page: "0" };

    const [limit, setLimit] = useState(25);
    const [search, setSearch] = useState();
    const [params, setParams] = useState({}); // eslint-disable-line no-unused-vars
    const [ selectedItem, setSelectedItem ] = useState();
    const [ reviewsPopup, setReviewsPopup ] = useState();

    const options = {
        skip: Number(page) * limit,
        limit,
        sort: { createdAt: -1 },
        search,
        params: {
            ...params
        }
    }

    const { data, isLoading } = usePaginatedQuery(["reviews", options], () =>
        getReviews({
            params: options,
        })
    );

    const tableHead = [
        {
            text: "Autore",
            key: "user",
            highlight: true,
            parse: (value, item) => {
                if(item?.createdByAdmin) {
                    return `ADMIN`
                }
                return `${value?.profile?.firstName} ${value?.profile?.lastName}`
            }
        },{
            text: "Attività",
            key: "reviewed",
            sort: "text",
            parse: (value) => value?.name
        },{
            text: "Voto",
            key: "vote",
            sort: "number",
            flex: 0.5
        },{
            flex: 0.5,
            actions: (item) => {
                return [{
                    icon: item.published ? "visible" : "not-visible",
                    action: () => setSelectedItem(item),
                },{
                    icon: "arrow-right",
                    component: Link,
                    to: `${match.url}/${item._id}`
                }]
            }
        }
    ]

    const tableRows = data?.map((item) => {
        return {
            ...item,
            ...item.contact
        }
    })

    const availableFilters = [{
        label: "Pubblicate",
        name: "published",
        type: "boolean",
        component: Select,
        action: (name, value, options) => {
            setParams({
                ...params,
                "published": value
            });
            history.push(`${match.url}`);
        }
    }]
    
    return (
        <MainContainer large>
            
            <ColumnContainer noMargin>
                <Column column={2}>
                    <TitleContainer>
                        <MainTitle text="Recensioni" />
                    </TitleContainer>
                </Column>
                <Column column={2}>
                    <ButtonsRow flexEnd noMargin>
                        <MainButton
                            text="Crea recensioni"
                            action={() => setReviewsPopup(true)}
                        />
                    </ButtonsRow>
                </Column>
            </ColumnContainer>

            <ColumnContainer>
                <Column>
                    <SimpleTable
                        match={match}
                        history={history}
                        setLimit={setLimit}
                        navigation
                        showFilters
                        data={tableRows}
                        head={tableHead}
                        availableFilters={availableFilters}
                        isLoading={isLoading}
                        page={page}
                        searched={search}
                        onSearch={(value) => {
                            setSearch(value);
                            history.push(match.url);
                        }}
                        onSearchClear={() => {
                            setSearch(undefined);
                            history.push(match.url);
                        }}
                    />
                </Column>
            </ColumnContainer>

            <ReviewsActivationPopup
                item={selectedItem}
                setItem={setSelectedItem}
                match={match}
            />

            <CreateMultipleReviews
                reviewsPopup={reviewsPopup}
                setReviewsPopup={setReviewsPopup}
                match={match}
            />
        </MainContainer>
    );
}
