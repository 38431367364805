import React from 'react';
import { Route } from "react-router-dom";

export default function SubRoute(route) {

    return (
        <Route
            exact={route.exact}
            path={route.path}
            render={(props) => {
                return <route.component {...props} {...route.props} routes={route.routes} />
            }}
        />

    )
}