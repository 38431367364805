// cities

import List from '../../pages/Places/Maps/List';

export const subRoutesMaps = [
    {
        path: "",
        component: List,
        exact: true,
    }
].map((item) => {
    return {
        ...item,
        path: `/maps${item.path}`,
        private: true
    };
});