import React, { Fragment, useState } from "react";
import moment from 'moment';
import { Link } from "react-router-dom";
import { useMutation, useQuery, useQueryCache } from 'react-query';
import Cookies from "js-cookie";

import { MainContainer, MainForm, MainFormContent, SectionTitle, TitleContainer, MainTitle, SubTitle, FormToggleGroup, FormToggleGroupPreview, SimpleFormField, LoadingContainer, ButtonsRow, MainButton, NormalPopup, TextAreaInput, SimpleField, Column, Divider, Paragraph } from "@beacharound/beacharound-ui";

import { formatDate, getSubFormData } from "@beacharound/beacharound-ui-helpers";

import { getRequest, updateRequest } from "../../../api/requestsForAssistance";

export default function Detail({ match }) {

    const { elementId } = match.params;

    const { data, isLoading } = useQuery(
        ["requestsForAssistance", elementId], () =>
        getRequest({ elementId })
    );

    const [replyPopup, setReplyPopup] = useState();

    const productsString = data?.subject?.products?.map(p => {
        switch (p?.relatedProductType) {
            case "umbrella":
                return "Postazione";
            case "bundle":
                return "Pacchetto";
            case "deckchair":
                return "Seduta";
            default:
                return "Prodotto";
        }
    })?.join(" + ");

    let replyUserString = "Anonimo";
    if (!!data?.reply?.repliedBy) {
        replyUserString = [data?.reply?.repliedBy?.profile?.firstName, data?.reply?.repliedBy?.profile?.lastName]?.filter(Boolean)?.join(" ")
    }

    const cache = useQueryCache();
    const [reply, { status }] = useMutation(updateRequest, {
        onSuccess: () => {
            cache.invalidateQueries(["requestsForAssistance", elementId]);
            setReplyPopup(false);
        }
    });

    async function sendReply(data) {
        try {
            await reply({
                elementId,
                data: {
                    reply: {
                        ...data,
                        repliedBy: Cookies.get("user-id"),
                        repliedAt: new Date()
                    }
                }
            });
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    return (
        <MainContainer marginBottom>
            <TitleContainer>
                <MainTitle text={data?.name || "Gestisci segnalazione"} />
                <SubTitle
                    text="< Torna alla lista"
                    component={Link}
                    to={`/costumerCare/reports`}
                />
            </TitleContainer>

            <LoadingContainer isLoading={isLoading}>
                <TitleContainer>
                    <SectionTitle text="Dati utente" />
                </TitleContainer>

                <Column>
                    <MainFormContent flex>
                        <SimpleField
                            label="Nome"
                            name="firstName"
                            value={data?.user?.profile?.firstName}
                            half
                        />
                        <SimpleField
                            label="Cognome"
                            name="lastName"
                            value={data?.user?.profile?.lastName}
                            half
                        />
                        <SimpleField
                            label="Email"
                            name="email"
                            value={data?.contacts?.email}
                            half
                        />
                        <SimpleField
                            label="Telefono"
                            name="phone"
                            value={data?.contacts?.phone}
                            half
                        />
                    </MainFormContent>
                </Column>

                <Divider />

                <TitleContainer>
                    <SectionTitle text="Segnalazione" />
                </TitleContainer>

                <MainForm data={data && getSubFormData(data, ["title", "description"])}>
                    <MainFormContent>
                        <FormToggleGroup>
                            <FormToggleGroupPreview notEditing>
                                <SimpleFormField
                                    label="Titolo"
                                    name="title"
                                />
                                <SimpleFormField
                                    label="Segnalazione"
                                    name="description"
                                />
                            </FormToggleGroupPreview>
                        </FormToggleGroup>
                    </MainFormContent>
                </MainForm>

                <TitleContainer>
                    <SectionTitle text="Dati prenotazione/preventivo" />
                </TitleContainer>

                <Column>
                    <MainFormContent flex>
                        <SimpleField
                            label="Codice"
                            value={data?.subject?._id}
                            half
                        />
                        <SimpleField
                            label="Checkin"
                            value={moment(data?.subject?.checkin).format("L")}
                            half
                        />
                        <SimpleField
                            label="Checkout"
                            value={moment(data?.subject?.checkout).format("L")}
                            half
                        />
                        <SimpleField
                            label="Stato della prenotazione"
                            value={data?.subject?.status}
                            half
                        />
                        <SimpleField
                            label="Attività"
                            value={data?.subject?.activity?.name}
                            half
                        />
                        <SimpleField
                            label="Prodotti"
                            value={productsString}
                            half
                        />
                    </MainFormContent>
                </Column>

                <Divider />

                {!!data?.reply ? (
                    <Fragment>
                        <TitleContainer >
                            <SectionTitle text={`Risposto il ${formatDate(new Date(data?.reply?.repliedAt), "dd/MM/yyyy")}`} />
                        </TitleContainer>
                        <Paragraph text={`<strong>${replyUserString}</strong>`} />
                        <Paragraph text={data?.reply?.message} />
                    </Fragment>
                ) : (
                    <ButtonsRow fill>
                        <MainButton
                            text="Rispondi"
                            action={() => setReplyPopup(true)}
                        />
                    </ButtonsRow>
                )}
            </LoadingContainer>

            <NormalPopup
                visible={replyPopup}
                setVisible={setReplyPopup}
                title="Rispondi al messaggio"
            >
                <MainForm
                    onSubmit={sendReply}
                    constraints={{ message: { presence: true } }}
                >
                    <MainFormContent>
                        <TextAreaInput
                            name="message"
                            label="Risposta per l'utente"
                        />
                    </MainFormContent>
                    <ButtonsRow fill>
                        <MainButton
                            border
                            text="Annulla"
                            action={() => setReplyPopup(false)}
                        />
                        <MainButton text="Invia" submit status={status} />
                    </ButtonsRow>
                </MainForm>
            </NormalPopup>
        </MainContainer>
    );
}
