
import List from "../../pages/Financial/Transactions/List";
// import Detail from "../../pages/Activities/Transactions/Detail";

export const subRoutesTransactions = [
    {
        path: "",
        component: List,
        exact: true,
    }
].map((item) => {
    return {
        ...item,
        path: `/transactions${item.path}`,
        private: true
    };
});

export const routesTransactions = [
    // {
    //     path: "/:elementId",
    //     component: Detail,
    // }
].map((item) => {
    return {
        ...item,
        path: `/transactions${item.path}`,
        private: true
    };
});