export const packTypeOptions = [{
    label: 'Gestione annuncio',
    value: 'BASIC',
    type: ['hotels','beaches']
},{
    label: 'Prenotazioni online',
    value: 'BOOKING',    
    type: ['hotels','beaches']
},{
    label: 'Richiesta preventivi',
    value: 'QUOTATIONS',
    type: ['hotels']
},{
    label: 'Creazione offerte',
    value: 'OFFERS',
    type: ['hotels']
},{
    label: 'Gestionale',
    value: 'MANAGEMENT',
    type: ['beaches']
}]

export const paymentTypeOptions = [{
    label: 'Gratis',
    value: 'FREE'
},{
    label: 'Prova',
    value: 'TRIAL'
},{
    label: 'Fee',
    value: 'FEE'
},{
    label: 'Iscrizione',
    value: 'SUBSCRIPTION'
},{
    label: 'Una tantum',
    value: 'LUMPSUM'
}]

export const activityTypeOptions = [{
    label: 'Spiagge',
    value: 'beaches'
},{
    label: 'Hotel',
    value: 'hotels'
}]