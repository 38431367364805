import List from '../../pages/CostumerCare/Reviews/List';
import Detail from '../../pages/CostumerCare/Reviews/Detail';
import Create from '../../pages/CostumerCare/Reviews/Create';

export const subRoutesReviews = [
    {
        path: "",
        component: List,
        exact: true,
    }
].map((item) => {
    return {
        ...item,
        path: `/reviews${item.path}`,
        private: true
    };
});

export const routesReviews = [
    {
        path: "/create",
        component: Create,
        exact: true
    },
    {
        path: "/:elementId",
        component: Detail,
    }
].map((item) => {
    return {
        ...item,
        path: `/reviews${item.path}`,
        private: true
    };
});