export const pointsOfInterestTypes = [
    {
        label: "Frazione",
        value: "hamlet",
    },
    {
        label: "Luogo conosciuto",
        value: "know-place",
    },
    {
        label: "Spiaggia (luogo)",
        value: "beach",
    },
    {
        label: "Attrazione",
        value: "attraction",
    },
    {
        label: "Museo",
        value: "museum",
    },
    {
        label: "Luogo storico",
        value: "historical-place",
    },
    {
        label: "Monumento",
        value: "monument",
    },
    {
        label: "Luogo artistico",
        value: "artistic-place",
    },
];