// users
import List from '../../pages/Settings/Invitations/List';

export const subRoutesInvitations = [
    {
        path: "",
        component: List,
        exact: true,
    },
].map((item) => {
    return {
        ...item,
        path: `/invitations${item.path}`,
        private: true
    };
});
