import React from 'react';

import { searchUsers } from "../../api/search";
import { getOptionsFromUsers } from "../../helpers/search";

import {
    ButtonsRow,
    Divider,
    MainButton,
    MainForm,
    MainFormContent,
    NormalPopup,
    FullSearchSelect,
} from "@beacharound/beacharound-ui";

import { useMutation, useQuery, useQueryCache } from 'react-query';
import { connectUserToOrganization } from "../../api/organizations";

export default function ConnectUserPopup({visible, setVisible, organization}) {

    const cache = useQueryCache();

    // STRUMENTI PER LA RICERCA ATTIVITÀ
    const usersOptions = {
        limit: 10,
    };

    const { data: users } = useQuery(
        ["users", "search", usersOptions],
        () => searchUsers({ params: usersOptions })
    );

    const [ connect, { status }] = useMutation(connectUserToOrganization, {
        onSuccess: (data) => {
            cache.invalidateQueries("organizations");
            cache.setQueryData(["organizations", data._id], data);
            return setVisible(false);
        }
    })

    async function loadUsersOptions(search, callback) {
        const searchUsersOptions = {
            ...usersOptions,
            search,
        };

        const result = await searchUsers({
            params: searchUsersOptions,
        });
        cache.setQueryData(["users", "search", searchUsersOptions], result);

        return callback(getOptionsFromUsers(result, { valueKey: "_id" }));
    }

    async function connectUser({user}) {
        await connect({
            elementId: organization._id,
            userId: user,
        });
    }

    return (
        <NormalPopup
            visible={visible}
            setVisible={() => setVisible(false)}
            title="Collega utente"
            overflow='visible'
        >
            <MainForm onSubmit={connectUser}>
                <MainFormContent>
                    <FullSearchSelect
                        buttonText="Collega utente"
                        name="user"
                        isAsync
                        loadOptions={loadUsersOptions}
                        defaultOptions={getOptionsFromUsers(users)}
                    />
                </MainFormContent>
                <Divider />
                <ButtonsRow fill>
                    <MainButton
                        text="Annulla"
                        border
                        action={() => setVisible(false)}
                    />
                    <MainButton text="Collega utente" submit status={status} />
                </ButtonsRow>
            </MainForm>
        </NormalPopup>
    );
}