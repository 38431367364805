import { returnError } from "../_defaults/error";
import axios from "../_defaults/user";

export async function getUsers({ role, params }) {
    try {
        const response = await axios.get(`/admin-users/${role}`, params);

        if (response?.data) {
            return response?.data;
        }
    } catch (error) {
        returnError(error);
    }
}

export async function getUser({ role, elementId }) {
    try {
        const response = await axios.get(`/admin-users/${role}/${elementId}`);
        if (response?.data) {
            return response?.data;
        }
    } catch (error) {
        returnError(error);
    }
}

export async function createUser({ data, role }) {
    try {
        const response = await axios.post(`/admin-users/${role}`, data);
        if (response?.data) {
            return response?.data;
        }
    } catch (error) {
        if(error?.response?.status === 409) {
            throw Error(error?.response?.data?.message);
        }
        returnError(error);
    }
}
