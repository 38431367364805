import React from "react";
import { Link } from "react-router-dom";
import { useMutation, useQuery, useQueryCache } from 'react-query';

import {
    MainContainer,
    MainForm,
    MainFormContent,
    SectionTitle,
    TitleContainer,
    MainTitle,
    SubTitle,
    FormToggleGroup,
    FormToggleGroupPreview,
    SimpleFormField,
    FormToggleGroupEdit,
    TextInput,
    TextAreaInput,
    LoadingContainer,
    SelectInput,
    CheckboxInput,
    EditAddressGroup,
    EditImageGroup,
    ColumnContainer,
    Column,
    ToggleInput
} from "@beacharound/beacharound-ui";

import { getSubFormData } from "@beacharound/beacharound-ui-helpers";

import {
    getBeach,
    updateImage,
    uploadGalleryImage as updateGalleryImage,
    updateBeach,
} from "../../../api/beach";
import { getServices } from "../../../api/services";

import {getServicesObject, getAccessibilitiesObject, getServicesString, getAccessibilitiesArray, getServicesArray} from '../../../helpers/services';

import { sandTypes } from '@beacharound/beacharound-ui-helpers'
import categoriesOptions from "./data/categories";

export default function Detail({ match }) {

    const { type, elementId } = match.params;
    const { data, isLoading } = useQuery(["beaches", type, elementId],
        () => getBeach({ type, elementId })
    )

    const servicesOptions = {
        params: {type: "beaches"}
    }

    // SERVIZI
    const { data: allServices } = useQuery(["allServices", servicesOptions], () =>
        getServices({ type: "services", params: servicesOptions })
    );

    // ACCESSIBILITA'
    const { data: allAccessibilities } = useQuery(["allAccessibilities", servicesOptions], () =>
        getServices({ type: "accessibilities", params: servicesOptions })
    );

    const booleanOptions = [
        { value: true, label: "Si" },
        { value: false, label: "No" },
    ]

    const cache = useQueryCache()
    const [update, {status: updateStatus, reset}] = useMutation(updateBeach, {
        onSuccess: (data) => {
            cache.invalidateQueries(["beaches", type])
            cache.setQueryData(["beaches", type, elementId], data);
        },
    });

    const [
        uploadImage,
        { status: updateImageStatus, reset: imageReset },
    ] = useMutation(updateImage, {
        onSuccess: (data) => {
            cache.invalidateQueries(["beaches", type]);
            cache.setQueryData(["beaches", type, elementId], data);
        },
    });

    const [
        uploadGallery,
    ] = useMutation(updateGalleryImage, {
        onSuccess: (data) => {
            cache.invalidateQueries(["beaches", type]);
            cache.setQueryData(["beaches", type, elementId], data);
        },
    });

    async function editElement(data, callback) {
        try {
        
            if (data.email || data.phone) {
                data.publicContact = {
                    email: data.email,
                    phone: data.phone,
                };
            }

            await update({
                type,
                elementId,
                data
            });
            return callback(reset)
        } catch(error) {
            return callback(null, error);
        }
    }

    async function editCover(file) {
        try {
            await uploadImage({ elementId, file });
        } catch (error) {
            throw error;
        }
    }

    async function uploadGalleryImage(file, index, callback) {
        try {
            await uploadGallery({elementId, file, index});
            return callback("LOADED")
        } catch (error) {
            throw error;
        }
    }

    async function removeGalleryImage(images, index, callback) {
        try {

            await update({
                type,
                elementId,
                data: {
                    $pull: {
                        gallery: {
                            index
                        }
                    }  
                },
            });
            return callback();

        } catch (error) {
            throw error;
        }
    }

    async function editGeodata(address, callback) {
        try {
            await update({
                type,
                elementId,
                data: {
                    address,
                },
            });
        } catch(error) {
            throw error;
        }
    }

    async function editServices(data, callback) {
        try {
            const services = getServicesArray(allServices, data);
            await update({
                type,
                elementId,
                data: {
                    services
                }
            })
            return callback(reset)
        } catch(error) {
            console.log(error);
            return callback(null, error)
        }
    }

    async function editAccessibilities(data, callback) {
        try {
            const accessibilities = getAccessibilitiesArray(allAccessibilities, data);
            await update({
                type,
                elementId,
                data: {
                    accessibilities
                }
            })
            return callback(reset)
        } catch(error) {
            return callback(null, error)
        }
    }

    return (
        <MainContainer marginBottom>

            <ColumnContainer>
                <Column column={2}>
                    <TitleContainer noMargin>
                        <MainTitle text={data?.name || "Gestisci spiaggia"} />
                        <SubTitle
                            text="< Torna alla lista"
                            component={Link}
                            to={`/places/beaches/${type}`}
                        />
                    </TitleContainer>
                </Column>

                <Column column={2}>
                    <ToggleInput
                        flexEnd
                        trueLabel="Pubblicato"
                        falseLabel="Non pubblicato"
                        status={updateStatus}
                        changeValue={()=> editElement({published: !data?.published}, (resetAfter) => resetAfter())}
                        value={data?.published}
                    />
                </Column>
            </ColumnContainer>

            <LoadingContainer isLoading={isLoading} isError={!data}>
                <TitleContainer>
                    <SectionTitle text="Dati generali" />
                </TitleContainer>

                <MainForm
                    onSubmit={editElement}
                    data={
                        data &&
                        getSubFormData(data, [
                            "name",
                            "subtitle",
                            "description",
                        ])
                    }
                >
                    <MainFormContent>
                        <FormToggleGroup>
                            <FormToggleGroupPreview>
                                <SimpleFormField
                                    label="Nome"
                                    name="name"
                                    half
                                />
                                <SimpleFormField
                                    label="Sottotitolo"
                                    name="subtitle"
                                />
                                <SimpleFormField
                                    label="Descrizione"
                                    name="description"
                                />
                            </FormToggleGroupPreview>

                            <FormToggleGroupEdit flex status={updateStatus}>
                                <TextInput label="Nome" name="name" half />
                                <TextInput
                                    label="Sottotitolo"
                                    name="subtitle"
                                />
                                <TextAreaInput
                                    label="Descrizione"
                                    name="description"
                                />
                            </FormToggleGroupEdit>
                        </FormToggleGroup>
                    </MainFormContent>
                </MainForm>

                <TitleContainer>
                    <SectionTitle text="Dati generali" />
                </TitleContainer>

                <MainForm
                    onSubmit={editElement}
                    data={
                        data && getSubFormData(data, ["sandType", "categories"])
                    }
                >
                    <MainFormContent>
                        <FormToggleGroup>
                            <FormToggleGroupPreview>
                                <SimpleFormField
                                    label="Categoria"
                                    name="categories"
                                    isMulti
                                    options={categoriesOptions}
                                    half
                                />
                                <SimpleFormField
                                    label="Tipologia spiaggia"
                                    name="sandType"
                                    options={sandTypes}
                                    half
                                />
                            </FormToggleGroupPreview>

                            <FormToggleGroupEdit flex status={updateStatus}>
                                <SelectInput
                                    label="Categoria"
                                    name="categories"
                                    isMulti
                                    options={categoriesOptions}
                                    placeholder="Scegli la categoria"
                                    half
                                />
                                <SelectInput
                                    label="Tipologia spiaggia"
                                    name="sandType"
                                    options={sandTypes}
                                    placeholder="Scegli la tipologia"
                                    half
                                />
                            </FormToggleGroupEdit>
                        </FormToggleGroup>
                    </MainFormContent>
                </MainForm>

                <TitleContainer>
                    <SectionTitle text="Contatti pubblici" />
                </TitleContainer>

                <MainForm
                    onSubmit={editElement}
                    data={data?.publicContact}
                    // constraints={{ email: { presence: true } }}
                >
                    <MainFormContent>
                        <FormToggleGroup>
                            <FormToggleGroupPreview>
                                <SimpleFormField
                                    label="Email"
                                    name="email"
                                    half
                                />
                                <SimpleFormField
                                    label="Telefono"
                                    name="phone"
                                    half
                                />
                            </FormToggleGroupPreview>

                            <FormToggleGroupEdit flex status={updateStatus}>
                                <TextInput label="Email" name="email" half />
                                <TextInput label="Telefono" name="phone" half />
                            </FormToggleGroupEdit>
                        </FormToggleGroup>
                    </MainFormContent>
                </MainForm>

                <EditImageGroup
                    data={data?.cover}
                    onSubmit={editCover}
                    reset={imageReset}
                    status={updateImageStatus}
                    gallery={data?.gallery}
                    uploadGalleryImage={uploadGalleryImage}
                    removeGalleryImage={removeGalleryImage}
                />

                <EditAddressGroup
                    data={data?.address}
                    onSubmit={editGeodata}
                    reset={reset}
                    status={updateStatus}
                />

                <TitleContainer>
                    <SectionTitle text="Servizi della spiaggia" />
                </TitleContainer>

                <MainForm
                    onSubmit={editServices}
                    data={getServicesObject(allServices, data?.services)}
                >
                    <MainFormContent>
                        <FormToggleGroup>
                            <FormToggleGroupPreview>
                                <SimpleFormField
                                    label="Servizi"
                                    value={getServicesString(
                                        allServices,
                                        data?.services
                                    )}
                                />
                            </FormToggleGroupPreview>

                            <FormToggleGroupEdit flex status={updateStatus}>
                                {allServices
                                    ?.sort((a, b) => {
                                        if (a.name > b.name) return 1;
                                        if (a.name < b.name) return -1;
                                        return 0;
                                    })
                                    .map((service, index) => {
                                        return (
                                            <CheckboxInput
                                                key={index}
                                                label={service.name}
                                                name={service.slug}
                                                half
                                            />
                                        );
                                    })}
                            </FormToggleGroupEdit>
                        </FormToggleGroup>
                    </MainFormContent>
                </MainForm>

                <TitleContainer>
                    <SectionTitle text="Accessibilità" />
                </TitleContainer>

                <MainForm
                    onSubmit={editAccessibilities}
                    data={getAccessibilitiesObject(
                        allAccessibilities,
                        data?.accessibilities
                    )}
                >
                    <MainFormContent>
                        <FormToggleGroup>
                            <FormToggleGroupPreview>
                                {allAccessibilities
                                    ?.sort((a, b) => {
                                        if (a.name > b.name) return 1;
                                        if (a.name < b.name) return -1;
                                        return 0;
                                    })
                                    .map((item, index) => {
                                        return (
                                            <SimpleFormField
                                                key={index}
                                                label={item.name}
                                                name={item.slug}
                                                options={booleanOptions}
                                                half
                                            />
                                        );
                                    })}
                            </FormToggleGroupPreview>

                            <FormToggleGroupEdit flex status={updateStatus}>
                                {allAccessibilities
                                    ?.sort((a, b) => {
                                        if (a.name > b.name) return 1;
                                        if (a.name < b.name) return -1;
                                        return 0;
                                    })
                                    .map((item, index) => {
                                        return (
                                            <SelectInput
                                                name={item.slug}
                                                key={index}
                                                label={item.name}
                                                placeholder="-"
                                                options={booleanOptions}
                                                half
                                            />
                                        );
                                    })}
                            </FormToggleGroupEdit>
                        </FormToggleGroup>
                    </MainFormContent>
                </MainForm>
            </LoadingContainer>
        </MainContainer>
    );
}
