import axios from "../_defaults/user";
import { uploadImage } from '../images';

export async function createRanking({ data }) {
    const response = await axios.post("/admin-rankings", data);

    if (response.data) {
        return response.data;
    }

    return response;
}

export async function getRankings({ params }) {
    const response = await axios.get("/admin-rankings", {
        params,
    });

    if (response.data) {
        return response.data;
    }

    return response;
}

export async function getRanking({ elementId }) {
    const response = await axios.get(`/admin-rankings/${elementId}`);

    if (response.data) {
        return response.data;
    }

    return response;
}

export async function updateRanking({ elementId, data }) {
    const response = await axios.post(`/admin-rankings/${elementId}`, data);

    if (response.data) {
        return response.data;
    }

    return response;
}

export async function getPutUrl({ elementId, file }) {
    const response = await axios.post(`/admin-rankings/${elementId}/images`, {
        contentType: file.type,
        extension: file.name.split(".")[1],
    });

    if (response.data) {
        return response.data;
    }

    return response;
}

export async function updateImage({ elementId, file }) {
    try {
        const { url, key } = await getPutUrl({ elementId, file });

        await uploadImage({ url, file });

        const response = await updateRanking({
            elementId,
            data: {
                cover: {
                    url: key,
                },
            },
        });

        if (response.data) {
            return response.data;
        }

        return response;
    } catch (error) {
        throw error;
    }
}

export async function updateProposedByImage({ elementId, file }) {
    try {
        const { url, key } = await getPutUrl({ elementId, file });

        await uploadImage({ url, file });

        const response = await updateRanking({
            elementId,
            data: {
                "proposedBy.image": {
                    url: key,
                },
            },
        });

        if (response.data) {
            return response.data;
        }

        return response;
    } catch (error) {
        throw error;
    }
}
