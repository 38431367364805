import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useMutation, usePaginatedQuery, useQueryCache } from "react-query";

import {
    MainContainer,
    TitleContainer,
    MainTitle,
    MainForm,
    MainFormContent,
    TextInput,
    TextAreaInput,
    ButtonsRow,
    MainButton,
    Divider,
    SectionTitle,
    SelectInput,
    LoadingContainer,
    DateInput
} from "@beacharound/beacharound-ui";

import { createArticle } from "../../../api/blog/articles";
import { getCategories } from "../../../api/blog/categories";

const constraints = {
    name: { presence: true },
    subtitle: { presence: true },
    categories: { presence: true },
    duration: { presence: true },
    description: { presence: true },
    publishedAt: { presence: true }
}

export default function Create({ match, history }) {

    const cache = useQueryCache();
    const [published, setPublished] = useState();

    const { data: categories, isLoading } = usePaginatedQuery("categories", () =>
        getCategories({
            params: { params: {} },
        })
    );

    const [create, { status }] = useMutation(createArticle, {
        onSuccess: (data) => {
            cache.invalidateQueries("tutorials")
            cache.setQueryData(["tutorials", data._id], data)
            return history.push(match.url.replace("create", data?._id))
        }
    });

    function save(data) {
        const payload = {
            ...data,
            section: 'tutorial',
            type: ['business'],
            video: {
                url: data.videoUrl,
                duration: data.videoDuration,
            },
            published
        }

        return create({ data: payload })
    }

    const categoriesOptions = categories?.map((cat) => {
        return {
            label: cat.name,
            value: cat._id
        }
    })

    return (
        <MainContainer marginBottom>
            <TitleContainer>
                <MainTitle text="Nuovo tutorial" />
            </TitleContainer>

            <LoadingContainer isLoading={isLoading}>
                <MainForm onSubmit={save} constraints={constraints}>
                    <TitleContainer>
                        <SectionTitle text="Dati principali" />
                    </TitleContainer>
                    <MainFormContent flex>
                        <TextInput
                            label="Titolo"
                            name="name"
                        />
                        <TextInput
                            label="Sottotitolo"
                            name="subtitle"
                        />
                        <SelectInput
                            label="Categoria"
                            name="categories"
                            isMulti
                            half
                            options={categoriesOptions}
                        />
                        <TextInput
                            label="Durata tutorial (minuti)"
                            name="duration"
                            type="number"
                            half
                        />
                        <DateInput
                            label="Data di pubblicazione"
                            name="publishedAt"
                            minDate={new Date()}
                            half
                        />
                    </MainFormContent>

                    <Divider />

                    <TitleContainer>
                        <SectionTitle text="Video" />
                    </TitleContainer>
                    <MainFormContent flex>
                        <TextInput
                            label="Link del video"
                            name="videoUrl"
                            half
                        />
                        <TextInput
                            label="Durata del video (minuti)"
                            name="videoDuration"
                            half
                        />
                    </MainFormContent>

                    <Divider />

                    <TitleContainer>
                        <SectionTitle text="Testo articolo" />
                    </TitleContainer>
                    <MainFormContent flex>
                        <TextAreaInput
                            label="Descrizione breve"
                            name="description"
                        />
                    </MainFormContent>

                    <Divider />

                    <ButtonsRow fill marginBottom>
                        <MainButton
                            text="Annulla"
                            border
                            component={Link}
                            to={`/blog/tutorials`}
                        />
                        <MainButton
                            border
                            submit
                            actionOnSubmit={() => setPublished(false)}
                            text="Salva bozza"
                        />
                        <MainButton
                            text="Crea tutorial"
                            submit
                            status={status}
                            actionOnSubmit={() => setPublished(true)}
                        />
                    </ButtonsRow>
                </MainForm>
            </LoadingContainer>
        </MainContainer>
    );
}
