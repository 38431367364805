// pubicAdmin

import List from '../../pages/Users/PublicAdministration/List';
import Detail from '../../pages/Users/PublicAdministration/Detail';

export const subRoutesUsersPublicAdministration = [
    {
        path: "",
        component: List,
        exact: true
    }
].map((item) => {
    return {
        ...item,
        path: `/publicAdministration${item.path}`,
        private: true
    };
});

export const routesUsersPublicAdministration = [
    {
        path: "/:elementId",
        component: Detail,
    }
].map((item) => {
    return {
        ...item,
        path: `/publicAdministration${item.path}`,
        private: true
    };
});