import React from 'react';
import { useMutation, useQueryCache } from 'react-query';

import { NormalPopup, MainForm, MainFormContent, TextInput, TextAreaInput, EditImageGroup, FormToggleGroup, FormToggleGroupPreview, SimpleFormField, FormToggleGroupEdit } from '@beacharound/beacharound-ui';
import { getSubFormData } from '@beacharound/beacharound-ui-helpers';
import { updateArticleStep, updateStepImage } from '../../api/blog/articles';

export default function TutorialEditStep({
    visible,
    setVisible,
    tutorialId
}) {

    const cache = useQueryCache();

    const [updateStep, {status: updateStepStatus}] = useMutation(updateArticleStep, {
        onSuccess: () => {
            setVisible()
            cache.invalidateQueries(["tutorials", tutorialId]);
        },
    });
    const [updateArticleStepImage, {status: updateStepImageStatus}] = useMutation(updateStepImage, {
        onSuccess: () => {
            cache.invalidateQueries(["tutorials", tutorialId]);
        },
    });

    async function editStepHandler(data) {

        try {
            await updateStep({
                elementId: tutorialId,
                stepId: visible._id,
                data
            });

        } catch(error) {
            throw error;
        }
    }

    async function editCover(file) {
        try {
            await updateArticleStepImage({
                elementId: tutorialId,
                stepId: visible._id,
                file
            });
        } catch (error) {
            console.log(error);
        }
    }


    return (
        <NormalPopup
            visible={visible}
            setVisible={setVisible}
            title="Modifica step"
        >
            <MainForm
                onSubmit={editStepHandler}
                data={ visible && getSubFormData(visible, ["name", "subtitle", "description", "startOffset", "endOffset"])} >
                <MainFormContent>
                    <FormToggleGroup>
                        <FormToggleGroupPreview>
                            <SimpleFormField
                                label="Titolo"
                                name="name"
                            />
                            <SimpleFormField
                                label="Sottotitolo"
                                name="subtitle"
                            />
                            <SimpleFormField
                                label="Descrizione"
                                name="description"
                            />
                            <SimpleFormField
                                label="Inizio video"
                                name="startOffset"
                                half
                            />
                            <SimpleFormField
                                label="Fine video"
                                name="endOffset"
                                half
                            />
                        </FormToggleGroupPreview>

                        <FormToggleGroupEdit flex status={updateStepStatus}>
                            <TextInput label="Titolo" name="name"/>
                            <TextInput label="Sottotitolo" name="subtitle"/>
                            <TextAreaInput label="Descrizione" name="description"/>
                            <TextInput label="Inizio video" name="startOffset"/>
                            <TextInput label="Fine video" name="endOffset"/>
                        </FormToggleGroupEdit>
                    </FormToggleGroup>
                </MainFormContent>
            </MainForm>
            
            <EditImageGroup data={visible?.cover} onSubmit={editCover} status={updateStepImageStatus}/>

        </NormalPopup>
    )
}