import React, { useState } from "react";
import moment from 'moment';
import { Link } from "react-router-dom";
import qs from "query-string";
import { usePaginatedQuery } from 'react-query';

import { MainContainer, TitleContainer, MainTitle, ColumnContainer, Column, SimpleTable } from "@beacharound/beacharound-ui";
import { formatDate } from "@beacharound/beacharound-ui-helpers";

import { getRequests } from "../../../api/requestsForAssistance";

export default function List({ match, location, history }) {
    const { page = 0 } = qs.parse(location?.search ?? "");

    const [limit, setLimit] = useState(25);
    // const [search, setSearch] = useState();
    // const [params, setParams] = useState({}); // eslint-disable-line no-unused-vars

    const options = {
        skip: Number(page) * limit,
        limit,
        sort: { createdAt: -1 },
        // search,
        params: {}
    };

    const { data, isLoading } = usePaginatedQuery(["requestsForAssistance", options], () =>
        getRequests({
            params: options,
        })
    );

    const tableHead = [{
        text: "Autore",
        key: "user",
        highlight: true,
        default: true,
        parse: (user) => `${user?.profile?.firstName} ${user?.profile?.lastName}`,
    }, {
        text: "Titolo",
        key: "title",
        flex: 0.5
    }, {
        text: "Stato",
        key: "reply",
        sort: "text",
        parse: (reply) => reply ? `Risposto il ${moment(reply?.repliedAt).format("L")}` : "In attesa..",
        flex: 0.5,
    }, {
        text: "Data",
        key: "createdAt",
        parse: date => formatDate(new Date(date), "dd/MM/yyyy"),
        flex: 0.3,
    }, {
        flex: 0.3,
        actions: (item) => [{
            icon: "arrow-right",
            component: Link,
            to: `${match.url}/${item._id}`
        }]
    }];

    const tableRows = data?.map((item) => ({
        ...item,
        ...item.contact
    }));

    return (
        <MainContainer large>

            <ColumnContainer noMargin>
                <Column>
                    <TitleContainer>
                        <MainTitle text="Segnalazioni" />
                    </TitleContainer>
                </Column>
            </ColumnContainer>

            <ColumnContainer>
                <Column>
                    <SimpleTable
                        match={match}
                        history={history}
                        setLimit={setLimit}
                        navigation
                        // search
                        data={tableRows}
                        head={tableHead}
                        isLoading={isLoading}
                        page={page}
                    // onSearch={(value) => {
                    //     setSearch(value);
                    //     history.push(match.url);
                    // }}
                    // onSearchClear={() => {
                    //     setSearch(undefined);
                    //     history.push(match.url);
                    // }}
                    />
                </Column>
            </ColumnContainer>
        </MainContainer>
    );
}
