import { Column, SectionTitle, Tile, TitleContainer } from '@beacharound/beacharound-ui';
import { formatDate } from '@beacharound/beacharound-ui-helpers';
import React, { Fragment } from 'react';

export default function StatusHistory({item}) {
    
    return (
        <Fragment>
            <TitleContainer>
                <SectionTitle text="Storia" />
            </TitleContainer>
            <Column>
                <Tile
                    title={item?.status}
                    text={`il ${formatDate(item?.statusUpdatedAt, "dd/MM/yyyy HH:mm")}`}
                    pretitle={item?.statusUpdatedBy?.profile && `status cambiato da <strong>${item?.statusUpdatedBy?.profile?.firstName || ""} ${item?.statusUpdatedBy?.profile?.lastName || ""}</strong>`}
                />
                {item?.statusHistory?.sort((a,b) => new Date(b.statusUpdatedAt) - new Date(a.statusUpdatedAt)).map((chapter) => (
                        <Tile
                            key={chapter.status}
                            title={chapter.status}
                            text={`il ${formatDate(chapter?.statusUpdatedAt, "dd/MM/yyyy HH:mm")}`}
                            pretitle={chapter?.statusUpdatedBy?.profile && `status cambiato da <strong>${chapter?.statusUpdatedBy?.profile?.firstName || ""} ${chapter?.statusUpdatedBy?.profile?.lastName || ""}</strong>`}
                        />
                    ))}
            </Column>
        </Fragment>
    )
}