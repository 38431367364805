import React, { Fragment, useState } from "react";
import qs from "query-string";
import { Link } from "react-router-dom";
import { usePaginatedQuery } from 'react-query';

import {
    MainContainer,
    TitleContainer,
    MainTitle,
    ColumnContainer,
    Column,
    SimpleImage
} from "@beacharound/beacharound-ui";

import { getImages } from '../../../api/media'
import { getImageCrop } from "@beacharound/beacharound-ui-helpers";
export default function List({match, history, location}) {
    
    const { page } = location.search
        ? qs.parse(location.search)
        : { page: "0" };

    const [limit] = useState(100);
    const [search] = useState();
    const [params] = useState({});

    const options = {
        skip: Number(page) * limit,
        limit,
        sort: { name: 1 },
        search,
        params,
    };

    const { data } = usePaginatedQuery(
        ["images", options],
        () =>
            getImages({
                params: options,
            })
    );

    return (
        <MainContainer large>
            <TitleContainer>
                <MainTitle text="Immagini" />
            </TitleContainer>

            <ColumnContainer flex>
                {data?.map((item, index) => {
                    let type;

                    switch (item.type) {
                        case "beaches":
                            type = "beaches/private";
                            break;
                        case "hotels":
                            type = "hotels";
                            break;
                        case "pointsOfInterest":
                            type = "pointsOfInterest";
                            break;

                        default:
                            type = undefined;
                            break;
                    }

                    if (!type) {
                        return <Fragment key={index}></Fragment>;
                    }

                    return (
                        <Column column={3} key={index}>
                            <SimpleImage
                                small
                                image={getImageCrop(item.image, "s")}
                                component={Link}
                                to={`/places/${type}/${item._id}`}
                            />
                        </Column>
                    );
                })}
            </ColumnContainer>
        </MainContainer>
    );
}
