import React, { useState } from "react";
import { Link } from "react-router-dom";
import qs from "query-string";
import { usePaginatedQuery } from 'react-query';
import moment from "moment";

import {
    MainContainer,
    TitleContainer,
    MainTitle,
    ButtonsRow,
    MainButton,
    ColumnContainer,
    Column,
    SimpleTable,
    DateDropdown
} from "@beacharound/beacharound-ui";

import { getArticles } from "../../../api/blog/articles";

export default function List({ match, location, history }) {

    const { page } = location.search ? qs.parse(location.search) : { page: "0" };

    const [dateFilters, setDateFilters] = useState();
    const [limit, setLimit] = useState(25);
    const [search, setSearch] = useState();

    const options = {
        skip: Number(page) * limit,
        limit,
        sort: { name: 1 },
        search,
        params: {
            section: 'tutorial'
        }
    }

    const { data, isLoading } = usePaginatedQuery("articles", () =>
        getArticles({ params: options })
    )

    const tableHead = [{
        text: "Titolo",
        key: ["name"],
        sort: "text",
        highlight: true,
    },{
        text: "Creato il",
        key: "createdAt",
        default: true,
        sort: "date",
        ascending: false,
        flex: 0.7,
        parse: (value) => moment(value).format("L")
    },
    {
        text: "Pubblicato dal",
        key: "publishedAt",
        default: true,
        sort: "date",
        ascending: false,
        flex: 0.7,
        parse: (value) => moment(value).format("L"),
    },
    {
        text: "Pubblicato",
        key: "published",
        parse: (value) => value ? "Si" : "No",
        color: (value) => value ? "green" : "red",
        flex: 0.5
    },{
        flex: 0.5,
        actions: (item) => {
            return [{
                icon: "edit",
                component: Link,
                to: `${match.url}/${item._id}`,
            }]
        }
    }];
    
    const tableRows = data
        ?.filter((item) => {
            if (!dateFilters) return item;
            // controlliamo che la data dell'oggetto sia uguale o più recente di quella indicata
            return moment(item.createdAt).isSameOrAfter(dateFilters);
        })
        .map((item) => {
            return {
                ...item,
                ...item.contact,
            };
        });

    // Imposto il filtro per data
    function dateFilterHandler(filter) {
        let startFilterDate;

        switch (filter) {
            case "today":
                startFilterDate = moment().startOf("day");
                break;
            case "week":
                startFilterDate = moment().startOf("week");
                break;
            case "month":
                startFilterDate = moment().startOf("month");
                break;
            case "halfYear":
                startFilterDate = moment()
                    .subtract(6, "month")
                    .startOf("month");
                break;
            case "year":
                startFilterDate = moment().startOf("year");
                break;
            default:
                startFilterDate = undefined;
        }

        return setDateFilters(startFilterDate);
    }

    return (
        <MainContainer large>
            
            <ColumnContainer noMargin>
                <Column column={2}>
                    <TitleContainer>
                        <MainTitle text="Tutorial" />
                        <DateDropdown action={dateFilterHandler} />
                    </TitleContainer>
                </Column>
                <Column column={2}>
                    <ButtonsRow flexEnd noMargin>
                        <MainButton
                            text="Nuovo tutorial"
                            component={Link}
                            to="/blog/tutorials/create"
                        />
                    </ButtonsRow>
                </Column>
            </ColumnContainer>

            <ColumnContainer>
                <Column>
                    <SimpleTable
                        match={match}
                        history={history}
                        setLimit={setLimit}
                        navigation
                        search
                        // showFilters
                        data={tableRows}
                        head={tableHead}
                        // availableFilters={availableFilters}
                        isLoading={isLoading}
                        page={page}
                        searched={search}
                        onSearch={(value) => {
                            setSearch(value);
                            history.push(match.url);
                        }}
                        onSearchClear={() => {
                            setSearch(undefined);
                            history.push(match.url);
                        }}
                    />
                </Column>
            </ColumnContainer>
        </MainContainer>
    );
}
