import React, { useState } from "react";
import qs from "query-string";
import { useMutation, usePaginatedQuery, useQueryCache } from "react-query";
import { Link } from "react-router-dom";

import {
    MainContainer,
    TitleContainer,
    MainTitle,
    ColumnContainer,
    Column,
    SimpleTable,
    ButtonsRow,
    MainButton,
    NormalPopup,
    MainForm,
    MainFormContent,
    DateInput,
    Divider,
    Paragraph,
    FullSearchSelect
} from "@beacharound/beacharound-ui";

import { getTransactions } from "../../../api/transactions";
import { formatDate } from "@beacharound/beacharound-ui-helpers";
import { createTransfer } from "../../../api/payments";
import { getBeaches } from "../../../api/beach";
import { getOptionsFromActivities } from "../../../helpers/search";

// function getFeeAmount(amount, fee, fixedFee) {
//     if(!fee || !amount) return 0;
//     let feeAmount = amount * fee / 100;
//     return (feeAmount / 100) + (fixedFee || 0)
// }

export default function Transactions({ history, match, location }) {
    
    const { page } = location.search ? qs.parse(location.search) : { page: "0" };

    const [dateFilter, setDateFilter] = useState();
    const [search, setSearch] = useState();
    const [limit, setLimit] = useState(25);
    const [createPaymentyPopup, setCreatePaymentyPopup] = useState();
    const [emptyPaymentPopup, setEmptyPaymentPopup] = useState();
    const [params, setParams] = useState({
        status: {$ne: "DECLINED"},
    });
    const [sort, setSort] = useState();
    const [dateParams, setDateParams] = useState({});

    let fromDateParam;
    let toDateParam;

    if(dateParams?.fromDate) {
        fromDateParam = dateParams?.fromDate;
    }

    if(dateParams?.toDate) {
        toDateParam = dateParams?.toDate;
    }

    if(dateFilter?.[0]) {
        fromDateParam = dateFilter?.[0];
    }

    if(dateFilter?.[1]) {
        toDateParam = dateFilter?.[1];
    }

    const options = {
        skip: Number(page) * limit,
        limit,
        search,
        fromDate: fromDateParam && formatDate(new Date(fromDateParam), "yyyy-MM-dd"),
        toDate: toDateParam && formatDate(new Date(toDateParam), "yyyy-MM-dd"),
        sort: sort || { paidAt: -1, createdAt: -1 },
        params: {
            gateway: {$in: ["stripe", "stripe_connect"]},
            ...params,
            "subjects.subjectType": "reservations"
        }
    }

    const cache = useQueryCache()
    
    const { data, isLoading } = usePaginatedQuery(["transactions", options], () =>
        getTransactions({params: options})
    )

    const beachesOptions = {
        sort: {name: 1},
        limit: 20,
        params: {
            type: "private",
            published: true,
            billingProfile: {
                $exists: true
            }
        }
    }

    const { data: beaches } = usePaginatedQuery(["beaches", "private", beachesOptions], () =>
        getBeaches({params: beachesOptions})
    )

    // const beachesSelectOptions = beaches?.map(o => {
    //     return {
    //         label: o.name,
    //         value: o._id,
    //     }
    // })

    async function loadActivitiesOptions(search, callback) {

        const searchActivitiesOptions = {
            ...beachesOptions,
            search,
        };

        const result = await getBeaches({
            params: searchActivitiesOptions,
        });

        cache.setQueryData(["beaches", "private", searchActivitiesOptions], result);

        return callback(getOptionsFromActivities(result, { valueKey: "_id" }));
    }


    const [create, { status }] = useMutation(createTransfer, {
        onSuccess: () => history.push('/financial/payments')
    });

    const tableHead = [{
        text: "Attività",
        key: "activityId",
        parse: (activityId) => activityId?.name || '-',
        highlight: true,
        default: true,
        flex: 0.6
    },{
        text: "Cliente",
        key: "paidBy",
        parse: (paidBy) => paidBy?.firstName ? `${paidBy?.firstName} ${paidBy?.lastName}` : '-',
        highlight: true,
        default: true,
        flex: 0.6
    },{
        text: "Importo",
        key: "amount",
        flex: 0.3,
        sort: true,
        hasMobileLabel: true,
        price: true,
        parse: (value) => value && `${Number(value).toFixed(2)}`
    },{
        text: "Da pagare",
        key: "payout",
        flex: 0.3,
        hasMobileLabel: true,
        price: true,
        parse: (payout, item) => item?.gateway === "stripe_connect" ? "connect" : (payout && `${Number(payout).toFixed(2)}`)
    },{
        text: "Fee",
        key: "application",
        flex: 0.3,
        price: true,
        parse: (application, item) => application ? `${(application).toFixed(2)}` : "",
    },{
        text: "Calcolo",
        key: "fee",
        flex: 0.3,
        price: true,
        parse: (fee, item) => item.fee && item.fixedFee ? `${Number(item.fee).toFixed(2)}/${Number(item.fixedFee).toFixed(2)}` : "",
    }, {
        text: "Del",
        key: "paidAt",
        flex: 0.3,
        price: true,
        sort: true,
        parse: (paidAt) => formatDate(new Date(paidAt), "dd MMM"),
    }, {
        text: "Stato",
        key: "status",
        flex: 0.3,
        sort: true
    },{
        flex: 0.1,
        actions: (item) => {
            if(["CANCELED", "TRANSFERED"].includes(item.status)) {
                return [{
                    icon: "arrow-right",
                    component: Link,
                    to: `/activities/beaches/private/${item.activityId?._id}/transactions/${item._id}`
                }]
            }
            // return [{
            //     icon: "delete",
            //     action: () => setCancelPopup(item)
            // }, {
            //     icon: "arrow-right",
            //     component: Link,
            //     to: `${match.url}/${item._id}`
            // }]
            return [{
                icon: "arrow-right",
                component: Link,
                to: `/activities/beaches/private/${item.activityId?._id}/transactions/${item._id}`
            }]
        }
    }]

    const tableRows = data || [];
    // Imposto il filtro per data
    // function dateFilterHandler(dates) {
    //     setDateFilter(dates);
    //     setDateParams({});
    // }

    function filterActionHandler(name, value, options) {
        setParams(options?.isRemoving ? {
            ...params,
            [name]: undefined,
        } : {
            ...options,
            [name]: value,
        })

        return history.push(`${match.url}`);
    }

    function filterActionDateHandler(name, value, options) {
        setDateParams(options?.isRemoving ? {
            [name]: undefined,
        } : {
            ...options,
            [name]: formatDate(new Date(value), "yyyy-MM-dd"),
        })
        setDateFilter()
        return history.push(`${match.url}`);
    }

    const availableFilters = [{
        label: "Pagate dal",
        name: "fromDate",
        type: "date",
        action: filterActionDateHandler,
        parse: (date) => date && formatDate(new Date(date), "dd MMM"),
        half: true,
    },{
        label: "Pagate al",
        name: "toDate",
        type: "date",
        action: filterActionDateHandler,
        parse: (date) => date && formatDate(new Date(date), "dd MMM"),
        half: true,
    },{
        label: "Gateway",
        name: "gateway",
        type: "select",
        options: [{
            value: "stripe",
            label: "Stripe",
        },{
            value: "stripe_connect",
            label: "Stripe Connect",
        }],
        action: filterActionHandler,
        half: true,
    },{
        label: "Stato",
        name: "status",
        type: "select",
        options: [{
            value: "ACCEPTED",
            label: "Pagate",
        },{
            value: "DECLINED",
            label: "Rifiutate",
        },{
            value: "TRANSFERPENDING",
            label: "In trasferimento",
        },{
            value: "TRANSFERED",
            label: "Trasferite",
        }],
        action: filterActionHandler,
        half: true,
    }]

    function goCreatePayment(data, bypass) {

        if (Object.keys(data).length < 1 && !bypass) {
            setCreatePaymentyPopup()
            return setEmptyPaymentPopup(true)
        }
        
        return create({
            data
        })
    }

    return (
        <MainContainer large>

            <ColumnContainer lowMargin>
                <Column column={2}>
                    <TitleContainer noMargin>
                        <MainTitle text="Transazioni" />
                    </TitleContainer>
                </Column>
                <Column column={2}>
                    <ButtonsRow flexEnd noMargin>
                        <MainButton
                            text="Crea pagamento"
                            action={() => setCreatePaymentyPopup(true)}
                        />
                    </ButtonsRow>
                </Column>
            </ColumnContainer>

            <ColumnContainer>
                <Column>
                    <SimpleTable
                        match={match}
                        history={history}
                        setLimit={setLimit}
                        elementsLimit={50}
                        navigation
                        showFilters
                        data={tableRows}
                        head={tableHead}
                        availableFilters={availableFilters}
                        isLoading={isLoading}
                        page={page}
                        searched={search}
                        sort={sort}
                        onSort={setSort}
                        onSearch={(value) => {
                            setSearch(value);
                            history.push(match.url);
                        }}
                        onSearchClear={() => {
                            setSearch(undefined);
                            history.push(match.url);
                        }}
                        hasDownload
                        downloadName="prenotazioni"
                    />
                </Column>
            </ColumnContainer>

            <NormalPopup
                visible={createPaymentyPopup}
                setVisible={setCreatePaymentyPopup}
                title="Nuovo pagamento"
                overflow="visible"
            >

                <MainForm onSubmit={(data) => goCreatePayment(data)}>
                    <MainFormContent flex>
                        <DateInput 
                            name="fromDate"
                            label="Da quando"
                            half
                        />
                        <DateInput 
                            name="toDate"
                            label="Fino a quando"
                            half
                        />

                        <FullSearchSelect
                            buttonText="Attività"
                            name="activity"
                            isAsync
                            loadOptions={loadActivitiesOptions}
                            defaultOptions={getOptionsFromActivities(beaches)}
                        />
                    </MainFormContent>
                    <Divider/>
                    <ButtonsRow fill>
                        <MainButton text="Annulla" border action={() => setCreatePaymentyPopup()}/>
                        <MainButton text="Conferma" submit status={status}/>
                    </ButtonsRow>
                </MainForm>
            </NormalPopup>

            <NormalPopup
                visible={emptyPaymentPopup}
                setVisible={setEmptyPaymentPopup}
                title="Attenzione"
            >
                <TitleContainer>
                    <Paragraph text="Stai creando il pagamento di tutte le transazioni eseguite, vuoi continuare?"/>
                </TitleContainer>
                <Divider/>
                <ButtonsRow fill>
                    <MainButton text="Annulla" border action={() => setEmptyPaymentPopup()}/>
                    <MainButton text="Conferma" action={() => goCreatePayment({}, true)} status={status}/>
                </ButtonsRow>
            </NormalPopup>

        </MainContainer>
    );
}
