import React from "react";
import { Link, Switch } from "react-router-dom";

import {
    MainContainer,
    MainTitle,
    TitleContainer,
    ColumnContainer,
    Column,
    Tabs,
} from "@beacharound/beacharound-ui";

import SubRoute from '../../components/Routes/SubRoute';

export default function Activities({ match, routes, location }) {
    
    const tabs = [{
        text: "Spiagge",
        component: Link,
        to: `${match.url}/beaches/private`,
    },{
        text: "Spiagge libere",
        component: Link,
        to: `${match.url}/beaches/public`,
    },{
        text: "Hotel",
        component: Link,
        to: `${match.url}/hotels`,
    },{
        text: "Organizzazioni",
        component: Link,
        to: `${match.url}/organizations`,
    },{
        text: "Memberships",
        component: Link,
        to: `${match.url}/memberships`,
    },{
        text: "Pacchetti",
        component: Link,
        to: `${match.url}/memberships-packs`,
    },{
        text: "Transazioni",
        component: Link,
        to: `${match.url}/transactions`,
    }]

    const tabsArray = tabs && tabs.map((t) => t.to)

    return (
        <React.Fragment>
            <MainContainer large>
                <TitleContainer>
                    <MainTitle text="Attività" />
                </TitleContainer>

                <ColumnContainer noMargin>
                    <Column>
                        <Tabs list={tabs} activeIndex={tabsArray.indexOf(location.pathname)}/>
                    </Column>
                </ColumnContainer>

                <ColumnContainer>
                    <Column>
                        <Switch>
                            {routes?.map((page, index) => {
                                return <SubRoute key={index} {...page} />;
                            })}
                        </Switch>
                    </Column>
                </ColumnContainer>
            </MainContainer>
        </React.Fragment>
    );
}
