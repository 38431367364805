import { returnError } from '../_defaults/error';
import axios from '../_defaults/user';

export async function inviteUser({ email, roles }) {
    try {
        const response = await axios.post(`/admin-users/invitations`, {
            email,
            roles
        });
        return response?.errors ? response?.data : {};
    } catch (error) {
        return error;
    }
}

export async function getInvitations({ params }) {
    try {
        const response = await axios.get(`/admin-users/invitations`, params);

        if (response?.data) {
            return response?.data;
        }
    } catch (error) {
        returnError(error);
    }
}

export async function getInvitation({ elementId, params }) {
    try {
        const response = await axios.get(
            `/admin-users/invitations/${elementId}`,
            params
        );

        if (response?.data) {
            return response?.data;
        }
    } catch (error) {
        returnError(error);
    }
}

export async function removeInvitation({ elementId, params }) {
    try {
        const response = await axios.delete(
            `/admin-users/invitations/${elementId}`,
            params
        );

        if (response?.data) {
            return response?.data;
        }
    } catch (error) {
        returnError(error);
    }
}