import React from "react";
import { Link, Switch } from "react-router-dom";
import { useQuery } from 'react-query';

import {
    MainContainer,
    Tabs,
    TitleContainer,
    MainTitle,
    SubTitle,
    ColumnContainer,
    Column
} from "@beacharound/beacharound-ui";

import { getImageCrop } from '@beacharound/beacharound-ui-helpers';

import { getHotelNameWithStars } from '../../../helpers/hotels/stars';
import SubRoute from '../../../components/Routes/SubRoute';

import { getHotel } from "../../../api/hotels/hotels";

export default function Detail({ match, routes, location }) {

    const { elementId } = match.params;
    const { data } = useQuery(["hotels", elementId], () =>
        getHotel({ elementId })
    )

    const tabs = [
        {
            text: "Preventivi",
            component: Link,
            to: `${match.url}/quotations`,
        },
        {
            text: "Prenotazioni offerte",
            component: Link,
            to: `${match.url}/reservations-offers`,
        },
        {
            text: "Camere",
            component: Link,
            to: `${match.url}/rooms`,
        },
        {
            text: "Offerte",
            component: Link,
            to: `${match.url}/offers`,
        },
    ];

    const tabsArray = tabs && tabs.map((t) => t.to)

    return (
        <MainContainer large marginBottom>

            <TitleContainer image={{type: 'hotel', url: getImageCrop(data?.cover, 'xs')}}>
                <MainTitle text={data && getHotelNameWithStars(data.name, data.stars)} />
                <SubTitle
                    text="< Torna alle attività"
                    component={Link}
                    to={`/activities/hotels`}
                />
            </TitleContainer>

            <ColumnContainer noMargin>
                <Column>
                    <Tabs list={tabs} activeIndex={tabsArray.indexOf(location.pathname)}/>
                </Column>
            </ColumnContainer>

            <ColumnContainer>
                <Column>
                    <Switch>
                        {routes?.map((page, index) => {
                            return <SubRoute key={index} {...page} />;
                        })}
                    </Switch>
                </Column>
            </ColumnContainer>
        
        </MainContainer>
    );
}
