import React, { useState } from "react";
import qs from "query-string";
import { Link } from "react-router-dom";
import { usePaginatedQuery, useQuery } from "react-query";

import {
    MainContainer,
    TitleContainer,
    MainTitle,
    ColumnContainer,
    Column,
    SimpleTable,
    ButtonsRow,
    MainButton,
    Paragraph,
    NormalPopup
} from "@beacharound/beacharound-ui";

import HighlightPopup from "../../../../../components/Popups/HighlightPopup";
import PublishPopup from "../../../../../components/Popups/PublishPopup";

import { getRooms } from "../../../../../api/hotels/rooms";
import { getOffers, updateOffer } from "../../../../../api/hotels/offers";

export default function Promos({ history, match, location }) {

    const { elementId } = match.params;
    const { page } = location.search ? qs.parse(location.search) : { page: "0" };

    const { data: rooms, status: roomStatus } = useQuery(["hotels", elementId, "rooms"], () =>
        getRooms({elementId})
    )

    const [ limit, setLimit ] = useState(25);
    const [ roomCheckPopup, setRoomCheckPopup ] = useState();
    const [ highlightItem, setHighlightItem ] = useState();
    const [ publishItem, setPublishItem ] = useState();

    const options = {
        skip: Number(page) * limit,
        limit,
        sort: { name: 1 },
        params: {}
    }
    
    const { data, isLoading } = usePaginatedQuery(["hotels", elementId, "offers", options], () =>
        getOffers({elementId, params: options})
    );

    const tableHead = [{
        text: "Nome",
        key: "name",
        sort: "text",
        highlight: true,
        default: true,
        image: (item) => item?.cover,
    },{
        text: "Attiva",
        key: "published",
        hasMobileLabel: true,
        sort: true,
        flex: 0.5,
        parse: (value) => (value === true ? "Si" : "No"),
    },{
        text: "Camere",
        key: "relatedProducts",
        hasMobileLabel: true,
        sort: true,
        flex: 0.5,
        parse: (relatedProducts) =>
            relatedProducts?.length || "0",
    },{
        text: "Prezzo",
        key: "price",
        sort: true,
        flex: 0.5,
        parse: (price) => `${price ? Number(price).toFixed(2) : "0.00"} €`,
    },{
        flex: 0.5,
        actions: (item) => {
            return [{
                icon: item.published ? "visible" : "not-visible",
                action: () => setPublishItem(item),
            },{
                icon: item.highlight ? "heart-full" : "heart",
                action: () => setHighlightItem(item),
            },{
                icon: "edit",
                component: Link,
                to: `${match.url}/${item._id}`,
            }]
        }
    }]

    function roomCheck() {
        if (rooms && rooms.length > 0) {
            return history.push(`${match.url}/create`)
        }

        return setRoomCheckPopup(true)
    }

    const tableRows = data || [];

    return (
        <MainContainer large>
            <ColumnContainer noMargin>
                <Column column={2}>
                    <TitleContainer>
                        <MainTitle text="Offerte dell'hotel" />
                    </TitleContainer>
                </Column>
                <Column column={2}>
                    <ButtonsRow flexEnd>
                        <MainButton text="Nuova offerta" action={roomCheck} status={roomStatus === 'loading' && roomStatus}/>
                    </ButtonsRow>
                </Column>
            </ColumnContainer>

            <ColumnContainer>
                <Column>
                    <SimpleTable
                        match={match}
                        history={history}
                        setLimit={setLimit}
                        navigation
                        data={tableRows}
                        head={tableHead}
                        isLoading={isLoading}
                        page={page}
                    />
                </Column>
            </ColumnContainer>

            <HighlightPopup
                elementId={elementId}
                item={highlightItem}
                setItem={setHighlightItem}
                type={"offers"}
                queryKey={["hotels", elementId, "offers", options]}
                updateFunction={updateOffer}
            />

            <PublishPopup
                elementId={elementId}
                item={publishItem}
                setItem={setPublishItem}
                type={"offers"}
                queryKey={["hotels", elementId, "offers", options]}
                updateFunction={updateOffer}
            />

            <NormalPopup
                visible={roomCheckPopup}
                setVisible={setRoomCheckPopup}
                title={"Attenzione"}
            >
                <TitleContainer>
                    <Paragraph text="Questo hotel non ha ancora una tipologia di camera, desideri crearla ora?"/>
                </TitleContainer>

                <ButtonsRow spaceBetween>
                    <MainButton border text="Annulla" action={() => setRoomCheckPopup(false)}/>
                    <MainButton text="Crea camera" component={Link} to={match.url.replace('promos', 'rooms/create')}/>
                </ButtonsRow>
            </NormalPopup>
        </MainContainer>
    );
}
