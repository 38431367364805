import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useMutation, useQuery, useQueryCache } from 'react-query';

import {
    MainContainer,
    MainForm,
    MainFormContent,
    SectionTitle,
    TitleContainer,
    MainTitle,
    SubTitle,
    FormToggleGroup,
    FormToggleGroupPreview,
    SimpleFormField,
    FormToggleGroupEdit,
    TextInput,
    TextAreaInput,
    LoadingContainer,
    EditImageGroup,
    ButtonsRow,
    MainButton,
    DragListing,
    Divider,
    GooglePlacesInput,
    CheckboxInput,
    SelectInput,
    ColumnContainer,
    Column,
    ToggleInput
} from "@beacharound/beacharound-ui";

import { getSubFormData } from "@beacharound/beacharound-ui-helpers";

import RankingsAddPlacePopup from "../../../components/Rankings/RankingsAddPlacePopup";
import { getRanking, updateRanking, updateImage, updateProposedByImage } from "../../../api/blog/rankings";

const typeOptions = [{
    label: 'Classifica visibile',
    value: 'ranking'
},{
    label: 'Lista',
    value: 'list'
}]

const categoryOptions = [{
    label: 'Punti di interesse',
    value: 'pointsOfInterest'
},{
    label: 'Spiagge',
    value: 'beaches'
},{
    label: 'Hotel',
    value: 'hotels'
}]

export default function Detail({ match }) {

    const { type, elementId } = match.params;

    const cache = useQueryCache();

    const { data, isLoading } = useQuery(["rakings", elementId], () =>
        getRanking({ elementId })
    );

    const [update, { status: updateStatus, reset }] = useMutation(updateRanking, { onSuccess: () =>
        cache.invalidateQueries(["rakings", elementId])}
    );

    const [uploadImage, { status: updateImageStatus, resetImage }] = useMutation(updateImage, { onSuccess: () =>
        cache.invalidateQueries(["rakings", elementId])}
    );

    const [uploadProposedByImage, { status: updateProposedByImageStatus, resetProposedByImage }] = useMutation(updateProposedByImage, { onSuccess: () =>
        cache.invalidateQueries(["rakings", elementId])}
    );

    const [addPopup, setAddPopup] = useState();

    async function editElement(data, callback) {
        try {

            await update({
                type,
                elementId,
                data
            });
            return callback(reset)
        } catch(error) {
            return callback(null, error);
        }
    }

    function editProposedBy(data, callback) {
        return editElement({"proposedBy.name": data.name}, callback)
    }

    async function updateElements(elements) {
        try {
            if (elements) {
                update({
                    data: {
                        places: elements.sort((a, b) => a.index - b.index),
                    },
                    elementId,
                });

                return setAddPopup(false);
            }
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async function addElements(selectedElements) {
        try {
            if (selectedElements) {

                let newElements = selectedElements
                    .filter(
                        ({ _id }) =>
                            !data?.places.find(
                                (item) => item.place?._id === _id
                            )
                    )
                    .map((item, index) => {
                        return {
                            place: item._id,
                            placeType: item.type,
                            index: data?.places?.length + index,
                        };
                    })
                    .sort((a, b) => a.index - b.index)
                
                const existingElements = data?.places || [];
                
                await updateElements([...existingElements, ...newElements])

                return setAddPopup(false);
            }
        } catch(error) {
            console.log(error);
            throw error;
        }
    }

    async function reorderElements(reorderedElements) {
        try {
            await updateElements(reorderedElements.map((place, index) => {
                return {
                    ...place,
                    place: place.place?._id,
                    index
                }
            }));
        } catch(error) {
            console.log(error);
            throw error;
        }
    }

    async function editCover(file) {
        try {
            await uploadImage({ elementId, file });
        } catch (error) {
            console.log(error);
        }
    }

    async function editGeodata({ coordinates }, callback) {
        try {
            await update({
                elementId,
                data: {
                    geocode: {
                        coordinates
                    }
                },
            });

            return callback(reset);
        } catch (error) {
            throw error;
        }
    }

    async function editProposedByImage(file) {
        try {
            await uploadProposedByImage({ elementId, file });
        } catch (error) {
            console.log(error);
        }
    }

    const selectedCatName = categoryOptions.find((cat) => cat.value === data?.category)?.label;

    return (
        <MainContainer marginBottom>

            <ColumnContainer>
                <Column column={2}>
                    <TitleContainer noMargin>
                        <MainTitle text={data?.title || "Gestisci classifica"} />
                        <SubTitle
                            text="< Torna alla lista"
                            component={Link}
                            to={`/blog/rankings`}
                        />
                    </TitleContainer>
                </Column>

                <Column column={2}>
                    <ToggleInput
                        flexEnd
                        trueLabel="Pubblicato"
                        falseLabel="Non pubblicato"
                        status={updateStatus}
                        changeValue={()=> editElement({published: !data?.published}, (resetAfter) => resetAfter())}
                        value={data?.published}
                    />
                </Column>
            </ColumnContainer>

            <LoadingContainer isLoading={isLoading} isError={!data}>
                <TitleContainer>
                    <SectionTitle text="Tipologia di classifica" />
                </TitleContainer>

                <MainForm onSubmit={editElement} data={ data && getSubFormData(data, ["type", "category", "ordered"])}>
                    <MainFormContent>
                        <FormToggleGroup>
                            <FormToggleGroupPreview>
                                <SimpleFormField
                                    label="Lista ordinata di elementi"
                                    name="ordered"
                                    value={data?.ordered ? 'Si' : 'No'}
                                />
                                <SimpleFormField
                                    label="Tipologia"
                                    name="type"
                                    options={typeOptions}
                                    half
                                />
                                <SimpleFormField
                                    label="Categoria"
                                    name="category"
                                    options={categoryOptions}
                                    half
                                />
                            </FormToggleGroupPreview>

                            <FormToggleGroupEdit flex status={updateStatus}>
                                <CheckboxInput
                                    label="Lista ordinata di elementi"
                                    name="ordered"
                                />
                                <SelectInput
                                    label="Tipologia"
                                    name="type"
                                    options={typeOptions}
                                    half
                                />
                            </FormToggleGroupEdit>
                        </FormToggleGroup>
                    </MainFormContent>
                </MainForm>

                <TitleContainer>
                    <SectionTitle text="Dati principali" />
                </TitleContainer>

                <MainForm
                    onSubmit={editElement}
                    data={
                        data &&
                        getSubFormData(data, [
                            "name",
                            "subtitle",
                            "description",
                        ])
                    }
                >
                    <MainFormContent>
                        <FormToggleGroup>
                            <FormToggleGroupPreview>
                                <SimpleFormField label="Titolo" name="name" />
                                <SimpleFormField
                                    label="Sottotitolo"
                                    name="subtitle"
                                />
                                <SimpleFormField
                                    label="Descrizione"
                                    name="description"
                                />
                            </FormToggleGroupPreview>

                            <FormToggleGroupEdit flex status={updateStatus}>
                                <TextInput label="Title" name="name" half />
                                <TextInput
                                    label="Sottotitolo"
                                    name="subtitle"
                                />
                                <TextAreaInput
                                    label="Descrizione"
                                    name="description"
                                />
                            </FormToggleGroupEdit>
                        </FormToggleGroup>
                    </MainFormContent>
                </MainForm>

                <TitleContainer>
                    <SectionTitle text="Classifica" />
                </TitleContainer>

                <ButtonsRow fill marginBottom>
                    <MainButton
                        border
                        text={`Aggiungi ${selectedCatName || 'elementi'}`}
                        action={() => setAddPopup(true)}
                    />
                </ButtonsRow>

                {data?.places?.length > 0 && (
                    <DragListing
                        title="Trascina gli elementi per ordinare la classifica"
                        list={data?.places?.map((place) => {
                            return {
                                ...place,
                                label: place?.place?.name,
                            };
                        })}
                        onDragEndCallback={reorderElements}
                        onDelete={reorderElements}
                    />
                )}

                <Divider />

                <EditImageGroup
                    data={data?.cover}
                    title="Immagine di copertina"
                    reset={resetImage}
                    status={updateImageStatus}
                    onSubmit={editCover}
                />

                <TitleContainer>
                    <SectionTitle text="Proposto da" />
                </TitleContainer>

                <MainForm onSubmit={editProposedBy} data={data && getSubFormData(data?.proposedBy, ['name'])}>
                    <MainFormContent>
                        <FormToggleGroup>
                            <FormToggleGroupPreview>
                                <SimpleFormField
                                    label="Proposto da"
                                    name="name"
                                />
                            </FormToggleGroupPreview>

                            <FormToggleGroupEdit flex status={updateStatus}>
                                <TextInput
                                    label="Proposto da"
                                    name="name"
                                />
                            </FormToggleGroupEdit>
                        </FormToggleGroup>
                    </MainFormContent>
                </MainForm>

                <EditImageGroup
                    data={data?.proposedBy?.image}
                    title="Logo proposto da"
                    reset={resetProposedByImage}
                    status={updateProposedByImageStatus}
                    onSubmit={editProposedByImage}
                    small
                />

                <TitleContainer>
                    <SectionTitle text="Riferimento geografico" />
                </TitleContainer>

                <MainForm onSubmit={editGeodata} data={data?.geocode}>
                    <MainFormContent>
                        <FormToggleGroup>
                            <FormToggleGroupPreview>
                                <SimpleFormField
                                    label="Coordinate"
                                    name="coordinates"
                                    half
                                />
                            </FormToggleGroupPreview>

                            <FormToggleGroupEdit flex status={updateStatus}>
                                <GooglePlacesInput
                                    name="coordinates"
                                    label="Indirizzo"
                                    placeholder="Cerca un luogo"
                                    height="370px"
                                    coordsOnly
                                    required
                                />
                            </FormToggleGroupEdit>
                        </FormToggleGroup>
                    </MainFormContent>
                </MainForm>
            </LoadingContainer>

            <RankingsAddPlacePopup
                visible={addPopup}
                setVisible={setAddPopup}
                addElements={addElements}
                category={data?.category}
                selectedCatName={selectedCatName}
                status={updateStatus}
            />
        </MainContainer>
    );
}
