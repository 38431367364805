import React, { useState } from "react";
import qs from "query-string";
import moment from "moment";
import { usePaginatedQuery } from "react-query";

import {
    MainContainer,
    TitleContainer,
    MainTitle,
    ColumnContainer,
    Column,
    SimpleTable,
    ButtonsRow,
    MainButton,
} from "@beacharound/beacharound-ui";

import { getInvitations } from "../../../api/users/admin";

import RemoveInvitationPopup from '../../../components/Settings/Invitations/RemoveInvitationPopup'
import SendInvitationPopup from "../../../components/Settings/Invitations/SendInvitationPopup";

export default function List({ match, location, history }) {

    const { page } = location.search ? qs.parse(location.search) : { page: "0" };

    const [limit, setLimit] = useState(25);
    const [search, setSearch] = useState();
    const [params, setParams] = useState({}); // eslint-disable-line no-unused-vars
    const [invitePopup, setInvitePopup] = useState()

    const options = {
        skip: Number(page) * limit,
        limit,
        sort: { name: 1 },
        search,
        params: {
            ...params,
        },
    };

    // GESTIONE POPUP DI DELETE
    const [itemToRemove, setItemToRemove] = useState();

    const { resolvedData: data, isLoading } = usePaginatedQuery(["userInvitations", options], () =>
        getInvitations({ params: options })
    );

    const tableHead = [{
        text: "Email",
        key: "email",
        sort: "text",
        highlight: true,
    },{
        text: "Inviata il",
        key: "createdAt",
        sort: "date",
        flex: 0.5,
        parse: (value) => (value ? moment(value).format("l") : "-"),
    },{
        text: "Stato",
        key: "status",
        flex: 0.5,
        parse: (value) => value === "PENDING" ? "In attesa" : "Invito accettato",
        color: (value) => value === "PENDING" ? "red" : "green",
    },{
        flex: 0.5,
        actions: (item) => {
            return [{
                text: "Cancella",
                action: () => setItemToRemove(item),
            }]
        }
    }]

    const tableRows = data || [];

    return (
        <MainContainer large>

            <ColumnContainer noMargin>
                <Column column={2}>
                    <TitleContainer>
                        <MainTitle text="Inviti admin" />
                    </TitleContainer>
                </Column>
                <Column column={2}>
                    <ButtonsRow flexEnd>
                        <MainButton text="Invita amministratore" action={() => setInvitePopup(true)}/>
                    </ButtonsRow>
                </Column>
            </ColumnContainer>

            <ColumnContainer>
                <Column>
                    <SimpleTable
                        match={match}
                        history={history}
                        setLimit={setLimit}
                        navigation
                        data={tableRows}
                        head={tableHead}
                        isLoading={isLoading}
                        page={page}
                        searched={search}
                        onSearch={(value) => {
                            setSearch(value);
                            history.push(match.url);
                        }}
                        onSearchClear={() => {
                            setSearch(undefined);
                            history.push(match.url);
                        }}
                    />
                </Column>
            </ColumnContainer>

            <RemoveInvitationPopup
                item={itemToRemove}
                setItem={setItemToRemove}
            />

            {/* POPUP PER L'INVIO DEGLI INVITI */}

            <SendInvitationPopup
                active={invitePopup}
                setActive={setInvitePopup}
            />
        </MainContainer>
    );
}
