import React from 'react';
import { useMutation, useQueryCache } from "react-query";

import {
    ButtonsRow,
    Divider,
    MainButton,
    MainForm,
    MainFormContent,
    NormalPopup,
    TextInput,
} from "@beacharound/beacharound-ui";

import { createUser } from '../../api/users/users';

export default function CreateUserPopup({ visible, setVisible, organizationType }) {

    const cache = useQueryCache();

    const [create, { status, error, reset }] = useMutation( async (data) => {

        try {
            await createUser({
                data,
                role: organizationType || "business",
            });
        } catch(error) {
            throw error;
        }
        
    }, {
        onSuccess: () => {
            cache.invalidateQueries("users")
            return setVisible(false);
        },
    });

    async function createUserHandler(data) {
        await create(data);
    }

    return (
        <NormalPopup
            visible={visible}
            setVisible={() => {
                reset();
                return setVisible(false);
            }}
            title="Crea utente"
        >
            <MainForm
                onSubmit={createUserHandler}
                errors={{ server: error?.message }}
            >
                <MainFormContent>
                    <TextInput label="Nome" name="firstName" />
                    <TextInput label="Cognome" name="lastName" />
                    <TextInput label="Telefono" name="phone" />
                    <TextInput label="Email" name="email" type="email" />
                </MainFormContent>
                <Divider />
                <ButtonsRow fill>
                    <MainButton
                        text="Annulla"
                        border
                        action={() => {
                            reset();
                            return setVisible(false);
                        }}
                    />
                    <MainButton text="Crea utente" submit status={status} />
                </ButtonsRow>
            </MainForm>
        </NormalPopup>
    );
}