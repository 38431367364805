import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useMutation, usePaginatedQuery, useQueryCache } from "react-query";

import {
    MainContainer,
    TitleContainer,
    MainTitle,
    MainForm,
    MainFormContent,
    TextInput,
    TextAreaInput,
    ButtonsRow,
    MainButton,
    Divider,
    SectionTitle,
    SelectInput,
    TextEditorInput,
    LoadingContainer,
    DateInput
} from "@beacharound/beacharound-ui";

import { createArticle } from "../../../api/blog/articles";
import { getCategories } from "../../../api/blog/categories";

const constraints = {
    name: { presence: true },
    categories: { presence: true },
    type: { presence: true },
    section: { presence: true },
    subtitle: { presence: true },
    description: { presence: true }
}

export default function Create({ match, history }) {

    const cache = useQueryCache();
    const [published, setPublished] = useState();

    const { data: categories, isLoading } = usePaginatedQuery("categories", () =>
        getCategories({
            params: {params: {}},
        })
    )
    
    const [create, { status }] = useMutation(createArticle, {
            onSuccess: (data) => {
                cache.invalidateQueries("articles")
                cache.setQueryData(["articles", data._id], data)
                return history.push(match.url.replace("create", data?._id))
            }
        }
    )

    function saveArticle(data) {
        
        return create({
            data:{
                ...data,
                section: 'article',
                published
            }
        })
    }

    const categoriesOptions = categories?.map((cat) => {
        return {
            label: cat.name,
            value: cat._id
        }
    })

    const typesOptions = [{
        label: 'Business',
        value: 'business'
    },{
        label: 'Privati',
        value: 'private'
    }]

    const sectionsOptions = [{
        label: 'Articolo',
        value: 'article'
    },{
        label: 'Tutorial',
        value: 'tutorial'
    }]

    return (
        <MainContainer marginBottom>
            <TitleContainer>
                <MainTitle text="Nuovo articolo" />
            </TitleContainer>

            <LoadingContainer isLoading={isLoading}>
                <MainForm onSubmit={saveArticle} constraints={constraints}>
                    <TitleContainer>
                        <SectionTitle text="Dati principali" />
                    </TitleContainer>
                    <MainFormContent flex>
                        <SelectInput
                            label="Categoria"
                            name="categories"
                            isMulti
                            half
                            options={categoriesOptions}
                        />
                        <SelectInput
                            label="Tipologia"
                            name="type"
                            isMulti
                            half
                            options={typesOptions}
                        />
                        <SelectInput
                            label="Sezione"
                            name="section"
                            half
                            options={sectionsOptions}
                        />
                        <DateInput
                            label="Data di pubblicazione"
                            name="publishedAt"
                            half
                        />
                        <TextInput
                            label="Titolo"
                            name="name"
                        />
                        <TextInput
                            label="Sottotitolo"
                            name="subtitle"
                        />
                    </MainFormContent>

                    <Divider />

                    <TitleContainer>
                        <SectionTitle text="Testo articolo" />
                    </TitleContainer>
                    <MainFormContent flex>
                        <TextAreaInput
                            label="Descrizione breve"
                            name="description"
                        />
                        <TextEditorInput
                            label="Descrizione completa"
                            name="content"
                        />
                    </MainFormContent>

                    <Divider />

                    <ButtonsRow fill marginBottom>
                        <MainButton
                            text="Annulla"
                            border
                            component={Link}
                            to={`/blog/articles`}
                        />
                        <MainButton
                            border
                            submit
                            actionOnSubmit={() => setPublished(false)}
                            text="Salva bozza"
                        />
                        <MainButton
                            text="Crea articolo"
                            submit
                            status={status}
                            actionOnSubmit={() => setPublished(true)}
                        />
                    </ButtonsRow>
                </MainForm>
            </LoadingContainer>
        </MainContainer>
    );
}
