export function getOptionsFromCities(cities, options) {
    if(!Array.isArray(cities)) return [];
    
    return cities?.map((city) => {
        return {
            label: city?.name,
            value:
                options && options.valueKey ? city[options.valueKey] : city._id,
        };
    })
}