import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useQuery } from 'react-query';

import {
    MainContainer,
    TitleContainer,
    MainTitle,
    ColumnContainer,
    Column,
    SimpleTable
} from "@beacharound/beacharound-ui";

import { getLocations } from "../../../api/locations";
import LocationActivationPopup from "../../../components/Locations/LocationActivationPopup";

export default function List({ match, history }) {

    const { data: regions } = useQuery("regions", () =>
        getLocations({ type: "regions" })
    );

    const [selectedItem, setSelectedItem] = useState();
    const [params, setParams] = useState({});
    const [search, setSearch] = useState();

    const options = {
        sort: { name: 1 },
        search,
        params: {
            ...params,
        },
    };

    const { data, isLoading } = useQuery(["districts", options], () =>
        getLocations({ type: "districts", params: options })
    );

    const tableHead = [
        {
            text: "Nome",
            key: "name",
            sort: "text",
            highlight: true,
        },
        {
            text: "Regione",
            key: "regionName",
            sort: true,
        },
        {
            text: "Attivo",
            key: "published",
            hasMobileLabel: true,
            flex: 0.5,
            parse: (value) => (value === true ? "Attivo" : "Non attivo"),
        },
        {
            flex: 0.5,
            actions: (item) => {
                return [
                    {
                        icon: item.published ? "visible" : "not-visible",
                        action: () => setSelectedItem(item),
                    },
                    {
                        icon: "edit",
                        component: Link,
                        to: `${match.url}/${item._id}`,
                    },
                ];
            },
        },
    ];

    const tableRows =
        data?.map((item) => {
            return {
                ...item,
                regionName: item.region?.name,
            };
        }) || [];

    function filterActionHandler(name, value, options) {
        setParams(
            options?.isRemoving
                ? {
                      ...params,
                      [name]: undefined,
                  }
                : {
                      ...options,
                      [name]: value,
                  }
        );
        return history.push(`${match.url}`);
    }

    const availableFilters = [
        {
            label: "Regione",
            name: "region",
            type: "select",
            options: regions?.map(({ _id, name }) => {
                return {
                    value: _id,
                    label: name,
                };
            }),
            action: filterActionHandler,
        },
        {
            label: "Attivi",
            name: "published",
            type: "boolean",
            action: filterActionHandler,
        },
    ];

    return (
        <MainContainer large>
            <TitleContainer>
                <MainTitle text="Province" />
            </TitleContainer>

            <ColumnContainer>
                <Column>
                    <SimpleTable
                        search
                        showFilters
                        data={tableRows}
                        head={tableHead}
                        availableFilters={availableFilters}
                        isLoading={isLoading}
                        searched={search}
                        onSearch={(value) => {
                            setSearch(value);
                            return history.push(match.url);
                        }}
                        onSearchClear={() => {
                            setSearch(undefined);
                            return history.push(match.url);
                        }}
                    />
                </Column>
            </ColumnContainer>

            <LocationActivationPopup
                item={selectedItem}
                setItem={setSelectedItem}
                locationType={"districts"}
            />
        </MainContainer>
    );
}
