import axios from "./_defaults/user";

export async function getRequests({ params }) {
    try {
        const response = await axios.get(`/admin-requestsForAssistance`, {
            params,
        });

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response?.status === 404) return [];
        console.log(error.response);
        throw error;
    }
}

export async function getRequest({ elementId, params }) {
    try {
        const response = await axios.get(
            `/admin-requestsForAssistance/${elementId}`,
            {
                params,
            }
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response?.status === 404) return [];
        console.log(error.response);
        throw error;
    }
}

export async function updateRequest({ elementId, data }) {
    try {
        const response = await axios.post(
            `/admin-requestsForAssistance/${elementId}`,
            {
                ...data,
            }
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        console.log(error.response);
        throw error;
    }
}