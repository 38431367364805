import MainLayout from '../layouts/MainLayout/MainLayout';

// Places

import Settings from '../pages/Settings/index.js';

// SubRoutes
import {subRoutesUsers, routesUsers} from './settings/settingsUsers';
import {subRoutesInvitations} from './settings/settingsInvitations';

export default [
    ...routesUsers,
    {
        path: "",
        component: Settings,
        layouts: MainLayout,
        routes: [
            ...subRoutesUsers,
            ...subRoutesInvitations
        ].map((item) => {
            return {
                ...item,
                path: `/settings${item.path}`
            };
        })
    }
].map((item) => {
    
    return {
        ...item,
        path: `/settings${item.path}`,
        private: true
    };
});