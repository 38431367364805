import React, { useState } from "react";
import qs from "query-string";
import { usePaginatedQuery } from "react-query";

import {
    MainContainer,
    ColumnContainer,
    Column,
    SimpleTable,
    TitleContainer,
    MainTitle
} from "@beacharound/beacharound-ui";
import { getProductImage } from "@beacharound/beacharound-ui-helpers";

import { getProductsTypes, updateProductType } from "../../../api/productsTypes";
import PublishPopup from "../../../components/Popups/PublishPopup";

const availableFilters = [
    {
        label: "Attiva",
        name: "published",
        type: "boolean",
    },
];

export default function List({ history, match, location }) {
    
    const { type } = match.params
    const { page } = location.search ? qs.parse(location.search) : { page: "0" };

    const [selectedItem, setSelectedItem] = useState();
    const [limit, setLimit] = useState(25);
    const [search, setSearch] = useState();

    const options = {
        skip: Number(page) * limit,
        limit,
        sort: { name: 1 },
        search,
        params: { type }
    };

    const { data, isLoading } = usePaginatedQuery(
        ["productsType", type, options],
        () =>
            getProductsTypes({
                params: options,
            })
    );

    const tableHead = [{
        text: "Nome",
        key: "name",
        sort: "text",
        highlight: true,
        default: true,
        image: (item) => getProductImage(item)
    },{
        text: "Attivo",
        key: "published",
        hasMobileLabel: true,
        flex: 0.5,
        parse: (value) => (value === true ? "Attivo" : "Non attivo")
    },{
        flex: 0.5,
        actions: (item) => {
            return [{
                icon: item.published ? "visible" : "not-visible",
                action: () => setSelectedItem(item)
            }]
        }
    }]

    const tableRows = data || [];
    let pageTitle;

    switch (type) {
        case 'umbrella': pageTitle = 'Ombrelloni'; break;
        case 'deckchair': pageTitle = 'Lettini'; break;
        case 'room': pageTitle = 'Camere'; break;
        case 'parking': pageTitle = 'Parcheggi'; break;
    
        default: pageTitle = 'Prodotti'; break;
    }

    return (
        <MainContainer large>
            <TitleContainer>
                <MainTitle text={pageTitle} />
            </TitleContainer>

            <ColumnContainer>
                <Column>
                    <SimpleTable
                        match={match}
                        history={history}
                        setLimit={setLimit}
                        navigation
                        showFilters
                        data={tableRows}
                        head={tableHead}
                        availableFilters={availableFilters}
                        isLoading={isLoading}
                        page={page}
                        searched={search}
                        onSearch={(value) => {
                            setSearch(value);
                            history.push(match.url);
                        }}
                        onSearchClear={() => {
                            setSearch(undefined);
                            history.push(match.url);
                        }}
                    />
                </Column>
            </ColumnContainer>

            <PublishPopup
                item={selectedItem}
                setItem={setSelectedItem}
                updateFunction={updateProductType}
                queryKey={["productsType", type]}
            />
        </MainContainer>
    );
}
