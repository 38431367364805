import React, { useState } from "react";
import qs from "query-string";
import { Link } from "react-router-dom";
import { usePaginatedQuery } from "react-query";

import {
    MainContainer,
    TitleContainer,
    MainTitle,
    ColumnContainer,
    Column,
    SimpleTable
} from "@beacharound/beacharound-ui";

import { getReservations } from "../../../../../api/reservations/index.js";
import { formatDate, getBeachesStatusColor } from "@beacharound/beacharound-ui-helpers";

export default function Reservations({ history, match, location }) {

    const { page } = location.search ? qs.parse(location.search) : { page: "0" };

    const [dateFilter, setDateFilter] = useState();
    const [search, setSearch] = useState();
    const [limit, setLimit] = useState(25);
    const [params, setParams] = useState();
    const [sort, setSort] = useState();
    const [dateParams, setDateParams] = useState({});

    let fromDateParam;
    let toDateParam;

    if (dateParams?.fromDate) {
        fromDateParam = dateParams?.fromDate;
    }

    if (dateParams?.toDate) {
        toDateParam = dateParams?.toDate;
    }

    if (dateFilter?.[0]) {
        fromDateParam = dateFilter?.[0];
    }

    if (dateFilter?.[1]) {
        toDateParam = dateFilter?.[1];
    }

    const options = {
        skip: Number(page) * limit,
        limit,
        search,
        fromDate: fromDateParam && formatDate(new Date(fromDateParam), "yyyy-MM-dd"),
        toDate: toDateParam && formatDate(new Date(toDateParam), "yyyy-MM-dd"),
        checkin: dateParams?.checkin && formatDate(new Date(dateParams?.checkin), "yyyy-MM-dd"),
        checkout: dateParams?.checkout && formatDate(new Date(dateParams?.checkout), "yyyy-MM-dd"),
        sort,
        activityId: match.params.elementId,
        params: {
            status: { $ne: "CHECKINGOUT" },
            ...params
        }
    }

    const { data: reservations, isLoading } = usePaginatedQuery(["reservations", options], () =>
        getReservations({
            params: options,
        })
    )

    let tableHead = [{
        text: "Utente",
        key: "user",
        parse: (user) => user?.profile ? `${user?.profile.lastName} ${user?.profile.firstName}` : '-',
        highlight: true,
        default: true,
        flex: 0.5
    }, {
        text: "Checkin",
        key: "checkin",
        sort: true,
        flex: 0.4,
        hasMobileLabel: true,
        parse: (date, item) => formatDate(new Date(date), 'dd/MM/yy')
    }, {
        text: "Checkout",
        key: "checkout",
        flex: 0.4,
        sort: true,
        hasMobileLabel: true,
        parse: (value, item) => (item?.durationType && item?.durationType !== 'daily') ? (item?.durationType === 'morning' ? 'Mattina' : 'Pomeriggio') : (value ? formatDate(new Date(value), 'dd/MM/yy') : '-'),
    }, {
        text: "Stato",
        key: "status",
        sort: true,
        flex: 0.5,
        color: (value) => getBeachesStatusColor(value),
        // parse: (value) => getBeachesStatusString(value, {isPublicBeach}),
    }, {
        text: "Online",
        key: "isBeacharound",
        flex: 0.2,
        color: (value) => value ? "blue" : "grey",
        parse: (value) => value ? "Si" : "No",
    }, {
        text: "Prezzo",
        key: "price",
        price: true,
        highlight: true,
        hasMobileLabel: true,
        parse: (value) => value ? `${Number(value).toFixed(2)} €` : "-",
        flex: 0.3
    }, {
        text: "Mancante",
        key: "unpaid",
        price: true,
        highlight: true,
        hasMobileLabel: true,
        parse: (value) => value > 0 ? `${Number(value).toFixed(2)} €` : "-",
        flex: 0.35
    }, {
        text: "Rimborso",
        key: "unpaid",
        price: true,
        highlight: true,
        hasMobileLabel: true,
        parse: (value) => value < 0 ? `${Math.abs(value).toFixed(2)} €` : "-",
        flex: 0.3
    }, {
        flex: 0.2,
        actions: (item) => [{
            icon: "arrow-right",
            component: Link,
            to: `${match.url}/${item._id}`,
        }]
    }];

    const tableRows = reservations;

    // Imposto il filtro per data
    // function dateFilterHandler(dates) {
    //     setDateFilter(dates);
    //     setDateParams({});
    // }

    function filterActionHandler(name, value, options) {
        setParams(options?.isRemoving ? {
            ...params,
            [name]: undefined,
        } : {
            ...options,
            [name]: value,
        })

        return history.push(`${match.url}`);
    }

    function filterActionDateHandler(name, value, options) {
        setDateParams(options?.isRemoving ? {
            [name]: undefined,
        } : {
            ...options,
            [name]: formatDate(new Date(value), "yyyy-MM-dd"),
        })
        setDateFilter()
        return history.push(`${match.url}`);
    }

    const availableFilters = [{
        label: "Prenotazioni esistenti dal",
        name: "fromDate",
        type: "date",
        action: filterActionDateHandler,
        parse: (date) => date && formatDate(new Date(date), "dd MMM"),
        half: true,
    }, {
        label: "Prenotazioni esistenti al",
        name: "toDate",
        type: "date",
        action: filterActionDateHandler,
        parse: (date) => date && formatDate(new Date(date), "dd MMM"),
        half: true,
    }, {
        label: "Checkin il",
        name: "checkin",
        type: "date",
        action: filterActionDateHandler,
        parse: (date) => date && formatDate(new Date(date), "dd MMM"),
        half: true,
    }, {
        label: "Checkout il",
        name: "checkout",
        type: "date",
        action: filterActionDateHandler,
        parse: (date) => date && formatDate(new Date(date), "dd MMM"),
        half: true,
    }, {
        label: "Pagato",
        name: "status",
        type: "select",
        options: [{
            value: "ACCEPTED",
            label: "In attesa di pagamento",
        }, {
            value: "PAID",
            label: "Pagato totalmente",
        }, {
            value: "PARTIALPAID",
            label: "Pagato parzialmente",
        }],
        action: filterActionHandler,
        half: true,
    }];

    return (
        <MainContainer large>

            <TitleContainer>
                <MainTitle text="Prenotazioni" />
            </TitleContainer>

            <ColumnContainer>
                <Column>
                    <SimpleTable
                        match={match}
                        history={history}
                        setLimit={setLimit}
                        elementsLimit={50}
                        navigation
                        showFilters
                        data={tableRows}
                        head={tableHead}
                        availableFilters={availableFilters}
                        isLoading={isLoading}
                        page={page}
                        searched={search}
                        sort={sort}
                        onSort={setSort}
                        onSearch={(value) => {
                            setSearch(value);
                            history.push(match.url);
                        }}
                        onSearchClear={() => {
                            setSearch(undefined);
                            history.push(match.url);
                        }}
                        hasDownload
                        downloadName="prenotazioni"
                    />
                </Column>
            </ColumnContainer>

        </MainContainer>
    )
}
