import moment from 'moment';

export function getChardData(list, key) {

    const daysOfPeriod = Array.apply(null, Array(6)).map((time, index) => {
        return {
            dayOfYear: moment().subtract(index, "day").dayOfYear(),
            count: 0,
        };
    });

    return daysOfPeriod
        .sort((a, b) => a.dayOfYear - b.dayOfYear)
        .map(({ dayOfYear: defaultDayOfYear, count }) => {

            const data = list && Array.isArray(list) && list.find(item => item.dayOfYear === defaultDayOfYear);

            let dateByDay = moment().dayOfYear(defaultDayOfYear);

            if (dateByDay.isAfter()) {
                dateByDay.subtract(1, "year");
            }

            return {
                date: dateByDay.format("LLLL"),
                name: dateByDay.format("ddd"),
                value: data?.count || count,
            };
        });
}