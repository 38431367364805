/*eslint no-unused-expressions: 0*/
import React, { Fragment, useState } from 'react';
import { useMutation, useQueryCache } from 'react-query';

import { SimpleDropdown, NormalPopup, TitleContainer, Paragraph, ButtonsRow, MainButton, Divider } from '@beacharound/beacharound-ui';
import { generateTransferPdf } from '../../helpers/transfersPdf';
import { confirmTransfer, removeTransfer } from '../../api/payments';
import { createCsvUrl } from '@beacharound/beacharound-ui-helpers';
import { generateTransferCsv } from '../../helpers/generateTransferCsv';

export default function PaymentActions({ history, match, full, flexEnd, transfer }) {


    const [ confirmPopup, setConfirmPopup ] = useState()
    const [ cancelPopup, setCancelPopup ] = useState()

    const cache = useQueryCache()
    const [confirm, { status }] = useMutation(confirmTransfer, {
        onSuccess: () => {
            cache.invalidateQueries(["transfers", transfer?._id]);
            setConfirmPopup()
        }
    });

    const [remove, { status: removeStatus }] = useMutation(removeTransfer, {
        onSuccess: () => {
            cache.invalidateQueries(["transfers", transfer?._id]);
            setCancelPopup()
            return history.push('/financial/payments')
        }
    });

    const csv = generateTransferCsv(transfer);

    let actions = [{
        text: "Cancella",
        icon: "cross",
        action: () => setCancelPopup(true)
    },{
        text: "Scarica pro forma",
        icon: "arrow-bottom",
        action: () => generateTransferPdf(transfer, transfer?.activity)
    },{
        text: "Scarica csv",
        icon: "arrow-bottom",
        component: "a",
        href: createCsvUrl(csv),
        download: `beacharound-pagamenti-${transfer?.number?.replace("/", "-")}.csv`,
        target: "_blank"
    }]

    if (!["TRANSFERED", "CANCELED"].includes(transfer?.status)) {
        actions = [
            {
                text: "Conferma",
                icon: "checked",
                action: () => setConfirmPopup(true)
            },
            ...actions
        ]
    }

    function confirmHandler() {
        return confirm({
            elementId: transfer?._id
        })
    }

    function cancelHandler() {
        return remove({
            elementId: transfer?._id
        })
    }

    return (
        <Fragment>
            <SimpleDropdown
                buttonText="Cosa vuoi fare?"
                list={actions}
                alignRight={!full}
                flexEnd={flexEnd}
                buttonStyle
                fullWrapper
            />


            {/* CONFERMA */}
            <NormalPopup
                visible={confirmPopup}
                setVisible={setConfirmPopup}
                title="Conferma Pagamento"
            >
                <TitleContainer>
                    <Paragraph text={`Sei sicuro di voler confermare questo pagamento?`}/>
                </TitleContainer>
                <Divider/>
                <ButtonsRow fill>
                    <MainButton
                        text="Annulla"
                        border
                        action={() => setConfirmPopup()}
                    />
                    <MainButton
                        text="Conferma"
                        action={confirmHandler}
                        status={status}
                    />
                </ButtonsRow>
            </NormalPopup>

            {/* CANCELLA */}
            <NormalPopup
                visible={cancelPopup}
                setVisible={setCancelPopup}
                title="Cancella Pagamento"
            >
                <TitleContainer>
                    <Paragraph text={`Sei sicuro di voler cancellare questo pagamento?`}/>
                </TitleContainer>
                <Divider/>
                <ButtonsRow fill>
                    <MainButton
                        text="Annulla"
                        border
                        action={() => setCancelPopup()}
                    />
                    <MainButton
                        text="Conferma"
                        action={cancelHandler}
                        status={removeStatus}
                    />
                </ButtonsRow>
            </NormalPopup>

        </Fragment>
    );
}