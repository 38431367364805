import { ButtonsRow, DateInput, Divider, MainButton, MainForm, MainFormContent, NormalPopup, TextInput } from '@beacharound/beacharound-ui';
import React from 'react';
import { useMutation, useQueryCache } from 'react-query';
import { updateMembership } from '../../api/memberships';
import { getSubFormData } from '../../helpers/main';

const constraints = {
    expireDate: {presence: true}
}

export default function EditMembershipPopup({editPopup, setEditPopup}) {
     
    const cache = useQueryCache()

    const [update, {status}] = useMutation(updateMembership, {
        onSuccess(data) {
            cache.invalidateQueries(["memberships"])
            return setEditPopup()
        },
    });

    async function saveMembership(data) {
        try {
            await update({
                elementId: editPopup?._id,
                data
            })
        } catch(error) {
            console.log(error)
        }
    }

    return (
        <NormalPopup
            visible={editPopup}
            setVisible={setEditPopup}
            title="Modifica membership"
        >
            <MainForm
                onSubmit={saveMembership}
                data={editPopup && getSubFormData(editPopup, ['price', 'fee', 'fixed','expireDate'])}
                constraints={constraints}
            >
                <MainFormContent flex>
                    <TextInput
                        name="price"
                        label="Prezzo"
                        price
                        half
                    />
                    <TextInput
                        label="Percentuale"
                        name="fee"
                        minNum={0}
                        maxNum={100}
                        step="0.01"
                        type="number"
                        half
                    />
                    <TextInput
                        label="Fisso a transazione"
                        name="fixed"
                        minNum={0}
                        price
                        type="number"
                        half
                    />
                    <DateInput
                        name="expireDate"
                        label="Scadenza"
                        half
                    />
                </MainFormContent>

                <Divider/>

                <ButtonsRow fill>
                    <MainButton text="Annulla" border action={() => setEditPopup()}/>
                    <MainButton text="Salva" submit status={status}/>
                </ButtonsRow>
            </MainForm>

        </NormalPopup>
    )
}