import React, { useState } from "react";
import qs from "query-string";
import { Link } from "react-router-dom";
import { usePaginatedQuery } from "react-query";

import {
    MainContainer,
    TitleContainer,
    MainTitle,
    ColumnContainer,
    Column,
    SimpleTable,
} from "@beacharound/beacharound-ui";

import { getUsers } from "../../../api/users/users";

export default function List({ match, location, history }) {
    const { page } = location.search ? qs.parse(location.search) : { page: "0" };

    const [limit, setLimit] = useState(25);
    const [params, setParams] = useState({}); // eslint-disable-line no-unused-vars

    const options = {
        skip: Number(page) * limit,
        limit,
        sort: { name: 1 },
        params: {
            ...params,
        }
    }

    const { resolvedData: data, isLoading } = usePaginatedQuery(["users", "public-administrations", options], () =>
        getUsers({
            role: "public-administrations",
            params: options,
        })
    )

    const tableHead = [{
        text: "Nome",
        key: ["lastName", "firstName"],
        sort: "text",
        highlight: true,
    },{
        actions: (item) => {
            return [{
                icon: "edit",
                component: Link,
                to: `${match.url}/${item._id}`,
            }]
        }
    }]

    const tableRows = data?.map(({ _id, profile }) => {
        return {
            ...profile,
            _id,
        };
    }) || [];

    return (
        <MainContainer large>
            <TitleContainer>
                <MainTitle text="Amministrazioni pubbliche" />
            </TitleContainer>

            <ColumnContainer>
                <Column>
                    <SimpleTable
                        match={match}
                        history={history}
                        setLimit={setLimit}
                        navigation
                        data={tableRows}
                        head={tableHead}
                        isLoading={isLoading}
                        page={page}
                    />
                </Column>
            </ColumnContainer>
        </MainContainer>
    )
}
