import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useMutation, useQueryCache } from "react-query";
import { useQuery } from "react-query";

import {
    MainContainer,
    TitleContainer,
    MainTitle,
    MainForm,
    MainFormContent,
    TextInput,
    TextAreaInput,
    GooglePlacesInput,
    ButtonsRow,
    MainButton,
    Divider,
    SectionTitle,
    SelectInput,
    CheckboxInput
} from "@beacharound/beacharound-ui";

import { sandTypes } from '@beacharound/beacharound-ui-helpers';
import categoriesOptions from "./data/categories";
import { createBeach } from "../../../api/beach";
import { getServices } from "../../../api/services";

export default function Create({ match, history }) {

    const { type } = match.params;
    const cache = useQueryCache();
    const [published, setPublished] = useState();

    const servicesOptions = {
        params: { type: 'beaches'}
    }

    const constraints = {
        name: { presence: true },
        sandType: { presence: true },
        description: { presence: true },
        address: { presence: true },
    }
    
    const [create, { status }] = useMutation(createBeach, {
            onSuccess: (data) => {
                cache.invalidateQueries("beaches")
                cache.setQueryData(["beaches", data._id], data)
                return history.push(match.url.replace("create", data?._id))
            }
        }
    )

    const { data: allServices } = useQuery(["allServices", "beaches"], () =>
        getServices({type: 'services', params: servicesOptions})
    )

    const { data: allAccessibilities } = useQuery(["allAccessibilities", "beaches"], () =>
        getServices({type: 'accessibilities', params: servicesOptions})
    )

    function save(data) {
        
        let accessibilities = Object
            .keys(data)
            .filter(key => allAccessibilities.some(accessibility => accessibility.slug === key))
            .map(key => {
                const accessibility = allAccessibilities.find(accessibility => accessibility.slug === key)
                return {
                    _id: accessibility._id,
                    isCustom: false,
                    value: data[key]
                };
            })

        let services = Object
            .keys(data)
            .filter(key => allServices.some(service => service.slug === key))
            .map(key => {
                const service = allServices.find(
                    (service) => service.slug === key
                );
                return {
                    _id: service._id,
                    isCustom: false,
                };
            })
        
        let publicContact;

        if(data.email || data.phone) {
            publicContact = {
                email: data.email,
                phone: data.phone
            }
        }

        return create({
            data: {
                ...data,
                type,
                services,
                accessibilities,
                publicContact,
                published
            },
        });
    }

    return (
        <MainContainer marginBottom>
            <TitleContainer>
                <MainTitle text="Crea spiaggia" />
            </TitleContainer>

            <MainForm onSubmit={save} constraints={constraints}>
                <TitleContainer>
                    <SectionTitle text="Dati generali" />
                </TitleContainer>
                <MainFormContent flex>
                    <TextInput label="Nome" name="name" half />
                    <TextInput label="Sottotitolo" name="subtitle" />
                    <TextAreaInput label="Descrizione" name="description" />
                </MainFormContent>

                <Divider />

                <TitleContainer>
                    <SectionTitle text="Caratteristiche" />
                </TitleContainer>
                <MainFormContent flex>
                    <SelectInput
                        label="Categoria"
                        name="categories"
                        isMulti
                        options={categoriesOptions}
                        placeholder="Scegli tipologia"
                        half
                    />
                    <SelectInput
                        label="Tipologia spiaggia"
                        name="sandType"
                        options={sandTypes}
                        placeholder="Scegli tipologia"
                        half
                    />
                </MainFormContent>

                <Divider />

                <TitleContainer>
                    <SectionTitle text="Contatti pubblici" />
                </TitleContainer>
                <MainFormContent flex>
                    <TextInput name="email" label="Email per info" half />
                    <TextInput name="phone" label="Telefono per info" half />
                </MainFormContent>

                <Divider />

                <TitleContainer>
                    <SectionTitle text="Indirizzo" />
                </TitleContainer>
                <MainFormContent flex>
                    <GooglePlacesInput
                        name="address"
                        label="Indirizzo"
                        zoom={16}
                        required
                    />
                </MainFormContent>

                <Divider />

                <TitleContainer>
                    <SectionTitle text="Servizi della spiaggia" />
                </TitleContainer>
                <MainFormContent flex>
                    {allServices
                        ?.sort((a, b) => {
                            if (a.name > b.name) return 1;
                            if (a.name < b.name) return -1;
                            return 0;
                        })
                        .map((item, index) => {
                            return (
                                <CheckboxInput
                                    key={index}
                                    name={item.slug}
                                    label={item.name}
                                    half
                                />
                            );
                        })}
                </MainFormContent>

                <Divider />

                <TitleContainer>
                    <SectionTitle text="Accessibilità" />
                </TitleContainer>
                <MainFormContent flex>
                    {allAccessibilities
                        ?.sort((a, b) => {
                            if (a.name > b.name) return 1;
                            if (a.name < b.name) return -1;
                            return 0;
                        })
                        .map((item, index) => (
                            <SelectInput
                                name={item.slug}
                                key={index}
                                label={item.name}
                                placeholder="-"
                                options={[
                                    { label: "Si", value: true },
                                    { label: "No", value: false },
                                ]}
                                half
                            />
                        ))}
                </MainFormContent>

                <Divider />

                <ButtonsRow fill marginBottom>
                    <MainButton
                        text="Annulla"
                        border
                        component={Link}
                        to={`/places/beaches/${type}`}
                    />
                    <MainButton
                        border
                        submit
                        actionOnSubmit={() => setPublished(false)}
                        text="Salva bozza"
                    />
                    <MainButton
                        text="Crea spiaggia"
                        submit
                        status={status}
                        actionOnSubmit={() => setPublished(true)}
                    />
                </ButtonsRow>
            </MainForm>
        </MainContainer>
    );
}
