import React, { useState } from "react";
import qs from "query-string";
import { Link } from "react-router-dom";
import { useQueryCache, usePaginatedQuery, useQuery } from "react-query";

import {
    MainContainer,
    TitleContainer,
    MainTitle,
    ColumnContainer,
    Column,
    SimpleTable,
    ButtonsRow,
    MainButton
} from "@beacharound/beacharound-ui";

import { getImageCrop } from '@beacharound/beacharound-ui-helpers';

import HighlightPopup from "../../../components/Popups/HighlightPopup";
import PublishPopup from "../../../components/Popups/PublishPopup";

import { getBeaches, updateBeach } from "../../../api/beach";
import { getLocations } from "../../../api/locations";
import { getOptionsFromCities } from "../../../helpers/cities";

export default function List({ history, match, location }) {
    
    const { page } = location.search ? qs.parse(location.search) : { page: "0" };
    const { type } = match.params;

    const [limit, setLimit] = useState(25);
    const [search, setSearch] = useState();
    const [params, setParams] = useState({});
    const [ highlightItem, setHighlightItem ] = useState();
    const [ publishItem, setPublishItem ] = useState();

    const options = {
        skip: Number(page) * limit,
        limit,
        sort: { name: 1 },
        search,
        params: {
            ...params,
            type,
        }
    }

    const { data, isLoading } = usePaginatedQuery(["beaches", type, options], () =>
        getBeaches({
            params: options,
        })
    )

    const queryCache = useQueryCache();

    const { data: districts } = useQuery("districts", () =>
        getLocations({ type: "districts" })
    );

    const [citiesSearch, setCitiesSearch] = useState(); // eslint-disable-line no-unused-vars
    const citiesOptions = {
        limit: 25,
        sort: { name: 1 },
        search: citiesSearch,
    };
    const { data: cities } = useQuery(["cities", citiesOptions], () =>
        getLocations({
            type: "cities",
            params: citiesOptions,
        })
    )

    const tableHead = [
        {
            text: "Nome",
            key: "name",
            sort: "text",
            highlight: true,
            default: true,
            image: (item) => getImageCrop(item?.cover, 'xs')
        }, {
            text: "Provincia",
            key: "districtName",
            sort: true,
            flex: 0.5
        }, {
            text: "Comune",
            key: "cityName",
            sort: true,
            flex: 0.5
        }, {
            flex: 0.5,
            actions: (item) => {
                return [
                    {
                        icon: item.published ? "visible" : "not-visible",
                        action: () => setPublishItem(item),
                    },
                    {
                        icon: item.highlight ? "heart-full" : "heart",
                        action: () => setHighlightItem(item),
                    },
                    {
                        icon: "edit",
                        component: Link,
                        to: `${match.url}/${item._id}`
                    }
                ]
            }
        }
    ]

    const tableRows = data?.map((item) => {
        return {
            ...item,
            districtName: item.address?.district,
            cityName: item.address?.city,
        }
    }) || [];

    function filterActionHandler(name, value, options) {
        setParams(
            options?.isRemoving
                ? {
                      ...params,
                      [name]: undefined,
                  }
                : {
                      ...options,
                      [name]: value,
                  }
        );
        return history.push(`${match.url}`);
    }

    const availableFilters = [
        {
            label: "Provincia",
            name: "address.district",
            type: "select",
            half: true,
            options: districts
                ?.map(({ iso, name }) => {
                    return {
                        value: iso,
                        label: name,
                    };
                })
                .sort(function (a, b) {
                    return a.label - b.label;
                }),
            action: filterActionHandler,
        },
        {
            label: "Comune",
            name: "address.city",
            type: "select",
            half: true,
            loadOptions: async (citiesSearch, callback) => {
                const searchCitiesOptions = {
                    ...citiesOptions,
                    search: citiesSearch,
                };

                const result = await getLocations({
                    type: "cities",
                    params: searchCitiesOptions,
                });

                queryCache.setQueryData(
                    ["cities", searchCitiesOptions],
                    result
                );

                return callback(
                    getOptionsFromCities(result, { valueKey: "name" })
                );
            },
            defaultOptions: getOptionsFromCities(cities, { valueKey: "name" }),
            action: filterActionHandler,
        },
        {
            label: "Prese in carico",
            name: "ownership.ownedBy",
            type: "boolean",
            action: (name, value, options) => {
                setParams({
                    ...params,
                    "ownership.ownedBy": {
                        $exists: !options?.isRemoving ? value : false,
                    },
                });
                return history.push(`${match.url}`);
            },
        },
    ];

    return (
        <MainContainer large>

            <ColumnContainer>
                <Column column={2}>
                    <TitleContainer noMargin>
                        <MainTitle text={type === "private" ? "Spiagge private" : "Spiagge libere"} />
                    </TitleContainer>
                </Column>
                <Column column={2}>
                    <ButtonsRow flexEnd noMargin>
                        <MainButton
                            text={type === "private" ? "Nuova spiaggia privata" : "Nuova spiaggia libera"}
                            component={Link}
                            to={`${match.url}/create`}
                        />
                    </ButtonsRow>
                </Column>
            </ColumnContainer>

            <ColumnContainer>
                <Column>
                    <SimpleTable
                        match={match}
                        history={history}
                        setLimit={setLimit}
                        navigation
                        showFilters
                        data={tableRows}
                        head={tableHead}
                        availableFilters={availableFilters}
                        isLoading={isLoading}
                        page={page}
                        searched={search}
                        onSearch={(value) => {
                            setSearch(value);
                            history.push(match.url);
                        }}
                        onSearchClear={() => {
                            setSearch(undefined);
                            history.push(match.url);
                        }}
                    />
                </Column>
            </ColumnContainer>

            <HighlightPopup
                item={highlightItem}
                setItem={setHighlightItem}
                type={"beaches"}
                updateFunction={updateBeach}
            />

            <PublishPopup
                item={publishItem}
                setItem={setPublishItem}
                type={"beaches"}
                updateFunction={updateBeach}
            />
            
        </MainContainer>
    );
}
