import React, { useState } from "react";
import qs from "query-string";
import { usePaginatedQuery } from "react-query";

import {
    MainContainer,
    TitleContainer,
    MainTitle,
    ColumnContainer,
    Column,
    SimpleTable
} from "@beacharound/beacharound-ui";

import { getMemberships } from "../../../api/memberships";
import { format, isBefore } from "date-fns";
import EditMembershipPopup from "../../../components/Memberships/EditMembershipPopup";

export default function List({ history, match, location }) {
    
    const { page } = location.search ? qs.parse(location.search) : { page: "0" };

    const [ limit, setLimit ] = useState(25);
    const [ search, setSearch ] = useState();
    const [ editPopup, setEditPopup ] = useState();

    const options = {
        skip: Number(page) * limit,
        limit,
        sort: { createdAt: -1 },
        search,
        params: {}
    }
    
    const { data, isLoading } = usePaginatedQuery(["memberships", options], () =>
        getMemberships({
            params: options
        })
    )

    const tableHead = [
        {
            text: "Attività",
            key: "activity",
            highlight: true,
            parse: (activity) => activity?.name,
        },
        {
            text: "Pacchetto",
            key: "pack",
            flex: 0.8,
        },
        {
            text: "Tipologia",
            key: "type",
            color: (type) => ["FREE", "TRIAL"].includes(type) && "grey",
            flex: 0.8,
        },
        {
            text: "Scadenza",
            key: "expireDate",
            flex: 0.8,
            color: (date) => isBefore(new Date(date), new Date()) && "red",
            parse: (date) => `${format(new Date(date), "dd/MM/yyyy")}`,
        }, {
            text: "Prezzo",
            key: "price",
            flex: 0.5,
            parse: (price) => price ? `${Number(price).toFixed(2)} €` : '-'
        }, {
            text: "Fee",
            key: "fee",
            flex: 0.5,
            parse: (price) => price ? `${Number(price).toFixed(2)} %` : "-"
        }, {
            text: "Fisso",
            key: "fixed",
            flex: 0.5,
            parse: (price) => price ? `${Number(price).toFixed(2)} €` : "-"
        },{
            flex: 0.5,
            actions: (item) => {
                return [{
                    icon: "edit",
                    action: () => setEditPopup({...item, activityId: item.activity?._id})
                }]
            }
        }
    ];

    const tableRows = data?.map(item => {
        return {
            ...item
        }
    }) || [];

    return (
        <MainContainer large>

            <TitleContainer>
                <MainTitle text="Membership" />
            </TitleContainer>

            <ColumnContainer>
                <Column>
                    <SimpleTable
                        match={match}
                        history={history}
                        setLimit={setLimit}
                        navigation
                        data={tableRows}
                        head={tableHead}
                        isLoading={isLoading}
                        page={page}
                        searched={search}
                        onSearch={(value) => {
                            setSearch(value);
                            history.push(match.url);
                        }}
                        onSearchClear={() => {
                            setSearch(undefined);
                            history.push(match.url);
                        }}
                    />
                </Column>
            </ColumnContainer>


            {/* MODIFICA */}
            <EditMembershipPopup
                editPopup={editPopup}
                setEditPopup={setEditPopup}
            />
        </MainContainer>
    );
}
