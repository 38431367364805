import { formatDate } from '@beacharound/beacharound-ui-helpers';

export function generateTransferCsv(transfer) {
    if(!transfer) {
        return [];
    }

    let rows = transfer?.transactions?.map(item => {
        return {
            transazione: item?.transaction?._id,
            prenotazione: item?.reservation?._id,
            cliente: item?.reservation?.customer ? `${item?.reservation?.customer?.firstName || ""} ${item?.reservation?.customer?.lastName || ""}` : "-",
            checkin: formatDate(item?.reservation?.checkin, "dd/MM/yyyy"),
            checkout: formatDate(item?.reservation?.checkout, "dd/MM/yyyy"),
            transato: item?.transaction?.amount,
            fee: item?.transaction?.application,
            iva: (Number(item?.transaction?.application) - Number(item?.transaction?.application) * 100 / 122).toFixed(2)
        }
    });

    return rows;
}