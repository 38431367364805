import React, { useState } from 'react';
import { useQuery } from "react-query";
import { Link } from "react-router-dom";

import { Column, ColumnContainer, MainContainer, MainTitle, TitleContainer, GoogleMaps, SimpleTable } from "@beacharound/beacharound-ui";
import { getImageCrop } from '@beacharound/beacharound-ui-helpers';

import HighlightPopup from "../../../components/Popups/HighlightPopup";
import PublishPopup from "../../../components/Popups/PublishPopup";

import { getBeaches, updateBeach, getBeach } from '../../../api/beach';
import AddressPopup from '../../../components/Popups/AddressPopup';

export default function List({history, match, location}) {

    const [ coordinates, setCoordinates ] = useState({})
    const [ highlightItem, setHighlightItem ] = useState();
    const [ publishItem, setPublishItem ] = useState();
    const [ addressItem, setAddressItem ] = useState();

    let options = {
        sort: { name: 1 },
        limit: 100,
        params: {
            "address.geocode.coordinates": {$exists: true}
        }
    };
    
    if(coordinates?.lat && coordinates?.lng && !coordinates.bounds) {
        options = {
            ...options,
            latitude: coordinates.lat,
            longitude: coordinates.lng,
        };
    }

    if(coordinates.bounds) {
        options = {
            ...options,
            geoBounds: coordinates.bounds
        }
    }

    if(!!coordinates.zoom && coordinates.zoom > 13) {
        options = {
            ...options,
            limit: undefined
        }
    }

    const { data, isLoading } = useQuery(["beaches", options], () =>
        getBeaches({
            params: options,
        })
    );

    const tableHead = [
        {
            text: "Nome",
            key: "name",
            sort: "text",
            highlight: true,
            default: true,
            image: (item) => getImageCrop(item?.cover, 'xs')
        }, {
            text: "Provincia",
            key: "districtName",
            sort: true,
            flex: 0.5
        }, {
            text: "Comune",
            key: "cityName",
            sort: true,
            flex: 0.5
        }, {
            flex: 0.5,
            actions: (item) => {
                return [
                    {
                        icon: item.published ? "visible" : "not-visible",
                        action: () => setPublishItem(item),
                    },
                    {
                        icon: item.highlight ? "heart-full" : "heart",
                        action: () => setHighlightItem(item),
                    },
                    {
                        icon: "edit",
                        component: Link,
                        to: `/places/beaches/private/${item._id}`
                    }
                ]
            }
        }
    ]

    const tableRows = data?.map((item) => {
        return {
            ...item,
            districtName: item.address?.district,
            cityName: item.address?.city,
        }
    }) || [];

    return (
        <MainContainer large>
            <ColumnContainer>
                <Column>
                    <TitleContainer>
                        <MainTitle text={"Mappa dei luoghi"} />
                    </TitleContainer>
                </Column>

                <Column>
                    <GoogleMaps
                        onPositionChange={(coords) => setCoordinates(coords)}
                        markers={data?.map((beach) => {
                            return {
                                name: beach.name,
                                coordinates:
                                    beach.address?.geocode?.coordinates,
                                action: () => setAddressItem(beach),
                            };
                        })}
                    />
                </Column>
            </ColumnContainer>

            <ColumnContainer>
                <Column>
                    <SimpleTable
                        match={match}
                        data={tableRows}
                        head={tableHead}
                        history={history}
                        isLoading={isLoading}
                    />
                </Column>
            </ColumnContainer>

            <HighlightPopup
                item={highlightItem}
                setItem={setHighlightItem}
                type={"beaches"}
                updateFunction={updateBeach}
            />

            <PublishPopup
                item={publishItem}
                setItem={setPublishItem}
                type={"beaches"}
                updateFunction={updateBeach}
            />

            <AddressPopup
                item={addressItem}
                setItem={setAddressItem}
                queryKey={["beaches", addressItem?._id]}
                updateKey={["beaches", options]}
                updateFunction={updateBeach}
                getFunction={() => getBeach({ elementId: addressItem?._id })}
                elementId={addressItem?._id}
            />
        </MainContainer>
    );
}