export function getHotelNameWithStars(name, stars) {

    let starsString = "";
    
    if(stars && typeof stars === 'number') {
        for (let index = 0; index < stars; index++) {
            starsString += "<i class='icon icon-star'></i>";
        }
    }

    return `${name} ${starsString}`;
}