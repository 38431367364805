import List from '../../pages/Financial/Payments/List';
import Detail from '../../pages/Financial/Payments/Detail';

export const subRoutesPayments = [
    {
        path: '',
        component: List,
        exact: true
    }
].map((item) => {
    return {
        ...item,
        path: `/payments${item.path}`,
        private: true
    }
})

export const routesPayments = [
    {
        path: '/:elementId',
        component: Detail,
        exact: true
    }
].map((item) => {
    return {
        ...item,
        path: `/payments${item.path}`,
        private: true
    }
})
