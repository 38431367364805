import React, { useState } from "react";
import qs from "query-string";
import { useMutation, usePaginatedQuery, useQueryCache } from "react-query";
import { Link } from "react-router-dom";

import {
    MainContainer,
    TitleContainer,
    MainTitle,
    ColumnContainer,
    Column,
    SimpleTable,
    ButtonsRow,
    MainButton,
    NormalPopup,
    MainForm,
    MainFormContent,
    SelectInput,
    Divider
} from "@beacharound/beacharound-ui";

import { getActivityTransactions, createActivityTransaction } from "../../../../../api/transactions";
import { formatDate } from "@beacharound/beacharound-ui-helpers";
import { getMemberships } from "../../../../../api/memberships";

// function getFeeAmount(amount, fee, fixedFee) {
//     if(!fee || !amount) return 0;
//     let feeAmount = amount * fee / 100;
//     return (feeAmount / 100) + (fixedFee || 0)
// }

export default function Transactions({ history, match, location }) {

    const { elementId } = match.params;
    const { page } = location.search ? qs.parse(location.search) : { page: "0" };

    const [newTransactionPopup, setNewTransactionPopup] = useState();

    const [dateFilter, setDateFilter] = useState();
    const [search, setSearch] = useState();
    const [limit, setLimit] = useState(25);
    const [params, setParams] = useState({
        status: { $ne: "DECLINED" },
    });
    const [sort, setSort] = useState();
    const [dateParams, setDateParams] = useState({});

    let fromDateParam;
    let toDateParam;

    if (dateParams?.fromDate) {
        fromDateParam = dateParams?.fromDate;
    }

    if (dateParams?.toDate) {
        toDateParam = dateParams?.toDate;
    }

    if (dateFilter?.[0]) {
        fromDateParam = dateFilter?.[0];
    }

    if (dateFilter?.[1]) {
        toDateParam = dateFilter?.[1];
    }

    const options = {
        skip: Number(page) * limit,
        limit,
        search,
        fromDate: fromDateParam && formatDate(new Date(fromDateParam), "yyyy-MM-dd"),
        toDate: toDateParam && formatDate(new Date(toDateParam), "yyyy-MM-dd"),
        sort: sort || { paidAt: -1, createdAt: -1 },
        params: {
            ...params,
            gateway: "stripe",
            "subjects.subjectType": "reservations"
        }
    }

    const optionsMemberships = {
        skip: Number(page) * limit,
        limit,
        sort: { name: 1 },
        params: {
            activity: elementId
        }
    }

    const { data, isLoading } = usePaginatedQuery(["transactions", elementId, options], () =>
        getActivityTransactions({ activityId: elementId, params: options })
    )


    const { data: memberships } = usePaginatedQuery(["memberships", elementId, optionsMemberships], () =>
        getMemberships({ params: optionsMemberships })
    )

    const cache = useQueryCache();

    const [create, { status: createStatus }] = useMutation(createActivityTransaction, {
        onSuccess: () => {
            setNewTransactionPopup();
            return cache.invalidateQueries(["transactions", elementId, options]);
        }
    })

    async function addTransaction(data) {
        const membership = memberships.find(({ _id }) => _id === data.subject);
        await create({
            activityId: elementId,
            data: {
                amount: membership.price * 100,
                subjects: [{
                    subject: membership._id,
                    subjectType: "memberships"
                }],
                activityType: "beaches",
                method: data.method,
                gateway: "beacharound",
                type: "TOTAL"
            }
        });
    }

    const membershipsOptions = memberships?.filter(({ type }) => type === "SUBSCRIPTION").map(membership => {
        return {
            label: `${membership.pack} - ${membership.type} - ${membership.price}`,
            value: membership._id
        }
    })

    const tableHead = [{
        text: "Tip",
        key: "subjects",
        highlight: true,
        default: true,
        flex: 0.2,
        parse: (value) => value?.some(subject => subject.subjectType === "memberships") ? "Mem" : "Pre",
    }, {
        text: "Id prenotazione",
        key: "subjects",
        parse: (value) => value?.[0]?.subject,
    }, {
        text: "Cliente",
        key: "paidBy",
        parse: (paidBy) => paidBy?.firstName ? `${paidBy?.firstName} ${paidBy?.lastName}` : '-',
        highlight: true,
        default: true,
        flex: 0.6
    }, {
        text: "Importo",
        key: "amount",
        flex: 0.3,
        sort: true,
        hasMobileLabel: true,
        price: true,
        parse: (value) => value && `${Number(value).toFixed(2)}€`
    }, {
        text: "Da pagare",
        key: "payout",
        flex: 0.3,
        hasMobileLabel: true,
        price: true,
        parse: (value) => value && `${Number(value).toFixed(2)}€`
    }, {
        text: "Fee",
        key: "application",
        flex: 0.3,
        price: true,
        parse: (value) => value && `${Number(value).toFixed(2)}€`
    }, {
        text: "Pagata il",
        key: "paidAt",
        flex: 0.3,
        sort: true,
        parse: (paidAt) => formatDate(new Date(paidAt), "dd MMM"),
    }, {
        text: "Stato",
        key: "status",
        flex: 0.3,
        sort: true
    }, {
        flex: 0.1,
        actions: (item) => {
            if (["CANCELED", "TRANSFERED"].includes(item.status)) {
                return [{
                    icon: "arrow-right",
                    component: Link,
                    to: `${match.url}/${item._id}`
                }]
            }
            // return [{
            //     icon: "delete",
            //     action: () => setCancelPopup(item)
            // }, {
            //     icon: "arrow-right",
            //     component: Link,
            //     to: `${match.url}/${item._id}`
            // }]
            return [{
                icon: "arrow-right",
                component: Link,
                to: `${match.url}/${item._id}`
            }]
        }
    }
    ]

    const tableRows = data || [];

    function filterActionHandler(name, value, options) {
        setParams(options?.isRemoving ? {
            ...params,
            [name]: undefined,
        } : {
            ...options,
            [name]: value,
        })

        return history.push(`${match.url}`);
    }

    function filterActionDateHandler(name, value, options) {
        setDateParams(options?.isRemoving ? {
            [name]: undefined,
        } : {
            ...options,
            [name]: formatDate(new Date(value), "yyyy-MM-dd"),
        })
        setDateFilter()
        return history.push(`${match.url}`);
    }

    const availableFilters = [{
        label: "Pagate dal",
        name: "fromDate",
        type: "date",
        action: filterActionDateHandler,
        parse: (date) => date && formatDate(new Date(date), "dd MMM"),
        half: true,
    }, {
        label: "Pagate al",
        name: "toDate",
        type: "date",
        action: filterActionDateHandler,
        parse: (date) => date && formatDate(new Date(date), "dd MMM"),
        half: true,
    }, {
        label: "Stato",
        name: "status",
        type: "select",
        options: [{
            value: "ACCEPTED",
            label: "Pagate",
        }, {
            value: "DECLINED",
            label: "Rifiutate",
        }],
        action: filterActionHandler,
        half: true,
    }];

    return (
        <MainContainer large>

            <ColumnContainer noMargin>
                <Column column={2}>
                    <TitleContainer>
                        <MainTitle text="Transazioni" />
                    </TitleContainer>
                </Column>
                <Column column={2}>
                    <ButtonsRow flexEnd>
                        <MainButton text="Nuova transazione" action={() => setNewTransactionPopup(true)} />
                    </ButtonsRow>
                </Column>
            </ColumnContainer>

            <ColumnContainer>
                <Column>
                    <SimpleTable
                        match={match}
                        history={history}
                        setLimit={setLimit}
                        elementsLimit={50}
                        navigation
                        showFilters
                        data={tableRows}
                        head={tableHead}
                        availableFilters={availableFilters}
                        isLoading={isLoading}
                        page={page}
                        searched={search}
                        sort={sort}
                        onSort={setSort}
                        onSearch={(value) => {
                            setSearch(value);
                            history.push(match.url);
                        }}
                        onSearchClear={() => {
                            setSearch(undefined);
                            history.push(match.url);
                        }}
                        hasDownload
                        downloadName="prenotazioni"
                    />
                </Column>
            </ColumnContainer>

            {/* NUOVA */}
            <NormalPopup
                visible={newTransactionPopup}
                setVisible={setNewTransactionPopup}
                title="Nuova transazione"
                overflow="visible"
            >
                <MainForm
                    onSubmit={addTransaction}
                    constraints={{ subject: { presence: true }, method: { presence: true } }}
                >
                    <MainFormContent flex>
                        <SelectInput
                            name="subject"
                            label="Membership"
                            options={membershipsOptions}
                        />
                        <SelectInput
                            name="method"
                            label="Metodo"
                            options={[{ value: "cash", label: "Contanti" }, { value: "transfer", label: "Bonifico" }, { value: "check", label: "Assegno" }]}
                        />
                    </MainFormContent>

                    <Divider />

                    <ButtonsRow fill>
                        <MainButton text="Annulla" border action={() => setNewTransactionPopup()} />
                        <MainButton text="Salva" submit status={createStatus} />
                    </ButtonsRow>
                </MainForm>

            </NormalPopup>
        </MainContainer>
    );
}
