import { cleanOfferBeforeUpdate, getOffersElement, getOffersList } from "../../helpers/hotels/offers";
import axios from "../_defaults/user";

export async function createOffer({ data, elementId }) {
    try {
        const payload = cleanOfferBeforeUpdate(data);
        const response = await axios.post(
            `/admin-hotels/${elementId}/offers`,
            payload
        );
        return getOffersElement(response?.data);
    } catch (error) {
        throw error;
    }
}

export async function getOffers({ params, elementId }) {
    try {
        const response = await axios.get(`/admin-hotels/${elementId}/offers`, {
            params,
        });
        return getOffersList(response?.data);
    } catch (error) {
        if (error.response?.status === 404) {
            return [];
        }
        throw error;
    }
}

export async function getOffer({ offerId, elementId }) {
    try {
        const response = await axios.get(
            `/admin-hotels/${elementId}/offers/${offerId}`
        );
        return getOffersElement(response?.data);
    } catch (error) {
        throw error;
    }
}

export async function updateOffer({ offerId, elementId, data }) {
    try {
        const payload = cleanOfferBeforeUpdate(data);
        const response = await axios.post(
            `/admin-hotels/${elementId}/offers/${offerId}`,
            payload
        );
        return getOffersElement(response?.data);
    } catch (error) {
        throw error;
    }
}

export async function getPutUrl({ elementId, file, offerId }) {
    const response = await axios.post(
        `/admin-hotels/${elementId}/offers/${offerId}/images`,
        {
            contentType: file.type,
            extension: file.name?.split(".")[1],
        }
    );

    if (response.data) {
        return response.data;
    }

    return response;
}