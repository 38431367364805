import React from 'react';
import { useMutation, useQueryCache } from 'react-query';

import {
    NormalPopup,
    ButtonsRow,
    MainButton,
    Paragraph,
    TitleContainer
} from "@beacharound/beacharound-ui";

import { updateReview } from '../../api/reviews';

export default function ReviewsActivationPopup({item, setItem, match}) {

    const elementId = item?._id;
    const isActive = item?.published || false;

    const cache = useQueryCache();
    const [update, { status }] = useMutation(updateReview, {
        onSuccess: () => {
            cache.invalidateQueries(["reviews"])
            return setItem(false)
        }
    });

    function activationReviewHandler() {
        update({elementId, data: {published: !isActive}});
    }

    return (
        <NormalPopup
            visible={item}
            setVisible={() => setItem(false)}
            title='Recensione'
            large
        >

            <TitleContainer>
                <Paragraph text={`Sei sicuro di voler ${isActive ? "nascondere" : "pubblicare"} questa recensione?`} />
            </TitleContainer>

            <ButtonsRow fill>
                <MainButton
                    text="Annulla"
                    border
                    action={() => setItem(false)}
                />
                <MainButton
                    text={isActive ? "Nascondi" : "Pubblica"}
                    action={activationReviewHandler}
                    status={status}
                />
            </ButtonsRow>
        </NormalPopup>
    );
}