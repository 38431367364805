import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useMutation, useQuery, useQueryCache, usePaginatedQuery } from 'react-query';

import {
    MainContainer,
    MainForm,
    MainFormContent,
    SectionTitle,
    TitleContainer,
    MainTitle,
    SubTitle,
    FormToggleGroup,
    FormToggleGroupPreview,
    SimpleFormField,
    FormToggleGroupEdit,
    TextInput,
    TextAreaInput,
    LoadingContainer,
    EditImageGroup,
    ColumnContainer,
    Column,
    ToggleInput,
    ButtonsRow,
    MainButton,
    DragListing,
    Divider,
    SelectInput,
    DateInput,
    SimpleTable,
    SimpleLink,
    NormalPopup,
    Paragraph
} from "@beacharound/beacharound-ui";

import { getSubFormData, formatDate } from "@beacharound/beacharound-ui-helpers";

import { getCategories } from "../../../api/blog/categories";
import { getArticle, updateArticle, updateImage } from "../../../api/blog/articles";
import TutorialAddStep from "../../../components/Blog/TutorialAddStep";
import TutorialEditStep from "../../../components/Blog/TutorialEditStep";

const message = { message: '^Campo obbligatorio'}

const constraints = {
    name: { presence: message },
    description: { presence: message },
    categories: { presence: message },
    duration: { presence: message }
}

export default function Detail({ match, history }) {

    const [ addPopup, setAddPopup ] = useState();
    const [ editPopup, setEditPopup ] = useState();
    const [ toolPopup, setToolPopup ] = useState();
    const [ deleteToolPopup, setDeleteToolPopup ] = useState();
    const [ supplyPopup, setSupplyPopup ] = useState();
    const [ deleteSupplyPopup, setDeleteSupplyPopup ] = useState();

    const { elementId } = match.params;
    const { data, isLoading } = useQuery(["tutorials", elementId],
        () => getArticle({ elementId, params: {} })
    )

    const { data: categories, isLoading: isLoadingCategories } = usePaginatedQuery("categories", () =>
        getCategories({ params: {params: {}} })
    )

    const cache = useQueryCache()
    const [update, {status: updateStatus, reset}] = useMutation(updateArticle, {
        onSuccess: (data) => {
            setAddPopup()
            setToolPopup()
            setSupplyPopup()
            setDeleteToolPopup()
            setDeleteSupplyPopup()
            cache.invalidateQueries(["tutorials"])
            cache.setQueryData(["tutorials", elementId], data);
        },
    });
    const [updateArticleImage, {status: updateImageStatus}] = useMutation(updateImage, {
        onSuccess: (data) => {
            cache.invalidateQueries(["tutorials", elementId]);
            cache.setQueryData(["tutorials", elementId], data);
        },
    });


    const tableToolHead = [{
        text: "Nome",
        key: ["name"],
    },{
        actions: (item) => {
            return [{
                icon: "delete",
                action: () => setDeleteToolPopup(item?._id)
            }]
        }
    }];

    const tableSupplyHead = [{
        text: "Nome",
        key: ["name"],
    },{
        actions: (item) => {
            return [{
                icon: "delete",
                action: () => setDeleteSupplyPopup(item?._id)
            }]
        }
    }];

    async function editElement(data, callback) {
        try {
            await update({
                elementId,
                data
            });
            return callback(reset)
        } catch(error) {
            return callback(null, error);
        }
    }

    async function editVideoElement(data, callback) {
        try {
            await update({
                elementId,
                data: {
                    video: {
                        url: data.url,
                        duration: data.duration
                    }
                }
            });
            return callback(reset)
        } catch(error) {
            return callback(null, error);
        }
    }

    async function reorderElements(reorderedElements) {

        const reoderedSteps = reorderedElements?.map((step, index) => {
            return {
                ...step,
                index
            }
        })
    
        try {
            await update({
                elementId,
                data: {
                    steps: reoderedSteps
                }
            });
        } catch(error) {
            throw error;
        }
    }

    async function editCover(file) {
        try {
            await updateArticleImage({ elementId, file });
        } catch (error) {
            console.log(error);
        }
    }

    async function addStep(step) {

        const payload = {
            ...step,
            index: data?.steps?.length || 0
        }
        
        try {
            await update({
                elementId,
                data: {
                    $addToSet: {steps:  payload}
                }
            });

        } catch(error) {
            throw error;
        }
    }

    async function removeStep(steps, stepId) {

        try {
            await update({
                elementId,
                data: {
                    $pull: {steps: {_id: stepId} }
                }
            });

        } catch(error) {
            throw error;
        }
    }

    const categoriesOptions = categories?.map((cat) => {
        return {
            label: cat.name,
            value: cat._id
        }
    })

    async function addElementHandler(addingData, type) {
        let payload;
        if(type === 'tool') { payload = {tools: {name: addingData.name}} }
        if(type === 'supply') { payload = {supply: {name: addingData.name}} }

        try {
            await update({
                elementId,
                data: { $addToSet: payload }
            });
        } catch(error) {
            throw error;
        }
    }

    async function removeElementHandler(elId, type) {
        let payload;
        if(type === 'tool') { payload = {tools: {_id: elId}} }
        if(type === 'supply') { payload = {supply: {_id: elId}} }

        try {
            await update({
                elementId,
                data: { $pull: payload }
            });
        } catch(error) {
            throw error;
        }
    }

    return (
        <MainContainer marginBottom>

            <ColumnContainer>
                <Column column={2}>
                    <TitleContainer noMargin>
                        <MainTitle text={data?.title || "Gestisci tutorial"} />
                        <SubTitle
                            text="< Torna alla lista"
                            component={Link}
                            to={`/blog/tutorials`}
                        />
                    </TitleContainer>
                </Column>

                <Column column={2}>
                    <ToggleInput
                        flexEnd
                        trueLabel="Pubblicato"
                        falseLabel="Non pubblicato"
                        status={updateStatus}
                        changeValue={()=> editElement({published: !data?.published}, (resetAfter) => resetAfter())}
                        value={data?.published}
                    />
                </Column>
            </ColumnContainer>

            <LoadingContainer isLoading={isLoading || isLoadingCategories} isError={!data}>
                <TitleContainer>
                    <SectionTitle text="Dati principali" />
                </TitleContainer>

                <MainForm
                    onSubmit={editElement}
                    data={ data && getSubFormData(data, [
                        "name",
                        "subtitle",
                        "description",
                        "categories",
                        "duration",
                        "publishedAt",
                    ])}
                    constraints={constraints}
                >
                    <MainFormContent>
                        <FormToggleGroup>
                            <FormToggleGroupPreview>
                                <SimpleFormField
                                    label="Titolo"
                                    name="name"
                                />
                                <SimpleFormField
                                    label="Sottotitolo"
                                    name="subtitle"
                                />
                                <SimpleFormField
                                    label="Descrizione"
                                    name="description"
                                />
                                <SimpleFormField
                                    label="Categoria"
                                    name="categories"
                                    isMulti
                                    options={categoriesOptions}
                                    half
                                />
                                <SimpleFormField
                                    label="Durata tutorial (in minuti)"
                                    name="duration"
                                    half
                                />
                                <SimpleFormField
                                    label="Data di pubblicazione"
                                    value={formatDate(data?.publishedAt, 'dd/MM/yyyy')}
                                    half
                                />
                            </FormToggleGroupPreview>

                            <FormToggleGroupEdit flex status={updateStatus}>
                                <TextInput
                                    label="Title"
                                    name="name"
                                    half
                                />
                                <TextInput 
                                    label="Sottotitolo"
                                    name="subtitle"
                                />
                                <TextAreaInput
                                    label="Descrizione"
                                    name="description"
                                />
                                <SelectInput
                                    label="Categoria"
                                    name="categories"
                                    isMulti
                                    half
                                    options={categoriesOptions}
                                />
                                <TextInput
                                    label="Durata tutorial (minuti)"
                                    name="duration"
                                    type="number"
                                    half
                                />
                                <DateInput
                                    label="Data di pubblicazione"
                                    name="publishedAt"
                                    minDate={new Date()}
                                    half
                                />
                            </FormToggleGroupEdit>
                        </FormToggleGroup>
                    </MainFormContent>
                </MainForm>

                <TitleContainer>
                    <SectionTitle text="Video" />
                </TitleContainer>

                <MainForm
                    onSubmit={editVideoElement}
                    data={data && {url: data.video?.url, duration: data.video?.duration}}
                >
                    <MainFormContent>
                        <FormToggleGroup>
                            <FormToggleGroupPreview>
                                <SimpleFormField
                                    label="Url del video"
                                    name="url"
                                    half
                                />
                                <SimpleFormField
                                    label="Durata del video"
                                    name="duration"
                                    half
                                />
                            </FormToggleGroupPreview>
                            <FormToggleGroupEdit flex status={updateStatus}>
                                <TextInput
                                    label="Url del video"
                                    name="url"
                                    half
                                />
                                <TextInput
                                    label="Durata del video (in minuti)"
                                    name="duration"
                                    half
                                />
                            </FormToggleGroupEdit>
                        </FormToggleGroup>
                    </MainFormContent>
                </MainForm>

                <TitleContainer>
                    <SectionTitle text="Step del tutorial" />
                </TitleContainer>

                <ButtonsRow fill marginBottom>
                    <MainButton
                        border
                        text={`Aggiungi step`}
                        action={() => setAddPopup(true)}
                    />
                </ButtonsRow>

                {data?.steps?.length > 0 && (
                    <DragListing
                        title="Trascina gli elementi per ordinare il tutorial"
                        list={data?.steps?.map((step) => {
                            return {
                                ...step,
                                label: step?.name,
                            }
                        })}
                        onDragEndCallback={reorderElements}
                        onDelete={removeStep}
                        onEdit={setEditPopup}
                    />
                )}

                <Divider/>

                <EditImageGroup data={data?.cover} onSubmit={editCover} status={updateImageStatus}/>

                <TitleContainer>
                    <SectionTitle text="Testo articolo" />
                </TitleContainer>

                <MainForm
                    onSubmit={editElement}
                    data={ data && getSubFormData(data, ["description"])} >
                    <MainFormContent>
                        <FormToggleGroup>
                            <FormToggleGroupPreview>
                                <SimpleFormField
                                    label="Descrizione completa"
                                    name="content"
                                />
                            </FormToggleGroupPreview>

                            <FormToggleGroupEdit flex status={updateStatus}>
                                <TextAreaInput
                                    label="Descrizione completa"
                                    name="content"
                                />
                            </FormToggleGroupEdit>
                        </FormToggleGroup>
                    </MainFormContent>
                </MainForm>

                <ColumnContainer lowMargin>
                    <Column>
                        <TitleContainer>
                            <SectionTitle text="Tool"/>
                        </TitleContainer>
                        {data?.tools?.length > 0 &&
                            <SimpleTable
                                match={match}
                                history={history}
                                data={data?.tools}
                                head={tableToolHead}
                                isLoading={isLoading}
                            />
                        }
                        <SimpleLink text="+ Aggiungi tool" action={() => setToolPopup(true)}/>
                    </Column>
                </ColumnContainer>

                <Divider/>

                <ColumnContainer>
                    <Column>
                        <TitleContainer>
                            <SectionTitle text="Supply"/>
                        </TitleContainer>
                        {data?.supply?.length > 0 &&
                            <SimpleTable
                                match={match}
                                history={history}
                                data={data?.supply}
                                head={tableSupplyHead}
                                isLoading={isLoading}
                            />
                        }
                        <SimpleLink text="+ Aggiungi supply" action={() => setSupplyPopup(true)}/>
                    </Column>
                </ColumnContainer>

            </LoadingContainer>

            {/* NUOVO STEP */}
            <TutorialAddStep
                visible={addPopup}
                setVisible={setAddPopup}
                addStep={addStep}
            />

            {/* MODIFICA STEP */}
            <TutorialEditStep
                visible={editPopup}
                setVisible={setEditPopup}
                tutorialId={elementId}
            />

            <NormalPopup
                visible={toolPopup}
                setVisible={setToolPopup}
                title="Aggiungi tool"
            >
                <MainForm onSubmit={(data) => addElementHandler(data, 'tool')}>
                    <MainFormContent flex>
                        <TextInput name="name" label="Nome" required/>
                    </MainFormContent>

                    <Divider/>

                    <ButtonsRow fill>
                        <MainButton text="Annulla" border action={() => setToolPopup()}/>
                        <MainButton text="Aggiungi" submit status={updateStatus}/>
                    </ButtonsRow>
                </MainForm>
            </NormalPopup>

            <NormalPopup
                visible={supplyPopup}
                setVisible={setSupplyPopup}
                title="Aggiungi supply"
            >
                <MainForm onSubmit={(data) => addElementHandler(data, 'supply')}>
                    <MainFormContent flex>
                        <TextInput name="name" label="Nome" required/>
                    </MainFormContent>

                    <Divider/>

                    <ButtonsRow fill>
                        <MainButton text="Annulla" border action={() => setSupplyPopup()}/>
                        <MainButton text="Aggiungi" submit status={updateStatus}/>
                    </ButtonsRow>
                </MainForm>
            </NormalPopup>

            <NormalPopup
                visible={deleteToolPopup}
                setVisible={setDeleteToolPopup}
                title="Elimina tool"
            >
                <TitleContainer>
                    <Paragraph text="Sei sicuro di voler eliminare questo elemento?"/>
                </TitleContainer>
                <Divider/>
                <ButtonsRow fill>
                    <MainButton text="Annulla" border action={() => setDeleteToolPopup()}/>
                    <MainButton text="Elimina" action={() => removeElementHandler(deleteToolPopup, 'tool')} status={updateStatus}/>
                </ButtonsRow>
            </NormalPopup>

            <NormalPopup
                visible={deleteSupplyPopup}
                setVisible={setDeleteSupplyPopup}
                title="Elimina supply"
            >
                <TitleContainer>
                    <Paragraph text="Sei sicuro di voler eliminare questo elemento?"/>
                </TitleContainer>
                <Divider/>
                <ButtonsRow fill>
                    <MainButton text="Annulla" border action={() => setDeleteSupplyPopup()}/>
                    <MainButton text="Elimina" action={() => removeElementHandler(deleteSupplyPopup, 'supply')} status={updateStatus}/>
                </ButtonsRow>
            </NormalPopup>
        </MainContainer>
    );
}
