import axios from "../_defaults/user";

import { cleanRoomBeforeUpdate } from "../../helpers/rooms";

export async function createRoom({ data, elementId }) {

    try {
        const payload = cleanRoomBeforeUpdate(data);
        const response = await axios.post(
            `/admin-hotels/${elementId}/rooms`,
            payload
        );
        return response?.data;
    } catch(error) {
        throw error;
    }
}

export async function getRooms({ params, elementId }) {
    try {
        const response = await axios.get(
            `/admin-hotels/${elementId}/rooms`,
            {
                params,
            }
        );

        return response?.data;
    } catch (error) {
        if (error.response?.status === 404) {
            return [];
        }
        throw error;
    }
}

export async function getRoom({ roomId, elementId }) {
    try {
        const response = await axios.get(
            `/admin-hotels/${elementId}/rooms/${roomId}`
        );

        return response?.data;
    } catch (error) {
        throw error;
    }
}

export async function updateRoom({ roomId, elementId, data }) {
    try {
        const payload = cleanRoomBeforeUpdate(data);
        const response = await axios.post(
            `/admin-hotels/${elementId}/rooms/${roomId}`,
            payload
        );
        return response?.data;
    } catch (error) {
        throw error;
    }
}

export async function getPutUrl({ elementId, file, roomId }) {
    const response = await axios.post(
        `/admin-hotels/${elementId}/rooms/${roomId}/images`,
        {
            contentType: file.type,
            extension: file.name?.split(".")[1],
        }
    );

    if (response.data) {
        return response.data;
    }

    return response;
}

export async function getRoomVariations({ roomId, elementId }) {
    try {
        const response = await axios.get(
            `/admin-hotels/${elementId}/rooms/${roomId}/variations`
        );

        return response?.data;
    } catch (error) {
        if (error.response?.status === 404) {
            return [];
        }
        throw error;
    }
}

export async function updateRoomVariation({ roomId, elementId, data }) {
    try {
        const payload = cleanRoomBeforeUpdate(data);
        const response = await axios.post(
            `/admin-hotels/${elementId}/rooms/${roomId}/variations`,
            payload,
            {
                params: {
                    variationId: data._id,
                },
            }
        );

        return response?.data;
    } catch (error) {
        return error;
    }
}
