import React, { useState } from "react";
import { Link } from "react-router-dom";
import qs from "query-string";
import { usePaginatedQuery } from 'react-query';
import moment from "moment";

import {
    MainContainer,
    TitleContainer,
    MainTitle,
    ColumnContainer,
    Column,
    SimpleTable
} from "@beacharound/beacharound-ui";

import { getInformationsRequests } from "../../../api/informationsRequests";

export default function List({ match, location, history }) {
    const { page } = location.search ? qs.parse(location.search) : { page: "0" };
    const { type } = match.params;

    const [dateFilters, setDateFilters] = useState(); // eslint-disable-line no-unused-vars
    const [limit, setLimit] = useState(25);
    const [search, setSearch] = useState();
    const [params, setParams] = useState({}); // eslint-disable-line no-unused-vars

    const options = {
        skip: Number(page) * limit,
        limit,
        sort: { name: 1 },
        search,
        params: {
            ...params,
            type: type?.toUpperCase()
        }
    }

    const { data, isLoading } = usePaginatedQuery(
        ["informationsRequests", options],
        () =>
            getInformationsRequests({
                params: options,
            })
    );

    const tableHead = [{
        text: "Nome",
        key: ["lastName", "firstName"],
        sort: "text",
        flex: 0.8,
        highlight: true,
    },{
        text: "Attività",
        
        key: "activityName",
        sort: true,
    },{
        text: "Email",
        key: "email",
    },{
        text: "Data",
        key: "createdAt",
        default: true,
        sort: "date",
        ascending: false,
        flex: 0.5,
        parse: (value) => moment(value).format("L"),
    },{
        flex: 0.3,
        actions: (item) => {
            return [{
                icon: "arrow-right",
                component: Link,
                to: `${match.url}/${item._id}`,
            }]
        }
    }]

    const tableRows = data?.filter((item) => {
        if (!dateFilters) return item;
        // controlliamo che la data dell'oggetto sia uguale o più recente di quella indicata
        return moment(item.createdAt).isSameOrAfter(dateFilters);
    })
    .map((item) => {
        return {
            ...item,
            ...item.contact,
        };
    });

    return (
        <MainContainer large>

            <ColumnContainer noMargin>
                <Column>
                    <TitleContainer>
                        <MainTitle text="Richieste di informazioni" />
                    </TitleContainer>
                </Column>
            </ColumnContainer>

            <ColumnContainer>
                <Column>
                    <SimpleTable
                        match={match}
                        history={history}
                        setLimit={setLimit}
                        navigation
                        search
                        // showFilters
                        data={tableRows}
                        head={tableHead}
                        // availableFilters={availableFilters}
                        isLoading={isLoading}
                        page={page}
                        searched={search}
                        onSearch={(value) => {
                            setSearch(value);
                            history.push(match.url);
                        }}
                        onSearchClear={() => {
                            setSearch(undefined);
                            history.push(match.url);
                        }}
                    />
                </Column>
            </ColumnContainer>
        </MainContainer>
    );
}
