// regions

import List from '../../pages/Places/Regions/List';
import Detail from '../../pages/Places/Regions/Detail';

export const subRoutesRegions = [
    {
        path: "",
        component: List,
        exact: true,
    }
].map((item) => {
    return {
        ...item,
        path: `/regions${item.path}`,
        private: true
    };
});

export const routesRegions = [
    {
        path: "/:elementId",
        component: Detail,
    }
].map((item) => {
    return {
        ...item,
        path: `/regions${item.path}`,
        private: true
    };
});