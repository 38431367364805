import React, { useState } from 'react';
import { searchActivities } from '../../api/search';
import { getOptionsFromActivities } from "../../helpers/search";
import { useMutation, useQuery, useQueryCache } from 'react-query';

import {
    NormalPopup,
    MainForm,
    MainFormContent,
    TextInput,
    RateInput,
    Divider,
    ButtonsRow,
    MainButton,
    TitleContainer,
    Paragraph,
    FullSearchSelect
} from "@beacharound/beacharound-ui";
import { createReviewMultiple } from '../../api/reviews';

const constraints = {
    voteMin: { presence: true },
    voteMax: { presence: true },
    reviewsLength: { presence: true },
};

export default function CreateMultipleReviews({
    reviewsPopup,
    setReviewsPopup
}) {

    const cache = useQueryCache();
    const [ activity, setActivity ] = useState();
    const [ activityError, setActivityError ] = useState();

    const activitiesOptions = {
        limit: 10,
    };

    const { data: activities } = useQuery(["activities", "search", activitiesOptions], () =>
        searchActivities({ params: activitiesOptions })
    );

    async function loadActivitiesOptions(search, callback) {
        const searchActivitiesOptions = {
            ...activitiesOptions,
            search,
        };

        const result = await searchActivities({
            params: searchActivitiesOptions,
        });
        cache.setQueryData(["activities", "search", searchActivitiesOptions], result);

        return callback(getOptionsFromActivities(result, { valueKey: "_id" }));
    }

    const [createMultiple, { status: createStatus }] = useMutation( createReviewMultiple );

    async function submitHandler(data) {

        if (!activity) {
            return setActivityError(true);
        }

        try {
            await createMultiple({
                data: {
                    ...data,
                    activity: activity._id,
                    activityType: activity.type
                }
            })
            return setReviewsPopup(false);
        } catch(error) {
            console.log(error)
            throw error;
        }
        
    }

    return (
        <NormalPopup
            visible={reviewsPopup}
            setVisible={setReviewsPopup}
            title="Crea recensioni multiple"
        >
            <MainForm onSubmit={submitHandler} constraints={constraints}>
                <MainFormContent>
                    <FullSearchSelect
                        label="Scegli un'attività"
                        isAsync
                        loadOptions={loadActivitiesOptions}
                        defaultOptions={getOptionsFromActivities(activities)}
                        onChangeInput={(name, value, option) =>
                            setActivity(option)
                        }
                        buttonText="Cerca attività"
                    />
                    <TextInput
                        type="number"
                        label="Numero di recensioni"
                        name="reviewsLength"
                        half
                    />
                    <RateInput label="Valutazione massima" name="voteMax" />
                    <RateInput label="Valutazione minima" name="voteMin" />
                </MainFormContent>
                <Divider />
                {activityError && (
                    <TitleContainer>
                        <Paragraph
                            text="Devi prima selezionare un'attività"
                            color="red"
                        />
                    </TitleContainer>
                )}
                <ButtonsRow fill>
                    <MainButton
                        border
                        text="Annulla"
                        action={() => setReviewsPopup(false)}
                    />
                    <MainButton text="Crea" submit status={createStatus} />
                </ButtonsRow>
            </MainForm>
        </NormalPopup>
    );
}