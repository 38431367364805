export const hotelTypes = [{
    label: 'Hotel',
    value: 'hotel'
}, {
    label: 'Ostello',
    value: 'hostel'
}, {
    label: 'Appartamento',
    value: 'apartment'
},{
    label: 'Casa',
    value: 'house'
},{
    label: 'Stanza privata',
    value: 'house-room'
},{
    label: 'Villaggio',
    value: 'village'
}]