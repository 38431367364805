import React from 'react';
import { Link } from 'react-router-dom';

import { Divider, ButtonsRow, MainButton, MainContainer, MainTitle, Paragraph, SubTitle, TitleContainer } from '@beacharound/beacharound-ui';


export default function SignupThanks() {

    return (

        <MainContainer>

            <TitleContainer>
                <MainTitle text="Registrazione completata!" />
                <SubTitle text="Grazie per esserti registrato" />
                <Paragraph text="Riceverai una mail per verificare il tuo account, nel frattempo puoi iniziare ad utilizzare Beacharound Business!"/>
            </TitleContainer>

            <Divider/>

            <ButtonsRow spaceBetween>
                <MainButton text="Crea subito la tua prima attività" component={Link} to="/new/activityType"/>
            </ButtonsRow>

        </MainContainer>
    )
}
