import { returnError } from "./_defaults/error";
import axios from "./_defaults/user";

export async function getServices({ type, params }) {
    try {
        const response = await axios.get(`/admin-services/${type}`, {
            params,
        });
        if (response?.data) {
            return response?.data;
        }
    } catch (error) {
        returnError(error);
    }
}

export async function createService({ type, data }) {
    try {
        const response = await axios.post(`/admin-services/${type}`, data);
        return response?.data;
    } catch (error) {
        return returnError(error);
    }
}

export async function deleteService({ type, elementId }) {
    try {
        const response = await axios.delete(`/admin-services/${type}/${elementId}`);
        return response?.data;
    } catch (error) {
        return returnError(error);
    }
}

export async function getService({ type, elementId }) {
    try {
        const response = await axios.get(
            `/admin-services/${type}/${elementId}`
        );
        if (response?.data) {
            return response?.data;
        }
    } catch (error) {
        returnError(error);
    }
}

export async function updateService({ type, elementId, data }) {
    try {
        const response = await axios.post(
            `/admin-services/${type}/${elementId}`,
            data
        );
        if (response?.data) {
            return response?.data;
        }
    } catch (error) {
        returnError(error);
    }
}

export async function serviceActivation({ type, elementId, published }) {
    try {
        const response = await axios.post(
            `/admin-services/${type}/${elementId}/activate`,
            { published }
        );
        if (response?.data) {
            return response?.data;
        }
    } catch (error) {
        returnError(error);
    }
}
