import axios from './_defaults/user';

export async function getSearchTerms({ params }) {
    try {
        const response = await axios.get(`/admin-searchTerms`, { params });

        if (response.data) {
            return response.data;
        }
        
    } catch(error) {

        if (error?.response?.status === 404) {
            return [];
        }

        throw error
    }
}
