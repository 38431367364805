import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from "react-query";
import moment from 'moment';

import {
    MainContainer,
    TitleContainer,
    MainTitle,
    SubTitle,
    SectionTitle,
    MainFormContent,
    SimpleField,
    LoadingContainer
} from "@beacharound/beacharound-ui";

import { getReservation } from "../../../../../api/reservations/index";
import { getPeopleString, getStatusString, getStatusColor } from '../../../../../helpers/quotations';
import StatusHistory from '../../../../../components/Status/StatusHistory';
import DetailUserSection from '../../../../../components/Users/DetailUserSection';
import DetailCustomerSection from '../../../../../components/Users/DetailCustomerSection';


export default function RevervationsOffersDetail({ match }) {

    const { elementId, reservationId } = match.params;
    
    const { data: reservation, isLoading } = useQuery(["reservations", elementId, reservationId], () =>
        getReservation({reservationId})
    );

    return (

        <MainContainer>

            <TitleContainer>
                <MainTitle text="Dettaglio prenotazione" />
                <SubTitle text="< Torna alle prenotazioni" component={Link} to={match.url.replace(`/${reservationId}`, "")}/>
            </TitleContainer>

            <LoadingContainer isLoading={isLoading}>
                <DetailUserSection user={reservation?.user} />
                <DetailCustomerSection customer={reservation?.customer} />

                <TitleContainer>
                    <SectionTitle text="Prenotazione" />
                </TitleContainer>

                <MainFormContent>
                    <SimpleField
                        label="Camera"
                        value={reservation?.products?.[0]?.product?.name}
                        half
                    />
                    <SimpleField
                        label="Persone"
                        value={getPeopleString(reservation?.details)}
                        half
                    />
                    <SimpleField
                        label="Checkin"
                        value={moment(reservation?.checkin).format("L")}
                        half
                    />
                    <SimpleField
                        label="Checkout"
                        value={moment(reservation?.checkout).format("L")}
                        half
                    />
                    <SimpleField
                        label="Stato"
                        value={getStatusString(reservation?.status)}
                        valueColor={getStatusColor(reservation?.status)}
                        half
                    />
                </MainFormContent>

                <MainContainer>
                    <LoadingContainer isLoading={isLoading}>
                        <StatusHistory item={reservation} />
                    </LoadingContainer>
                </MainContainer>

            </LoadingContainer>

        </MainContainer>

    )
}