import React, { useState } from "react";
import qs from "query-string";
import { usePaginatedQuery, useQueryCache, useMutation } from "react-query";
import { Link } from "react-router-dom";

import {
    MainContainer,
    TitleContainer,
    MainTitle,
    ColumnContainer,
    Column,
    SimpleTable,
    MainButton,
    ButtonsRow,
    Divider,
    Paragraph,
    NormalPopup
} from "@beacharound/beacharound-ui";
import { formatDate } from "@beacharound/beacharound-ui-helpers";

import { getTransfers, removeTransfer } from "../../../api/payments";

export default function Payments({ history, match, location }) {

    const { page } = location.search ? qs.parse(location.search) : { page: "0" };

    const [dateFilter, setDateFilter] = useState();
    const [search, setSearch] = useState();
    const [limit, setLimit] = useState(25);
    const [params, setParams] = useState({});
    const [sort, setSort] = useState();
    const [dateParams, setDateParams] = useState({});
    const [cancelPopup, setCancelPopup] = useState()

    let fromDateParam;
    let toDateParam;

    if (dateParams?.fromDate) {
        fromDateParam = dateParams?.fromDate;
    }

    if (dateParams?.toDate) {
        toDateParam = dateParams?.toDate;
    }

    if (dateFilter?.[0]) {
        fromDateParam = dateFilter?.[0];
    }

    if (dateFilter?.[1]) {
        toDateParam = dateFilter?.[1];
    }

    const options = {
        skip: Number(page) * limit,
        limit,
        search,
        fromDate: fromDateParam && formatDate(new Date(fromDateParam), "yyyy-MM-dd"),
        toDate: toDateParam && formatDate(new Date(toDateParam), "yyyy-MM-dd"),
        sort: sort || { statusUpdatedAt: -1 },
        params: {
            status: { $ne: "CANCELED" },
            ...params
        }
    }

    const { data, isLoading } = usePaginatedQuery(["transfers", options], () =>
        getTransfers({ params: options })
    )

    const cache = useQueryCache()

    const [remove, { status: removeStatus }] = useMutation(removeTransfer, {
        onSuccess: () => {
            cache.invalidateQueries(["transfers"]);
            setCancelPopup()
        }
    });

    function cancelHandler() {
        return remove({
            elementId: cancelPopup?._id
        });
    }

    const tableHead = [{
        text: "Numero",
        key: "number",
        highlight: true,
        default: true,
        flex: 0.3
    }, {
        text: "Stato",
        key: "status",
        sort: true,
        highlight: true,
        default: true,
        flex: 0.5
    }, {
        text: "Attività",
        key: "activity",
        parse: (activity) => activity?.name || '-',
        highlight: true,
        default: true,
        flex: 1
    }, {
        text: "Creato il",
        key: "createdAt",
        flex: 0.3,
        hasMobileLabel: true,
        parse: (value) => formatDate(new Date(value), 'dd/MM/yy')
    }, {
        text: "Bonifico",
        key: "transfer",
        flex: 0.4,
        hasMobileLabel: true,
        price: true,
        parse: (value) => value?.total && `${Number(value?.total).toFixed(2)} €`
    }, {
        text: "Fattura",
        key: "invoice",
        flex: 0.4,
        hasMobileLabel: true,
        price: true,
        parse: (value) => value?.total && `${Number(value?.total).toFixed(2)} €`
    }, {
        flex: 0.4,
        actions: (item) => {
            if (["CANCELED", "TRANSFERED"].includes(item.status)) {
                return [{
                    icon: "arrow-right",
                    component: Link,
                    to: `${match.url}/${item._id}`
                }]
            }
            return [{
                icon: "delete",
                action: () => setCancelPopup(item)
            }, {
                icon: "arrow-right",
                component: Link,
                to: `${match.url}/${item._id}`
            }]
        }
    }
    ]

    const tableRows = data || [];
    // Imposto il filtro per data
    // function dateFilterHandler(dates) {
    //     setDateFilter(dates);
    //     setDateParams({});
    // }

    function filterActionHandler(name, value, options) {
        setParams(options?.isRemoving ? {
            ...params,
            [name]: undefined,
        } : {
            ...options,
            [name]: value,
        })

        return history.push(`${match.url}`);
    }

    function filterActionDateHandler(name, value, options) {
        setDateParams(options?.isRemoving ? {
            [name]: undefined,
        } : {
            ...options,
            [name]: formatDate(new Date(value), "yyyy-MM-dd"),
        })
        setDateFilter()
        return history.push(`${match.url}`);
    }

    const availableFilters = [{
        label: "Creati dal",
        name: "fromDate",
        type: "date",
        action: filterActionDateHandler,
        parse: (date) => date && formatDate(new Date(date), "dd MMM"),
        half: true,
    }, {
        label: "Pagate al",
        name: "toDate",
        type: "date",
        action: filterActionDateHandler,
        parse: (date) => date && formatDate(new Date(date), "dd MMM"),
        half: true,
    }, {
        label: "Stato",
        name: "status",
        type: "select",
        options: [{
            value: "CANCELED",
            label: "Cancellati",
        }, {
            value: "PENDING",
            label: "In trasferimento",
        }, {
            value: "TRANSFERED",
            label: "Eseguiti",
        }],
        action: filterActionHandler,
        half: true,
    }]


    return (
        <MainContainer large>
            <ColumnContainer lowMargin>
                <Column>
                    <TitleContainer noMargin>
                        <MainTitle text="Pagamenti" />
                    </TitleContainer>
                </Column>
            </ColumnContainer>

            <ColumnContainer>
                <Column>
                    <SimpleTable
                        match={match}
                        history={history}
                        setLimit={setLimit}
                        elementsLimit={50}
                        navigation
                        showFilters
                        data={tableRows}
                        head={tableHead}
                        availableFilters={availableFilters}
                        isLoading={isLoading}
                        page={page}
                        searched={search}
                        sort={sort}
                        onSort={setSort}
                        onSearch={(value) => {
                            setSearch(value);
                            history.push(match.url);
                        }}
                        onSearchClear={() => {
                            setSearch(undefined);
                            history.push(match.url);
                        }}
                    // hasDownload
                    // downloadName="prenotazioni"
                    />
                </Column>
            </ColumnContainer>

            {/* CANCELLA */}
            <NormalPopup
                visible={cancelPopup}
                setVisible={setCancelPopup}
                title="Cancella Pagamento"
            >
                <TitleContainer>
                    <Paragraph text={`Sei sicuro di voler cancellare questo pagamento?`} />
                </TitleContainer>
                <Divider />
                <ButtonsRow fill>
                    <MainButton
                        text="Annulla"
                        border
                        action={() => setCancelPopup()}
                    />
                    <MainButton
                        text="Conferma"
                        action={cancelHandler}
                        status={removeStatus}
                    />
                </ButtonsRow>
            </NormalPopup>

        </MainContainer>
    );
}
