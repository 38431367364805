import convert from "convert-units";

export function getLocationsOptionsFromData(data, options) {
    return data?.map((item) => {
        const { _id,
            name,
            address,
            slug,
            slugCity,
            type,
            subType,
            distance,
            distanceFrom,
            beaches,
            hotels,
            search
        } = item;
        
        let sublabel;

        if (options?.withBeaches && beaches) {
            const total = beaches.find(
                ({ total }) => total !== undefined
            );
            const searched = beaches.find(
                ({ searched }) => searched !== undefined
            );

            if (total) {
                sublabel = `${total.total} spiagge in questo luogo`;
            }

            if (total && searched) {
                sublabel = `${searched.searched} spiagge su ${total.total} in questo luogo`;
            }

            if (!total && !searched) {
                sublabel = "Nessuna spiaggia trovata in questo luogo";
            }
        }

        if (options?.withHotels && hotels) {
            const total = hotels.find(
                ({ total }) => total !== undefined
            );
            const searched = hotels.find(
                ({ searched }) => searched !== undefined
            );

            if (total) {
                sublabel = `${total.total} strutture in questo luogo`;
            }

            if (total && searched) {
                sublabel = `${searched.searched} strutture su ${total.total} in questo luogo`;
            }

            if (!total && !searched) {
                sublabel = "Nessuna struttura trovata in questo luogo";
            }
        }

        let icon = "spot";
        let readeableType;
        let readeableLabel = name;
        let readeableDistance = distance && convert(distance).from("m").toBest();

        switch (type) {
            case "beaches":
                icon = subType == "public" ? "public" : "beach";
                readeableType =
                    subType == "public"
                        ? "spiaggia pubblica"
                        : "spiaggia";
                break;
            case "hotels":
                icon = "hotel";
                readeableType = "hotel";
                break;
            case "pointsOfInterest":
                icon = "interest-point";
                readeableType = "luogo";
                break;
            case "cities":
                icon = "city";
                readeableType = "città";
                break;
            default:
                break;
        }

        if (options?.hasGeolocalization && !sublabel && readeableDistance) {
            sublabel = `${readeableType} a ${readeableDistance.val?.toFixed(0)} ${readeableDistance.unit} da te`;
        }

        if (
            (!sublabel || (sublabel && options?.hasGeolocalization)) &&
            readeableDistance &&
            distanceFrom
        ) {
            sublabel = `${readeableType} a ${readeableDistance.val?.toFixed(0)} ${readeableDistance.unit} da ${distanceFrom}`;
        }

        if (!sublabel) {
            sublabel = readeableType;
        }

        if(address?.district) {
            readeableLabel = `${name} - ${address?.district}`;
        }

        return {
            label: readeableLabel,
            sublabel,
            value: options?.value ? item[options.value] : (slugCity || slug),
            type,
            subType,
            icon,
            search,
            _id,
        };
    }) || []
}
