import React from "react";
import { useMutation, useQueryCache } from "react-query";

import {
    NormalPopup,
    ButtonsRow,
    MainButton,
    Paragraph,
    TitleContainer,
    MainForm,
    MainFormContent,
    TextInput,
    CheckboxInput,
    Divider,
} from "@beacharound/beacharound-ui";

import { inviteUser } from "../../../api/users/admin";
// import InputError from "@beacharound/beacharound-ui/src/components/Form/lib/InputError";

const roleOptions = [{
    label: 'Addetto finanze',
    value: 'financial'
},{
    label: 'Editoriale',
    value: 'editor'
},{
    label: 'Servizio clienti',
    value: 'costumer-care'
}]


function constraints(values) {
    
    if(!values.email) {
        return {
            email: ["Inserisci un'email valida"]
        }
    }
    
    const rolesData = Object.keys(values).filter(key => key.includes("role-")).map(key => values[key] && key.replace('role-', '')).filter(role => role)

    if(rolesData.length === 0) {
        return {
            "role-financial": ["Scegli almeno un ruolo per l'utente"]
        }
    }

    return undefined
}

export default function SendInvitationPopup({
    active,
    setActive,
}) {
    const cache = useQueryCache();

    const [invite, { status }] = useMutation(inviteUser, {
        onSuccess: () => {
            cache.invalidateQueries("userInvitations");
            return setActive(undefined);
        },
    });

    function sendInvitation(data) {

        const rolesData = Object.keys(data).filter(key => key.includes("role-")).map(key => data[key] && key.replace('role-', '')).filter(role => role)

        invite({
            email: data.email,
            roles: rolesData
        });
    }

    return (
        <NormalPopup
            visible={active}
            setVisible={() => setActive(false)}
            title="Invita amministratore"
            large
        >
            <TitleContainer>
                <Paragraph text={"Scegli il ruolo e invia una mail di invito ad un utente che diventerà amministratore"}/>
            </TitleContainer>

            <MainForm onSubmit={sendInvitation} constraints={constraints}>
                <MainFormContent>
                    <TextInput name="email" label="Email" />
                    {roleOptions.map((role, index) => {
                        return (
                            <CheckboxInput name={`role-${role.value}`} label={`Ruolo di ${role.label}`} key={index}/>
                        )
                    })}
                </MainFormContent>

                <Divider />

                <ButtonsRow fill>
                    <MainButton
                        text="Annulla"
                        border
                        action={() => setActive(false)}
                    />
                    <MainButton text="Invita" submit status={status} />
                </ButtonsRow>
            </MainForm>
        </NormalPopup>
    );
}
