import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useMutation, useQueryCache } from "react-query";
import Select from 'react-select';

import {
    MainContainer,
    TitleContainer,
    MainTitle,
    MainForm,
    MainFormContent,
    TextInput,
    SelectInput,
    ButtonsRow,
    MainButton,
    Divider,
    SectionTitle,
} from "@beacharound/beacharound-ui";

import { createService } from "../../../api/services";

const constraints = {
    name: { presence: true },
    type: { presence: true }
}

const typeOptions = [
    { label: "Spiaggie", value: "beaches" },
    { label: "Hotel", value: "hotels" },
    { label: "Luoghi", value: "places" }
]

export default function Create({ match, history }) {

    const { type } = match.params;
    const cache = useQueryCache();
    const [published, setPublished] = useState();

    const [create, { status }] = useMutation(
        createService,
        {
            onSuccess: (data) => {
                cache.invalidateQueries("services");
                cache.setQueryData(["services", data._id], data);
                return history.push(match.url.replace("create", `edit/${data?._id}`))
            },
        }
    )

    async function save(data) {
        try {
            await create({ data: { ...data, published }, type });
        } catch(error) {
            console.log(error)
        }
    }

    return (
        <MainContainer marginBottom>
            <TitleContainer>
                <MainTitle text={type === 'service' ? "Nuovo servizio" : "Nuova accessibilità"} />
            </TitleContainer>

            <MainForm onSubmit={save} constraints={constraints}>
                <TitleContainer>
                    <SectionTitle text="Dati generali" />
                </TitleContainer>
                <MainFormContent flex>
                    <TextInput
                        label="Nome servizio"
                        name="name"
                        half
                    />
                    <SelectInput
                        Component={Select}
                        label="Tipologia"
                        name="type"
                        options={typeOptions}
                        isMulti
                    />
                </MainFormContent>

                <Divider />

                <ButtonsRow fill>
                    <MainButton
                        text="Annulla"
                        border
                        component={Link}
                        to={`${match.url.replace(`/create`, "")}`}
                    />
                    <MainButton
                        border
                        submit
                        actionOnSubmit={() => setPublished(false)}
                        text="Salva bozza"
                    />
                    <MainButton
                        text={type === 'service' ? "Crea servizio" : "Crea accessibilità"}
                        actionOnSubmit={() => setPublished(true)}
                        submit
                        status={status}
                    />
                </ButtonsRow>
            </MainForm>
        </MainContainer>
    );
}
