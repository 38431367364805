import React from "react";

import { getBedName } from "@beacharound/beacharound-ui-helpers";
import {
    SectionTitle,
    TitleContainer,
    Tile,
    SimpleLink,
    ColumnContainer,
    Column,
    InputError,
} from "@beacharound/beacharound-ui";

export default function RoomBedsListEdit({ title, beds, setBedPopup, error }) {

    return (
        <React.Fragment>
            <TitleContainer>
                <SectionTitle text={title || "Letti"} />
            </TitleContainer>

            <ColumnContainer>
                <Column>
                    {beds?.map((bed, index) => {
                        return (
                            <Tile
                                key={index}
                                title={getBedName(bed.type)}
                                text={`Quantità: ${bed?.quantity}`}
                                buttonAction={() =>
                                    setBedPopup({ ...bed, index })
                                }
                                buttonLinkText="Modifica"
                                buttonRight
                            />
                        );
                    })}

                    <SimpleLink
                        text="Aggiungi letto"
                        action={() => setBedPopup(true)}
                    />

                    {error && <InputError errors={[error]} />}
                </Column>
            </ColumnContainer>
        </React.Fragment>
    );
}
