import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import {
    MainContainer,
    TitleContainer,
    MainTitle,
    SubTitle,
    SectionTitle,
    MainForm,
    MainFormContent,
    FormToggleGroup,
    FormToggleGroupPreview,
    FormToggleGroupEdit,
    SimpleFormField,
    LoadingContainer,
    TextInput,
    Divider,
    TextAreaInput,
    DateInput,
    EditImageGroup,
    ColumnContainer,
    Column,
    ToggleInput
} from '@beacharound/beacharound-ui';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import {
    getOffer,
    updateOffer,
    getPutUrl,
} from "../../../../../api/hotels/offers";

import OfferConditions from "../../../../../components/Hotel/Offers/OfferConditions";
import OfferConditionsPopup from "../../../../../components/Hotel/Offers/OfferConditionsPopup";
import RelatedRooms from "../../../../../components/Hotel/Rooms/RelatedRooms";
import RelatedRoomsPopup from "../../../../../components/Hotel/Rooms/RelatedRoomsPopup";
import { getSubFormData } from '@beacharound/beacharound-ui-helpers';
import { uploadImage } from '../../../../../api/images';

export default function PromosDetail({ match, history }) {


    const { elementId, offerId } = match.params;
    // const [deletePopup, setDeletePopup] = useState(); // eslint-disable-line no-unused-vars
    
    const [conditionsListPopup, setConditionListPopup] = useState();
    const [conditionItemPopup, setConditionItemPopup] = useState();
    
    const [roomsPopup, setRoomsPopup] = useState();

    const { data, isLoading } = useQuery(["hotels", elementId, "offers", offerId], () =>
        getOffer({elementId, offerId})
    );

    const cache = useQueryCache();
    const [update, { status, reset }] = useMutation(updateOffer, {
        onSuccess: (data) => {
            cache.invalidateQueries(["hotels", elementId, "offers"]);
            cache.setQueryData(["hotels", elementId, "offers", data._id], data);
            return history.push(match.url.replace("create", data._id));
        },
    });

    async function editOffer(data, callback) {
        try {
            await update({
                data,
                elementId,
                offerId
            })
            return callback(reset);
        } catch(error) {
            return callback(null, error);
        }
    }

    async function editConditions(conditions) {
        try {
            await update({
                data: {
                    conditions,
                },
                elementId,
                offerId,
            });
        } catch (error) {
            throw error;
        }
    }

    async function editRooms(rooms) {
        try {
            await update({
                data: {
                    relatedProducts: rooms.map(({ _id }) => _id),
                },
                elementId,
                offerId,
            });
        } catch (error) {
            throw error;
        }
    }

    async function editCover(file) {
        try {
            const { url, key } = await getPutUrl({ elementId, offerId, file });
            await uploadImage({ url, file });
            await update({
                elementId,
                offerId,
                data: {
                    cover: {
                        url: key,
                    },
                },
            });
        } catch (error) {
            console.log(error);
        }
    }

    // function deleteOffer() {
    //     setDeletePopup(false);
    // }

    return (
        <MainContainer>

            <ColumnContainer>
                <Column column={2}>
                    <TitleContainer noMargin>
                        <MainTitle text={data?.name || "Dettaglio offerta"} />
                        <SubTitle
                            text="< Torna alle offerte"
                            component={Link}
                            to={match.url.replace(`/${offerId}`, "")}
                        />
                    </TitleContainer>
                </Column>

                <Column column={2}>
                    <ToggleInput
                        flexEnd
                        trueLabel="Pubblicato"
                        falseLabel="Non pubblicato"
                        status={status}
                        changeValue={()=> editOffer({published: !data?.published}, (resetAfter) => resetAfter())}
                        value={data?.published}
                    />
                </Column>
            </ColumnContainer>

            <LoadingContainer isLoading={isLoading}>
                <TitleContainer>
                    <SectionTitle text="Dettagli offerta" />
                </TitleContainer>

                <MainForm
                    onSubmit={editOffer}
                    data={getSubFormData(data, [
                        "name",
                        "price",
                        "description",
                    ])}
                    constraints={{
                        name: { presence: true },
                        description: { presence: true },
                    }}
                >
                    <MainFormContent>
                        <FormToggleGroup>
                            <FormToggleGroupPreview>
                                <SimpleFormField
                                    label="Nome"
                                    name="name"
                                    half
                                />
                                <SimpleFormField
                                    label="Prezzo a persona a notte"
                                    name="price"
                                    price
                                    half
                                />
                                <SimpleFormField
                                    label="Descrizione"
                                    name="description"
                                />
                            </FormToggleGroupPreview>

                            <FormToggleGroupEdit flex status={status}>
                                <TextInput label="Nome" name="name" half />
                                <TextInput
                                    label="Prezzo a persona"
                                    name="price"
                                    type="number"
                                    price
                                    half
                                />
                                <TextAreaInput
                                    label="Descrizione"
                                    name="description"
                                />
                            </FormToggleGroupEdit>
                        </FormToggleGroup>
                    </MainFormContent>
                </MainForm>

                <TitleContainer>
                    <SectionTitle text="Validità" />
                </TitleContainer>

                <MainForm
                    onSubmit={editOffer}
                    data={getSubFormData(data, [
                        "purchasableFromDate",
                        "purchasableToDate",
                    ])}
                >
                    <MainFormContent>
                        <FormToggleGroup>
                            <FormToggleGroupPreview>
                                <SimpleFormField
                                    label="Acquistabile dal"
                                    name="purchasableFromDate"
                                    date
                                    half
                                />
                                <SimpleFormField
                                    label="Acquistabile fino al"
                                    name="purchasableToDate"
                                    date
                                    half
                                />
                            </FormToggleGroupPreview>

                            <FormToggleGroupEdit flex status={status}>
                                <DateInput
                                    label="Acquistabile dal"
                                    name="purchasableFromDate"
                                    half
                                />
                                <DateInput
                                    label="Acquistabile fino al"
                                    name="purchasableToDate"
                                    half
                                />
                            </FormToggleGroupEdit>
                        </FormToggleGroup>
                    </MainFormContent>
                </MainForm>

                <TitleContainer>
                    <SectionTitle text="Prenotazioni" />
                </TitleContainer>

                <MainForm
                    onSubmit={editOffer}
                    data={getSubFormData(data, [
                        "availabilityFromDate",
                        "availabilityToDate",
                    ])}
                >
                    <MainFormContent>
                        <FormToggleGroup>
                            <FormToggleGroupPreview>
                                <SimpleFormField
                                    label="Soggiorno valido dal"
                                    name="availabilityFromDate"
                                    date
                                    half
                                />
                                <SimpleFormField
                                    label="Soggiorno valido fino al"
                                    name="availabilityToDate"
                                    date
                                    half
                                />
                            </FormToggleGroupPreview>

                            <FormToggleGroupEdit flex>
                                <DateInput
                                    label="Soggiorno valido dal"
                                    name="availabilityFromDate"
                                    half
                                />
                                <DateInput
                                    label="Soggiorno valido fino al"
                                    name="availabilityToDate"
                                    half
                                />
                            </FormToggleGroupEdit>
                        </FormToggleGroup>
                    </MainFormContent>
                </MainForm>

                <OfferConditions
                    conditions={data?.conditions}
                    setConditionItemPopup={setConditionItemPopup}
                    setConditionsPopup={setConditionListPopup}
                />

                <Divider />

                <RelatedRooms
                    rooms={data?.relatedProducts}
                    setRoomsPopup={setRoomsPopup}
                />

                <Divider />

                <EditImageGroup data={data?.cover} onSubmit={editCover} />

                {/* <ButtonsRow spaceBetween>
                    <MainButton border text="Elimina" action={deleteOffer} />
                </ButtonsRow> */}
            </LoadingContainer>

            <OfferConditionsPopup
                conditions={data?.conditions}
                setConditions={editConditions}
                conditionsListPopup={conditionsListPopup}
                setConditionListPopup={setConditionListPopup}
                conditionItemPopup={conditionItemPopup}
                setConditionItemPopup={setConditionItemPopup}
                status={status}
            />

            <RelatedRoomsPopup
                rooms={data?.relatedProducts}
                setRooms={editRooms}
                roomsPopup={roomsPopup}
                setRoomsPopup={setRoomsPopup}
                elementId={elementId}
                status={status}
            />
        </MainContainer>
    );
}