import user from './user';
import privateRoutes from './private';
import generalRoutes from './general';

export default [
    ...user,
    ...privateRoutes,
    ...generalRoutes?.filter(r => !["/"].includes(r.path)),
].map(route => ({
    ...route,
    path: `${route.path}`,
}));