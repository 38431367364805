import axios from 'axios';
import Cookies from 'js-cookie';

import { refreshUser } from '../user';

const userAxios = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    headers: {
        'content-type': 'application/json'
    },
    // withCredentials: true
})

userAxios.interceptors.request.use(async (config) => {
    let token = Cookies.get('user-authorization');

    if (!token) {
        const data = await refreshUser();
        token = data.token;
    }

    if (!!token) {
        config.headers.authorization = `Bearer ${token}`;
    }
    return config;
});

export default userAxios;