import axios from '../_defaults/user';

export async function getReservations({ params }) {
    try {
        const response = await axios.get(
            `/admin-reservations`,
            {
                params,
            }
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error?.response?.status === 404) {
            return [];
        }

        throw error;
    }
}

export async function getReservation({ reservationId }) {
    try {
        const response = await axios.get(`/admin-reservations/${reservationId}`);

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error?.response?.status === 404) {
            return [];
        }

        throw error;
    }
}