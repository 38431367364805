import MainLayout from '../layouts/MainLayout/MainLayout';

// Activities
import Activities from '../pages/Activities/index.js';

// SubRoutes
import {subRoutesBeaches, routesBeaches, routesBeachesDetails} from './activities/activitiesBeaches';
import {subRoutesHotels, routesHotels, routesHotelsDetails} from './activities/activitiesHotels';
import {subRoutesOrganizations, routesOrganizations} from './activities/activitiesOrganizations';
import {subRoutesMemberships} from './activities/activitiesMemberships';
import {subRoutesMembershipsPacks, routesMembershipsPacks} from './activities/activitiesMembershipsPacks';
import {subRoutesTransactions, routesTransactions} from './activities/activitiesTransactions';

export default [
    ...routesBeachesDetails,
    ...routesHotelsDetails,
    ...routesBeaches,
    ...routesHotels,
    ...routesOrganizations,
    ...routesMembershipsPacks,
    ...routesTransactions,
    {
        path: "",
        component: Activities,
        layouts: MainLayout,
        routes: [
            ...subRoutesBeaches,
            ...subRoutesHotels,
            ...subRoutesOrganizations,
            ...subRoutesMemberships,
            ...subRoutesMembershipsPacks,
            ...subRoutesTransactions
        ]
    }
].map((item) => {
    
    return {
        ...item,
        routes: item.routes?.map(route => {
            return {
                ...route,
                path: `/activities${route.path}`
            }
        }),
        path: `/activities${item.path}`,
        private: true
    };
});