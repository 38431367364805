import List from '../../pages/Activities/Beaches/List';
import BeachDetail from '../../pages/Activities/Beaches/Detail';
import BeachReservations from '../../pages/Activities/Beaches/Detail/Reservations/List';
import BeachReservationsDetail from '../../pages/Activities/Beaches/Detail/Reservations/Detail';

import BeachUmbrellas from '../../pages/Activities/Beaches/Detail/Umbrellas/List';
import BeachUmbrellasCreate from '../../pages/Activities/Beaches/Detail/Umbrellas/Create';
import BeachUmbrellasDetail from '../../pages/Activities/Beaches/Detail/Umbrellas/Detail';

import BeachDeckchairs from '../../pages/Activities/Beaches/Detail/Deckchairs/List';
import BeachDeckchairsCreate from '../../pages/Activities/Beaches/Detail/Deckchairs/Create';
import BeachDeckchairsDetail from '../../pages/Activities/Beaches/Detail/Deckchairs/Detail';

import BeachParkings from '../../pages/Activities/Beaches/Detail/Parkings/List';
import BeachParkingsCreate from '../../pages/Activities/Beaches/Detail/Parkings/Create';
import BeachParkingsDetail from '../../pages/Activities/Beaches/Detail/Parkings/Detail';

import BeachServices from '../../pages/Activities/Beaches/Detail/Services/List';
import BeachServicesCreate from '../../pages/Activities/Beaches/Detail/Services/Create';
import BeachServicesDetail from '../../pages/Activities/Beaches/Detail/Services/Detail';

import BeachMemberships from '../../pages/Activities/Beaches/Detail/Memberships/List';

import BeachOrganizations from '../../pages/Activities/Beaches/Detail/Organizations/List';
import OrganizationDetail from '../../pages/Activities/Organizations/Detail';

import BeachTransactions from '../../pages/Activities/Beaches/Detail/Transactions/List';
import BeachTransactionsDetail from "../../pages/Financial/Transactions/Detail";

import SettingsDetail from '../../pages/Activities/Beaches/Detail/Settings/Detail';

export const subRoutesBeaches = [
    {
        path: "",
        component: List,
    }
].map((item) => {
    return {
        ...item,
        path: `/beaches/:type${item.path}`,
        private: true
    };
});

export const routesBeachesDetails = [{
    path: "/reservations/:reservationId",
    component: BeachReservationsDetail
},{
    path: "/transactions/:transactionId",
    component: BeachTransactionsDetail
},{
    path: "/umbrellas/create",
    component: BeachUmbrellasCreate,
    exact: true
},{
    path: "/umbrellas/:productId",
    component: BeachUmbrellasDetail
},{
    path: "/deckchairs/create",
    component: BeachDeckchairsCreate,
    exact: true
},{
    path: "/deckchairs/:productId",
    component: BeachDeckchairsDetail
},{
    path: "/parkings/create",
    component: BeachParkingsCreate,
    exact: true
},{
    path: "/parkings/:productId",
    component: BeachParkingsDetail
},{
    path: "/services/create",
    component: BeachServicesCreate,
    exact: true
},{
    path: "/services/:productId",
    component: BeachServicesDetail
},{
    path: "/organizations/:elementId",
    component: OrganizationDetail
}].map(item => {
    return {
        ...item,
        path: `/beaches/:type/:elementId${item.path}`,
        private: true
    }
})

export const routesBeaches = [
    {
        path: "",
        component: BeachDetail,
        routes: [
            {
                path: '/reservations',
                component: BeachReservations
            },{
                path: '/umbrellas',
                component: BeachUmbrellas
            },{
                path: '/deckchairs',
                component: BeachDeckchairs
            },{
                path: '/parkings',
                component: BeachParkings
            },{
                path: '/services',
                component: BeachServices
            },{
                path: '/memberships',
                component: BeachMemberships
            },{
                path: '/transactions',
                component: BeachTransactions
            }, {
                path: '/organizations',
                component: BeachOrganizations
            },{
                path: "/settings",
                component: SettingsDetail
            }
        ]
    }
].map((item) => {
    return {
        ...item,
        routes: item.routes?.map(route => {
            return {
                ...route,
                path: `/beaches/:type/:elementId${route.path}`
            }
        }),
        path: `/beaches/:type/:elementId${item.path}`,
        private: true
    }
})