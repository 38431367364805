import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useQuery, useQueryCache, useMutation } from "react-query";
import moment from 'moment';

import {
    MainContainer,
    TitleContainer,
    MainTitle,
    SubTitle,
    SectionTitle,
    Column,
    MainFormContent,
    SimpleField,
    Divider,
    LoadingContainer,
    Tile,
    DateInput,
    MainForm,
    FormToggleGroup,
    FormToggleGroupEdit,
    FormToggleGroupPreview,
    SimpleFormField,
    TextInput
} from "@beacharound/beacharound-ui";

import { getAddressString, getSubFormData } from "@beacharound/beacharound-ui-helpers";

import { getQuotation, updateQuotation } from "../../../../../api/quotations";
import { getPeopleString, getStatusHistoryString, getStatusString, getStatusColor } from '../../../../../helpers/quotations';

export default function QuotationsDetail({ match }) {

    const { elementId, quotationId } = match.params;

    const { data: quotation, isLoading } = useQuery(["quotations", elementId, quotationId], () =>
        getQuotation({ elementId, quotationId })
    )

    const cache = useQueryCache();
    const [update, { status: updateStatus, errors, reset }] = useMutation(updateQuotation, {
        onSuccess(data) {
            cache.invalidateQueries(["quotations", elementId, quotationId]);
            cache.setQueryData(["quotations", elementId, quotationId, data._id], data);
        }
    })

    async function editQuotation(data, callback) {

        const details = [
            ...(quotation?.details?.filter(({key}) => !["adults", "children", "babies"].includes(key)) || []),
            ...(Object.keys(data).filter(key => ["adults", "children", "babies"].includes(key)).reduce((accumulator, key) => {
                return [...accumulator, {key: key, value: data[key]}]
            }, []))
        ]

        try {
            await update({
                data: {
                    ...data,
                    details
                },
                elementId,
                quotationId
            })
            return callback(reset);
        } catch(error) {
            return callback(null, error)
        }
    }

    return (

        <MainContainer>
            <TitleContainer>
                <MainTitle text="Dettaglio preventivo" />
                <SubTitle
                    text="< Torna ai preventivi"
                    component={Link}
                    to={match.url.replace(`/${quotationId}`, "")}
                />
            </TitleContainer>

            <LoadingContainer isLoading={isLoading}>
                {quotation &&
                    <Fragment>
                        <TitleContainer>
                            <SectionTitle text="Dati utente" />
                        </TitleContainer>
                        <Column>
                            <MainFormContent flex>
                                <SimpleField
                                    label="Nome"
                                    value={`${quotation.user?.profile?.firstName} ${quotation.user?.profile?.lastName}`}
                                    half
                                />
                                <SimpleField
                                    label="Email"
                                    value={quotation.user?.emails?.[0]?.address}
                                    half
                                />
                                {quotation.user?.profile?.address &&
                                    <SimpleField
                                        label="Indirizzo"
                                        value={getAddressString(quotation.user?.profile?.address)}
                                    />
                                }
                            </MainFormContent>
                        </Column>
                        <Divider />
                    </Fragment>
                }

                <TitleContainer>
                    <SectionTitle text="Richiesta di preventivo" />
                </TitleContainer>

                <MainForm
                    onSubmit={editQuotation}
                    data={getSubFormData({
                        ...quotation,
                        ...(quotation?.details?.reduce((accumulator, detail) => {
                            return {
                                ...accumulator,
                                [detail.key]: detail.value
                            }
                        }, {}) || {})
                    }, [
                        "adults",
                        "children",
                        "babies",
                        "checkin",
                        "checkout"
                    ])}
                    constraints={{
                        adults: { presence: true },
                        checkin: { presence: true },
                        checkout: { presence: true }
                    }}
                    errors={errors}
                >
                    <MainFormContent>
                        <FormToggleGroup>
                            <FormToggleGroupPreview>
                                <SimpleFormField
                                    label="Camera"
                                    value={quotation?.products?.[0]?.product?.name}
                                    half
                                />
                                <SimpleFormField
                                    label="Persone"
                                    value={getPeopleString(quotation?.details)}
                                    half
                                />
                                <SimpleFormField
                                    label="Checkin"
                                    value={moment(quotation?.checkin).format("L")}
                                    half
                                />
                                <SimpleFormField
                                    label="Checkout"
                                    value={moment(quotation?.checkout).format("L")}
                                    half
                                />
                                <SimpleFormField
                                    label="Stato"
                                    value={getStatusString(quotation?.status)}
                                    valueColor={getStatusColor(quotation?.status)}
                                    half
                                />
                                <SimpleFormField
                                    label="Ultima informazione"
                                    value={moment(quotation?.statusUpdatedAt).format("L")}
                                    date
                                    half
                                />
                            </FormToggleGroupPreview>

                            <FormToggleGroupEdit flex status={updateStatus}>
                                <DateInput
                                    label="Checkin"
                                    name="checkin"
                                    half
                                />
                                <DateInput
                                    label="Checkout"
                                    name="checkout"
                                    half
                                />
                                <TextInput
                                    label="Adulti"
                                    name="adults"
                                    type="number"
                                    half
                                />
                                <TextInput
                                    label="Bambini"
                                    name="children"
                                    type="number"
                                    half
                                />
                                <TextInput
                                    label="Neonati"
                                    name="babies"
                                    type="number"
                                    half
                                />
                            </FormToggleGroupEdit>
                        </FormToggleGroup>
                    </MainFormContent>
                </MainForm>

                {quotation?.statusHistory?.length > 0 &&
                    <Fragment>
                        <TitleContainer>
                            <SectionTitle text="Storia del preventivo" />
                        </TitleContainer>
                        <Column>
                            <Tile
                                title={getStatusHistoryString(quotation?.status)}
                                text={`il ${moment(
                                    quotation?.statusUpdatedAt
                                ).format("LLL")}`}
                            />
                            {quotation?.statusHistory?.sort((a,b) => new Date(b.statusUpdatedAt) - new Date(a.statusUpdatedAt)).map((chapter) => (
                                    <Tile
                                        key={chapter.status}
                                        title={getStatusHistoryString(
                                            chapter.status
                                        )}
                                        text={`il ${moment(
                                            chapter.statusUpdatedAt
                                        ).format("LLL")}`}
                                    />
                                ))}
                        </Column>

                        <Divider />
                    </Fragment>
                }

            </LoadingContainer>

        </MainContainer>
    )
}