import React, { useState } from "react";
import { Link } from "react-router-dom";
import qs from "query-string";
import { usePaginatedQuery } from 'react-query';
import moment from "moment";

import {
    MainContainer,
    TitleContainer,
    MainTitle,
    ButtonsRow,
    MainButton,
    ColumnContainer,
    Column,
    SimpleTable
} from "@beacharound/beacharound-ui";

import { getCategories } from "../../../api/blog/categories";

export default function List({ match, location, history }) {
    const { page } = location.search ? qs.parse(location.search) : { page: "0" };

    const [limit, setLimit] = useState(25);
    const [search, setSearch] = useState();
    const [params, setParams] = useState({}); // eslint-disable-line no-unused-vars

    const options = {
        skip: Number(page) * limit,
        limit,
        sort: { name: 1 },
        search,
        params: {
            ...params,
        },
    };

    const { data, isLoading } = usePaginatedQuery(["blog", "categories"], () =>
        getCategories({
            params: options,
        })
    );

    const tableHead = [
        {
            text: "nome",
            key: ["name"],
            sort: "text",
            highlight: true,
        },
        {
            text: "Creata il",
            key: "createdAt",
            default: true,
            sort: "date",
            ascending: false,
            flex: 0.5,
            parse: (value) => moment(value).format("L"),
        },
        {
            text: "Pubblicata",
            key: "published",
            parse: (value) => value ? "Si" : "No",
            color: (value) => value ? "green" : "red",
            flex: 0.5,
        },
        {
            flex: 0.5,
            actions: (item) => {
                return [
                    {
                        text: "Dettaglio",
                        component: Link,
                        to: `${match.url}/${item._id}`,
                    },
                ];
            },
        },
    ];

    const tableRows = data?.map((item) => {
        return {
            ...item,
            ...item.contact,
        }
    })
    
    return (
        <MainContainer large>
            
            <ColumnContainer noMargin>
                <Column column={2}>
                    <TitleContainer>
                        <MainTitle text="Categorie del blog" />
                    </TitleContainer>
                </Column>
                <Column column={2}>
                    <ButtonsRow flexEnd noMargin>
                        <MainButton
                            text="Nuova categoria"
                            component={Link}
                            to="/blog/categories/create"
                        />
                    </ButtonsRow>
                </Column>
            </ColumnContainer>

            <ColumnContainer>
                <Column>
                    <SimpleTable
                        match={match}
                        history={history}
                        setLimit={setLimit}
                        navigation
                        search
                        data={tableRows}
                        head={tableHead}
                        isLoading={isLoading}
                        page={page}
                        onSearch={(value) => {
                            setSearch(value);
                            history.push(match.url);
                        }}
                        onSearchClear={() => {
                            setSearch(undefined);
                            history.push(match.url);
                        }}
                    />
                </Column>
            </ColumnContainer>
        </MainContainer>
    );
}
