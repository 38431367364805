import axios from "../_defaults/user";

const baseUrl = "/admin-data-counters"

export async function getQuotations({params}) {

    try {
        const response = await axios.get(
            `${baseUrl}/quotations`,
            {
                params
            }
        );

        if(response?.data) {
            return response?.data;
        }

    } catch (error) {
        return error;
    }
}
