import React, { useState } from "react";
import qs from "query-string";
import { Link } from "react-router-dom";
import { useQuery, usePaginatedQuery } from "react-query";

import {
    MainContainer,
    TitleContainer,
    MainTitle,
    ColumnContainer,
    Column,
    SimpleTable,
    ButtonsRow,
    MainButton
} from "@beacharound/beacharound-ui";

import {
    getImageCrop,
    pointsOfInterestTypes,
} from "@beacharound/beacharound-ui-helpers";

import HighlightPopup from "../../../components/Popups/HighlightPopup";
import PublishPopup from "../../../components/Popups/PublishPopup";

import { getPointsOfInterest, updatePointOfInterest } from "../../../api/pointsOfInterests";
import { getLocations } from "../../../api/locations";

export default function List({ history, match, location }) {

    const { page } = location.search ? qs.parse(location.search) : { page: "0" };
    const { data: districts } = useQuery("districts", () => getLocations({ type: "districts" }));

    const [ limit, setLimit ] = useState(25);
    const [ search, setSearch ] = useState();
    const [ params, setParams ] = useState({});
    const [ highlightItem, setHighlightItem ] = useState();
    const [ publishItem, setPublishItem ] = useState();

    const options = {
        skip: Number(page) * limit,
        limit,
        sort: { name: 1 },
        search,
        params: {
            ...params
        },
    };
    
    const { data, isLoading } = usePaginatedQuery(["pointsOfInterests", options], () =>
        getPointsOfInterest({
            params: options,
        })
    );

    const tableHead = [
        {
            text: "Nome",
            key: "name",
            sort: "text",
            highlight: true,
            default: true,
            image: (item) => getImageCrop(item?.cover, "xs"),
        },
        {
            text: "Provincia",
            key: "districtName",
            sort: true,
            flex: 0.5,
        },
        {
            flex: 0.5,
            actions: (item) => {
                return [
                    {
                        icon: item.published ? "visible" : "not-visible",
                        action: () => setPublishItem(item),
                    },
                    {
                        icon: item.highlight ? "heart-full" : "heart",
                        action: () => setHighlightItem(item),
                    },
                    {
                        icon: "edit",
                        component: Link,
                        to: `${match.url}/${item._id}`,
                    },
                ];
            },
        },
    ];

    const tableRows =
        data?.map((item) => {
            return {
                ...item,
                districtName: item.address?.district,
            };
        }) || [];

    function filterActionHandler(name, value, options) {
        setParams(
            options?.isRemoving
                ? {
                      ...params,
                      [name]: undefined,
                  }
                : {
                      ...options,
                      [name]: value,
                  }
        );
        return history.push(`${match.url}`);
    }

    const availableFilters = [
        {
            label: "Provincia",
            name: "address.district",
            type: "select",
            half: true,
            options: districts
                ?.map(({ iso, name }) => {
                    return {
                        value: iso,
                        label: name,
                    };
                })
                .sort(function (a, b) {
                    return a.label - b.label;
                }),
            action: filterActionHandler,
        },
        {
            label: "Tipologia",
            name: "type",
            type: "select",
            options: pointsOfInterestTypes,
            half: true,
            action: filterActionHandler,
        },
        {
            label: "Attivi",
            name: "published",
            type: "boolean",
            half: true,
            action: filterActionHandler,
        },
    ];

    return (
        <MainContainer large>
            
            <ColumnContainer noMargin>
                <Column column={2}>
                    <TitleContainer noMargin>
                        <MainTitle text="Punti di interesse" />
                    </TitleContainer>
                </Column>
                <Column column={2}>
                    <ButtonsRow flexEnd noMargin>
                        <MainButton
                            text="Nuovo punto di interesse"
                            component={Link}
                            to={`${match.url}/create`}
                        />
                    </ButtonsRow>
                </Column>
            </ColumnContainer>

            <ColumnContainer>
                <Column>
                    <SimpleTable
                        match={match}
                        history={history}
                        setLimit={setLimit}
                        navigation
                        search
                        showFilters
                        data={tableRows}
                        head={tableHead}
                        availableFilters={availableFilters}
                        isLoading={isLoading}
                        page={page}
                        searched={search}
                        onSearch={(value) => {
                            setSearch(value);
                            history.push(match.url);
                        }}
                        onSearchClear={() => {
                            setSearch(undefined);
                            history.push(match.url);
                        }}
                    />
                </Column>
            </ColumnContainer>

            <HighlightPopup
                item={highlightItem}
                setItem={setHighlightItem}
                type={"pointsOfInterests"}
                updateFunction={updatePointOfInterest}
            />

            <PublishPopup
                item={publishItem}
                setItem={setPublishItem}
                type={"pointsOfInterests"}
                updateFunction={updatePointOfInterest}
            />

        </MainContainer>
    )
}
