import React from 'react';

import { SectionTitle, TitleContainer, Tile, SimpleLink, ColumnContainer, Column, InputError } from '@beacharound/beacharound-ui';

export default function RoomPricesListEdit({ title, prices, setPricePopup, priceType, error }) {

    return (
        <React.Fragment>
            <TitleContainer>
                <SectionTitle text={title || "Prezzi"} />
            </TitleContainer>

            <ColumnContainer>
                <Column>
                    {prices?.map((price, index) => {
                        let pricesString = `Adulti: <strong>${price.prices?.adults}€</strong>`;
                        
                        if(priceType === 'daily-each') {
                            pricesString += ` - Bambini: <strong>${price.prices?.children ? (price.prices?.children + '€') : 'gratis'}</strong> - Neonati: <strong>${price.prices?.babies ? (price.prices?.babies + '€') : 'gratis'}</strong>`;
                        }

                        return (
                            <Tile
                                key={index}
                                title={price.name}
                                text={pricesString}
                                buttonAction={() => setPricePopup({index, ...price, ...(price?.prices || {})})}
                                buttonLinkText="Modifica"
                                buttonRight
                            />
                        )
                    })}

                    <SimpleLink
                        text="Aggiungi prezzo"
                        action={() => setPricePopup(true)}
                    />

                    {error && <InputError errors={[error]} />}
                </Column>
            </ColumnContainer>
        </React.Fragment>
    );
}
