import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { QueryCache, ReactQueryCacheProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query-devtools'

import "react-datepicker/dist/react-datepicker.css";
// import '@beacharound/beacharound-ui/dist/css/index.css';

import 'moment/locale/it';

// import { refreshUser } from './api/user';
import routes from './routes';
import NotFound from './pages/NotFound';

import MainRoute from './components/Routes/MainRoute';

import BeacharoundPackage from "../package.json";
import BeacharoundUiPackage from "@beacharound/beacharound-ui/package.json";
import BeacharoundUiHelpersPackage from "@beacharound/beacharound-ui-helpers/package.json";
import { LoadingContainer } from '@beacharound/beacharound-ui';

const queryCache = new QueryCache({
  defaultConfig: { retry: true }
});

function App() {

  if (process.env.NODE_ENV === 'development') {
    import('@beacharound/beacharound-ui/dist/css/index.css').then(() => console.log('stile caricato', process.env.NODE_ENV));
  }

  console.log(
    `%c proudly made by Opificio Lamantini Anonimi`,
    "background-color:#3A80B4; font-weight:bold; color:white;"
  );
  console.log(
    `%c beacharound: ${BeacharoundPackage?.version}`,
    "background-color:#3A80B4; font-weight:bold; color:white;"
  );
  console.log(
    `%c beacharound-ui: ${BeacharoundUiPackage?.version}`,
    "background-color:#3A80B4; font-weight:bold; color:white;"
  );
  console.log(
    `%c beacharound-ui-helpers: ${BeacharoundUiHelpersPackage?.version}`,
    "background-color:#3A80B4; font-weight:bold; color:white;"
  );

  return (
    <ReactQueryCacheProvider queryCache={queryCache}>
      <Suspense fallback={<LoadingContainer isLoading />}>
        <Router>
          <Switch>
            {routes?.map((route, i) => <MainRoute key={i} {...route} />)}
            <Route path="*" render={() => <NotFound />} />
          </Switch>
        </Router>
      </Suspense>

      {process.env.NODE_ENV === "development" &&
        <ReactQueryDevtools initialIsOpen={false} />
      }
    </ReactQueryCacheProvider>
  );
}

export default App;
