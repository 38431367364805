import React from "react";
import { Link, Switch } from "react-router-dom";

import {
    MainContainer,
    MainTitle,
    TitleContainer,
    ColumnContainer,
    Column,
    Tabs,
} from "@beacharound/beacharound-ui";

import SubRoute from "../../components/Routes/SubRoute";

export default function Places({ match, routes, location }) {
    const tabs = [
        {
            text: "Servizi",
            component: Link,
            to: `${match.url}/services`,
        },
        {
            text: "Accessibilità",
            component: Link,
            to: `${match.url}/accessibilities`,
        },
        {
            text: "Ombrelloni",
            component: Link,
            to: `${match.url}/productsTypes/umbrella`,
        },
        {
            text: "Lettini",
            component: Link,
            to: `${match.url}/productsTypes/deckchair`,
        },
        {
            text: "Camere",
            component: Link,
            to: `${match.url}/productsTypes/room`,
        },
        {
            text: "Parcheggi",
            component: Link,
            to: `${match.url}/productsTypes/parking`,
        }
    ];

    const tabsArray = tabs && tabs.map((t) => t.to);

    return (
        <React.Fragment>
            <MainContainer large>
                <TitleContainer>
                    <MainTitle text="Cataloghi" />
                </TitleContainer>

                <ColumnContainer noMargin>
                    <Column>
                        <Tabs
                            list={tabs}
                            activeIndex={tabsArray.indexOf(location.pathname)}
                        />
                    </Column>
                </ColumnContainer>

                <ColumnContainer>
                    <Column>
                        <Switch>
                            {routes?.map((page, index) => {
                                return <SubRoute key={index} {...page} />;
                            })}
                        </Switch>
                    </Column>
                </ColumnContainer>
            </MainContainer>
        </React.Fragment>
    );
}
