import React from "react";
import { Link } from "react-router-dom";
import { useMutation, useQueryCache } from "react-query";

import {
    MainContainer,
    TitleContainer,
    MainTitle,
    MainForm,
    MainFormContent,
    TextInput,
    TextAreaInput,
    ButtonsRow,
    MainButton,
    Divider,
    SectionTitle,
    SelectInput
} from "@beacharound/beacharound-ui";

import { createCategory } from "../../../api/blog/categories";
import { typeOptions } from "../../../helpers/blog";

const constraints = {
    name: { presence: true },
    type: { presence: true },
    subtitle: { presence: true },
    description: { presence: true }
}

export default function Create({ match, history }) {

    const cache = useQueryCache();
    
    const [create, { status }] = useMutation(createCategory, {
            onSuccess: (data) => {
                cache.invalidateQueries("beaches")
                cache.setQueryData(["beaches", data._id], data)
                return history.push(match.url.replace("create", data?._id))
            }
        }
    )

    function save(data) {
        return create({ data })
    }

    return (
        <MainContainer marginBottom>
            <TitleContainer>
                <MainTitle text="Nuova categoria" />
            </TitleContainer>

            <MainForm onSubmit={save} constraints={constraints}>
                <TitleContainer>
                    <SectionTitle text="Dati principali" />
                </TitleContainer>
                <MainFormContent flex>
                    <TextInput
                        label="Titolo"
                        name="name"
                        half
                    />
                    <SelectInput
                        label="Tipologia"
                        name="type"
                        isMulti
                        half
                        options={typeOptions}
                    />
                    <TextInput
                        label="Sottotitolo"
                        name="subtitle"
                    />
                    <TextAreaInput
                        label="Descrizione"
                        name="description"
                    />
                </MainFormContent>

                <Divider />

                <ButtonsRow fill marginBottom>
                    <MainButton
                        text="Annulla"
                        border
                        component={Link}
                        to={`/blog/categories`}
                    />
                    <MainButton text="Crea" submit status={status} />
                </ButtonsRow>
            </MainForm>
        </MainContainer>
    );
}
