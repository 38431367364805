import React from 'react';
import { useMutation, useQueryCache } from "react-query";

import {
    NormalPopup,
    ButtonsRow,
    MainButton,
    Paragraph,
    TitleContainer
} from "@beacharound/beacharound-ui";

function getPopupText(type, published) {
    const publishedText = published ? "nascondere" : "pubblicare";
    let typeText;

    switch (type) {
        case 'beaches': typeText = 'questa spiaggia'; break;
        case 'hotels': typeText = "questo hotel"; break;
        case 'offers': typeText = "questa offerta"; break;
        case 'pointsOfInterests': typeText = "questo punto di interesse"; break;
        default: typeText = "questo elemento"; break;
    }

    return `Sei sicuro di voler ${publishedText} ${typeText}?`;
}

export default function PublishPopup({item, setItem, type, updateFunction, elementId, queryKey}) {

    const cache = useQueryCache();

    const [update, { status, isIdle }] = useMutation(updateFunction, {
        onSuccess: () => {
            cache.invalidateQueries(queryKey || type);
            return setItem(undefined);
        },
    });

    function publishHandler() {
        if (type === 'offers') {
            update({elementId: elementId, offerId: item._id, data: {published: !item.published}});
        } else if (type === 'rooms') {
            update({elementId: elementId, roomId: item._id, data: {published: !item.published}});
        } else {
            update({elementId: item._id, data: {published: !item.published}});
        }
    }

    return (
        <NormalPopup
            visible={item}
            setVisible={() => setItem(false)}
            title={item?.name}
            large
        >

            <TitleContainer>
                <Paragraph text={getPopupText(type, item?.published)} />
            </TitleContainer>

            <ButtonsRow fill>
                <MainButton
                    text="Annulla"
                    border
                    action={() => setItem(false)}
                />
                <MainButton
                    text="Conferma"
                    action={publishHandler}
                    status={!isIdle ? status : undefined}
                />
            </ButtonsRow>
        </NormalPopup>
    );
}