import List from '../../pages/Places/SearchTerms/List';
// import Detail from '../../pages/Places/SearchTerms/Detail';

export const subRoutesSearchTerms = [
    {
        path: "",
        component: List,
        exact: true,
    }
].map((item) => {
    return {
        ...item,
        path: `/searchTerms${item.path}`,
        private: true
    };
});

// export const routesSearchTerms = [
//     {
//         path: "/:elementId",
//         component: Detail,
//     }
// ].map((item) => {
//     return {
//         ...item,
//         path: `/searchTerms${item.path}`,
//         private: true
//     };
// });