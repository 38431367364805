import React, { useState } from "react";
import qs from "query-string";
import { Link } from "react-router-dom";
import { useQueryCache, usePaginatedQuery, useQuery } from "react-query";

import {
    MainContainer,
    TitleContainer,
    MainTitle,
    ColumnContainer,
    Column,
    SimpleTable
} from "@beacharound/beacharound-ui";

import { getImageCrop } from '@beacharound/beacharound-ui-helpers';

import { getHotelNameWithStars } from '../../../helpers/hotels/stars';
import HighlightPopup from "../../../components/Popups/HighlightPopup";
import PublishPopup from "../../../components/Popups/PublishPopup";

import { getHotels, updateHotel } from "../../../api/hotels/hotels";
import { getLocations } from "../../../api/locations";
import { getOptionsFromCities } from "../../../helpers/cities";

export default function List({ history, match, location }) {

    const { page } = location.search ? qs.parse(location.search) : { page: "0" };

    const queryCache = useQueryCache();
    const [limit, setLimit] = useState(25);
    const [search, setSearch] = useState();
    const [params, setParams] = useState({});
    const [highlightItem, setHighlightItem] = useState();
    const [publishItem, setPublishItem] = useState();
    const [citiesSearch, setCitiesSearch] = useState(); // eslint-disable-line no-unused-vars

    const options = {
        skip: Number(page) * limit,
        limit,
        sort: { name: 1 },
        search,
        params: {
            ...params
        }
    }

    const { data, isLoading } = usePaginatedQuery(["hotels", options], () =>
        getHotels({ params: options })
    )

    const { data: districts } = useQuery("districts", () =>
        getLocations({ type: "districts" })
    )

    const citiesOptions = {
        limit: 25,
        sort: { name: 1 },
        search: citiesSearch,
    }

    const { data: cities } = useQuery(["cities", citiesOptions], () =>
        getLocations({
            type: "cities",
            params: citiesOptions,
        })
    );

    const tableHead = [{
        text: "Nome",
        key: "name",
        sort: "text",
        highlight: true,
        default: true,
        image: (item) => getImageCrop(item?.cover, 'xs')
    }, {
        text: "Provincia",
        key: "districtName",
        sort: true,
        flex: 0.5,
    }, {
        text: "Comune",
        key: "cityName",
        sort: true,
        flex: 0.5,
    }, {
        flex: 0.5,
        actions: (item) => {
            return [{
                icon: item.published ? "visible" : "not-visible",
                action: () => setPublishItem(item),
            }, {
                icon: item.highlight ? "heart-full" : "heart",
                action: () => setHighlightItem(item),
            }, {
                icon: "edit",
                title: "Modifica hotel",
                component: Link,
                to: `${match.url}/${item._id}/quotations`
            }]
        }
    }]

    const tableRows = data?.map(item => {
        return {
            ...item,
            name: getHotelNameWithStars(item.name, item.stars),
            districtName: item.address?.district,
            cityName: item.address?.city,
        };
    }) || [];

    const availableFilters = [{
        label: "Provincia",
        name: "district",
        type: "select",
        half: true,
        options: districts?.map(({ iso, name }) => {
            return {
                value: iso,
                label: name
            }
        }).sort(function (a, b) {
            return a.label - b.label
        }),
        action: (name, value, options) => {
            setParams(
                options?.isRemoving
                    ? { ...params, "address.district": undefined }
                    : { ...params, "address.district": value }
            );
            history.push(`${match.url}`);
        },
    }, {
        label: "Comune",
        name: "city",
        type: "select",
        half: true,
        loadOptions: async (citiesSearch, callback) => {
            const searchCitiesOptions = {
                ...citiesOptions,
                search: citiesSearch,
            };

            const result = await getLocations({
                type: "cities",
                params: searchCitiesOptions,
            });

            queryCache.setQueryData(
                ["cities", searchCitiesOptions],
                result
            );

            return callback(
                getOptionsFromCities(result, { valueKey: "name" })
            );
        },
        defaultOptions: getOptionsFromCities(cities, { valueKey: "name" }),
        action: (name, value, options) => {
            setParams(
                options?.isRemoving
                    ? { ...params, "address.city": undefined }
                    : { ...params, "address.city": value }
            );
            history.push(`${match.url}`);
        }
    }, {
        label: "Presi in carico",
        name: "ownership",
        type: "boolean",
        action: (name, value, options) => {
            setParams({
                ...params,
                "ownership.ownedBy": {
                    $exists: !options?.isRemoving ? value : false,
                },
            })
            history.push(`${match.url}`);
        }
    }]

    return (
        <MainContainer large>

            <TitleContainer>
                <MainTitle text="Hotel" />
            </TitleContainer>

            <ColumnContainer>
                <Column>
                    <SimpleTable
                        match={match}
                        history={history}
                        setLimit={setLimit}
                        navigation
                        showFilters
                        data={tableRows}
                        head={tableHead}
                        availableFilters={availableFilters}
                        isLoading={isLoading}
                        page={page}
                        searched={search}
                        onSearch={(value) => {
                            setSearch(value);
                            history.push(match.url);
                        }}
                        onSearchClear={() => {
                            setSearch(undefined);
                            history.push(match.url);
                        }}
                    />
                </Column>
            </ColumnContainer>

            <HighlightPopup
                item={highlightItem}
                setItem={setHighlightItem}
                type={"hotels"}
                updateFunction={updateHotel}
            />

            <PublishPopup
                item={publishItem}
                setItem={setPublishItem}
                type={"hotels"}
                updateFunction={updateHotel}
            />

        </MainContainer>
    )
}
