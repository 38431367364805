import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useMutation, useQuery, useQueryCache } from 'react-query';

import { MainContainer, MainForm, MainFormContent, SectionTitle, TitleContainer, MainTitle, SubTitle, FormToggleGroup, FormToggleGroupPreview, SimpleFormField, FormToggleGroupEdit, TextInput, SelectInput, LoadingContainer, ColumnContainer, Column, Tile, ButtonsRow, SimpleLink, Divider, NormalPopup, Paragraph, MainButton } from '@beacharound/beacharound-ui';

import { disconnectActivityToOrganization, disconnectUserToOrganization, getOrganization, updateOrganization } from "../../../api/organizations";
import ConnectActivityPopup from "../../../components/Organizations/ConnectActivityPopup";
import { getActivityNameFromType } from "../../../helpers/activities";
import ConnectUserPopup from "../../../components/Organizations/ConnectUserPopup";
import CreateAndConnectUserPopup from "../../../components/Organizations/CreateAndConnectUserPopup";

const typeOptions = [{
    label: 'Privata',
    value: 'private'
}, {
    label: 'Pubblica',
    value: 'civil'
}]

export default function Detail({ match }) {

    const [activityPopup, setActivityPopup] = useState();
    const [userPopup, setUserPopup] = useState();
    const [userAddPopup, setUserAddPopup] = useState();
    const [deletingStatus, setDeletingStatus] = useState();

    // ELIMINAZIONE ATTIVITA'
    const [deletingActivity, setDeletingActivity] = useState();
    const [deleteActivityPopup, setDeleteActivityPopup] = useState();

    // ELIMINAZIONE UTENTE
    const [deletingUser, setDeletingUser] = useState();
    const [deleteUserPopup, setDeleteUserPopup] = useState();

    const { elementId } = match.params;
    const { data, isLoading } = useQuery(["organizations", elementId], () =>
        getOrganization({ elementId })
    );

    const cache = useQueryCache()
    const [update, { status: updateStatus, reset }] = useMutation(updateOrganization, {
        onSuccess: (data) => {
            cache.invalidateQueries("organizations");
            cache.setQueryData(["organizations", data._id], data);
        },
    });

    const [disconnectActivity] = useMutation(disconnectActivityToOrganization, {
        onSuccess: (data) => {
            cache.invalidateQueries("organizations");
            cache.setQueryData(["organizations", data._id], data);
        },
    });

    const [disconnectUser] = useMutation(disconnectUserToOrganization, {
        onSuccess: (data) => {
            cache.invalidateQueries("organizations");
            cache.setQueryData(["organizations", data._id], data);
        },
    });

    // Eliminazione attività
    async function deleteActivity() {
        const { type, _id } = deletingActivity;
        setDeletingStatus('LOADING')

        await disconnectActivity({
            elementId,
            activityId: _id,
            activityType: type
        })

        setDeletingStatus('READY')
        return setDeleteActivityPopup(false)
    }

    // Eliminazione utente
    async function deleteUser() {
        const { userId, role } = deletingUser;
        setDeletingStatus('LOADING')

        await disconnectUser({
            elementId,
            userId: userId,
            role: role
        });

        setDeletingStatus('READY')
        return setDeleteUserPopup(false)
    }

    async function editElement(data, callback) {
        try {
            await update({
                elementId,
                data
            })
            return callback(reset)
        } catch (error) {
            return callback(null, error)
        }
    }

    return (
        <MainContainer marginBottom>
            <TitleContainer>
                <MainTitle text={data?.name || "Gestisci organizzazione"} />
                <SubTitle
                    text="< Torna alla lista"
                    component={Link}
                    to={match.url.replace(`/${elementId}`, "")}
                />
            </TitleContainer>

            <LoadingContainer isLoading={isLoading}>
                <TitleContainer>
                    <SectionTitle text="Dati generali" />
                </TitleContainer>

                <MainForm
                    onSubmit={editElement}
                    data={data}
                    constraints={{ name: { presence: true }, type: { presence: true } }}
                >
                    <MainFormContent>
                        <FormToggleGroup>
                            <FormToggleGroupPreview>
                                <SimpleFormField
                                    label="Nome"
                                    name="name"
                                    half
                                />
                                <SimpleFormField
                                    label="Tipologia"
                                    name="type"
                                    options={typeOptions}
                                    half
                                />
                            </FormToggleGroupPreview>

                            <FormToggleGroupEdit flex status={updateStatus}>
                                <TextInput
                                    label="Nome"
                                    name="name"
                                    half
                                />
                                <SelectInput
                                    label="Tipologia"
                                    name="type"
                                    options={typeOptions}
                                    half
                                />
                            </FormToggleGroupEdit>
                        </FormToggleGroup>
                    </MainFormContent>

                    <TitleContainer>
                        <SectionTitle text="Attività collegate" />
                    </TitleContainer>

                    <ColumnContainer>
                        <Column>
                            {data?.activities?.map((element, index) => {
                                return (
                                    <Tile
                                        key={index}
                                        title={element?.activity?.name}
                                        text={getActivityNameFromType(
                                            element?.type
                                        )}
                                        buttonAction={() => {
                                            setDeletingActivity({
                                                _id: element?.activity?._id,
                                                type: element?.type,
                                            })
                                            setDeleteActivityPopup(true)
                                        }
                                        }
                                        buttonLinkText="Rimuovi"
                                        buttonRight
                                    />
                                );
                            })}

                            <ButtonsRow spaceBetween>
                                <SimpleLink
                                    block
                                    text="Collega attività"
                                    action={() => setActivityPopup(true)}
                                />
                                <SimpleLink
                                    block
                                    text="Crea nuova attività"
                                    component={Link}
                                    to={""}
                                />
                            </ButtonsRow>
                        </Column>
                    </ColumnContainer>

                    <Divider />

                    <TitleContainer>
                        <SectionTitle text="Utenti collegati" />
                    </TitleContainer>

                    <ColumnContainer>
                        <Column>
                            {data?.users?.map(({ user, role }, index) => {
                                return (
                                    <Tile
                                        key={index}
                                        title={`${user?.profile?.firstName} ${user?.profile?.lastName}`}
                                        text={user?.emails?.[0]?.address}
                                        buttonAction={() => {
                                            setDeletingUser({
                                                userId: user?._id,
                                                role: role,
                                            })
                                            setDeleteUserPopup(true)
                                        }
                                        }
                                        buttonLinkText="Rimuovi"
                                        buttonRight
                                    />
                                );
                            })}

                            <ButtonsRow spaceBetween>
                                <SimpleLink
                                    block
                                    text="Collega utente"
                                    action={() => setUserPopup(true)}
                                />
                                <SimpleLink
                                    block
                                    text="Crea nuovo utente"
                                    action={() => setUserAddPopup(true)}
                                />
                            </ButtonsRow>
                        </Column>
                    </ColumnContainer>
                </MainForm>
            </LoadingContainer>

            {/* POPUP COLLEGAMENTO ATTIVITÀ */}
            <ConnectActivityPopup
                visible={activityPopup}
                setVisible={setActivityPopup}
                organization={data}
            />

            {/* POPUP COLLEGAMENTO UTENTE */}
            <ConnectUserPopup
                visible={userPopup}
                setVisible={setUserPopup}
                organization={data}
            />

            {/* POPUP CREAZIONE UTENTE */}
            <CreateAndConnectUserPopup
                visible={userAddPopup}
                setVisible={setUserAddPopup}
                organization={data}
            />

            {/* POPUP ELIMINAZIONE ATTIVITA' */}
            <NormalPopup visible={deleteActivityPopup} setVisible={setDeleteActivityPopup} title="Rimuovi attività">
                <TitleContainer>
                    <Paragraph text="Sei sicuro di voler rimuovere questa attività?" />
                </TitleContainer>
                <ButtonsRow fill>
                    <MainButton border text="Annulla" action={() => setDeleteActivityPopup(false)} />
                    <MainButton
                        text="Conferma"
                        action={deleteActivity}
                        status={deletingStatus}
                    />
                </ButtonsRow>
            </NormalPopup>

            {/* POPUP ELIMINAZIONE UTENTE */}
            <NormalPopup visible={deleteUserPopup} setVisible={setDeleteUserPopup} title="Rimuovi utente">
                <TitleContainer>
                    <Paragraph text="Sei sicuro di voler rimuovere questo utente?" />
                </TitleContainer>
                <ButtonsRow fill>
                    <MainButton border text="Annulla" action={() => setDeleteUserPopup(false)} />
                    <MainButton
                        text="Conferma"
                        action={deleteUser}
                        status={deletingStatus}
                    />
                </ButtonsRow>
            </NormalPopup>
        </MainContainer>
    );
}
