import axios from "../_defaults/user";
import moment from 'moment';

const baseUrl = "/admin-data-counters"

export async function getInformationsRequests({fromDate: userFromDate, toDate: userToDate}) {

    const fromDate = userFromDate || moment().subtract(7, 'day');
    const toDate = userToDate || moment();

    try {
        const response = await axios.get(
            `${baseUrl}/information-requests`,
            {
                params: {
                    fromDate: fromDate.toString(),
                    toDate: toDate.toString()
                }
            }
        );

        if(response?.data) {
            return response?.data;
        }

    } catch (error) {
        return error;
    }
}
