import React from 'react';
import { Link } from 'react-router-dom';
import { Column, ColumnContainer, DateInput, Divider, MainContainer, MainFormContent, MainForm, MainTitle, SectionTitle, TitleContainer, ButtonsRow, MainButton, SubTitle } from '@beacharound/beacharound-ui';

import RoomBedsListEdit from "../../../../../components/Hotel/Rooms/RoomBedsListEdit";

const constraints = {
    fromDate: { presence: true },
    toDate: { presence: true },
    adult: { presence: true },
    child: { presence: true },
    baby: { presence: true }
}

const availablePacks = [{
    name: 'Mezza pensione',
    _id: '31635424325',
    prices: {
        adult: 50,
        child: 26,
        baby: 10
    }
}, {
    name: 'Pensione completa',
    _id: '31624325',
    prices: {
        adult: 50,
        child: 26,
        baby: 10
    }
}]

export default function RoomDetailSeasonPrice({match, history, saveSeasonPrice}) {

    const { elementId, roomId, seasonId } = match.params;
    let packs;

    if (seasonId) {
        packs = [{
            name: 'Mezza pensione',
            _id: '31635424325',
            prices: {
                adult: 50,
                child: 26,
                baby: 10
            }
        }, {
            name: 'Pensione completa',
            _id: '31624325',
            prices: {
                adult: 50,
                child: 26,
                baby: 10
            }
        }]
    }

    function saveSeasonPriceHandler(data) {
        saveSeasonPrice()

        return history.push(`/activities/hotel/${elementId}/rooms/${roomId}`);
    }

    const packsOptions = availablePacks?.map((pack) => {
        return { label: pack.name, value: pack._id }
    })

    return (
        <MainContainer>
            <TitleContainer>
                <MainTitle
                    text={
                        seasonId
                            ? "Modifica prezzo stagionale"
                            : "Aggiungi prezzo stagionale"
                    }
                />
                <SubTitle
                    text="< Torna al dettaglio"
                    component={Link}
                    to={`/activities/hotels/${elementId}/rooms/${roomId}`}
                />
            </TitleContainer>

            <ColumnContainer>
                <Column>
                    <MainForm
                        constraints={constraints}
                        onSubmit={saveSeasonPriceHandler}
                    >
                        <TitleContainer>
                            <SectionTitle text="Validità" />
                        </TitleContainer>

                        <MainFormContent flex>
                            <DateInput
                                name="fromDate"
                                label="Valido dal"
                                half
                            />
                            <DateInput
                                name="toDate"
                                label="Valido fino al"
                                half
                            />
                        </MainFormContent>

                        <Divider />

                        <RoomBedsListEdit
                            title="Pacchetti"
                            savePack={() => console.log("salva")}
                            packs={packs}
                            packsOptions={packsOptions}
                        />

                        {/* TODO: [BEACHDEV-57] Far funzionare l'aggiunta ed eliminazione dei pacchetti dentro al componente RoomPacks */}

                        <Divider />

                        <ButtonsRow fill>
                            <MainButton
                                border
                                text="Annulla"
                                action={() =>
                                    history.push(
                                        `/activities/hotel/${elementId}/rooms/${roomId}`
                                    )
                                }
                            />
                            <MainButton submit text="Salva" />
                        </ButtonsRow>
                    </MainForm>
                </Column>
            </ColumnContainer>
        </MainContainer>
    );
}