import React from 'react';

import {
    ButtonsRow,
    Divider,
    MainButton,
    MainForm,
    MainFormContent,
    NormalPopup,
    TextInput,
} from "@beacharound/beacharound-ui";

import { useMutation, useQueryCache } from 'react-query';
import { connectUserToOrganization } from "../../api/organizations";
import { createUser } from '../../api/users/users';

export default function CreateAndConnectUserPopup({ visible, setVisible, organization }) {
    const cache = useQueryCache();

    const [connect, { status, error, reset }] = useMutation( async (data) => {
        try {

            const userData = await createUser({
                data,
                role:
                    organization.type === "civil"
                        ? "public-administrations"
                        : "business",
            });

            const updateOrganization = await connectUserToOrganization({
                elementId: organization._id,
                userId: userData._id,
            });

            return updateOrganization

        } catch(error) {
            throw error;
        }
        
    }, {
        onSuccess: (data) => {
            cache.invalidateQueries("organizations");
            cache.setQueryData(["organizations", data._id], data);
            return setVisible(false);
        },
    });

    async function connectUser(data) {
        await connect(data);
    }

    return (
        <NormalPopup
            visible={visible}
            setVisible={() => {
                reset();
                return setVisible(false);
            }}
            title="Crea utente"
        >
            <MainForm
                onSubmit={connectUser}
                errors={{ server: error?.message }}
            >
                <MainFormContent>
                    <TextInput label="Nome" name="firstName" />
                    <TextInput label="Cognome" name="lastName" />
                    <TextInput label="Telefono" name="phone" />
                    <TextInput label="Email" name="email" type="email" />
                </MainFormContent>
                <Divider />
                <ButtonsRow fill>
                    <MainButton
                        text="Annulla"
                        border
                        action={() => {
                            reset();
                            return setVisible(false);
                        }}
                    />
                    <MainButton text="Crea utente" submit status={status} />
                </ButtonsRow>
            </MainForm>
        </NormalPopup>
    );
}