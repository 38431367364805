// hotels

import List from '../../pages/Places/Hotels/List';
import Detail from '../../pages/Places/Hotels/Detail';
import Create from '../../pages/Places/Hotels/Create';

export const subRoutesHotels = [
    {
        path: "",
        component: List,
        exact: true,
    }
].map((item) => {
    return {
        ...item,
        path: `/hotels${item.path}`,
        private: true
    };
});

export const routesHotels = [
    {
        path: "/create",
        component: Create,
        exact: true
    },
    {
        path: "/:elementId",
        component: Detail,
    }
].map((item) => {
    return {
        ...item,
        path: `/hotels${item.path}`,
        private: true
    };
});