import React, { useState } from 'react';
import { ButtonsRow, Divider, MainButton, MainForm, MainFormContent, NormalPopup, SearchComponent } from "@beacharound/beacharound-ui";
import { useQuery, useQueryCache } from 'react-query';
import { getLocationsOptionsFromData } from '@beacharound/beacharound-ui-helpers';

import { searchPointsOfInterest } from '../../api/search';
import { getBeaches } from '../../api/beach';
import { getHotels } from '../../api/hotels/hotels';

export default function RankingsAddPlacePopup({ visible, setVisible, addElements, status, category, selectedCatName }) {

    const cache = useQueryCache();
    const [selectingPlaces, setSelectingPlaces] = useState([]);
    const options = { limit: 10 };

    let finder;

    switch (category) {
        case "pointsOfInterest":
            finder = searchPointsOfInterest;
            break;
        case "beaches":
            finder = getBeaches;
            break;
        case "hotels":
            finder = getHotels;
            break;
    
        default:
            break;
    }

    const { data: pointsList } = useQuery([category, options], () =>
        finder ? finder({params: options}) : []
    );
    async function loadOptions(search, callback) {
        const searchOptions = { ...options, search };
        let result = await finder({params: searchOptions});
        result = result?.map(item => {
            return {
                ...item,
                type: category
            }
        });
        cache.setQueryData([category, searchOptions], result);
        return callback(getLocationsOptionsFromData(result, { valueKey: "_id" }));
    }

    function addElementsHandler() {
        addElements(selectingPlaces);
    }

    return (
        <NormalPopup visible={visible} setVisible={setVisible} title={`Aggiungi ${selectedCatName || 'elementi'}`}>
            <MainForm onSubmit={addElementsHandler}>
                <MainFormContent>
                    <SearchComponent
                        label={`Aggiungi ${selectedCatName || 'elementi'}`}
                        isMulti
                        isAsync
                        loadOptions={loadOptions}
                        defaultOptions={getLocationsOptionsFromData(pointsList?.map(item => {
                                return {
                                    ...item,
                                    type: category
                                }
                            }), { value: "_id" })}
                        onChangeInput={(name, value, options) => setSelectingPlaces(options) }
                        buttonText="Cerca"
                    />
                </MainFormContent>
                <Divider />
                <ButtonsRow fill>
                    <MainButton
                        border
                        text="Annulla"
                        action={() => setVisible(false)}
                    />
                    <MainButton text="Aggiungi" submit status={status}/>
                </ButtonsRow>
            </MainForm>
        </NormalPopup>
    );
}