import React from "react";
import { useMutation, useQueryCache, useQuery } from "react-query";

import {
    NormalPopup,
    EditAddressGroup,
} from "@beacharound/beacharound-ui";
import { getAddressString } from "@beacharound/beacharound-ui-helpers";

export default function AddressPopup({
    item,
    setItem,
    type,
    updateFunction,
    getFunction,
    elementId,
    updateKey,
    queryKey,
}) {
    const cache = useQueryCache();

    const { data } = useQuery(queryKey || type, getFunction, {enabled: !!item});

    const [update, { status, reset }] = useMutation(updateFunction, {
        onSuccess: () => {
            cache.invalidateQueries(type);
            return setItem(undefined);
        },
    });

    async function editGeodata(address, callback) {
        try {
            await update({
                elementId,
                data: {
                    address,
                },
            });
        } catch (error) {
            throw error;
        }
    }

    return (
        <NormalPopup
            visible={item}
            setVisible={() => setItem(false)}
            title={item?.name}
            text={getAddressString(data?.address)}
            large
        >
            <EditAddressGroup
                data={data?.address}
                onSubmit={editGeodata}
                reset={reset}
                status={status}
                withoutLoading
            />
        </NormalPopup>
    );
}
