export function getOptionsFromActivities(activities, options) {
    if (!Array.isArray(activities)) return [];

    return activities?.map((activity) => {

        let sublabel = "";
        let icon = activity?.type || "spot";

        if(activity?.type) {
            switch (activity?.type) {
                case "beaches":

                    sublabel = "spiaggia privata";
                    icon = "beach";

                    if(activity?.subType === "civil") {
                        sublabel = "spiaggia libera";
                        icon = "public-beach"
                    }
                    break;

                case "hotels":
                    sublabel = "hotel";
                    icon = "hotel";
                    break;

                case "pointsOfInterest":
                    sublabel = "punto d'interesse";
                    icon = "interest-point";
                    break;

                case "cities":
                    sublabel = "città"
                    icon = "city";
                    break;
            
                default:
                    break;
            }
        }
        
        sublabel = activity?.location
            ? `${sublabel} - ${activity.location}`
            : sublabel;

        return {
            ...activity,
            label: activity?.name,
            sublabel,
            icon, 
            value:
                options && options.valueKey ? activity[options.valueKey] : activity._id,
        };
    });
}

export function getOptionsFromUsers(users, options) {
    if (!Array.isArray(users)) return [];

    return users?.map((user) => {
        let sublabel;
        let icon = "user";
        
        if(user.email) {
            sublabel = user.email
        }

        return {
            ...user,
            label: user?.name,
            sublabel,
            icon,
            value: user._id,
        };
    });
}
