import React from "react";
import { Link } from "react-router-dom";
import { useMutation, useQuery, useQueryCache } from 'react-query';

import {
    MainContainer,
    MainForm,
    MainFormContent,
    SectionTitle,
    TitleContainer,
    MainTitle,
    SubTitle,
    FormToggleGroup,
    FormToggleGroupPreview,
    SimpleFormField,
    FormToggleGroupEdit,
    TextInput,
    TextAreaInput,
    LoadingContainer,
    RateInput,
    ColumnContainer,
    Column,
    ToggleInput
} from "@beacharound/beacharound-ui";

import { getSubFormData } from "@beacharound/beacharound-ui-helpers";

import { getReview, updateReview } from "../../../api/reviews";

export default function Detail({ match }) {

    const { elementId } = match.params;
    const { data, isLoading } = useQuery(["reviews", elementId],
        () => getReview({ elementId })
    )

    const cache = useQueryCache()
    const [update, {status: updateStatus, reset}] = useMutation(updateReview, {
        onSuccess: (data) => {
            cache.invalidateQueries(["reviews"]);
            cache.setQueryData(["reviews", elementId], data);
        }
    })

    async function editElement(data, callback) {
        try {
            await update({
                elementId,
                data
            })
            return callback(reset)
        } catch(error) {
            return callback(null, error);
        }
    }

    return (
        <MainContainer marginBottom>

            <ColumnContainer>
                <Column column={2}>
                    <TitleContainer noMargin>
                        <MainTitle text={data?.title || "Gestisci recensione"} />
                        <SubTitle
                            text="< Torna alla lista"
                            component={Link}
                            to={`/costumerCare/reviews`}
                        />
                    </TitleContainer>
                </Column>

                <Column column={2}>
                    <ToggleInput
                        flexEnd
                        trueLabel="Pubblicato"
                        falseLabel="Non pubblicato"
                        status={updateStatus}
                        changeValue={()=> editElement({published: !data?.published}, (resetAfter) => resetAfter())}
                        value={data?.published}
                    />
                </Column>
            </ColumnContainer>

            <LoadingContainer isLoading={isLoading}>
                <TitleContainer>
                    <SectionTitle text="Dati utente"/>
                </TitleContainer>

                <MainForm
                    onSubmit={editElement}
                    data={ data?.user?.profile && getSubFormData(data.user.profile, [
                        "firstName",
                        "lastName"
                    ])}>

                    <MainFormContent>
                        <FormToggleGroup>
                            <FormToggleGroupPreview notEditing>
                                 <SimpleFormField
                                    label="Nome"
                                    name="firstName"
                                    half
                                />
                                <SimpleFormField
                                    label="Cognome"
                                    name="lastName"
                                    half
                                />
                                {/* <SimpleFormField
                                    label="Provincia"
                                    name="district"
                                    half
                                /> */}
                            </FormToggleGroupPreview>
                        </FormToggleGroup>

                    </MainFormContent>
                </MainForm>

                <TitleContainer>
                    <SectionTitle text="Recensione" />
                </TitleContainer>

                <MainForm
                    onSubmit={editElement}
                    data={ data && getSubFormData(data, [
                        "title",
                        "vote",
                        "description",
                        "createdAt"
                    ])}>

                    <MainFormContent>
                        <FormToggleGroup>
                            <FormToggleGroupPreview>
                                <SimpleFormField
                                    label="Titolo"
                                    name="title"
                                />
                                <SimpleFormField
                                    label="Valutazione"
                                    value={`${data?.vote}/5`}
                                />
                                <SimpleFormField
                                    label="Testo recensione"
                                    name="description"
                                />
                                <SimpleFormField
                                    label="Data creazione"
                                    name="createdAt"
                                    half
                                />
                            </FormToggleGroupPreview>

                            <FormToggleGroupEdit flex status={updateStatus}>
                                <TextInput
                                    label="Titolo"
                                    name="title"
                                />
                                <RateInput
                                    label="Valutazione"
                                    name="vote"
                                />
                                <TextAreaInput
                                    label="Testo recensione"
                                    name="description"
                                />
                            </FormToggleGroupEdit>
                        </FormToggleGroup>
                    </MainFormContent>
                </MainForm>

            </LoadingContainer>
        </MainContainer>
    );
}
