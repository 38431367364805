import React from 'react';
import { Link } from 'react-router-dom';
import { useMutation, useQueryCache } from "react-query";
import qs from "query-string";

import { ButtonsRow, Divider, MainButton, MainContainer, MainForm, MainFormContent, TextInput, MainTitle, SubTitle, TitleContainer, Tile, ColumnContainer, Column } from '@beacharound/beacharound-ui';

import { activateUser } from "../../../api/user";

const constraints = {
    activationCode: {
        presence: true
    }
}

export default function SignupActivation({history, location}) {

    const cache = useQueryCache();
    const { backUrlAfterActivation } = qs.parse(location.search)

    const [userActivationHandler, { status }] = useMutation(activateUser, {
        onSuccess: () => {
            cache.invalidateQueries("user");
            history.push(backUrlAfterActivation || "/");
        }
    })

    return (
        <MainContainer>
            <TitleContainer>
                <MainTitle text="Attivazione utente" />
                <SubTitle text="Step 3: inserisci il tuo codice attivazione" />
            </TitleContainer>

            <ColumnContainer>
                <Column>
                    <Tile alert title="Attenzione" text="Non puoi utilizzare Beacharound finchè non attivi il tuo profilo utente"/>
                </Column>
            </ColumnContainer>

            <MainForm
                onSubmit={userActivationHandler}
                constraints={constraints}
            >
                <MainFormContent flex>
                    <TextInput
                        name="activationCode"
                        type="password"
                        label="Codice attivazione"
                        placeholder="Codice attivazione ricevuto"
                        half
                    />
                </MainFormContent>

                <Divider />

                <ButtonsRow spaceBetween>
                    {backUrlAfterActivation && 
                        <MainButton
                            border
                            text="Torna al login"
                            component={Link}
                            to="/user/login"
                        />
                    }
                    {!backUrlAfterActivation && 
                        <MainButton
                            border
                            text="Indietro"
                            component={Link}
                            to="/user/signup/data"
                        />
                    }
                    <MainButton
                        text="Attiva utente"
                        submit
                        status={status}
                    />
                </ButtonsRow>
            </MainForm>
        </MainContainer>
    );
}
