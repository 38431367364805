import React from 'react';
import { useMutation, useQueryCache } from "react-query";

import {
    NormalPopup,
    ButtonsRow,
    MainButton,
    Paragraph,
    TitleContainer
} from "@beacharound/beacharound-ui";

import { locationActivation } from '../../api/locations';

function getPopupText(type, name, published) {
    const publishedText = published ? "disattivare" : "attivare";
    const publishedText2 = published ? "disattivati" : "attivati";

    if(type === "cities") {
        return `Sei sicuro di voler ${publishedText} la città di ${name}?`;
    }
    if(type === "districts") {
        return `Sei sicuro di voler ${publishedText} la provincia di ${name}? In questo modo verranno ${publishedText2} anche tutti i comuni di questa provincia.`;
    }
    if(type === "regions") {
        return `Sei sicuro di voler ${publishedText} la regione ${name}? In questo modo verranno ${publishedText2} anche tutti i comuni e le province di questa regione.`;
    }
}

export default function LocationActivationPopup({item, setItem, locationType}) {

    const cache = useQueryCache();

    const [update, { status, isIdle }] = useMutation(locationActivation, {
        onSuccess: () => {
            cache.invalidateQueries(locationType);
            return setItem(undefined);
        },
    }); // TODO: [BEACHDEV-41] fare in modo che lo status non venga mantenuto dopo una disattivazione/attivazione

    function activationLocationHandler() {
        update({type: locationType, elementId: item._id, published: !item.published});
    }

    return (
        <NormalPopup
            visible={item}
            setVisible={() => setItem(false)}
            title={item?.name}
            large
        >
            {/* TODO: [BEACHDEV-35] aggiungere il testo con domanda di conferma */}

            <TitleContainer>
                <Paragraph text={getPopupText(locationType, item?.name, item?.published)} />
            </TitleContainer>

            <ButtonsRow fill>
                <MainButton
                    text="Annulla"
                    border
                    action={() => setItem(false)}
                />
                <MainButton
                    text={item?.published ? "Disattiva" : "Attiva"}
                    action={activationLocationHandler}
                    status={!isIdle ? status : undefined}
                />
            </ButtonsRow>
        </NormalPopup>
    );
}