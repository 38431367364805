import React, { useState } from 'react';

import { searchActivities } from "../../api/search";
import { getOptionsFromActivities } from "../../helpers/search";

import {
    ButtonsRow,
    Divider,
    MainButton,
    MainForm,
    MainFormContent,
    NormalPopup,
    FullSearchSelect,
    CheckboxInput,
    Column,
    SimpleField,
} from "@beacharound/beacharound-ui";

import { useMutation, useQuery, useQueryCache } from 'react-query';
import { connectActivityToOrganization } from '../../api/organizations';
import { getMembershipsPacks } from "../../api/memberships";

export default function ConnectActivityPopup({visible, setVisible, organization}) {

    const cache = useQueryCache();
    const [ selectedActivity, setSelectedActivity ] = useState();

    // STRUMENTI PER LA RICERCA ATTIVITÀ
    const activitiesOptions = {
        limit: 10,
    };

    const packOptions = {
        sort: {name: 1},
        params: {
            published: true
        }
    }

    const { data: activities } = useQuery(["activities", "search", activitiesOptions], () =>
        searchActivities({ params: activitiesOptions })
    );

    const { data: packs } = useQuery(["membershipsPacks", packOptions], () =>
        getMembershipsPacks({
            params: packOptions
        })
    )

    const [ connect, { status }] = useMutation(connectActivityToOrganization, {
        onSuccess: (data) => {
            cache.invalidateQueries("organizations");
            cache.setQueryData(["organizations", data._id], data);
            return setVisible(false);
        }
    })

    async function loadActivitiesOptions(search, callback) {

        const searchActivitiesOptions = {
            ...activitiesOptions,
            search,
        };

        const result = await searchActivities({
            params: searchActivitiesOptions,
        });
        cache.setQueryData(["activities", "search", searchActivitiesOptions], result);

        return callback(getOptionsFromActivities(result, { valueKey: "_id" }));
    }

    async function connectActivity(data) {

        let payload = {
            elementId: organization._id,
            activityId: selectedActivity?._id,
            activityType: selectedActivity.type,
        };

        if(Object.keys(data).some(key => key.includes("pack."))) {
            const membershipsPacks = Object.keys(data).filter(key => key.includes("pack.")).reduce((accumulator, key) => {
                return [...accumulator, key.split('.').pop()]
            }, [])
            payload = {
                ...payload,
                membershipsPacks,
            };
        }

        await connect(payload);
    }

    return (
        <NormalPopup
            visible={visible}
            setVisible={() => setVisible(false)}
            title="Aggiungi attività"
            overflow='visible'
        >
            <MainForm onSubmit={connectActivity}>
                <MainFormContent>
                    <Column>
                        <SimpleField label="Attività"/>
                    </Column>

                    <FullSearchSelect
                        buttonText="Collega attività"
                        name="activity"
                        isAsync
                        loadOptions={loadActivitiesOptions}
                        defaultOptions={getOptionsFromActivities(activities)}
                        onChangeInput={(value, name, option) =>
                            setSelectedActivity(option)
                        }
                    />

                    <Column>
                        <SimpleField label="Membership da creare"/>
                    </Column>

                    {packs?.map((pack ) => {
                        return (
                            <CheckboxInput
                                key={pack._id}
                                label={pack.name}
                                name={`pack.${pack._id}`}
                            />
                        )
                    })}
                </MainFormContent>
                <Divider />
                <ButtonsRow fill>
                    <MainButton
                        text="Annulla"
                        border
                        action={() => setVisible(false)}
                    />
                    <MainButton
                        text="Collega attività"
                        submit
                        status={status}
                    />
                </ButtonsRow>
            </MainForm>
        </NormalPopup>
    );
}