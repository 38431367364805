import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";

import { getUser } from "../../../api/users/users";

import {
    MainContainer,
    MainForm,
    MainFormContent,
    SectionTitle,
    TitleContainer,
    MainTitle,
    SubTitle,
    FormToggleGroup,
    FormToggleGroupPreview,
    SimpleFormField,
    FormToggleGroupEdit,
    TextInput,
    LoadingContainer,
} from "@beacharound/beacharound-ui";

export default function Detail({ match }) {
    const { elementId } = match.params;
    const { data, isLoading } = useQuery(["users", "business", elementId], () =>
        getUser({ role: "business", elementId })
    );

    // const cache = useQueryCache()
    // const [update] = useMutation(updateInformationsRequest, {
    //     onSuccess: () => cache.invalidateQueries('informationsRequests')
    // })

    function editElement(data) {
        console.log(data);
    }

    return (
        <MainContainer marginBottom>
            <TitleContainer>
                <MainTitle text="Dettaglio utente amministrazione pubblica" />
                <SubTitle
                    text="< Torna alla lista"
                    component={Link}
                    to={`${match.url.replace(`/${elementId}`, "")}`}
                />
            </TitleContainer>

            <LoadingContainer isLoading={isLoading}>
                <TitleContainer>
                    <SectionTitle text="Dati generali" />
                </TitleContainer>

                <MainForm onSubmit={editElement} data={data}>
                    <MainFormContent>
                        <FormToggleGroup>
                            <FormToggleGroupPreview>
                                <SimpleFormField label="Nome" name="name" />
                            </FormToggleGroupPreview>

                            <FormToggleGroupEdit flex>
                                <TextInput label="Nome" name="name" />
                            </FormToggleGroupEdit>
                        </FormToggleGroup>
                    </MainFormContent>
                </MainForm>
            </LoadingContainer>
        </MainContainer>
    );
}
