import React from 'react';

import { NormalPopup, MainForm, MainFormContent, TextInput, TextAreaInput, Divider, ButtonsRow, MainButton } from '@beacharound/beacharound-ui';

export default function TutorialAddStep({
    visible,
    setVisible,
    addStep
}) {
    return (
        <NormalPopup
            visible={visible}
            setVisible={setVisible}
            title="Aggiungi step"
        >

            <MainForm
                onSubmit={addStep}
                constraints={{
                    name: { presence: true },
                    description: { presence: true }
                }}
            >
                <MainFormContent>
                    <TextInput label="Titolo" name="name"/>
                    <TextInput label="Sottotitolo" name="subtitle"/>
                    <TextAreaInput label="Descrizione" name="description"/>
                    <TextInput label="Inizio video" name="startOffset"/>
                    <TextInput label="Fine video" name="endOffset"/>
                </MainFormContent>

                <Divider/>

                <ButtonsRow fill>
                    <MainButton text="Annulla" border action={() => setVisible()}/>
                    <MainButton text="Aggiungi" submit/>
                </ButtonsRow>
            </MainForm>
        </NormalPopup>
    )
}