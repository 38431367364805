import axios from './_defaults/user';


export async function getTransfers({ params }) {
    try {
        const response = await axios.get(`/admin-transfers`, { params });

        if (response.data) {
            return response.data;
        }
        
    } catch(error) {

        if (error?.response?.status === 404) {
            return [];
        }

        throw error
    }
}

export async function getTransfer({ elementId }) {
    try {
        const response = await axios.get(`/admin-transfers/${elementId}`);

        if (response.data) {
            return response.data;
        }
        
    } catch(error) {

        if (error?.response?.status === 404) {
            return [];
        }

        throw error
    }
}

export async function createTransfer({ data }) {
    try {
        const response = await axios.post(`/admin-transfers`, data);

        if (response.data) {
            return response.data;
        }
        
    } catch(error) {

        if (error?.response?.status === 404) {
            return [];
        }

        throw error
    }
}

export async function removeTransfer({ elementId }) {
    try {
        const response = await axios.delete(`/admin-transfers/${elementId}`);

        if (response.data) {
            return response.data;
        }
        
    } catch(error) {

        if (error?.response?.status === 404) {
            return [];
        }

        throw error
    }
}

export async function confirmTransfer({ elementId }) {
    try {
        const response = await axios.post(`/admin-transfers/${elementId}/confirm`, {});

        if (response.data) {
            return response.data;
        }
        
    } catch(error) {

        if (error?.response?.status === 404) {
            return [];
        }

        throw error
    }
}

export async function removeTransactionFromTransfer({ elementId, transactionId }) {
    try {
        const response = await axios.delete(`/admin-transfers/${elementId}/transactions/${transactionId}`);

        if (response.data) {
            return response.data;
        }
        
    } catch(error) {

        if (error?.response?.status === 404) {
            return [];
        }

        throw error
    }
}
