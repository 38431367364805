import { getProductImage } from '@beacharound/beacharound-ui-helpers'

export function getProductsOptions(productsTypes) {
    
    if (!Array.isArray(productsTypes)) { return [] }
    return productsTypes?.map(({ _id, name, type, category, color }) => {
        return {
            value: _id,
            label: name,
            image: getProductImage({ category, type, color })
        }
    }) || [];
}