import axios from "./_defaults/user";
import { uploadImage } from "./images";
import {returnError} from './_defaults/error'

export async function getPointsOfInterest({params}) {

    try {
        const response = await axios.get(`/admin-points-of-interest`, {params});
        if(response?.data) {
            return response?.data;
        }
    } catch(error) {
        returnError(error);
    }
}

export async function createPointsOfInterest({ data }) {
    try {
        const response = await axios.post(`/admin-points-of-interest`, data);
        return response?.data || {};
    } catch (error) {
        return error;
    }
}

export async function getPointOfInterest({ elementId }) {
    try {
        const response = await axios.get(`/admin-points-of-interest/${elementId}`);
        return response?.data;
    } catch (error) {
        return error;
    }
}

export async function updatePointOfInterest({ elementId, data }) {
    try {
        const response = await axios.post(`/admin-points-of-interest/${elementId}`, data);
        return response?.data;
    } catch (error) {
        return error;
    }
}

export async function removePointOfInterest({ elementId }) {
    try {
        const response = await axios.delete(`/admin-points-of-interest/${elementId}`);
        return response?.data;
    } catch (error) {
        return error;
    }
}

export async function getPutUrl({ elementId, file, isGallery, galleryIndex }) {
    const response = await axios.post(
        `/admin-points-of-interest/${elementId}/images`,
        {
            contentType: file.type,
            extension: file.name.split(".").pop(),
            isGallery,
            galleryIndex,
        }
    );

    if (response.data) {
        return response.data;
    }

    return response;
}

export async function updateImage({ elementId, file }) {
    try {
        const { url, key } = await getPutUrl({ elementId, file });

        await uploadImage({ url, file });

        const response = await updatePointOfInterest({
            elementId,
            data: {
                cover: {
                    url: key,
                },
            },
        });

        if (response.data) {
            return response.data;
        }

        return response;
    } catch (error) {
        throw error;
    }
}

export async function uploadGalleryImage({ elementId, file, index }) {
    try {
        const { url, key } = await getPutUrl({
            elementId,
            file,
            isGallery: true,
            galleryIndex: index,
        });

        await uploadImage({ url, file });

        const response = await updatePointOfInterest({
            elementId,
            data: {
                $addToSet: {
                    gallery: {
                        url: key,
                        index,
                    },
                },
            },
        });

        if (response.data) {
            return response.data;
        }

        return response;
    } catch (error) {
        throw error;
    }
}